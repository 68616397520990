export default [
  {
    open: true,
    field: 'TAPI_c_type',
    label: 'Classification',
    count: [{
      fieldValue: 'SERIES',
      operator: 'OR',
      checked: false,
      index: 0,
    }, {
      fieldValue: 'SEASON',
      operator: 'OR',
      checked: false,
      index: 1,
    }, {
      fieldValue: 'EPISODE',
      operator: 'OR',
      checked: false,
      index: 2,
    }, {
      fieldValue: 'MOVIE',
      operator: 'OR',
      checked: false,
      index: 3,
    }],
  },
  {
    open: false,
    field: 'TAPI_item_type',
    label: 'Item',
    count: [{
      fieldValue: 'MASTER',
      operator: 'OR',
      checked: false,
      index: 0,
    }, {
      fieldValue: 'Joyn',
      operator: 'OR',
      checked: false,
      index: 1,
    }],
  },
  {
    open: false,
    field: 'TAPI_c_tenant_id',
    label: 'Tenant',
    count: [],
  },
  {
    open: false,
    field: 'created',
    label: 'Created date',
    values: { start: null, end: null },
    type: 'date',
  },
  {
    open: false,
    field: 'ems_lastEditDate',
    label: 'Last edited',
    values: { start: null, end: null },
    type: 'date',
  },
  {
    open: false,
    field: 'master_status',
    label: 'Master status',
    count: [{
      fieldValue: 'lo_res_failed',
      operator: 'OR',
      checked: false,
      index: 0,
    }],
  },
  {
    open: false,
    field: 'latest_tapi_status',
    label: 'TAPI status',
    count: [],
  },
  {
    open: false,
    field: 'abr_status',
    label: 'ABR status',
    count: [],
  },
  {
    open: false,
    field: 'is_blocked',
    label: 'Blocking',
    count: [{
      fieldValue: 'true',
      operator: 'OR',
      checked: false,
      index: 0,
    }, {
      fieldValue: 'false',
      operator: 'OR',
      checked: false,
      index: 1,
    }],
  },
  {
    open: false,
    field: 'has_second_master_item',
    label: 'Second master item',
    count: [{
      fieldValue: 'true',
      operator: 'OR',
      checked: false,
      index: 0,
    }, {
      fieldValue: 'false',
      operator: 'OR',
      checked: false,
      index: 1,
    }],
  },
  {
    open: false,
    field: 'TAPI_c_priority_level',
    label: 'Priority',
    count: [{
      fieldValue: 'HIGHEST',
      operator: 'OR',
      checked: false,
      index: 0,
    }, {
      fieldValue: 'HIGH',
      operator: 'OR',
      checked: false,
      index: 1,
    }, {
      fieldValue: 'MEDIUM',
      operator: 'OR',
      checked: false,
      index: 2,
    }, {
      fieldValue: 'LOW',
      operator: 'OR',
      checked: false,
      index: 2,
    }, {
      fieldValue: 'LOWEST',
      operator: 'OR',
      checked: false,
      index: 2,
    }],
  },
  {
    open: false,
    field: 'transcoding_mode',
    label: 'Transcoding mode',
    count: [{
      fieldValue: 'standardPerTitle',
      operator: 'OR',
      checked: false,
      index: 0,
    }, {
      fieldValue: 'special480p',
      operator: 'OR',
      checked: false,
      index: 1,
    }, {
      fieldValue: 'fixedBitrateLadder',
      operator: 'OR',
      checked: false,
      index: 2,
    }, {
      fieldValue: 'videoExport',
      operator: 'OR',
      checked: false,
      index: 3,
    }],
  },
  {
    open: false,
    field: 'durationSeconds',
    label: 'Runtime',
    values: { start: null, end: null },
    type: 'range',
  },
  {
    open: false,
    field: 'originalVideoCodec',
    label: 'Video codec',
    count: [],
  },
  {
    open: false,
    field: 'originalAudioCodec',
    label: 'Audio codec',
    count: [],
  },
  {
    open: false,
    field: 'mimeType',
    label: 'MIME type',
    count: [],
  },
  {
    open: false,
    field: 'originalFormat',
    label: 'Original type',
    count: [],
  },
  {
    open: false,
    field: 'originalHeight',
    label: 'Video height',
    count: [],
  },
  {
    open: false,
    field: 'originalWidth',
    label: 'Video width',
    count: [],
  },
  {
    open: false,
    field: 'mediaType',
    label: 'Media type',
    count: [{
      fieldValue: 'video',
      operator: 'OR',
      checked: false,
      index: 0,
    }, {
      fieldValue: 'audio',
      operator: 'OR',
      checked: false,
      index: 0,
    }, {
      fieldValue: 'data',
      operator: 'OR',
      checked: false,
      index: 0,
    }],
  },
  {
    open: false,
    field: 'shapeTag',
    label: 'Shape tag',
    count: [],
  },
  {
    open: false,
    field: 'safe_to_delete',
    label: 'Delete marker',
    count: [{
      fieldValue: 'true',
      operator: 'OR',
      checked: false,
      index: 0,
    }],
  },
  {
    open: false,
    field: 'mxdm_delete_lock',
    label: 'Permanent marker',
    count: [{
      fieldValue: 'true',
      operator: 'OR',
      checked: false,
      index: 0,
    }],
  },
  {
    open: false,
    field: 'lo_res_failed',
    label: 'Lo-Res failed',
    count: [{
      fieldValue: 'true',
      operator: 'OR',
      checked: false,
      index: 0,
    }],
  },
]
