import axios from 'axios'
import SearchAsset from '../vs-entities/searchAsset'

export default {
  getItems: (first, number, fields) => {
    const data = {
      first,
      number,
      content: 'metadata',
      field: fields.join(','),
      filters: [],
      master: true,
    }

    return axios.put('/api/newItems', data).then(({ data: { hits = 0, item = [] } }) => ({
      items: item.map(i => new SearchAsset(i)),
      hits,
    }))
  },
  getItem: (id) => {
    const data = {
      content: 'metadata,shape,uri',
    }

    return axios.put(`/api/newItem/${ id }?content=${ data.content }`)
      .then(({ data: item }) => ({ item: new SearchAsset(item) }))
  },
  getItemPath: ({ itemId, path }) => axios.put(`/api/newItem/${ itemId }?path=${ path }`)
    .then(({ data: item }) => ({ item: new SearchAsset(item) })),
}
