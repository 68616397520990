import { parseMetadata } from './asset'

export default class DetailedCollection {
  constructor(collection) {
    try {
      const { metadata } = collection
      const keylist = {
        collectionId: 'id',
        TAPI_c_video_id: 'videoId',
        title: 'collectionName',
        collection_title: 'collectionName',
        created: 'created',
        user: 'user',
        master_status: 'masterStatus',
        latest_tapi_status: 'tapiStatus',
        abr_status: 'abrStatus',
        sp_item: 'spItem',
        TAPI_c_type: 'type',
        TAPI_c_tenant_id: 'tenantId',
        TAPI_c_priority_level: 'priority',
        TAPI_c_7tv_id: 'externalId',
        TAPI_c_suported_partner: 'partner',
        __ancestor_collection: 'asd',
        __parent_collection: 'parent',
        ems_lastEditUser: 'lastEditUser',
        ems_lastEditDate: 'lastEditDate',
        rvs_rejection_reason: 'rejectionReason',
        lo_res_failed: 'loResFailed',
        is_blocked: 'isBlocked',
        has_second_master_item: 'hasSecondMasterItem',
        mxdm_delete_lock: 'permanent',
      }
      this.metadata = parseMetadata(metadata, {
        joinValue: ',',
        flat: true,
        multipleGroups: true,
        includeGroupAttributes: true,
        keylist,
      })
      this.type = 'Collection'
      this.thumbnail = '/folder.svg'

      if (this.metadata.TAPI) {
        this.metadata.TAPI = JSON.parse(this.metadata.TAPI)
      }
      const { masterStatus = '', tapiStatus = '', abrStatus = '' } = this.metadata
      const aggregatedStatus = [masterStatus, tapiStatus, abrStatus].join(',').toLowerCase()
      if (aggregatedStatus.includes('reject')) {
        this.metadata.status = 'REJECTED'
      } else if (aggregatedStatus.includes('error')) {
        this.metadata.status = 'ERROR'
      } else {
        this.metadata.status = 'OK'
      }
    } catch (err) {
      /* eslint-disable */
      console.error(err);
      /* eslint-enable */
    }
  }
}
