const filters = [
  {
    count: [
      { checked: false, operator: 'OR', fieldValue: 'ABR_INGEST' },
      { checked: false, operator: 'OR', fieldValue: 'ABR_PLAYOUT_UPLOAD' },
      { checked: false, operator: 'OR', fieldValue: 'AUTO_IMPORT' },
      { checked: false, operator: 'OR', fieldValue: 'COPY_FILE' },
      { checked: false, operator: 'OR', fieldValue: 'DELETE_FILE' },
      { checked: false, operator: 'OR', fieldValue: 'DELETE_USER' },
      { checked: false, operator: 'OR', fieldValue: 'EXPORT' },
      { checked: false, operator: 'OR', fieldValue: 'INGEST_V2' },
      { checked: false, operator: 'OR', fieldValue: 'MAINTENANCE_DEV_OPS' },
      { checked: false, operator: 'OR', fieldValue: 'PLACEHOLDER_IMPORT' },
      { checked: false, operator: 'OR', fieldValue: 'TOTAL_MASTER_DURATION' },
      { checked: false, operator: 'OR', fieldValue: 'TRANSCODE' },
      { checked: false, operator: 'OR', fieldValue: 'IMPORT' },
      { checked: false, operator: 'OR', fieldValue: 'RAW_IMPORT' },
      { checked: false, operator: 'OR', fieldValue: 'SHAPE_IMPORT' },
      { checked: false, operator: 'OR', fieldValue: 'SIDECAR_IMPORT' },
      { checked: false, operator: 'OR', fieldValue: 'ESSENCE_VERSION' },
      { checked: false, operator: 'OR', fieldValue: 'TRANSCODE_RANGE' },
      { checked: false, operator: 'OR', fieldValue: 'CONFORM' },
      { checked: false, operator: 'OR', fieldValue: 'TIMELINE' },
      { checked: false, operator: 'OR', fieldValue: 'THUMBNAIL' },
      { checked: false, operator: 'OR', fieldValue: 'ANALYZE' },
      { checked: false, operator: 'OR', fieldValue: 'SHAPE_UPDATE' },
      { checked: false, operator: 'OR', fieldValue: 'RAW_TRANSCODE' },
      { checked: false, operator: 'OR', fieldValue: 'MOVE_FILE' },
      { checked: false, operator: 'OR', fieldValue: 'LIST_ITEMS' },
      { checked: false, operator: 'OR', fieldValue: 'FILE_ANALYZE' },
      { checked: false, operator: 'OR', fieldValue: 'IMF_ANALYZE' },
      { checked: false, operator: 'OR', fieldValue: 'CUSTOM_ANALYZE' },
    ].sort((a, b) => a.fieldValue.localeCompare(b.fieldValue)),
    field: 'type',
    label: 'Job type',
    open: false,
  },
  {
    count: [
      { checked: false, operator: 'OR', fieldValue: 'ABORTED' },
      { checked: false, operator: 'OR', fieldValue: 'ABORTED_PENDING' },
      { checked: false, operator: 'OR', fieldValue: 'FAILED_TOTAL' },
      { checked: false, operator: 'OR', fieldValue: 'FINISHED' },
      { checked: false, operator: 'OR', fieldValue: 'FINISHED_WARNING' },
      { checked: false, operator: 'OR', fieldValue: 'READY' },
      { checked: false, operator: 'OR', fieldValue: 'STARTED' },
      { checked: false, operator: 'OR', fieldValue: 'VIDINET_JOB' },
      { checked: false, operator: 'OR', fieldValue: 'WAITING' },
    ].sort((a, b) => a.fieldValue.localeCompare(b.fieldValue)),
    field: 'state',
    label: 'Status',
    open: true,
  },
  {
    values: { start: null, end: null },
    field: 'starttime',
    label: 'Started',
    open: false,
    type: 'date',
  },
  {
    values: { start: null, end: null },
    field: 'finishtime',
    label: 'Finished',
    open: false,
    type: 'date',
  },
]

export default filters
