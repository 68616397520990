<template>
  <section class="main-navigation">
    <header class="main-navigation__header">
      <router-link
        :to="{name: 'default'}"
        class="nav-head">
        {{ !breakpointActive ? 'Essence Management System' : 'EMS' }}
      </router-link>
      <slot name="subTitle">
        <pre class="env-info">{{ environmentInfo }}</pre>
      </slot>
    </header>
    <nav class="main-navigation__routes">
      <div
        v-if="breakpointActive"
        :class="{'open': open}"
        class="main-navigation__menu"
        @mouseleave="open = false"
      >
        <Hamburger @click.native="toggle"/>
        <transition name="menu">
          <div
            v-if="open"
            class="dropdown">
            <ul>
              <router-link
                v-for="entity in entities"
                :key="entity.name"
                :to="{name: entity.route}">
                <li>
                  {{ entity.name }}
                </li>
              </router-link>
              <router-link
                v-for="entity in adminEntities"
                :key="entity.name"
                :to="{name: entity.route}">
                <li>
                  {{ entity.name }}
                </li>
              </router-link>
            </ul>
          </div>
        </transition>
      </div>
      <ul
        v-else
        class="main-navigation__inline">
        <li>
          <router-link :to="{name: entities[0].route}">{{ entities[0].name }}</router-link>
        </li>
        <li>
          <router-link :to="{name: entities[1].route}">{{ entities[1].name }}</router-link>
        </li>
        <li
          @mouseenter="admin = true"
          @mouseleave="admin = false">
          <router-link :to="{name: entities[2].route}">{{ entities[2].name }} &dtrif;</router-link>
          <transition name="menu">
            <div
              v-if="admin"
              class="dropdown"
              @blur="admin = false">
              <ul>
                <router-link
                  v-for="entity in adminEntities"
                  :key="entity.name"
                  :to="{name: entity.route}">
                  <li>
                    {{ entity.name }}
                  </li>
                </router-link>
              </ul>
            </div>
          </transition>
        </li>
      </ul>
    </nav>
  </section>
</template>

<script>
import axios from 'axios'
import Hamburger from 'vue-material-design-icons/Menu.vue'
// eslint-disable-next-line import/no-extraneous-dependencies

export default {
  name: 'Navigation',
  components: {
    Hamburger,
  },
  props: {
    entity: {
      type: String,
      required: false,
      default: 'Essence Management System',
    },
  },
  data() {
    return {
      envInfo: null,
      windowWidth: '',
      open: false,
      admin: false,
      entities: [
        {
          name: 'Masterfiles',
          route: 'Items',
        },
        {
          name: 'Collections',
          route: 'Collections',
        },
        {
          name: 'Admin',
          route: 'Admin',
        },
        {
          name: 'Logout',
          route: 'Logout',
        },
      ],
      adminEntities: [
        {
          name: 'Overview',
          route: 'Overview',
        },
        {
          name: 'Jobs',
          route: 'Jobs',
        },
        {
          name: 'Users',
          route: 'Users',
        },
        {
          name: 'Groups',
          route: 'Groups',
        },
        {
          name: 'Storages',
          route: 'Storages',
        },
      ],
    }
  },
  computed: {
    breakpointActive() {
      return this.windowWidth <= 800
    },
    environmentInfo() {
      if (this.envInfo !== null && this.envInfo.FrontEndEnvFlag !== null) {
        return `${this.envInfo.FrontEndEnvFlag.toUpperCase()} - ${this.envInfo.build_tag}`
        // return `${this.envInfo.FrontEndEnvFlag.toUpperCase()} - 7b3d3a2`;
      }
      return ''
    },
  },
  mounted() {
    this.windowWidth = window.innerWidth
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
    this.getEnvInfo()
  },
  methods: {
    getEnvInfo() {
      axios.get('/api/system/frontend-env-flag').then((response) => {
        this.envInfo = response.data
      })
    },
    toggle() {
      this.open = !this.open
    },
  },
}

</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";
.main-navigation {
  display: grid;
  grid-template-columns: auto 1fr;
  height: 5rem;
  position: relative;
  .main-navigation__header {
    display: grid;
    grid-template-rows: 3rem 2rem;
    height: 5rem;
    .nav-head {
      font-size: 2.3rem;
      font-weight: 500;
      display: flex;
      align-items: baseline;
      color: $contrast-color;
      &:hover {
        text-decoration: none;
      }
    }
    pre {
      margin: 0;
    }
  }
  .main-navigation__inline {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding: 0 0 0 5rem;
    border-left: 1px solid rgba(#000, .1);
    margin: 0 0 0 5rem;
    @media (max-width: 1500px) {
      justify-content: flex-end;
      border-left: 0;
      margin-left: 0;
    }
    li {
      display: block;
      position: relative;
      &:not(:first-child) {
        margin-left: 5rem;
      }
      > a {
        font-size: 1.5rem;
        color: $item-color;
        font-weight: 500;
        position: relative;
        text-transform: uppercase;
        &:after {
          content: ' ';
          background: $contrast-color;
          height: 2px;
          width: 100%;
          position: absolute;
          top: 100%;
          left: 0;
          transform: scaleX(0);
          transition: all .2s;
        }
        &:hover:after, &.router-link-active:after {
          transform: scaleX(1);
        }
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  .main-navigation__menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    padding: 1rem 0;
    position: relative;
    cursor: pointer;
    span {
      font-size: 3em;
    }
    &.open {
      .menu-icon {
        background-color: rgba($contrast-color, .1);
        border-radius: .25rem;
      }
    }
    .menu-icon:hover {
      background-color: rgba($contrast-color, .1);
      border-radius: .25rem;
    }
  }
  .main-navigation__routes {
    .dropdown {
      z-index: 1000;
      background: #fff;
      box-shadow: 0 0 .25rem rgba(#000, .5);
      position: absolute;
      top: 100%;
      transform-origin: top;
      right: 0;
      ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 0;
        margin: 0;
        a {
          padding: 1rem 2rem;
          font-size: 1.5rem;
          color: $contrast-color;
          font-weight: 500;
          text-transform: uppercase;
          &:hover {
            text-decoration: none;
            background-color: rgba(#000, .1);
          }
          &.router-link-active {
            font-weight: 900;
            background-color: $contrast-color;
            color: #fff;
          }
        }
      }
      &.menu-enter-active, &.menu-leave-active {
        transition: all .2s;
        transition-delay: .2s;
      }
      &.menu-enter, &.menu-leave-to {
        transform: scaleY(0);
        opacity: 0;
      }
    }
  }
}
</style>
