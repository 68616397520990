/* eslint-disable */
import axios from 'axios'
import store from '../store'
import { debounce as _debounce } from 'lodash-es'

let timeout = null

// intercept the global error
const interceptor = axios.interceptors.response.use(
  response => {
    refreshToken()
    return response
  },
  (error) => {
    let errorMessage = ''
    let errorVariant = 'info'
    switch (error.response.status) {
      case 400:
        errorMessage = error.response.data.message !== undefined ? getErrorMessage(error.response) : `An internal server error has occurred, please contact admin if the error persists. ${ error.response.data }`
        break
      case 401:
        store.dispatch('setAuthenticated', { authenticated: false })
        return Promise.reject(error)
      case 403:
        errorVariant = 'danger'
        const {
          response: {
            data = 'An unexpected error has occurred, please contact admin if the error persists.',
            status = 403,
            statusText = 'Unauthorized'
          }
        } = error
        if (typeof data === 'object') {
          const { url = '', message = '' } = data
          const obj = JSON.parse(message)
          console.error(`${ status } ${ statusText }: ${ url }`)
          errorMessage = obj.message
        } else if (typeof data === 'string') {
          errorMessage = `${ status } ${ statusText }`
        } else {
          errorMessage = error.response.data.message !== undefined ? getErrorMessage(error.response) : `The resource belongs to another user. Operation aborted. ${error.response.data}`
        }
        break
      case 404:
        errorMessage = error.response.data.message !== undefined ? getErrorMessage(error.response) : `The resource you’re trying to access does not exist. ${error.response.data}`
        break
      case 409:
        errorMessage = error.response.data.message !== undefined ? getErrorMessage(error.response) : `The resource could not be deleted, since it’s currently in use. ${error.response.data}`
        break
      case 500:
        errorMessage = error.response.data.message !== undefined ? getErrorMessage(error.response) : `An internal server error has occurred, please contact admin if the error persists. ${error.response.data}`
        break
      default:
        errorMessage = error.response.data.message !== undefined ? getErrorMessage(error.response) : `An unexpected error has occurred, please contact admin if the error persists. ${error.response.data}`
    }

    store.dispatch('setAlert', { showAlert: 20, messageAlert: errorMessage, messageVariant: errorVariant })
    refreshToken()
    return Promise.reject(error)
  })

function getErrorMessage(data) {
  return `There is an error in ${data.request.responseURL}. Error Status is "${data.status}". Error text is "${data.statusText }"`
}

const refreshToken = _debounce(function () {
  clearTimeout(timeout)
  // sets timeout to 12 hours, forces logout and redirect if not refreshed
  timeout = setTimeout(() => store.dispatch('system/logOut'), 43200000)
}, 1000)

export default { interceptor }
