<template>
  <div class="ems-login__wrapper">
    <component
      :is="darkMode ? 'LightbulbOn' : 'Lightbulb'"
      @click="$store.commit('preferences/SET_DARK_MODE', !darkMode)"/>
    <form class="ems-login">
      <div class="ems-login__welcome">
        <User title="" />
        <span>Essence Management System</span>
        <span>Please log in to continue</span>
      </div>
      <div class="ems-login__block">
        <label for="ems-username">
          Username
        </label>
        <input
          id="ems-username"
          ref="username"
          name="ems-username"
          type="text"
          placeholder="Username"
        >
      </div>
      <div class="ems-login__block">
        <label for="ems-password">
          Password
        </label>
        <input
          id="ems-password"
          ref="password"
          name="ems-password"
          type="password"
          placeholder="********"
        >
      </div>
      <div class="ems-login__error">
        <span v-if="errorMessage">
          {{ errorMessage }}
        </span>
      </div>
      <div class="ems-login__block">
        <button @click="login">
          Log in
          <span
            v-if="spinner"
            class="ems-login__spinner" />
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import User from 'vue-material-design-icons/AccountCircle.vue'
import Lightbulb from 'vue-material-design-icons/Lightbulb.vue'
import LightbulbOn from 'vue-material-design-icons/LightbulbOn.vue'

export default {
  name: 'EmsLogin',
  components: {
    User,
    Lightbulb,
    LightbulbOn,
  },
  data() {
    return {
      refreshToken: true,
      authenticated: false,
      errorMessage: null,
      spinner: false,
    }
  },
  computed: {
    ...mapGetters('preferences', {
      darkMode: 'DARK_MODE',
    }),
  },
  methods: {
    login(evt) {
      this.spinner = true
      evt.preventDefault()
      const { username: { value: username }, password: { value: password } } = this.$refs
      axios.put('/api/version', { username, password }).then(({ status }) => {
        const authenticated = status === 200
        this.$store.dispatch('getUser')
        return this.$store.dispatch('setAuthenticated', { authenticated })
      }).catch(({ response }) => {
        const { data: { content } } = response
        this.errorMessage = content
        this.spinner = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.ems-login__wrapper {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--overlay-dark);
  > span {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: var(--font-normal);
    font-size: 2rem;
    cursor: pointer;
  }
  .ems-login {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 40rem;
    background-color: var(--overlay-dark);
    border-radius: 4px;
    > div.ems-login__welcome {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: var(--font-normal);
      padding: 2rem 1rem;
      width: 100%;
      > span:first-child {
        margin-bottom: 2rem;
        font-size: 10rem;
      }
      > span {
        font-size: 2rem;
      }
      > span:last-child {
        font-size: 1.6rem;
        opacity: .7;
      }
    }
    > div.ems-login__block {
      position: relative;
      display: flex;
      width: 100%;
      flex-direction: column;
      color: var(--font-dark);
      > input {
        color: inherit;
        font-size: 1.6rem;
        border: none;
        height: 5rem;
        line-height: 3rem;
        padding: 0 1rem;
        outline-color:  var(--accent-color-2);
        border-radius: 4px;
        background-color: var(--overlay-light);
      }
      > button {
        position: relative;
        color: var(--color-on-accent);
        font-size: 1.6rem;
        border: none;
        height: 5rem;
        line-height: 5rem;
        padding: 0 1rem;
        border-radius: 5px;
        background-color: var(--accent-color-1);
        text-transform: uppercase;
        font-weight: 900;
        cursor: pointer;
        transition: background-color .3s;
        &:hover {
          background-color: var(--accent-color-2);
        }
        .ems-login__spinner {
          width: 5rem;
          height: 5rem;
          position: absolute;
          top: 0;
          right: 0;
          &::before {
            content: '';
            position: absolute;
            top: calc(50% - 1rem);
            left: calc(50% - 1rem);
            width: 2rem;
            height: 2rem;
            border-width: 3px;
            border-style: solid;
            border-color: rgba(#eee, .3);
            border-radius: 2rem;
          }
          &::after {
            content: '';
            position: absolute;
            top: calc(50% - 1.5rem);
            left: calc(50% - 1.5rem);
            width: 3rem;
            height: 3rem;
            border-width: 3px;
            border-style: solid;
            border-color: #eee transparent transparent transparent;
            border-radius: 3rem;
            animation: spin .6s linear infinite;
          }
        }
      }
      > label {
        font-size: 1.3rem;
        padding: .5rem 1rem;
        margin-bottom: 0;
      }
    }
    > div.ems-login__error {
      position: relative;
      height: 2rem;
      > span {
        line-height: 2rem;
        font-size: 1.3rem;
        color: var(--accent-color-4);
      }
    }
    > div:not(:first-child) {
      margin-top: 1rem;
    }
    @media (max-width: 500px) {
      width: 30rem;
    }
  }
}
</style>
