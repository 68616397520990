import { head as _head, last as _last } from 'lodash-es'
import { DateTime } from 'luxon'

export default class DetailedJob {
  constructor(job) {
    Object.entries(job).forEach(([key, value]) => {
      if (typeof job[key] === 'object') { return }
      this[key] = value
    })
    const {
      data = [],
      log = {},
      status = '',
      started: created,
      currentStep = {},
      jobId,
    } = job
    this.log = log
    this.data = data
    this.metadata = {}
    this.created = created
    for (let i = 0; i < data.length; i += 1) {
      const { key, value } = data[i]
      this.metadata[key] = value
    }
    this.metadata.id = jobId

    function getTimestamp(task) {
      // get timestamp with 5 minute offset
      const { timestamp } = task
      return DateTime.fromISO(timestamp).minus(300000)
    }

    const inProgress = ['WAITING', 'VIDINET_JOB', 'STARTED'].indexOf(status) > -1
    const isFinished = ['ABORTED', 'ABORTED_PENDING', 'FAILED_TOTAL', 'FINISHED', 'FINISHED_WARNING'].indexOf(status) > -1
    const isFailed = ['ABORTED', 'ABORTED_PENDING', 'FAILED_TOTAL'].indexOf(status) > -1
    const { task = [] } = log
    if (task.length) {
      if (inProgress) {
        // get first task from job log
        const firstTask = _head(task)
        const start = getTimestamp(firstTask)
        const jobCreated = DateTime.fromISO(created)
        const waited = start.diff(jobCreated)
        this.start = start.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
        this.waited = waited.toFormat('hh:mm:ss')
        this.end = '-'
        this.duration = '-'
        // fallback to regular status if current step is not available
        const { description = this.status } = currentStep
        this.status = description
      } else if (isFinished) {
        const [firstTask = {}, lastTask = {}] = [_head(task), _last(task)]
        const start = getTimestamp(firstTask)
        const end = getTimestamp(lastTask)
        const jobCreated = DateTime.fromISO(created)
        const waited = start.diff(jobCreated)
        const duration = end.diff(start)
        this.start = start.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
        this.end = end.toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
        this.waited = waited.toFormat('hh:mm:ss:SSS')
        this.duration = duration.toFormat('hh:mm:ss:SSS')
      }
      if (isFailed) {
        let errorMessage
        // get next to last task since thats most likely the one that failed
        if (task.length >= 2) { ({ errorMessage = '' } = task[task.length - 2]) } else {
          ({ errorMessage = '' } = _last(task))
        }
        this.message = errorMessage
      } else {
        const { description = '' } = _last(task)
        this.message = description
      }
    } else {
      this.start = '-'
      this.end = '-'
      this.duration = '-'
      this.waited = '-'
    }
  }
}
