import axios from 'axios'
import SearchItem from '../vs-entities/searchItem'
import SearchLibrary from '../vs-entities/searchLibrary'

export default {
  getLibraries: (updateMode, first, number) => {
    const data = {
      updateMode,
      first,
      number,
    }

    return axios.put('/api/libraries', data)
      .then(response => [{
        libraries: response.data.uri !== undefined
          ? response.data.uri.map(library => new SearchLibrary(library)) : [],
        hits: response.data.hits,
      }][0])
  },

  getLibrary: (libraryId, first, number) => {
    const data = {
      // query,
      first,
      number,
      contentPath: '',
      fieldPath: 'itemId,title,created,user,originalFormat,originalFilename,mediaType,representativeThumbnailNoAuth',
    }

    return axios.put(`/api/library/${ libraryId }`, data)
      .then(response => [{
        items: response.data.item !== undefined
          ? response.data.item.map(item => new SearchItem(item)) : [],
        hits: response.data.hits,
      }][0])
  },
}
