import he from 'he'

const sortTimespanList = ({ start: firstStart }, { start: secondStart }) => {
  const [first] = firstStart.split('@')
  const [second] = secondStart.split('@')
  if (Number(first) < Number(second)) {
    return -1
  }
  if (Number(first) > Number(second)) {
    return 1
  }
  return 0
}

const parseValueList = (
  valueList = [],
  options = {}
) => {
  const {
    joinValue,
    includeAttributes,
    includeValueAttributes,
  } = options
  if (includeAttributes || includeValueAttributes) return valueList
  const valueArray = []
  valueList.forEach((thisValue) => {
    if (thisValue.value) valueArray.push(he.decode(thisValue.value))
  })
  if (joinValue) return valueArray.join(joinValue)
  return valueArray
}

const parseField = (field = {}, options = {}) => {
  const {
    includeAttributes,
    includeFieldAttributes,
  } = options
  const {
    value = [],
    ...attributes
  } = field
  const parsedValueList = parseValueList(value, options)
  if (includeAttributes || includeFieldAttributes) return { ...attributes, value: parsedValueList }
  return parsedValueList
}

const parseFieldList = (fieldList = [], options = {}) => {
  const {
    keylist = {},
    joinValue,
    includeAttributes,
    includeFieldAttributes,
    includeValueAttributes,
  } = options
  const output = {}
  fieldList.forEach((thisField) => {
    let { name: key } = thisField
    if (keylist[key]) { key = keylist[key] }
    const parsedField = parseField(thisField, options)
    if (output[key]) {
      if (includeAttributes || includeFieldAttributes) {
        const { value: currentValue } = output[key]
        const { value: parsedValue } = parsedField
        if (joinValue && !includeAttributes && !includeValueAttributes) {
          output[key].value = [currentValue, parsedValue].join(joinValue)
        } else {
          output[key].value = parsedValue.concat(currentValue)
        }
      } else {
        const currentValue = output[key]
        const parsedValue = parsedField
        if (joinValue) {
          output[key] = [currentValue, parsedValue].join(joinValue)
        } else {
          output[key] = parsedValue.concat(currentValue)
        }
      }
    } else {
      output[key] = parsedField
    }
  })
  return output
}

const parseGroup = (group = {}, options = {}) => {
  const {
    includeAttributes,
    includeGroupAttributes,
    flat,
    flatGroup,
  } = options
  const {
    field: fieldList = [],
    group: groupList = [],
    ...attributes
  } = group
  const parsedFieldList = parseFieldList(fieldList, options)
  let parsedGroupList
  if (groupList.length) {
    parsedGroupList = parseGroupList(groupList, options) // eslint-disable-line no-use-before-define,max-len
  }
  const output = {}
  if (includeAttributes || includeGroupAttributes) {
    Object.assign(output, attributes)
  }
  if (flat || flatGroup) {
    Object.assign(output, parsedFieldList)
    Object.assign(output, parsedGroupList)
  } else {
    Object.assign(output, { field: parsedFieldList })
    Object.assign(output, { group: parsedGroupList })
  }
  return output
}

const parseGroupList = (groupList = [], options = {}) => {
  const { keylist = {}, multipleGroups } = options
  const output = {}
  groupList.forEach((thisGroup) => {
    let { name: key } = thisGroup
    if (keylist[key]) { key = keylist[key] }
    const parsedGroup = parseGroup(thisGroup, options)
    if (!output[key]) {
      output[key] = parsedGroup
    } else if (multipleGroups) {
      output[key] = Array.isArray(output[key]) ?
        [...output[key], parsedGroup] : [output[key], parsedGroup]
    } else {
      const { field: currentField, group: currentGroup } = output[key]
      const { field: parsedField, group: parsedGroupList } = parsedGroup
      output[key].field = { ...currentField, ...parsedField }
      output[key].group = { ...currentGroup, ...parsedGroupList }
    }
  })
  return output
}

const parseTimespan = (timespan = {}, options = {}) => {
  const {
    includeAttributes,
    includeTimespanAttributes,
    flat,
    flatTimespan,
  } = options
  const {
    field: fieldList = [],
    group: groupList = [],
    ...attributes
  } = timespan
  const field = parseFieldList(fieldList, options)
  const group = parseGroupList(groupList, options)
  const output = {}
  if (includeAttributes || includeTimespanAttributes) {
    Object.assign(output, attributes)
  }
  if (flat || flatTimespan) {
    Object.assign(output, field)
    Object.assign(output, group)
  } else {
    Object.assign(output, { field })
    Object.assign(output, { group })
  }
  return output
}

const parseTimespanList = (timespanList = [], options = {}) => {
  const {
    joinTimespan,
    flat,
  } = options
  let output = {}
  timespanList.forEach((thisTimespan) => {
    const { start, end } = thisTimespan
    const key = [start, end].join(joinTimespan || '_')
    const parsedTimespan = parseTimespan(thisTimespan, options)
    if (flat) {
      output = { ...output, ...parsedTimespan }
    } else if (output[key]) {
      const { field: currentField, group: currentGroup } = output[key]
      const { field: parsedField, group: parsedGroup } = parsedTimespan
      output[key].field = { ...currentField, ...parsedField }
      output[key].group = { ...currentGroup, ...parsedGroup }
    } else {
      output[key] = parsedTimespan
    }
  })
  return output
}

/**
 * Parses MetadataType into key/value object.
 * The attributes can be targeted for each sub-type.
 * @param {Object} metadataType - The response from the API.
 * @param {Object} options - Options which change how the metadataType is parsed.
 * @param {Object} options.joinValue - String to join the values, eg ','.
 * @param {Object} options.includeAttributes - include attributes on all objects.
 * @param {Object} options.includeMetadataAttributes - include attributes on root.
 * @param {Object} options.includeTimespanAttributes - include attributes on timespans.
 * @param {Object} options.includeGroupAttributes - include attributes on groups.
 * @param {Object} options.includeFieldAttributes - include attributes on fields.
 * @param {Object} options.includeValueAttributes - include attributes on values.
 * @param {Object} options.flat - Flatten to key/value (Note: keys may be overwritten).
 * @param {Object} options.flatTimespan - Flatten timespan.
 * @param {Object} options.flatGroup - Flatten group.
 * @param {Object} options.sortTimespan - Sort timespan by start time.
 * @param {Object} options.multipleGroups - Turn groups with same name into arrays.
 */

const parseMetadata = (metadataType = {}, options = {}) => {
  const {
    includeAttributes,
    includeMetadataAttributes,
    sortTimespan,
  } = options
  const {
    timespan: timespanList = [],
    ...attributes
  } = metadataType
  if (sortTimespan) timespanList.sort(sortTimespanList)
  const timespan = parseTimespanList(timespanList, options)
  if (includeAttributes || includeMetadataAttributes) {
    Object.assign(timespan, attributes)
  }
  return timespan
}

export {
  parseTimespan,
  parseTimespanList,
  parseMetadata,
  sortTimespanList,
}
export default parseMetadata
