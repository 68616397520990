/* eslint-disable no-shadow, no-param-reassign */
import storageApi from '../../api/storage.api'

// initial state
const state = {
  storages: [],
  hits: 0,
  query: '',
  first: 1,
  number: 40,
  status: null, // success, failed, loading

  storage: {},
}

// getters
const getters = {
  storages: state => state.storages,
  hits: state => state.hits,
  status: state => state.status,
  query: state => state.query,
  first: state => state.first,
  number: state => state.number,
  storage: state => state.storage,
}

// actions
const actions = {
  getStorages({ commit }) {
    commit('SET_STATUS', 'loading')
    storageApi.getStorages(state.query, state.first, state.number)
      .then((response) => {
        const newStorages = response.storages
        // const newHits = response.hits;
        commit('ADD_STORAGES', newStorages)
        commit('SET_HITS', newStorages.length)
        commit('SET_STATUS', 'success')
      })
      .catch(() => {
        commit('SET_STATUS', 'failed')
        commit('SET_HITS', 0)
      })
  },
  createStorage({ commit }, payload) {
    commit('SET_STATUS', 'loading')
    return storageApi.createStorage(payload)
      .then((response) => {
        commit('UNSHIFT_STORAGE', response.storage)
        commit('SET_HITS', state.hits + 1)
        commit('SET_STATUS', 'success')
      })
      .catch(() => {
        commit('SET_STATUS', 'failed')
        commit('SET_HITS', 0)
      })
  },
}

// mutations
const mutations = {
  ADD_STORAGES(state, storages) {
    state.storages = storages
  },
  UNSHIFT_STORAGE(state, storage) {
    if (state.storages.length === state.number) {
      state.storages.pop()
    }
    state.storages.unshift(storage)
  },
  SET_HITS(state, hits) {
    state.hits = hits
  },
  SET_FIRST(state, first) {
    if (first) {
      state.first = first
    }
  },
  SET_NUMBER(state, number) {
    if (number) {
      state.number = number
    }
  },
  SET_STORAGE(state, storage) {
    state.storage = storage
  },
  SET_STATUS(state, status) {
    state.status = status
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
