import Vue from 'vue'
import Vuex from 'vuex'
import { Modal, Tooltip, Popover, Button, Progress, Dropdown, Alert } from 'bootstrap-vue/es/components'
import vBTooltip from 'bootstrap-vue/es/directives/tooltip/tooltip'
import vBPopover from 'bootstrap-vue/es/directives/popover/popover'
import store from './store'
import router from './router'
import App from './App.vue'
// eslint-disable-next-line
import interceptor from './resources/axios-interceptor';

Vue.directive('b-tooltip', vBTooltip)
Vue.directive('b-popover', vBPopover)
Vue.use(Tooltip)
Vue.use(Popover)
Vue.use(Modal)
Vue.use(Button)
Vue.use(Vuex)
Vue.use(Button)
Vue.use(Progress)
Vue.use(Dropdown)
Vue.use(Alert)
// eslint-disable-next-line no-new
new Vue({
  el: '#app',
  store,
  router,
  interceptor,
  render: h => h(App),
})
