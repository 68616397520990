import he from 'he'

export default class Item {
  static getTimespan(metadata, start, end) {
    let i = 0
    for (i; i < metadata.timespan.length; i += 1) {
      if (metadata.timespan[i].start === start && metadata.timespan[i].end === end) {
        break
      }
    }
    return metadata.timespan[i]
  }

  static getAllTimespans(metadata, excludeStart, excludeEnd) {
    let timespans = []
    if (excludeStart || excludeEnd) {
      for (let i = 0; i < metadata.timespan.length; i += 1) {
        if (metadata.timespan[i].start !== excludeStart &&
            metadata.timespan[i].end !== excludeEnd) {
          timespans.push(metadata.timespan[i])
        }
      }
    } else {
      ({ timespans } = metadata)
    }

    return timespans
  }
  static getGroups({ timespan = [] }) {
    const groups = []
    for (let i = 0; i < timespan.length; i += 1) {
      const { group = [] } = timespan[i]
      for (let j = 0; j < group.length; j += 1) {
        groups.push(group[j])
      }
    }
    return groups
  }

  static getFields(timespan, keyList) {
    const metadata = {}
    for (let i = 0; i < timespan.field.length; i += 1) {
      if (keyList[timespan.field[i].name]) {
        metadata[keyList[timespan.field[i].name]] = he
          .decode(timespan.field[i].value[0].value)
      }
    }
    return metadata
  }

  setField(vsField, value) {
    this.metadata[this.keyList[vsField]] = value
  }
}
