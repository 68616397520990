/* eslint-disable no-shadow, no-param-reassign */
import userApi from '../../api/user.api'

// initial state
// shape: [{ id, quantity }]
const state = {
  users: [],
  hits: 0,
  query: '',
  first: 1,
  number: 100,
  status: null, // success, failed, loading

  user: null,
}

// getters
const getters = {
  users: state => state.users,
  hits: state => state.hits,
  status: state => state.status,
  query: state => state.query,
  first: state => state.first,
  number: state => state.number,
  user: state => state.user,
}

// actions
const actions = {
  getUser({ commit }, userName) {
    commit('SET_STATUS', 'loading')
    return userApi.getUser(userName)
      .then((user) => {
        commit('SET_USER', user)
        commit('SET_STATUS', 'success')
      })
      .catch(() => {
        commit('SET_STATUS', 'failed')
      })
  },
  getUsers({ commit }) {
    commit('SET_STATUS', 'loading')
    return userApi.getUsers(state.query, state.first, state.number)
      .then((response) => {
        const newUsers = response.users
        const newHits = response.hits
        commit('ADD_USERS', newUsers)
        commit('SET_HITS', newHits)
        commit('SET_STATUS', 'success')
      })
      .catch(() => {
        commit('SET_STATUS', 'failed')
        commit('SET_HITS', 0)
      })
  },
  createUser({ commit, dispatch }, payload) {
    commit('SET_STATUS', 'loading')
    return userApi.createUser(payload)
      .then(() => {
        dispatch('getUsers')
        commit('SET_STATUS', 'success')
      })
      .catch((err) => {
        commit('SET_STATUS', 'failed')
        return err
      })
  },
  deleteUser({ commit, dispatch }, data) {
    commit('SET_STATUS', 'loading')
    return userApi.deleteUser(data)
      .then(() => {
        dispatch('getUsers')
        commit('SET_STATUS', 'success')
      })
      .catch((err) => {
        commit('SET_STATUS', 'failed')
        return err
      })
  },
  addUsersToGroups({ commit }, payload) {
    commit('SET_STATUS', 'loading')
    return userApi.addUsersToGroups(payload)
      .then(() => {
        commit('SET_STATUS', 'success')
        return 'success'
      })
      .catch(() => {
        commit('SET_STATUS', 'failed')
        commit('SET_HITS', 0)
        return 'failed'
      })
  },
  deleteUserFromGroup({ commit }, payload) {
    commit('SET_STATUS', 'loading')
    return userApi.deleteUserFromGroup(payload)
      .then(() => {
        commit('SET_STATUS', 'success')
        return 'success'
      })
      .catch(() => {
        commit('SET_STATUS', 'failed')
        commit('SET_HITS', 0)
        return 'failed'
      })
  },
  addAssets({ commit }, {
    user, assets, permission,
  }) {
    commit('SET_STATUS', 'loading')
    return userApi.addAssets(user, assets, permission)
      .then(() => {
        commit('SET_STATUS', 'success')
      })
      .catch(() => commit('SET_STATUS', 'failed'))
  },
  getUsersAutocomplete({ commit }, { query }) {
    commit('SET_STATUS', 'loading')
    return userApi.getUsersAutocomplete(query)
      .then((response) => {
        commit('SET_STATUS', 'success')
        return response.autocomplete.userNames
      })
      .catch(() => {
        commit('SET_STATUS', 'failed')
      })
  },
}

// mutations
const mutations = {
  ADD_USERS(state, users) {
    state.users = users
  },
  SET_HITS(state, hits) {
    state.hits = hits
  },
  SET_QUERY(state, query) {
    state.query = query
  },
  SET_FIRST(state, first) {
    if (first) {
      state.first = first
    }
  },
  SET_NUMBER(state, number) {
    if (number) {
      state.number = number
    }
  },
  SET_USER(state, user) {
    state.user = user
  },
  SET_STATUS(state, status) {
    state.status = status
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
