/* eslint-disable max-len */
import { parseMetadata } from './asset'
import { parseShape } from './shape'
import lodash from 'lodash-es'

export default class SearchAsset {
  constructor(entry, options) {
    const { collection, item } = entry
    let asset
    if (item) {
      asset = { ...item, type: 'Item' }
    } else if (collection) {
      asset = { ...collection, type: 'Collection' }
    } else {
      asset = { ...entry, type: 'Item' }
    }
    try {
      const { metadata, type, shape = [] } = asset
      this.type = type

      const keylist = {
        itemId: 'id',
        collectionId: 'id',
        title: 'title',
        collection_title: 'collectionTitle',
        originalFilename: 'name',
        created: 'created',
        originalVideoCodec: 'format',
        mediaType: 'mediaType',
        user: 'user',
        __user: 'user',
        __collection: 'collectionId',
        master_status: 'masterStatus',
        master_item: 'master',
        latest_tapi_status: 'tapiStatus',
        abr_status: 'abrStatus',
        sp_item: 'spItem',
        sp_status: 'spStatus',
        TAPI_c_type: 'type',
        TAPI_item_type: 'type',
        TAPI_c_video_id: 'videoId',
        TAPI_c_tenant_id: 'tenantId',
        TAPI_c_priority_level: 'priority',
        TAPI_c_7tv_id: 'externalId',
        durationSeconds: 'duration',
        org_shape_total_size_bytes: 'size',
        ems_lastEditDate: 'lastEditDate',
        ems_lastEditUser: 'lastEditUser',
        number_idx: 'number',
        tapi_group: 'tapiGroup',
        tapi_status: 'tapiStatus',
        tapi_job_id: 'tapiJobId',
        lo_res_failed: 'loResFailed',
        rvs_rejection_reason: 'rejectionReason',
        tapi_error_reason: 'tapiErrorReason',
        tapi_file_list: 'tapiFileList',
        tapi_priority: 'tapiPriority',
        tapi_transcoding_mode: 'tapiTranscodingMode',
        tapi_supported_partner: 'supportedPartner',
        mxdm_delete_lock: 'permanent',
      }
      this.metadata = parseMetadata(metadata, {
        joinValue: ',',
        flat: true,
        multipleGroups: true,
        includeGroupAttributes: true,
        keylist,
        ...options,
      })

      if (this.metadata.collection_comment) {
        const commentField = asset.metadata.timespan[0].field
          .filter(field => field.name === 'collection_comment')
          .pop()
        this.metadata.comment = {
          value: this.metadata.collection_comment,
          updatedBy: commentField.user,
          updatedDate: commentField.timestamp,
        }
      }

      // Format dates
      const { lastEditDate, lastEditUser } = this.metadata
      if (lastEditDate) {
        if (lastEditUser) this.metadata.lastEditCombined = `${ lastEditUser } ${ lastEditDate }`
      }
      // Get thumbnail if it exists
      const { representativeThumbnailNoAuth: thumb } = this.metadata
      if (thumb) {
        const end = thumb.slice(11, thumb.length)
        this.thumbnail = `/apinoauth/${ end }`
      } else {
        this.thumbnail = null
      }

      const {
        type: entity, number, title, collectionTitle = '-',
      } = this.metadata

      if (['EPISODE', 'SEASON'].indexOf(this.metadata.type) > -1) {
        if (number && title) {
          this.metadata.collectionName = `${ entity } ${ number }: ${ title }`
        } else {
          this.metadata.collectionName = title || collectionTitle
        }
      } else {
        this.metadata.collectionName = title || collectionTitle
      }
      if (shape.length) {
        this.shapes = shape.map(s => parseShape(s, {}))
      }

      const {
        masterStatus = '', tapiStatus = '', abrStatus = '', loResFailed, spStatus = '',
      } = this.metadata
      const aggregatedStatus = [masterStatus, tapiStatus, abrStatus].join(',').toLowerCase()
      if (this.metadata.supportedPartner) {
        // this is for backward compatibility
        if (this.metadata.supportedPartner.toLowerCase() === 'joyn' && !spStatus) {
          this.metadata.status = lodash.capitalize(abrStatus) ? lodash.capitalize(abrStatus) : '-'
          return
        }
        this.metadata.status = lodash.capitalize(spStatus) ? lodash.capitalize(spStatus) : '-'
        return
      }
      if (aggregatedStatus.includes('reject')) {
        this.metadata.status = 'REJECTED'
        if (entity === 'ABR') this.metadata.status = 'ERROR'
      } else if (loResFailed === 'true') {
        this.metadata.status = 'LORES FAILED'
        if (entity === 'ABR') this.metadata.status = 'ERROR'
      } else if (aggregatedStatus.includes('error')) {
        this.metadata.status = 'ERROR'
      } else {
        this.metadata.status = 'OK'
      }
    } catch (err) {
      /* eslint-disable-next-line */
      console.error(err)
    }
  }
}
