export default {
  getActiveFilters: (filters) => {
    const appliedFilters = []
    function recursiveFunction(group) {
      function booleanOperator() {
        if (group.exclusive) {
          return 'NOT'
        }
        return 'OR'
      }
      if (group.options) {
        group.options.forEach((option) => {
          let existingGroup = false
          let existingGroupIndex
          if (option.value === true || Array.isArray(option.value)) {
            if (appliedFilters.length) {
              appliedFilters.forEach((appliedFilter, index) => {
                if (appliedFilter.field === group.vsField) {
                  existingGroup = true
                  existingGroupIndex = index
                }
              })
              if (existingGroup) {
                switch (group.type) {
                  case 'boolean':
                    appliedFilters[existingGroupIndex].values.push(option.name)
                    break
                  case 'range':
                    if (option.value[0] !== option.range[0]
                      || option.value[1] !== option.range[1]) {
                      appliedFilters[existingGroupIndex].values.push({
                        start: option.value[0],
                        end: option.value[1],
                      })
                    }
                    break
                  case 'date':
                    if (option.value[0] !== null && option.value[1] !== null) {
                      appliedFilters[existingGroupIndex].values.push({
                        start: option.value[0],
                        end: option.value[1],
                      })
                    }
                    break
                  default:
                    break
                }
              } else {
                switch (group.type) {
                  case 'boolean':
                    appliedFilters.push({
                      field: group.vsField,
                      operator: booleanOperator(),
                      values: [option.name],
                    })
                    break
                  case 'range':
                    if (option.value[0] !== option.range[0]
                      || option.value[1] !== option.range[1]) {
                      appliedFilters.push({
                        field: group.vsField,
                        operator: booleanOperator(),
                        range: true,
                        values: [{ start: option.value[0], end: option.value[1] }],
                      })
                    }
                    break
                  case 'date':
                    if (option.value[0] !== null && option.value[1] !== null) {
                      appliedFilters.push({
                        field: group.vsField,
                        operator: booleanOperator(),
                        range: true,
                        values: [{ start: option.value[0], end: option.value[1] }],
                      })
                    }
                    break
                  default:
                    break
                }
              }
            } else {
              switch (group.type) {
                case 'boolean':
                  appliedFilters.push({
                    field: group.vsField,
                    operator: booleanOperator(),
                    values: [option.name],
                  })
                  break
                case 'range':
                  if (option.value[0] !== option.range[0]
                    || option.value[1] !== option.range[1]) {
                    appliedFilters.push({
                      field: group.vsField,
                      operator: booleanOperator(),
                      range: true,
                      values: [{ start: option.value[0], end: option.value[1] }],
                    })
                  }
                  break
                case 'date':
                  if (option.value[0] !== null && option.value[1] !== null) {
                    appliedFilters.push({
                      field: group.vsField,
                      operator: booleanOperator(),
                      range: true,
                      values: [{ start: option.value[0], end: option.value[1] }],
                    })
                  }
                  break
                default:
                  break
              }
            }
          }
        })
      }
      if (group.groups) {
        group.groups.forEach((childGroup) => {
          recursiveFunction(childGroup)
        })
      }
    }
    filters.forEach((group) => {
      recursiveFunction(group)
    })
    return appliedFilters
  },
}
