/* eslint-disable no-shadow, no-param-reassign */
import Vue from 'vue'
import itemApi from '../../api/item.api'
import newItemApi from '../../api/newItem.api'
import filterHelper from '../../resources/filterHelper'
// import filtersArray from '../../resources/items/filters.array';


// initial state
// shape: [{ id, quantity }]
const state = {
  items: [],
  hits: 0,
  query: '',
  filters: [],
  activeFilters: [],
  facets: false,
  sort: null,
  order: null,
  first: 1,
  number: 40,
  status: null, // success, failed, loading

  item: {},
  rawItem: {},
}

const defaultFields = [
  'itemId',
  'TAPI_c_video_id',
  'created',
  'collection_title',
  'master_status',
  'latest_tapi_status',
  'abr_status',
  'TAPI_c_type',
  'TAPI_item_type',
  'TAPI_c_tenant_id',
  'TAPI_c_priority_level',
  'TAPI_c_7tv_id',
  '__ancestor_collection',
  '__collection',
  'rvs_rejection_reason',
  'lo_res_failed',
]

// getters
const getters = {
  items: state => state.items,
  hits: state => state.hits,
  status: state => state.status,
  query: state => state.query,
  filters: state => state.filters,
  activeFilters: state => state.activeFilters,
  facets: state => state.facets,
  sort: state => state.sort,
  order: state => state.order,
  first: state => state.first,
  number: state => state.number,
  item: state => state.item,
  rawItem: state => state.rawItem,
}

// actions
const actions = {
  getNewItem({ commit }, { itemId, preview = false, fields = defaultFields }) {
    commit('SET_ITEM_STATUS', 'loading')
    return newItemApi.getItem(itemId, fields)
      .then(({ item }) => {
        commit('SET_ITEM', item)
        if (preview) commit('assets/SET_ASSET', item, { root: true })
        commit('SET_ITEM_STATUS', 'success')
      })
      .catch(() => {
        commit('SET_ITEM_STATUS', 'failed')
      })
  },
  getItems({ commit }, { unshift, append }) {
    commit('SET_STATUS', 'loading')
    itemApi.getItems(
      state.query, state.activeFilters,
      state.sort, state.order, state.first, state.number
    )
      .then((response) => {
        const newItems = response.items
        const newHits = response.hits
        if (append) {
          commit('APPEND_ITEMS', newItems)
        } else if (unshift) {
          commit('UNSHIFT_ITEMS', newItems)
        } else {
          commit('ADD_ITEMS', newItems)
        }
        commit('SET_HITS', newHits)
        commit('SET_STATUS', 'success')
      })
      .catch(() => {
        commit('SET_STATUS', 'failed')
        commit('SET_HITS', 0)
      })
  },
  getNewItems({ commit }, { fields }) {
    commit('SET_STATUS', 'loading')
    newItemApi.getItems(state.first, state.number, fields)
      .then((response) => {
        const newItems = response.items
        const newHits = response.hits
        commit('ADD_ITEMS', newItems)
        commit('SET_HITS', newHits)
        commit('SET_STATUS', 'success')
      })
      .catch(() => {
        commit('ADD_ITEMS', [])
        commit('SET_STATUS', 'failed')
        commit('SET_HITS', 0)
      })
  },
  getCollectionItems({
    commit,
  }, {
    id, filters, sort, order, first, number, append,
  }) {
    commit('SET_STATUS', 'loading')
    commit('SET_FIRST', first)
    commit('SET_NUMBER', number)
    return itemApi.getCollectionItems(id, filters, sort, order, first, number)
      .then((response) => {
        const newItems = response.items
        const newHits = response.hits
        if (append) {
          commit('APPEND_ITEMS', newItems)
        } else {
          commit('ADD_ITEMS', newItems)
        }
        commit('SET_HITS', newHits)
        commit('SET_STATUS', 'success')
      })
      .catch(() => {
        commit('SET_STATUS', 'failed')
        commit('SET_HITS', 0)
      })
  },
  getItem({
    commit,
  }, payload) {
    commit('SET_STATUS', 'loading')
    return itemApi.getItem(payload.itemId, payload.oldItem)
      .then((response) => {
        const newItem = response.item
        const newRawItem = response.rawItem
        commit('SET_ITEM', newItem)
        commit('RAW_ITEM', newRawItem)
        commit('SET_STATUS', 'success')
        return 'success'
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err)
        commit('SET_STATUS', 'failed')
        return 'failed'
      })
  },
  setItemMetadataField({
    commit,
  }, {
    id, vsField, value,
  }) {
    commit('SET_ITEM_FIELD', {
      field: vsField,
      value,
    })

    // Customer specific: if the fieldName starts with 'TAPI_' reroute to specific url
    // to set metadata as JSON blob
    if (vsField.includes('TAPI_') && vsField !== 'TAPI_c_priority_level') {
      return itemApi.setTAPImetadataField(id, vsField, value)
        .then(() => {
          itemApi.getItem(id).then((response) => {
            commit('SET_ITEM', response.item)
            return 'success'
          })
        })
        .catch(() => 'failed')
    }
    return itemApi.setItemMetadataField(id, vsField, value)
      .then((response) => {
        const newItem = response.item
        commit('SET_ITEM', newItem)
        return 'success'
      })
      .catch(() => 'failed')
  },
  tagAudioChannel(
    { commit }
    , { itemId, payload }
  ) {
    commit('SET_STATUS', 'loading')

    return itemApi.tagAudioChannel(itemId, payload)
      .then(() => {
        itemApi.getItem(itemId).then((response) => {
          commit('SET_ITEM', response.item)
          commit('SET_STATUS', 'success')

          return 'success'
        })
        return 'success'
      })
  },
  untagAudioChannel(
    { commit },
    { itemId, payload }
  ) {
    commit('SET_STATUS', 'loading')

    return itemApi.deleteAudioChannel(itemId, payload)
      .then(() => {
        itemApi.getItem(itemId).then((response) => {
          commit('SET_ITEM', response.item)
          commit('SET_STATUS', 'success')

          return 'success'
        })
        return 'success'
      })
  },
  addTrack({ commit }, data) {
    commit('SET_STATUS', 'loading')
    const { id } = data

    return itemApi.addTrack(data)
      .then(() => itemApi.getItem(id, {}).then(({ item }) => {
        commit('SET_ITEM', item)
      }))
  },
  removeTrack({ commit }, data) {
    commit('SET_STATUS', 'loading')
    const { id, uuid } = data

    return itemApi.removeTrack(id, uuid)
      .then(() => itemApi.getItem(id, {}).then(({ item }) => {
        commit('SET_ITEM', item)
      }))
  },
  getItemsAutocomplete({
    commit,
  }, {
    query, fields,
  }) {
    commit('SET_STATUS', 'loading')
    return itemApi.getItemsAutocomplete(query, fields)
      .then((response) => {
        commit('SET_STATUS', 'success')
        return response
      })
      .catch(() => {
        commit('SET_STATUS', 'failed')
      })
  },
  getFacets({
    commit,
  }, {
    facets,
  }) {
    commit('SET_STATUS', 'loading')
    return itemApi.getFacets(facets)
      .then((response) => {
        commit('SET_STATUS', 'success')
        return response
      })
      .catch(() => {
        commit('SET_STATUS', 'failed')
      })
  },
}

// mutations
const mutations = {
  ADD_ITEMS(state, items) {
    state.items = items
  },
  APPEND_ITEMS(state, items) {
    state.items = [...state.items, ...items]
  },
  UNSHIFT_ITEMS(state, items) {
    state.items = [...items, ...state.items]
  },
  SET_HITS(state, hits) {
    state.hits = hits
  },
  SET_QUERY(state, query) {
    state.query = query
  },
  SET_FILTERS(state, filters) {
    Vue.set(state, 'filters', filters)
    state.activeFilters = filterHelper.getActiveFilters(filters)
  },
  SET_SORT(state, sort) {
    state.sort = sort
  },
  SET_ORDER(state, order) {
    state.order = order
  },
  SET_FIRST(state, first) {
    if (first) {
      state.first = first
      localStorage.setItem('itemsFirst', first)
    }
  },
  SET_NUMBER(state, number) {
    if (number) {
      state.number = number
    }
  },
  SET_ITEM(state, item) {
    state.item = item
  },
  SET_ITEM_STATUS(state, status) {
    state.itemStatus = status
  },
  SET_ITEM_FIELD(state, vsField) {
    state.item.setField(vsField.field, vsField.value)
  },
  RAW_ITEM(state, rawItem) {
    state.rawItem = rawItem
  },
  SET_STATUS(state, status) {
    state.status = status
  },
  SET_FACETS(state, facets) {
    state.facets = facets
  },
  UPDATE_ITEM(state, update) {
    const { key, value } = update
    state.item.metadata[key] = value
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
