/* eslint-disable no-shadow, no-param-reassign */
import fileApi from '../../api/file.api'

// initial state
const state = {
  files: [],
  fileHits: 0,
  filesStatus: null, // success, failed, loading
}

// getters
const getters = {
  files: state => state.files,
  fileHits: state => state.fileHits,
  filesStatus: state => state.files,
}

// actions
const actions = {
  getImportableFiles({ commit }, payload) {
    // payload.first, payload.number
    commit('SET_FILE_STATUS', 'loading')
    fileApi.getImportableFiles(payload.storageId)
      .then((response) => {
        const newFiles = response.files
        commit('ADD_FILES', newFiles)
        commit('SET_FILE_STATUS', 'success')
      })
      .catch(() => commit('SET_FILE_STATUS', 'failed'))
  },
}

// mutations
const mutations = {
  ADD_FILES(state, files) {
    state.files = files
  },

  SET_FILE_SELECTED(state, { index, selected }) {
    state.files[index].selected = selected
  },

  ADD_HITS(state, fileHits) {
    state.fileHits = fileHits
  },

  SET_FILE_STATUS(state, status) {
    state.filesStatus = status
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
