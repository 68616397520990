import axios from 'axios';
import { clone, cloneDeep, debounce, filter, find, findIndex, get, isEmpty, isEqual, last, merge, range, sortedIndex, throttle, uniqueId } from 'lodash-es';
import videoIcon from 'vue-material-design-icons/PlayCircleOutline.vue';
import audioIcon from 'vue-material-design-icons/MusicNote.vue';
import imageIcon from 'vue-material-design-icons/Image.vue';
import documentIcon from 'vue-material-design-icons/FileDocument.vue';
import pdfIcon from 'vue-material-design-icons/FilePdfBox.vue';
import subtitleIcon from 'vue-material-design-icons/Subtitles.vue';
import binaryIcon from 'vue-material-design-icons/SelectInverse.vue';
import unknownIcon from 'vue-material-design-icons/FileQuestion.vue';
import noneIcon from 'vue-material-design-icons/CodeBraces.vue';
import dataIcon from 'vue-material-design-icons/CloudPrintOutline.vue';
import collectionIcon from 'vue-material-design-icons/Folder.vue';
import { ResponsiveDirective } from 'vue-responsive-components';
import sortIcon from 'vue-material-design-icons/ArrowUpThick.vue';
import Checked from 'vue-material-design-icons/CheckCircle.vue';
import Unchecked from 'vue-material-design-icons/CheckboxBlankCircleOutline.vue';
import Vue from 'vue';
import ChevronRight from 'vue-material-design-icons/ChevronRight.vue';
import ToggleOn from 'vue-material-design-icons/ToggleSwitch.vue';
import ToggleOff from 'vue-material-design-icons/ToggleSwitchOff.vue';
import Checked$1 from 'vue-material-design-icons/CheckboxIntermediate.vue';
import Unchecked$1 from 'vue-material-design-icons/CheckboxBlankOutline.vue';
import vueSlider from 'vue-slider-component';
import { DateTime } from 'luxon';
import Pencil from 'vue-material-design-icons/Pencil.vue';
import Check from 'vue-material-design-icons/Check.vue';
import close from 'vue-material-design-icons/Close.vue';
import ArrowLeft from 'vue-material-design-icons/ArrowLeft.vue';
import ArrowRight from 'vue-material-design-icons/ArrowRight.vue';
import RestoreClock from 'vue-material-design-icons/RestoreClock.vue';
import Noop from 'vue-material-design-icons/SwapVertical.vue';
import DscIcon from 'vue-material-design-icons/ArrowDownThick.vue';
import MscIcon from 'vue-material-design-icons/Sort.vue';
import DotsHorizontal from 'vue-material-design-icons/DotsHorizontal.vue';
import SearchIcon from 'vue-material-design-icons/Magnify.vue';
import IncludeIcon from 'vue-material-design-icons/PlusCircleOutline.vue';
import ExcludeIcon from 'vue-material-design-icons/MinusCircleOutline.vue';
import videojs from 'video.js';
import CloseCircleIcon from 'vue-material-design-icons/CloseCircle.vue';
import CompletedIcon from 'vue-material-design-icons/CheckCircleOutline.vue';
import FailedIcon from 'vue-material-design-icons/CloseCircleOutline.vue';
import UploadIcon from 'vue-material-design-icons/Upload.vue';
import ChevronDown from 'vue-material-design-icons/ChevronDown.vue';
import ExpandAll from 'vue-material-design-icons/ArrowExpandHorizontal.vue';
import CollapseAll from 'vue-material-design-icons/ArrowCollapseHorizontal.vue';
import minus from 'vue-material-design-icons/MinusCircle.vue';
import plus from 'vue-material-design-icons/PlusCircle.vue';
import draggable from 'vuedraggable';

var BreadcrumbsItem = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('ul', {
      staticClass: "vdt-breadcrumbs-list"
    }, [_c('li', {
      staticClass: "vdt-breadcrumbs-list-item",
      class: {
        'active': _vm.open
      },
      on: {
        "mouseleave": function ($event) {
          _vm.open = false;
        }
      }
    }, [_c('span', {
      on: {
        "mouseenter": function ($event) {
          _vm.open = true;
        },
        "click": function ($event) {
          _vm.$emit('pushRoute', _vm.crumb.id);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.getCollectionTitle(_vm.crumb)) + " "), _vm.hasSiblings && !_vm.isRoot ? _c('span', [_vm._v(" ▾ ")]) : _vm._e()]), _vm._v(" "), _c('transition', {
      attrs: {
        "name": "fade"
      }
    }, [_vm.open ? _c('div', {
      staticClass: "breadcrumb-dropdown",
      on: {
        "blur": function ($event) {
          _vm.open = false;
        }
      }
    }, _vm._l(_vm.siblings, function (sibling) {
      return _c('span', {
        key: sibling.id,
        class: sibling.id === _vm.crumb.id ? 'crumbActive' : '',
        on: {
          "click": function ($event) {
            _vm.$emit('pushRoute', sibling.id);
          }
        }
      }, [_vm._v(" " + _vm._s(_vm.getCollectionTitle(sibling)) + " ")]);
    })) : _vm._e()])], 1), _vm._v(" "), !_vm.hasChildren && _vm.previewItem !== null ? _c('li', {
      staticClass: "vdt-breadcrumbs-list-item"
    }, [_c('span', {
      staticClass: "vdt-breadcrumbs-list-preview"
    }, [_vm._v(" " + _vm._s(_vm.previewItem) + " ")])]) : _vm._e(), _vm._v(" "), _vm.hasChildren ? _c('BreadcrumbsItem', {
      attrs: {
        "crumb": _vm.firstChild,
        "parent-id": _vm.crumb.id,
        "preview-item": _vm.previewItem,
        "get-children-url": _vm.getChildrenUrl
      },
      on: {
        "pushRoute": function ($event) {
          _vm.$emit('pushRoute', $event);
        }
      }
    }) : _vm._e()], 1);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-1b5ebbb3',
  name: 'BreadcrumbsItem',
  props: {
    /**
     * An object containing the current branch
     */
    crumb: {
      type: Object,
      required: true
    },

    /**
     * A string containing the ID of the parent branch,
     * used to retrieve siblings
     */
    parentId: {
      type: String,
      required: false,
      default: null
    },

    /**
     * Optional string that will be displayed to the right of the current collection,
     * you might want this if you are looking at a specific item within the current collection.
     */
    previewItem: {
      type: String,
      required: false,
      default: null
    },
    getChildrenUrl: {
      type: String,
      required: false,
      default: null
    }
  },

  data() {
    return {
      siblings: [],
      open: false
    };
  },

  computed: {
    firstChild() {
      if (this.crumb.children !== undefined && this.crumb.children.length > 0) {
        return this.crumb.children[0];
      }

      return null;
    },

    hasChildren() {
      return this.crumb.children !== undefined && this.crumb.children.length > 0;
    },

    hasSiblings() {
      return this.siblings.length > 1;
    },

    isRoot() {
      return this.parentId === null;
    }

  },

  mounted() {
    if (this.getChildrenUrl && this.parentId !== null) {
      this.getChildren(this.parentId);
    }

    this.getCollectionTitle(this.crumb);
  },

  methods: {
    getChildren(id) {
      axios.get(`${this.getChildrenUrl}?parentCollection=${id}`).then(response => {
        if (response.data.children.length > 0) {
          this.siblings = response.data.children;
        }
      });
    },

    getCollectionTitle(crumb) {
      let title;

      if (crumb.metadata.timespan) {
        crumb.metadata.timespan[0].field.forEach(field => {
          if (field.name === 'title') {
            title = field.value[0].value;
          }
        });
      }

      return title;
    },

    toggle() {
      this.open = !this.open;
    }

  }
};

/**
 * @description Breadcrumbs component that displays a collection tree
 * @since 1.1
 *
 * ***
 *
 * ###CSS-variables used:
 *
 * > **--contrast-color-10** text color of breadcrumbs.<br/>
 * > **--default** text color of root item.<br/>
 */

var VdtBreadcrumbs = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-breadcrumbs"
    }, [_c('ul', {
      staticClass: "vdt-breadcrumbs-list"
    }, [_c('li', {
      staticClass: "vdt-breadcrumbs-list-item vdt-breadcrumbs-root"
    }, [_vm._t("breadcrumbs-root", [_c('span', {
      on: {
        "click": function ($event) {
          _vm.$emit('goToRoot');
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.entity) + " ")])])], 2), _vm._v(" "), _vm.collectionTree !== null ? _c('BreadcrumbsItem', {
      attrs: {
        "crumb": _vm.collectionTree,
        "get-children-url": _vm.getChildrenUrl,
        "preview-item": _vm.previewItem
      },
      on: {
        "pushRoute": function ($event) {
          _vm.$emit('pushRoute', $event);
        }
      }
    }) : _vm._e()], 1)]);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-7650ef71',
  name: 'VdtBreadcrumbs',
  components: {
    BreadcrumbsItem
  },
  props: {
    /**
     * @description An object containing a set of collections within a tree structure,
     * starting from the root.
     */
    collectionTree: {
      type: Object,
      required: false,
      default: () => null
    },

    /**
     * @description Name of the entity the breadcrumbs component is displayed on, used
     * to display the root of the collection. For example, 'Item' or 'Collection'.
     */
    entity: {
      type: String,
      required: false,
      default: 'Content'
    },

    /**
     * Optional string that will be displayed to the right of the current collection,
     * you might want this if you are looking at a specific item within the current collection.
     */
    previewItem: {
      type: String,
      required: false,
      default: null
    },

    /**
     * @description String of the exact backend url to find children to a collection,
     * if none is given, you will only get the breadcrumbs without dropdown.<br/>
     * **:get-children-url="'/api/collection_children'"**<br/>
     * Result is a backend call to:
     * **GET '/api/collection_children?parentCollection=<str:collectionId>'**
     */
    getChildrenUrl: {
      type: String,
      required: false,
      default: null
    }
  }
};

var BulkCheckbox = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-bulk-boolean",
      class: {
        'vdt-bulk-boolean--checked': _vm.checked
      }
    }, [_c('label', {
      attrs: {
        "for": `${_vm.trackBy}-bulk-checkbox`
      }
    }, [_vm.checked ? _c('Checked', {
      staticClass: "vdt-bulk-boolean__checkbox",
      attrs: {
        "title": ""
      }
    }) : _c('Unchecked', {
      staticClass: "vdt-bulk-boolean__checkbox",
      attrs: {
        "title": ""
      }
    })], 1), _vm._v(" "), _c('input', {
      staticClass: "vdt-bulk-boolean__input",
      attrs: {
        "id": `${_vm.trackBy}-bulk-checkbox`,
        "type": "checkbox"
      },
      domProps: {
        "checked": _vm.checked
      },
      on: {
        "change": function ($event) {
          _vm.$emit('change', $event.target.checked);
        }
      }
    })]);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-87c446ec',
  name: 'BulkCheckbox',
  components: {
    Checked,
    Unchecked
  },
  props: {
    checked: {
      type: Boolean,
      required: false,
      default: false
    },
    trackBy: {
      type: [String, Number],
      required: false,
      default: 'header'
    }
  }
};

/**
 * @description A grid header for wrapping vdtGridItems
 * @since 0.9
 *
 * ***
 *
 * ###CSS-variables used:
 *
 * > **--default** text color of grid header.<br/>
 */

var VdtGridHeader = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      directives: [{
        name: "responsive",
        rawName: "v-responsive",
        value: _vm.breakpoints,
        expression: "breakpoints"
      }],
      staticClass: "vdt-grid__scroll"
    }, [_vm.showHeader ? _c('div', {
      staticClass: "vdt-grid-header vdt-grid-header__checkbox"
    }, [_c('div', {
      staticClass: "vdt-grid-header__bulk-select"
    }, [_vm.showCheckbox ? _vm._t("checkbox-slot", [_c('BulkCheckbox', {
      attrs: {
        "checked": _vm.selected,
        "track-by": 'grid-header'
      },
      on: {
        "change": function ($event) {
          _vm.toggleAll();
        }
      }
    }), _vm._v(" "), _c('label', {
      staticClass: "select-all",
      on: {
        "click": function ($event) {
          _vm.toggleAll();
        }
      }
    }, [_vm._v(" Select all ")])]) : _vm._e()], 2), _vm._v(" "), _vm.sortableFields.length ? _c('div', {
      staticClass: "vdt-grid-header__sorting"
    }, [_vm._v(" Sorting: "), _c('div', {
      staticClass: "vdt-grid-header__sorting__dropdown"
    }, [_c('select', {
      domProps: {
        "value": _vm.currentSorting.field || 'Set order'
      },
      on: {
        "change": function ($event) {
          _vm.sortFieldChanged($event.target.value);
        }
      }
    }, [_c('option', {
      attrs: {
        "disabled": "disabled"
      }
    }, [_vm._v(" Set order ")]), _vm._v(" "), _vm._l(_vm.sortableFields, function (field) {
      return _c('option', {
        key: field.label,
        domProps: {
          "value": field.sort.field
        }
      }, [_vm._v(" " + _vm._s(field.label) + " ")]);
    })], 2)]), _vm._v(" "), _vm.currentSorting.field ? _c('sortIcon', {
      class: `vdt-grid-header__sorting__order vdt-grid-header__sorting__${_vm.currentSorting.order}`,
      attrs: {
        "title": _vm.currentSorting.order
      },
      nativeOn: {
        "click": function ($event) {
          return _vm.sortOrderChanged($event);
        }
      }
    }) : _vm._e()], 1) : _vm._e()]) : _vm._e(), _vm._v(" "), _c('div', {
      staticClass: "vdt-grid-content"
    }, [_c('section', {
      staticClass: "vdt-grid-content__wrapper",
      style: {
        'grid-template-columns': _vm.gridItemSize,
        'grid-template-columns': `var(--grid-size, ${_vm.gridItemSize})`
      }
    }, [_vm._t("default")], 2)]), _vm._v(" "), _vm._t("custom-markup")], 2);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-09edb3fc',
  name: 'VdtGridHeader',
  components: {
    BulkCheckbox,
    sortIcon
  },
  directives: {
    responsive: ResponsiveDirective
  },
  props: {
    /**
     * @description String with grid item min width value. Must be css syntax formatted.
     */
    itemMinSize: {
      type: String,
      required: false,
      default: '16rem'
    },

    /**
     * @description String with grid item max width value. Must be css syntax formatted.
     */
    itemMaxSize: {
      type: String,
      requred: false,
      default: '1fr'
    },

    /**
     * @description Option to enable or disable check-all in header.
     */
    checkbox: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * @description State emitted from wrapper when all assets are selected.
     */
    selected: {
      type: Boolean,
      reqired: false,
      default: false
    },

    /**
     * @description Pixel width breakpoint at which layout switches to adapt to smaller screens.
     */
    breakPointWidth: {
      type: Number,
      requied: false,
      default: 800
    },

    /**
     * @description Array with fields (objects) to be able to sort on.
     *
     */
    sortingFields: {
      type: Array,
      reqiured: false,
      default: () => []
    }
  },
  computed: {
    visibleSortingFields() {
      if (findIndex(this.sortingFields, 'visible') > -1) {
        return this.sortingFields.filter(field => field.visible === true);
      }

      return this.sortingFields;
    },

    gridItemSize() {
      return `repeat(auto-fill, minmax( ${this.itemMinSize}, ${this.itemMaxSize}))`;
    },

    showHeader() {
      return this.checkbox || this.sortableFields.length;
    },

    showCheckbox() {
      return this.checkbox && this.checkbox !== 'hidden';
    },

    breakpoints() {
      return {
        small: el => el.width <= this.breakPointWidth
      };
    },

    sortableFields() {
      if (this.visibleSortingFields.length) {
        return filter(this.visibleSortingFields, 'sort.field');
      }

      return [];
    },

    currentSorting() {
      let sortObject = {
        order: 'descending'
      };
      this.sortableFields.forEach(field => {
        if (field.sort.order !== 'noop') {
          sortObject = {
            field: field.sort.field,
            order: field.sort.order || 'descending'
          };
        }
      });
      return sortObject;
    }

  },

  mounted() {
    this.$el.style.setProperty('--grid-size', `repeat(auto-fill, minmax( ${this.itemMinSize}, ${this.itemMaxSize}))`);
  },

  methods: {
    toggleAll() {
      /**
      * @event toggleAll
      * @description Emits when the Select all checkbox is toggled
      * @type null
      */
      this.$emit('toggleAll');
    },

    sortFieldChanged(field) {
      const newSortingFields = cloneDeep(this.sortingFields);

      newSortingFields.forEach((sortingField, index) => {
        if (sortingField.sort && sortingField.sort.field !== field) {
          newSortingFields[index].sort.order = 'noop';
        } else if (sortingField.sort && sortingField.sort.field === field) {
          newSortingFields[index].sort.order = this.currentSorting.order || 'descending';
        }
      });
      /**
       * @event sortUpdate
       * @description Emits the changed array sortingFields.
       * @type {Array}
       */

      this.$emit('sortUpdate', newSortingFields);
    },

    sortOrderChanged() {
      const newSortingFields = cloneDeep(this.sortingFields);

      newSortingFields.forEach((sortingField, index) => {
        if (sortingField.sort && sortingField.sort.order !== 'noop') {
          newSortingFields[index].sort.order = newSortingFields[index].sort.order === 'descending' ? 'ascending' : 'descending';
        }
      });
      this.$emit('sortUpdate', newSortingFields);
    }

  }
};

var ContextMenu = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _vm.options.length ? _c('div', {
      staticClass: "right-click-menu",
      attrs: {
        "tabindex": "-1"
      },
      on: {
        "blur": function ($event) {
          if ($event.target !== $event.currentTarget) {
            return null;
          }

          _vm.blurMe();
        }
      }
    }, _vm._l(_vm.options, function (option) {
      return _c('span', {
        key: option.value,
        on: {
          "!click": function ($event) {
            _vm.doAction(option);
          }
        }
      }, [option.icon ? _c(option.icon, {
        tag: "component",
        attrs: {
          "title": ""
        }
      }) : _vm._e(), _vm._v(" " + _vm._s(option.label) + " ")], 1);
    })) : _vm._e();
  },
  staticRenderFns: [],
  _scopeId: 'data-v-712681ae',
  name: 'MarkerTrack',
  props: {
    options: {
      type: Array,
      required: true
    }
  },

  mounted() {
    const vm = this;
    this.$nextTick(() => {
      const target = vm.$el.getBoundingClientRect();
      const {
        x,
        y,
        width,
        height
      } = target;
      const {
        innerWidth,
        innerHeight
      } = window;
      const offsetX = x + width;
      const offsetY = y + height;

      if (offsetX >= innerWidth && offsetY >= innerHeight) {
        vm.$el.style.transform = 'translate(-100%, -100%)';
      } else if (offsetX >= innerWidth) {
        vm.$el.style.transform = 'translateX(-100%)';
      } else if (offsetY >= innerHeight) {
        vm.$el.style.transform = 'translateY(-100%)';
      }
    });
  }

};

const ToggleContextMenu = {
  methods: {
    toggleContextMenu(evt) {
      this.$emit('openContextMenu', evt);
      const contextMenusOpen = [...document.getElementsByClassName('right-click-menu')];

      if (contextMenusOpen.length) {
        contextMenusOpen.forEach(el => el.blur());
      }

      if (!evt) {
        const removeMe = document.querySelector('.right-click-menu');

        if (removeMe !== null) {
          removeMe.remove();
        }

        this.$el.classList.remove('contextMenuOpen');
      } else {
        const vm = this;
        const event = this.$root.$el;
        const {
          pageX,
          pageY
        } = evt;
        const CM = Vue.extend(ContextMenu);
        const CMInstance = new CM({
          components: {},
          propsData: {
            options: vm.actions
          },
          methods: {
            doAction(option) {
              vm.handleAction(option);
            },

            blurMe() {
              vm.toggleContextMenu(null);
            }

          }
        });
        CMInstance.$mount();
        event.appendChild(CMInstance.$el);
        CMInstance.$el.focus();
        vm.$el.classList.add('contextMenuOpen');
        CMInstance.$el.style.left = `${pageX + 5}px`;
        CMInstance.$el.style.top = `${pageY + 5}px`;
      }

      if (evt) {
        evt.preventDefault();
      }
    },

    handleAction(option) {
      const {
        emit
      } = option;
      const {
        asset
      } = this;
      this.$emit('contextMenuOption', {
        emit,
        asset
      });
      this.$emit(`${option.emit}`, asset);
      this.$nextTick(() => {
        const blurMe = document.querySelector('.right-click-menu');
        blurMe.blur();
      });
    }

  }
};

/**
 * @description Shows a grid item with provided props
 * Can be used toghether with GridHeader
 * @since 0.9
 *
 * ***
 *
 * ###CSS-variables used:
 *
 * > **--contrast-color-1** text and icon color of grid item.<br/>
 * > **--contrast-color-3** background color of grid item without thumbnail.<br/>
 * > **--primary** background color of grid item.<br/>
 */

var VdtGridItem = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-grid-item",
      class: [_vm.selected ? `vdt-grid-item__selected` : '', `vdt-grid-item__${_vm.customClass}`]
    }, [_vm.checkbox ? _c('div', {
      staticClass: "vdt-grid-item__checkbox"
    }, [_vm._t("checkbox-slot", [_c('BulkCheckbox', {
      attrs: {
        "checked": _vm.selected,
        "track-by": _vm.trackBy
      },
      on: {
        "change": function ($event) {
          _vm.toggle();
        }
      }
    })])], 2) : _vm._e(), _vm._v(" "), _c('div', {
      staticClass: "vdt-grid-item__outer",
      on: {
        "click": function ($event) {
          _vm.click($event);
        },
        "dblclick": function ($event) {
          _vm.dblClick();
        },
        "!contextmenu": function ($event) {
          _vm.contextMenu ? _vm.toggleContextMenu($event) : '';
        }
      }
    }, [_c('div', {
      staticClass: "vdt-grid-item__container"
    }, [_vm.thumbnail == '' || _vm.thumbnail == 'No thumbnail' ? _c('div', {
      staticClass: "vdt-grid-item__thumbnail vdt-grid-item__thumbnail--no-thumb"
    }, [_vm._v(" " + _vm._s(_vm.thumbnail) + " ")]) : Array.isArray(_vm.thumbnail) ? _c('div', {
      ref: "scrubbThumbnail",
      staticClass: "vdt-grid-item__thumbnail vdt-grid-item__thumbnail-scrubbing",
      style: _vm.thumbnailScrub,
      on: {
        "hover": function ($event) {
          _vm.changeIndex();
        }
      }
    }) : _c('div', {
      staticClass: "vdt-grid-item__thumbnail",
      style: _vm.thumbnailObject
    }), _vm._v(" "), _c('div', {
      staticClass: "vdt-grid-item__details",
      class: _vm.customClass
    }, [_vm._t("icon"), _vm._v(" "), _c('div', {
      staticClass: "vdt-grid-item__details--metadata"
    }, [_c('span', {
      staticClass: "vdt-grid-item__details--title"
    }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _vm._v(" "), _vm.ingress !== '' ? _c('span', {
      staticClass: "vdt-grid-item__details--info"
    }, [_vm._v(" " + _vm._s(_vm.ingress) + " ")]) : _vm._e()])], 2)])])]);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-3e480342',
  name: 'VdtGridItem',
  components: {
    BulkCheckbox
  },
  mixins: [ToggleContextMenu],
  props: {
    /**
     * @description Single asset that should be displayed.
     */
    asset: {
      type: Object,
      required: true
    },

    /**
     * @description Option to enable or disable checkbox for each asset.
     */
    checkbox: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * @description Event emitted from grid header to select all assets.
     */
    allChecked: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * @description State of the asset, toggled and emitted.
     */
    selected: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * @description Class applied to the grid items details section.
     */
    customClass: {
      type: String,
      required: false,
      default: ''
    },

    /**
     * @description The title that if provided will be displayed in
     * the grid items detail section, e.g a title.
     */
    title: {
      type: String,
      required: false,
      default: 'No name'
    },

    /**
     * @description Ingress that is displayed underneath the title.
     */
    ingress: {
      type: String,
      required: false,
      default: ''
    },

    /**
     * @description URL to thumbnail image. This prop can also be an array if you want to
     * enable hover-Scrubbing
     */
    thumbnail: {
      type: [String, Array],
      required: false,
      default: 'No thumbnail'
    },

    /**
     * @description Insert a unique attribute about the object.
     */
    trackBy: {
      type: [String, Number],
      required: true
    },

    /**
     * @description Array containing custom actions, each object should contain a label and an emit.
     * The label will be displayed in the dropdown while the emit will be the name of the emit
     * sent by the component.
     */
    actions: {
      type: Array,
      required: false,
      default: () => []
    },

    /**
     * @description If true, enables a custom context menu with options defined in 'actions' prop.
     */
    contextMenu: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      assetClass: this.title,
      scrubIndex: 0
    };
  },

  computed: {
    thumbnailObject() {
      return {
        'background-image': `url(${this.thumbnail})`
      };
    },

    thumbnailScrub() {
      return {
        'background-image': `url(${this.thumbnail[this.scrubIndex]})`
      };
    }

  },

  mounted() {
    if (this.$refs.scrubbThumbnail) {
      this.$refs.scrubbThumbnail.addEventListener('mousemove', throttle(this.changeIndex, 100));
      this.$refs.scrubbThumbnail.addEventListener('mouseleave', () => {
        this.scrubIndex = 0;
      });
    }
  },

  destroyed() {
    if (this.$refs.scrubbThumbnail) {
      this.$refs.scrubbThumbnail.removeEventListener('mousemove', throttle(this.changeIndex, 100));
      this.$refs.scrubbThumbnail.removeEventListener('mouseleave', () => {
        this.showThumbnail = false;
      });
    }
  },

  methods: {
    /**
     * @event openContextMenu
     * @description Emits an event to trigger a context menu when right clicked.
     * @type {Emit}
     */
    click(event) {
      if (!this.disableClick) {
        /**
        * @event assetClick
        * @description Emits the asset clicked.
        * @type {Object}
        */
        const {
          ctrlKey,
          metaKey,
          shiftKey
        } = event;

        if (ctrlKey || metaKey) {
          this.toggle();
        } else if (shiftKey) {
          this.toggleMulti();
        } else {
          this.$emit('assetClick', this.asset);
        }
      }
    },

    dblClick() {
      /**
       * @event assetDblClick
       * @description Emits the asset when double clicked.
       * @type {Object}
       */
      this.$emit('assetDblClick', this.asset);
    },

    toggle() {
      /**
       * @event checkboxToggled
       * @description Emits the asset that was toggled.
       * @type {Object}
       */
      this.$emit('checkboxToggled', {
        asset: this.asset,
        selected: !this.selected
      });
    },

    toggleMulti() {
      /**
       * @event checkboxToggleMulti
       * @description Emits the asset that was toggled.
       * @type {Object}
       */
      if (this.checkbox) {
        document.getSelection().removeAllRanges();
        this.$emit('checkboxToggleMulti', {
          asset: this.asset
        });
      }
    },

    changeIndex(event) {
      const relativePosition = event.offsetX / event.target.clientWidth;
      this.scrubIndex = Math.floor(relativePosition * this.thumbnail.length);
    }

  }
};

/**
 * @mixin
 */

const BulkSelection = {
  props: {
    /**
     * @description The assets that have been checked.
     * Can be synced with parent-component by using v-bind:update:selectedAssets.
     */
    selectedAssets: {
      type: Array,
      required: false,
      default: () => []
    },

    /**
     * @description Option to enable or disable checkboxes.
     */
    checkbox: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * @description Path of a unique identifier on the asset to help tracking it.
     */
    trackBy: {
      type: String,
      required: false,
      default: 'metadata.id'
    },

    /**
     * @description Forcing the whole asset to be bulk-selectable.
     */
    forceSelectable: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * @description Object containing the selected asset, enables highlighting of list rows.
     */
    selectedAsset: {
      type: Object,
      required: false,
      default: () => {}
    },

    /**
     * @description Set to true in order to prevent click emits and cursor css will be default.
     */
    disableClick: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    lastClickedAsset: null
  }),
  computed: {
    allChecked() {
      // Check if all (loaded) assets are in selectedAssets
      if (this.selectedAssets.length >= this.assets.length) {
        const filter$$1 = this.assets.filter(asset => this.getIndex(asset) > -1);
        return filter$$1.length === this.assets.length;
      }

      return false;
    },

    bulkActive() {
      return this.selectedAssets.length > 0 || this.forceSelectable;
    }

  },
  watch: {
    checkbox() {
      if (!this.checkbox) {
        this.$emit('update:selectedAssets', []);
      }
    }

  },
  methods: {
    assetClicked(asset) {
      this.lastClickedAsset = get(asset, this.trackBy);

      if (this.bulkActive) {
        this.updateSelected({
          asset
        });
      } else if (!this.disableClick) {
        /**
        * @event assetClick
        * @description Emits the asset that was clicked.
        * @type {Object}
        */
        this.$emit('assetClick', asset);
      }
    },

    sortNumber(a, b) {
      return a - b;
    },

    updateSelected({
      asset,
      selected = null,
      silent = false
    }) {
      const index = this.assets.findIndex(a => get(a, this.trackBy) === get(asset, this.trackBy));
      const indexes = [];

      if (this.selectedAssets.length) {
        this.selectedAssets.forEach(sa => {
          const i = this.assets.findIndex(a => get(a, this.trackBy) === get(sa, this.trackBy));

          if (i > -1) {
            indexes.push(i);
          }
        });
      }

      if (indexes.length) {
        indexes.sort(this.sortNumber);
      }

      const isSelected = selected || indexes.indexOf(index) < 0;

      if (asset && isSelected && indexes.indexOf(index) < 0) {
        const sorted = sortedIndex(indexes, index);

        this.selectedAssets.splice(sorted, 0, asset);
      } else if (asset && !isSelected && indexes.indexOf(index) > -1) {
        this.selectedAssets.splice(indexes.indexOf(index), 1);
      }

      if (!silent) {
        /**
         * @event update:selectedAssets
         * @description Emits all the selected assets, use with vue _.sync_ binding.
         * @type {Array}
         */
        this.$emit('update:selectedAssets', this.selectedAssets);
      }
      /**
       * @event lastSelectedAsset
       * @description Emits the last selected asset.
       * @type {Object}
       */


      this.$emit('lastSelectedAsset', asset);
    },

    updateSelectedMulti({
      asset
    }) {
      const index = this.assets.findIndex(a => get(a, this.trackBy) === get(asset, this.trackBy));
      const indexes = [];

      if (this.selectedAssets.length || this.lastClickedAsset) {
        this.selectedAssets.forEach(sa => {
          const i = this.assets.findIndex(a => get(a, this.trackBy) === get(sa, this.trackBy));

          if (i > -1) {
            indexes.push(i);
          }
        });
        let closest;

        const assetsCopy = cloneDeep(this.assets);

        if (indexes.length) {
          indexes.sort(this.sortNumber);
          closest = indexes.reduce((prev, curr) => Math.abs(curr - index) < Math.abs(prev - index) ? curr : prev);
        } else if (this.lastClickedAsset) {
          closest = this.assets.findIndex(a => get(a, this.trackBy) === this.lastClickedAsset);

          if (closest > index) {
            closest += 1;
          } else if (closest < index) {
            closest -= 1;
          }
        }

        let span = [];

        if (indexes.indexOf(index) > -1) {
          const clicked = indexes.indexOf(index);
          indexes.forEach((i, ix) => {
            if (ix > clicked) {
              span.push(assetsCopy[i]);
            }
          });
        } else if (closest < index) {
          span = assetsCopy.splice(closest + 1, index - closest);
        } else {
          span = assetsCopy.splice(index, closest - index);
        }

        span.forEach(s => this.updateSelected({
          asset: s,
          silent: true
        }));
        /**
         * @event update:selectedAssets
         * @description Emits all the selected assets, use with vue _.sync_ binding.
         * @type {Array}
         */

        this.$emit('update:selectedAssets', this.selectedAssets);
      } else {
        this.updateSelected({
          asset
        });
      }

      this.lastClickedAsset = null;
    },

    checkAll() {
      // Clone this.allChecked to new object that won't be changed during loop
      const temp = Object.assign({}, {
        allChecked: this.allChecked
      });
      this.assets.forEach(asset => {
        this.updateSelected({
          asset,
          selected: !temp.allChecked,
          silent: true
        });
      });
      /**
       * @event update:selectedAssets
       * @description Emits all the selected assets, use with vue _.sync_ binding.
       * @type {Array}
       */

      this.$emit('update:selectedAssets', this.selectedAssets);
    },

    isSelected(asset) {
      return this.getIndex(asset) > -1;
    },

    getIndex(asset) {
      const index = this.selectedAssets.findIndex(file => {
        const fileId = get(file, this.trackBy); // default: file.metadata.id


        const assetId = get(asset, this.trackBy); // default: asset.metadata.id


        return fileId === assetId;
      });
      return index;
    },

    activeAsset(asset) {
      return get(this.selectedAsset, this.trackBy) === get(asset, this.trackBy);
    }

  }
};

class Intersection {
  static intersects(a, b, mode = 'touch') {
    if (mode === 'center') {
      const bxc = b.left + b.width / 2;
      const byc = b.top + b.height / 2;
      return bxc >= a.left && bxc <= a.right && byc >= a.top && byc <= a.bottom;
    } else if (mode === 'cover') {
      return b.left >= a.left && b.top >= a.top && b.right <= a.right && b.bottom <= a.bottom;
    }

    return a.right >= b.left && a.left <= b.right && a.bottom >= b.top && a.top <= b.bottom;
  }

}

const AreaSelection = {
  methods: {
    toggleAreaSelection(event) {
      // create reference to parent component
      const vm = this;
      let selected = [];
      let singleClick = true; // get all selectable grid items

      const selectables = vm.$el.getElementsByClassName('vdt-grid-item'); // create new selection area

      const selection = document.createElement('div');
      selection.classList.add('vdt-grid__selection'); // get X and Y of initial click event

      const {
        clientX,
        clientY,
        button
      } = event; // find all items inside current selection

      const loopSelectables = throttle(selectionArea => {
        selected = [];

        for (let i = 0; i < selectables.length; i += 1) {
          const node = selectables[i];

          if (Intersection.intersects(selectionArea, node.getBoundingClientRect())) {
            node.classList.add('vdt-grid-item__active');
            selected.push(vm.assets[i]);
          } else {
            node.classList.remove('vdt-grid-item__active');
          }
        }
      }, 100);

      function createSelectionArea() {
        // append selection area to root element
        vm.$root.$el.appendChild(selection);
        const style = {
          border: '1px solid #2991c8',
          backgroundColor: '#2991c850',
          position: 'fixed',
          left: `${clientX}px`,
          top: `${clientY}px`,
          willChange: 'top, left, bottom, right, width, height'
        };
        Object.assign(selection.style, style);
      }

      function onMouseMove(evt) {
        evt.preventDefault();

        if (singleClick) {
          document.removeEventListener('mousemove', onMouseMove);
          document.removeEventListener('touchmove', onMouseMove, {
            passive: false
          });
        }

        const {
          clientX: x,
          clientY: y
        } = evt;
        const x3 = Math.min(clientX, x);
        const y3 = Math.min(clientY, y);
        const x4 = Math.max(clientX, x);
        const y4 = Math.max(clientY, y);
        Object.assign(selection.style, {
          top: `${y3}px`,
          left: `${x3}px`,
          width: `${x4 - x3}px`,
          height: `${y4 - y3}px`
        });
        const selectionArea = selection.getBoundingClientRect();

        if (!singleClick) {
          loopSelectables(selectionArea);
        }
      }

      function onDelayedMouseMove(evt) {
        document.removeEventListener('mousemove', onDelayedMouseMove);
        document.removeEventListener('touchmove', onDelayedMouseMove, {
          passive: false
        });
        document.addEventListener('mousemove', onMouseMove, {
          passive: false
        });
        document.addEventListener('touchmove', onMouseMove, {
          passive: false
        });
        singleClick = false;
        evt.preventDefault();
        createSelectionArea();
      }

      function onMouseUp(evt) {
        const {
          shiftKey,
          metaKey,
          ctrlKey
        } = evt;
        const prevSelectedIds = vm.selectedAssets.map(id => get(id, vm.trackBy));
        const selectedIds = selected.map(id => get(id, vm.trackBy));

        if (selected.length) {
          if (vm.selectedAssets.length) {
            const remove = vm.selectedAssets.filter(asset => selectedIds.indexOf(get(asset, vm.trackBy)) < 0);
            const add = selected.filter(asset => prevSelectedIds.indexOf(get(asset, vm.trackBy)) < 0);

            if (shiftKey) {
              add.forEach(asset => vm.updateSelected({
                asset
              }));
            } else if (ctrlKey || metaKey) {
              selected.forEach(asset => vm.updateSelected({
                asset
              }));
            } else {
              remove.concat(add).forEach(asset => vm.updateSelected({
                asset
              }));
            }
          } else {
            selected.forEach(asset => {
              vm.updateSelected({
                asset
              });
            });
          }
        }

        if (selection) {
          selection.remove();
        } // remove all event listeners


        document.removeEventListener('mousemove', onDelayedMouseMove);
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('touchmove', onDelayedMouseMove, {
          passive: false
        });
        document.removeEventListener('touchmove', onMouseMove, {
          passive: false
        });
        document.removeEventListener('mouseup', onMouseUp);
        document.removeEventListener('touchup', onMouseUp, {
          passive: false
        }); // eslint-disable-next-line no-useless-return

        return;
      } // button === 0 means only left-click triggers selection


      if (this.areaSelection && button === 0) {
        document.addEventListener('mousemove', onDelayedMouseMove);
        document.addEventListener('touchmove', onDelayedMouseMove, {
          passive: false
        });
      }

      document.addEventListener('mouseup', onMouseUp);
      document.addEventListener('touchup', onMouseUp, {
        passive: false
      });
    }

  }
};

/**
 * @description Displays a grid with assets
 * Ability to select multiple assets with checkboxes
 * Adds ready-made checkbox/bulk logic based on vdtGridHeader and vdtGridItem.
 * @since 1.0
 *
 * ***
 *
 * ###CSS-variables used:
 *
 * > **--contrast-color-1** text and icon color of grid item.<br/>
 * > **--contrast-color-3** background color of grid item without thumbnail.<br/>
 * > **--default** text color of grid header.<br/>
 * > **--primary** backgound color of grid item.<br/>
 */

var VdtGrid = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-grid__wrapper",
      on: {
        "&mousedown": function ($event) {
          _vm.areaSelection ? _vm.toggleAreaSelection($event) : '';
        }
      }
    }, [_c('VdtGridHeader', {
      attrs: {
        "item-min-size": _vm.itemMinSize,
        "item-max-size": _vm.itemMaxSize,
        "selected": _vm.allChecked,
        "checkbox": _vm.checkbox,
        "sorting-fields": _vm.sortingFields,
        "break-point-width": _vm.breakPointWidth
      },
      on: {
        "toggleAll": function ($event) {
          _vm.checkAll($event);
        },
        "sortUpdate": function ($event) {
          _vm.$emit('sortUpdate', $event);
        }
      }
    }, _vm._l(_vm.assets, function (asset) {
      return _c('div', {
        key: _vm.getKey(asset)
      }, [_c('VdtGridItem', {
        class: [_vm.getTypeClasses(asset), {
          'vdt-grid-item__active': _vm.activeAsset(asset)
        }],
        attrs: {
          "asset": asset,
          "track-by": _vm.getKey(asset),
          "custom-class": _vm.entityClass,
          "checkbox": _vm.checkbox,
          "selected": _vm.isSelected(asset),
          "title": _vm.getAssetTitle(asset),
          "ingress": _vm.getAssetIngress(asset),
          "thumbnail": _vm.getThumbnail(asset),
          "actions": _vm.actions,
          "context-menu": _vm.contextMenu
        },
        on: {
          "openContextMenu": function ($event) {
            _vm.$emit('openContextMenu', $event);
          },
          "contextMenuOption": function ($event) {
            _vm.$emit(`${$event.emit}`, $event.asset);
          },
          "checkboxToggled": function ($event) {
            _vm.updateSelected($event);
          },
          "checkboxToggleMulti": function ($event) {
            _vm.updateSelectedMulti($event);
          },
          "assetClick": function ($event) {
            _vm.assetClicked(asset);
          },
          "assetDblClick": function ($event) {
            _vm.$emit('assetDblClick', asset);
          }
        }
      }, [_vm.showIcons ? _c(_vm.getIconComponentName(asset), {
        tag: "component",
        staticClass: "vdt-grid-item--icon",
        attrs: {
          "slot": "icon",
          "title": ""
        },
        slot: "icon"
      }) : _vm._e()], 1)], 1);
    }))], 1);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-05ebf90f',
  name: 'VdtGrid',
  components: {
    VdtGridItem,
    VdtGridHeader,
    videoIcon,
    imageIcon,
    documentIcon,
    audioIcon,
    subtitleIcon,
    pdfIcon,
    collectionIcon,
    binaryIcon,
    unknownIcon,
    noneIcon,
    dataIcon
  },
  mixins: [BulkSelection, AreaSelection],
  props: {
    /**
     * @description Array containing assets.
     */
    assets: {
      type: Array,
      required: true
    },

    /**
     * @description Css class that is applied to each GridItem's details section.
     */
    entityClass: {
      type: String,
      required: false,
      default: ''
    },

    /**
     * @description String with grid item min width value. Must be css syntax formatted.
     */
    itemMinSize: {
      type: String,
      required: false,
      default: '18rem'
    },

    /**
     * @description String with grid item max width value. Must be css syntax formatted.
     */
    itemMaxSize: {
      type: String,
      required: false,
      default: '1fr'
    },

    /**
     * @description Property accessor in dot or bracket notation
     * to the value that should be display as title.
     */
    title: {
      type: String,
      required: false,
      default: 'metadata.filename'
    },

    /**
     * @description Property accessor in dot or bracket notation
     * to the value that should be display as ingress.
     */
    ingress: {
      type: String,
      required: false,
      default: 'metadata.mediaType'
    },

    /**
     * @description Property accessor in dot or bracket notation
     * to the thumbnail-url, could also be a list of thumbnails which will enable
     * hover-Scrubbing.
     */
    thumbnail: {
      type: String,
      required: false,
      default: 'thumbnail'
    },

    /**
     * @description Try to display icons on the GridItems based on
     * Vidispine defaults mediaType values (video, image, and so on..)
     * and asset types (item or collection)
     * Requires mediaType and assetType-prop.
     *
     * To display custom icons, use VdtGridItem component directly and
     * take advantage of the icon slot.
     */
    showIcons: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * @description Property accessor in dot or bracket notation
     * to the value that should be mapped to an icon.
     *
     * Only used if showIcons equals true.
     */
    mediaType: {
      type: String,
      required: false,
      default: 'metadata.mediaType'
    },

    /**
     * @description Add mediaType (if available) value as CSS-class on th grid item.
     * Will be prefixed with "vdt-grid-item--".
     */
    mediaTypeAsClass: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * @description Property accessor in dot or bracket notation
     * to a value that tells if the asset is "item" or "collection".
     *
     * Only used if showIcons equals true.
     */
    assetType: {
      type: String,
      required: false,
      default: 'metadata.type'
    },

    /**
     * @description Add assetType (if available) value as CSS-class on th grid item.
     * Will be prefixed with "vdt-grid-item--".
     */
    assetTypeAsClass: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * @description Pixel width breakpoint at which layout switches to adapt to smaller screens.
     */
    breakPointWidth: {
      type: Number,
      required: false,
      default: 800
    },

    /**
     * @description Array with fields (objects) to be able to sort on.
     *
     */
    sortingFields: {
      type: Array,
      required: false,
      default: () => []
    },

    /**
     * @description Array containing custom actions, each object should contain a label and an emit.
     * The label will be displayed in the dropdown while the emit will be the name of the emit
     * sent by the component.
     */
    actions: {
      type: Array,
      required: false,
      default: () => []
    },

    /**
     * @description If true, enables a custom context menu with options defined in 'actions' prop.
     */
    contextMenu: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * @description If true, enables selection of grid items via box area selection.
     */
    areaSelection: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    showIcon() {
      return this.mediaType.length > 0 || this.isCollection;
    }

  },
  methods: {
    getAssetTitle(asset) {
      return get(asset, this.title);
    },

    getAssetIngress(asset) {
      return get(asset, this.ingress);
    },

    getThumbnail(asset) {
      return get(asset, this.thumbnail);
    },

    getKey(asset) {
      return get(asset, this.trackBy);
    },

    getMediaType(asset) {
      return get(asset, this.mediaType);
    },

    getAssetType(asset) {
      return get(asset, this.assetType).toLowerCase();
    },

    getIconComponentName(asset) {
      const assetType = this.getAssetType(asset);

      if (assetType === 'collection') {
        return 'collectionIcon';
      } else if (assetType === 'item') {
        const assetMediaType = this.getMediaType(asset);

        if (assetMediaType.length > 0) {
          return `${assetMediaType}Icon`;
        }
      }

      return '';
    },

    getTypeClasses(asset) {
      const classes = [];

      if (this.assetTypeAsClass && this.getAssetType(asset)) {
        classes.push(`vdt-grid-item--${this.getAssetType(asset)}`);
      }

      if (this.mediaTypeAsClass && this.getMediaType(asset)) {
        classes.push(`vdt-grid-item--${this.getMediaType(asset)}`);
      }

      if (classes.length) {
        const classString = classes.join(' ');
        return classString;
      }

      return '';
    }

  }
};

/**
 * @description Filter option component that emits checkbox value on change
 * @since 1.0
 */

var VdtFilterOption = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-filter-boolean vdt-filter-option"
    }, [_c('label', {
      attrs: {
        "for": `${_vm.groupName}-${_vm.option.name}`
      }
    }, [_vm.option.value ? _c('Checked', {
      staticClass: "vdt-filter-boolean__checkbox",
      style: _vm.checkboxColor,
      attrs: {
        "title": ""
      }
    }) : _c('Unchecked', {
      staticClass: "vdt-filter-boolean__checkbox",
      style: _vm.checkboxColor,
      attrs: {
        "title": ""
      }
    }), _vm._v(" "), _c('span', {
      staticClass: "option-name"
    }, [_vm._v(" " + _vm._s(_vm.option.name) + " ")]), _vm._v(" "), _vm.showHits ? _c('span', {
      staticClass: "option-count"
    }, [_vm._v(" (" + _vm._s(_vm.option.count) + ") ")]) : _vm._e()], 1), _vm._v(" "), _c('input', {
      attrs: {
        "id": `${_vm.groupName}-${_vm.option.name}`,
        "type": "checkbox"
      },
      domProps: {
        "checked": _vm.option.value,
        "value": `${_vm.option.name}`
      },
      on: {
        "change": function ($event) {
          _vm.$emit('optionUpdated', $event.target.checked);
        }
      }
    })]);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-168c7b65',
  name: 'VdtFilterOption',
  components: {
    Checked: Checked$1,
    Unchecked: Unchecked$1
  },
  props: {
    option: {
      type: Object,
      required: true
    },
    groupName: {
      type: String,
      required: false,
      default: ''
    },
    checkboxFillColor: {
      type: String,
      required: true
    },
    showHits: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    checkboxColor() {
      return {
        fill: this.checkboxFillColor,
        color: this.checkboxFillColor
      };
    }

  }
};

/**
 * @description Filter range component that emits range value on change
 * @since 1.0
 */
var VdtFilterRange = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-filter-range vdt-filter-option"
    }, [_c('label', [_c('span', {
      staticClass: "option-name"
    }, [_vm._v(" " + _vm._s(_vm.option.name) + " ")])]), _vm._v(" "), _c('vueSlider', {
      ref: "slider",
      attrs: {
        "min": _vm.option.range[0],
        "max": _vm.option.range[1],
        "process-style": {
          'background-color': _vm.checkboxFillColor
        },
        "show": _vm.show,
        "tooltip": false,
        "speed": 0
      },
      on: {
        "callback": _vm.emitChanges
      },
      model: {
        value: _vm.option.value,
        callback: function ($$v) {
          _vm.$set(_vm.option, "value", $$v);
        },
        expression: "option.value"
      }
    }), _vm._v(" "), _c('div', {
      staticClass: "vdt-filter-range__timecode-wrapper"
    }, [_c('span', [_vm._v(_vm._s(_vm.formatedValue[0]))]), _vm._v(" "), _c('span', [_vm._v(_vm._s(_vm.formatedValue[1]))])])], 1);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-5d9bc77d',
  name: 'VdtFilterRange',
  components: {
    vueSlider
  },
  props: {
    option: {
      type: Object,
      required: true
    },
    groupName: {
      type: String,
      required: false,
      default: ''
    },
    checkboxFillColor: {
      type: String,
      required: true
    },
    animationDelay: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      show: false
    };
  },

  computed: {
    checkboxColor() {
      return {
        fill: this.checkboxFillColor,
        color: this.checkboxFillColor
      };
    },

    formatedValue() {
      // Don't format the options if type: 'raw' is passed
      if (this.option.formatting && this.option.formatting === 'raw') {
        return this.option.value;
      } // default to minute formatting to be backwards compatible


      return [new Date(this.option.value[0] * 1000).toISOString().substr(11, 8), new Date(this.option.value[1] * 1000).toISOString().substr(11, 8)];
    }

  },

  mounted() {
    setTimeout(() => {
      this.show = true;
    }, this.animationDelay);
  },

  methods: {
    // eslint-disable-next-line func-names
    emitChanges: debounce(function () {
      this.$emit('optionUpdated', this.option.value);
    }, 500)
  }
};

var VdtDateTimePicker = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-datepicker",
      attrs: {
        "tabindex": "1"
      },
      on: {
        "click": function ($event) {
          if ($event.target !== $event.currentTarget) {
            return null;
          }

          _vm.dateTimePickerOpen = !_vm.dateTimePickerOpen;
        },
        "blur": function ($event) {
          _vm.autoHide ? _vm.toggle($event) : '';
        }
      }
    }, [_c('span', {
      on: {
        "click": function ($event) {
          if ($event.target !== $event.currentTarget) {
            return null;
          }

          _vm.dateTimePickerOpen = !_vm.dateTimePickerOpen;
        }
      }
    }, [_vm._v(" " + _vm._s(_vm._f("filter")(_vm.pickedValue, _vm.placeholderValue)) + " ")]), _vm._v(" "), _vm.dateTimePickerOpen ? _c('div', {
      staticClass: "date-time-picker"
    }, [_c('Close', {
      staticClass: "date-time-picker__close",
      attrs: {
        "title": "",
        "data-label": "Close"
      },
      nativeOn: {
        "click": function ($event) {
          _vm.dateTimePickerOpen = false;
        }
      }
    }), _vm._v(" "), _c('div', {
      ref: "calendar",
      staticClass: "calendar"
    }, [_c('div', {
      staticClass: "calendar-month"
    }, [_c('ArrowLeft', {
      attrs: {
        "title": ""
      },
      nativeOn: {
        "click": function ($event) {
          _vm.addMonth({
            add: false
          });
        }
      }
    }), _vm._v(" "), _c('div', {
      staticClass: "month"
    }, [_c('transition', {
      attrs: {
        "name": "slide"
      }
    }, [_c('span', {
      key: _vm.currentDate.monthLong
    }, [_vm._v(_vm._s(_vm.currentDate.monthLong))])])], 1), _vm._v(" "), _c('span', [_vm._v(" - ")]), _vm._v(" "), _c('div', {
      staticClass: "year"
    }, [_c('transition', {
      attrs: {
        "name": "slide"
      }
    }, [_c('span', {
      key: _vm.currentDate.year
    }, [_vm._v(_vm._s(_vm.currentDate.year))])])], 1), _vm._v(" "), _c('ArrowRight', {
      attrs: {
        "title": ""
      },
      nativeOn: {
        "click": function ($event) {
          _vm.addMonth({
            add: true
          });
        }
      }
    })], 1), _vm._v(" "), _c('div', {
      staticClass: "calendar-weekdays"
    }, _vm._l(_vm.weekdays, function (day) {
      return _c('div', {
        key: day
      }, [_c('span', [_vm._v(_vm._s(day))])]);
    })), _vm._v(" "), _c('div', {
      staticClass: "calendar-picker"
    }, [_vm._l(_vm.currentDate.first - 1, function (empty) {
      return _c('div', {
        key: `first_${empty}`,
        staticClass: "disabled"
      }, [_c('span', [_vm._v(_vm._s(_vm.currentDate.prevMonth - (_vm.currentDate.first - 1) + empty))])]);
    }), _vm._v(" "), _vm._l(_vm.currentDate.totalDays, function (day) {
      return _c('div', {
        key: day,
        staticClass: "calendar-day",
        class: {
          'active': _vm.currentDate.day === day
        },
        on: {
          "click": function ($event) {
            _vm.setDate({
              day: day
            });
          }
        }
      }, [_c('span', [_vm._v(_vm._s(day))])]);
    }), _vm._v(" "), _vm._l(7 - _vm.currentDate.last, function (empty) {
      return _c('div', {
        key: `last_${empty}`,
        staticClass: "disabled"
      }, [_c('span', [_vm._v(_vm._s(empty))])]);
    })], 2), _vm._v(" "), _vm.showTime ? _c('div', {
      staticClass: "calendar-time"
    }, [_c('div', {
      attrs: {
        "data-label": "Hour"
      }
    }, [_c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.currentDate.hour,
        expression: "currentDate.hour"
      }],
      staticClass: "time-input time-input__hour",
      attrs: {
        "data-label": "Hour"
      },
      on: {
        "change": [function ($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });

          _vm.$set(_vm.currentDate, "hour", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
        }, function ($event) {
          _vm.setDate({});
        }]
      }
    }, [_c('option', {
      domProps: {
        "value": 0
      }
    }, [_vm._v(" " + _vm._s('0'.padStart(2, '0')) + " ")]), _vm._v(" "), _vm._l(23, function (hour) {
      return _c('option', {
        key: hour,
        domProps: {
          "value": hour
        }
      }, [_vm._v(" " + _vm._s(hour.toString().padStart(2, '0')) + " ")]);
    })], 2)]), _vm._v(" "), _c('span', [_vm._v(":")]), _vm._v(" "), _c('div', {
      attrs: {
        "data-label": "Minute"
      }
    }, [_c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.currentDate.minute,
        expression: "currentDate.minute"
      }],
      staticClass: "time-input time-input__minute",
      attrs: {
        "data-label": "Minute"
      },
      on: {
        "change": [function ($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });

          _vm.$set(_vm.currentDate, "minute", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
        }, function ($event) {
          _vm.setDate({});
        }]
      }
    }, [_c('option', {
      domProps: {
        "value": 0
      }
    }, [_vm._v(" " + _vm._s('0'.padStart(2, '0')) + " ")]), _vm._v(" "), _vm._l(59, function (minute) {
      return _c('option', {
        key: minute,
        domProps: {
          "value": minute
        }
      }, [_vm._v(" " + _vm._s(minute.toString().padStart(2, '0')) + " ")]);
    })], 2)]), _vm._v(" "), _c('span', [_vm._v(":")]), _vm._v(" "), _c('div', {
      attrs: {
        "data-label": "Second"
      }
    }, [_c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.currentDate.second,
        expression: "currentDate.second"
      }],
      staticClass: "time-input time-input__second",
      attrs: {
        "data-label": "Second"
      },
      on: {
        "change": [function ($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });

          _vm.$set(_vm.currentDate, "second", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
        }, function ($event) {
          _vm.setDate({});
        }]
      }
    }, [_c('option', {
      domProps: {
        "value": 0
      }
    }, [_vm._v(" " + _vm._s('0'.padStart(2, '0')) + " ")]), _vm._v(" "), _vm._l(59, function (second) {
      return _c('option', {
        key: second,
        domProps: {
          "value": second
        }
      }, [_vm._v(" " + _vm._s(second.toString().padStart(2, '0')) + " ")]);
    })], 2)])]) : _vm._e()]), _vm._v(" "), _c('RestoreClock', {
      staticClass: "date-time-picker__reset",
      attrs: {
        "title": "",
        "data-label": "Reset"
      },
      nativeOn: {
        "click": function ($event) {
          return _vm.reset($event);
        }
      }
    })], 1) : _vm._e()]);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-04acbf6f',
  name: 'VdtDateTimePicker',
  components: {
    Pencil,
    Check,
    Close: close,
    ArrowLeft,
    ArrowRight,
    RestoreClock
  },
  filters: {
    filter(val, placeholder) {
      if (!val) {
        return placeholder;
      }

      const formattedDate = DateTime.fromISO(val).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
      return formattedDate;
    }

  },
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },
    showTime: {
      type: Boolean,
      required: false,
      default: true
    },
    placeholderValue: {
      type: String,
      required: false,
      default: 'yyyy-mm-dd'
    },
    autoHide: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data: () => ({
    editing: false,
    pickedValue: '',
    dateTimePickerOpen: false,
    currentDate: {},
    weekdays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  }),
  watch: {
    dateTimePickerOpen(val) {
      if (val) {
        this.initDateTimePicker();
      } else {
        this.currentDate = {};
      }
    },

    pickedValue() {
      if (this.pickedValue) {
        this.$emit('updateValue', this.pickedValue);
      }
    },

    value(newVal) {
      if (!this.dateTimePickerOpen && this.pickedValue !== newVal) {
        this.pickedValue = '';
      }
    }

  },

  mounted() {
    if (this.value) {
      let newVal;
      const JSDate = new Date(this.value);

      if (DateTime.fromJSDate(JSDate).isValid) {
        newVal = DateTime.fromJSDate(JSDate).toISO();
      } else {
        newVal = DateTime.fromJSDate(this.value).toISO();
      }

      this.pickedValue = newVal;
    }
  },

  methods: {
    initDateTimePicker() {
      let newDate = new Date();

      if (this.pickedValue) {
        newDate = new Date(this.pickedValue);
      }

      const theYear = newDate.getFullYear();
      const theMonth = newDate.getMonth() + 1;
      const theDay = newDate.getDate();
      const theHour = newDate.getHours();
      const theMinute = newDate.getMinutes();
      const theSecond = newDate.getSeconds();
      const dateObj = DateTime.fromObject({
        year: theYear,
        month: theMonth,
        day: theDay,
        hour: theHour,
        minute: theMinute,
        second: theSecond
      });
      const {
        daysInMonth: totalDays,
        day,
        month,
        monthLong,
        year,
        hour,
        minute,
        second
      } = dateObj;
      const {
        weekday: first
      } = DateTime.local(year, month, 1);
      const {
        weekday: last$$1
      } = DateTime.local(year, month, totalDays);
      let prevMonth = 1;

      if (first > 1) {
        if (month === 1) {
          prevMonth = DateTime.local(year - 1, 12).daysInMonth;
        } else {
          prevMonth = DateTime.local(year, month - 1).daysInMonth;
        }
      }

      this.currentDate = Object.assign(this.currentDate, {
        totalDays,
        first,
        last: last$$1,
        day,
        month,
        monthLong,
        year,
        hour,
        minute,
        second,
        prevMonth
      });
      this.$nextTick(() => {
        const {
          innerWidth,
          innerHeight
        } = window;
        const timePicker = this.$el.querySelector('.date-time-picker');
        const {
          right,
          bottom
        } = timePicker.getBoundingClientRect();

        if (right + 10 > innerWidth) {
          const margin = `${innerWidth - 10 - right}px`;
          timePicker.style.marginLeft = margin;
        }

        if (bottom + 10 > innerHeight) {
          const margin = `${innerHeight - 10 - bottom}px`;
          timePicker.style.marginTop = margin;
        }
      });
    },

    updateDateTimePicker({
      newMonth = this.currentDate.month,
      newYear = this.currentDate.year
    }) {
      const {
        hour,
        minute,
        second
      } = this.currentDate;
      const dateObj = DateTime.local(newYear, newMonth, 1);
      const {
        daysInMonth: totalDays,
        day,
        month,
        monthLong,
        year
      } = dateObj;
      const {
        weekday: first
      } = DateTime.local(year, month, 1);
      const {
        weekday: last$$1
      } = DateTime.local(year, month, totalDays);
      let prevMonth = 1;

      if (first > 1) {
        if (month === 1) {
          prevMonth = DateTime.local(year - 1, 12).daysInMonth;
        } else {
          prevMonth = DateTime.local(year, month - 1).daysInMonth;
        }
      }

      this.currentDate = {
        totalDays,
        first,
        last: last$$1,
        day,
        month,
        monthLong,
        year,
        hour,
        minute,
        second,
        prevMonth
      };
    },

    setDate({
      day = this.currentDate.day
    }) {
      const {
        second,
        minute,
        hour,
        month,
        year
      } = this.currentDate;
      const clone$$1 = Object.assign(cloneDeep(this.currentDate), {
        day
      });
      this.currentDate = clone$$1;
      this.pickedValue = DateTime.local(year, month, day, hour, minute, second).toISO();
    },

    addMonth({
      add = true
    }) {
      const {
        month
      } = this.currentDate;

      if (add) {
        if (month === 12) {
          this.addYear({
            add: true
          });
        } else {
          this.updateDateTimePicker({
            newMonth: month + 1
          });
        }
      } else if (month === 1) {
        this.addYear({
          add: false
        });
      } else {
        this.updateDateTimePicker({
          newMonth: month - 1
        });
      }
    },

    addYear({
      add = true
    }) {
      const {
        year
      } = this.currentDate;

      if (add) {
        this.updateDateTimePicker({
          newYear: year + 1,
          newMonth: 1
        });
      } else {
        this.updateDateTimePicker({
          newYear: year - 1,
          newMonth: 12
        });
      }
    },

    toggle(event) {
      const vm = this;

      if (event.relatedTarget && event.relatedTarget.className.includes('time-input')) {
        this.dateTimePickerOpen = true;
        const element = event.relatedTarget;

        element.onblur = function handler(evt) {
          vm.toggle(evt);
        };
      } else {
        this.dateTimePickerOpen = false;
      }
    },

    reset() {
      this.pickedValue = '';
      this.$emit('updateValue', null);
      this.initDateTimePicker();
    }

  }
};

/**
 * @description Filter date component that emits range value on change
 * @since 1.0
 */
// import { cloneDeep as _cloneDeep } from 'lodash-es';
var VdtFilterDate = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-filter-date vdt-filter-option"
    }, [_c('label', [_c('span', {
      staticClass: "option-name"
    }, [_vm._v(" " + _vm._s(_vm.option.name) + " ")])]), _vm._v(" "), _c('div', {
      staticClass: "flexWrapper",
      class: _vm.filterType
    }, [_c('div', {
      staticClass: "vdt-filter-date__date-wrapper"
    }, [_c('span', [_vm._v(" Start ")]), _vm._v(" "), _c('DateTimePicker', {
      attrs: {
        "value": _vm.option.value[0],
        "placeholder-value": 'yyyy-mm-dd'
      },
      on: {
        "updateValue": function ($event) {
          _vm.dateChanged($event, 0);
        }
      }
    })], 1), _vm._v(" "), _c('div', {
      staticClass: "vdt-filter-date__date-wrapper"
    }, [_c('span', [_vm._v(" End ")]), _vm._v(" "), _c('DateTimePicker', {
      attrs: {
        "value": _vm.option.value[1],
        "placeholder-value": 'yyyy-mm-dd'
      },
      on: {
        "updateValue": function ($event) {
          _vm.dateChanged($event, 1);
        }
      }
    })], 1)])]);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-567b741e',
  name: 'VdtFilterDate',
  components: {
    DateTimePicker: VdtDateTimePicker
  },
  props: {
    option: {
      type: Object,
      required: true
    },
    groupName: {
      type: String,
      required: false,
      default: ''
    },
    exclusive: {
      type: Boolean,
      required: false,
      default: false
    },
    checkboxFillColor: {
      type: String,
      required: true
    }
  },
  computed: {
    checkboxColor() {
      return {
        fill: this.checkboxFillColor,
        color: this.checkboxFillColor
      };
    },

    filterType() {
      if (this.exclusive) {
        return 'vdt-filter-date__exclusive';
      }

      return 'vdt-filter-date__inclusive';
    }

  },
  methods: {
    dateChanged(value, index) {
      const vm = this;
      vm.$set(this.option.value, index, value);
      vm.$emit('optionUpdated', this.option.value); // const newDates = [null, null];
      // const vm = this;
      // const optionClone = _cloneDeep(this.option.value);
      // this.option.value.forEach((value, index) => {
      //   // only update value if it passes test
      //   if (/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(value)) {
      //     newDates[index] = value;
      //     vm.$set(this.option.value, index, value);
      //   } else if (value !== null) {
      //     newDates[index] = null;
      //     optionClone[index] = null;
      //     vm.$set(this.option.value, index, null);
      //   }
      // });
      // // only emit value if it passed test and updated value
      // if (this.equalDates(newDates, optionClone)) {
      //   this.$emit('optionUpdated', newDates);
      // }
    },

    equalDates(first, second) {
      return JSON.stringify(first) === JSON.stringify(second);
    }

  }
};

/**
 * @description Filter text component that emits a string value on change
 * @since 1.0
 */
var VdtFilterText = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-filter-option"
    }, [_c('form', {
      on: {
        "submit": function ($event) {
          $event.preventDefault();
        }
      }
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.text,
        expression: "text"
      }],
      staticClass: "vdt-text-input",
      attrs: {
        "type": "text"
      },
      domProps: {
        "value": _vm.text
      },
      on: {
        "change": _vm.emitChanges,
        "input": function ($event) {
          if ($event.target.composing) {
            return;
          }

          _vm.text = $event.target.value;
        }
      }
    })])]);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-a0641bc6',
  name: 'VdtFilterText',
  props: {
    option: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      text: ''
    };
  },

  watch: {
    option: {
      immediate: true,

      handler(option) {
        this.text = option.name;
      }

    }
  },
  methods: {
    // eslint-disable-next-line func-names
    emitChanges: debounce(function () {
      this.$emit('optionUpdated', this.text);
    }, 500)
  }
};

/**
 * @description Filter group component that recursivly loops out all filter groups
 * in provided group prop
 * Every Filter group component renders a Filter option component for each
 * option in that group
 * @since 1.0
 */

var VdtFilterGroup = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-filter-group",
      class: _vm.getLevelClass
    }, [_c('button', {
      staticClass: "vdt-filter-group__title",
      on: {
        "click": _vm.toggleExpanded
      }
    }, [_c('ChevronRight', {
      staticClass: "vdt-filter-group__icon vdt-filter-group__icon-chevron",
      class: {
        'vdt-filter-group__icon-chevron--expanded': _vm.isExpanded
      },
      attrs: {
        "title": ""
      }
    }), _vm._v(" "), _c('span', {
      staticClass: "vdt-filter-group__heading__name"
    }, [_vm._v(_vm._s(_vm.localGroup.name))])], 1), _vm._v(" "), _c('section', {
      staticClass: "vdt-filter-group-content"
    }, [_vm.localGroup.options && _vm.isExpanded && _vm.localGroup.exclusive !== undefined ? _c('div', {
      staticClass: "vdt-filter-group__inclusive-toggler",
      on: {
        "click": _vm.toggleInclusive
      }
    }, [!_vm.localGroup.exclusive ? _c('ToggleOn', {
      staticClass: "vdt-filter-group__icon",
      style: _vm.checkboxColor,
      attrs: {
        "title": ""
      }
    }) : _c('ToggleOff', {
      staticClass: "vdt-filter-group__icon",
      style: _vm.checkboxColor,
      attrs: {
        "title": ""
      }
    }), _vm._v(" "), _c('span', {
      staticClass: "vdt-filter-group__inclusive-toggler__status",
      style: `color:${_vm.checkboxFillColor}`
    }, [_vm._v(_vm._s(_vm.InclusiveStatusText))])], 1) : _vm._e(), _vm._v(" "), _vm.group.type === 'range' ? _c('section', {
      staticClass: "rangeWrapper"
    }, _vm._l(_vm.visibleOptions, function (option) {
      return _c('VdtFilterRange', {
        key: option.name,
        attrs: {
          "option": option,
          "checkbox-fill-color": _vm.checkboxFillColor,
          "group-name": _vm.group.name,
          "animation-delay": _vm.computedAnimationDelay
        },
        on: {
          "optionUpdated": function ($event) {
            _vm.updateOption($event, option);
          }
        }
      });
    })) : _vm.group.type === 'boolean' ? _c('section', {
      staticClass: "booleanWrapper"
    }, [_vm._l(_vm.visibleOptions, function (option) {
      return _c('VdtFilterOption', {
        key: option.name,
        attrs: {
          "option": option,
          "show-hits": _vm.showHits,
          "checkbox-fill-color": _vm.checkboxFillColor,
          "group-name": _vm.group.name
        },
        on: {
          "optionUpdated": function ($event) {
            _vm.updateOption($event, option);
          }
        }
      });
    }), _vm._v(" "), !_vm.localGroup.options || _vm.localGroup.options.length === 0 && _vm.isExpanded ? _c('span', {
      staticClass: "no-visiable-filters"
    }, [_vm._v(" No content added ")]) : _vm._e()], 2) : _vm.group.type === 'date' ? _c('section', {
      staticClass: "dateWrapper"
    }, _vm._l(_vm.visibleOptions, function (option) {
      return _c('VdtFilterDate', {
        key: option.name,
        attrs: {
          "option": option,
          "checkbox-fill-color": _vm.checkboxFillColor,
          "group-name": _vm.group.name,
          "exclusive": _vm.localGroup.exclusive
        },
        on: {
          "optionUpdated": function ($event) {
            _vm.updateOption($event, option);
          }
        }
      });
    })) : _vm.group.type === 'text' ? _c('section', _vm._l(_vm.visibleOptions, function (option) {
      return _c('VdtFilterText', {
        key: option.name,
        attrs: {
          "option": option
        },
        on: {
          "optionUpdated": function ($event) {
            _vm.updateTextOption($event, option);
          }
        }
      });
    })) : _vm._e(), _vm._v(" "), _vm._l(_vm.visibleGroups, function (group, index) {
      return _c('VdtFilterGroup', {
        key: group.name,
        attrs: {
          "group": group,
          "level": _vm.level + 1,
          "show-hits": group.showHits !== undefined ? group.showHits : _vm.showHits,
          "context": _vm.context + '.' + group.name,
          "show-active-only": _vm.showActiveOnly,
          "animation-delay": _vm.animationDelay,
          "inclusive-color": _vm.inclusiveColor,
          "exclusive-color": _vm.exclusiveColor
        },
        on: {
          "updateGroup": function ($event) {
            _vm.updateGroup($event, index);
          },
          "groupVisibilityChange": function ($event) {
            _vm.updateVisibleChildGroups($event);
          }
        }
      });
    })], 2)]);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-320413bf',
  name: 'VdtFilterGroup',
  components: {
    ChevronRight,
    ToggleOn,
    ToggleOff,
    VdtFilterOption,
    VdtFilterRange,
    VdtFilterDate,
    VdtFilterText
  },
  props: {
    filters: {
      type: Array,
      required: false,
      default: () => []
    },
    group: {
      type: Object,
      requred: false,
      default: () => ({})
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    level: {
      type: Number,
      required: false,
      default: 0
    },
    context: {
      type: String,
      required: false,
      default: ''
    },
    showActiveOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    animationDelay: {
      type: Number,
      required: false,
      default: 0
    },
    inclusiveColor: {
      type: String,
      required: true
    },
    exclusiveColor: {
      type: String,
      required: true
    },
    showHits: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      localGroup: {},
      hiddenChildGroups: {},
      useAnimationDelay: true
    };
  },

  computed: {
    isExpanded() {
      const expanded = this.localGroup.expanded !== undefined ? this.localGroup.expanded : true;
      return this.showActiveOnly ? true : expanded;
    },

    getLevelClass() {
      return `vdt-filter-group__level${this.level}`;
    },

    checkboxFillColor() {
      if (!this.localGroup.exclusive) {
        return this.inclusiveColor;
      }

      return this.exclusiveColor;
    },

    InclusiveStatusText() {
      if (!this.localGroup.exclusive) {
        return 'Inclusive filtering';
      }

      return 'Exclusive filtering';
    },

    checkboxColor() {
      return {
        fill: this.checkboxFillColor,
        color: this.checkboxFillColor
      };
    },

    visibleOptions() {
      if (this.localGroup === undefined || this.localGroup.options === undefined) return [];

      if (this.showActiveOnly) {
        if (this.localGroup.type === 'boolean') {
          // option-value === true
          return this.localGroup.options.filter(option => option.value);
        } else if (this.localGroup.type === 'range') {
          return this.localGroup.options.filter(option => // If the values differs from min and max range
          option.value[0] !== option.range[0] || option.value[1] !== option.range[1]);
        } else if (this.localGroup.type === 'date') {
          return this.localGroup.options.filter(option => // Default is null, so anything different than that and makes date-fitler "active"
          option.value[0] !== null || option.value[1] !== null);
        }
      } else if (!this.isExpanded) {
        return [];
      }

      return this.localGroup.options;
    },

    visibleGroups() {
      if (this.localGroup.groups !== undefined) {
        if (this.showActiveOnly) {
          return this.localGroup.groups.filter(group => {
            const hidden = Object.keys(this.hiddenChildGroups).indexOf(group.name) > -1;
            return !hidden;
          });
        } else if (!this.isExpanded) {
          return [];
        }
      }

      return this.localGroup.groups;
    },

    showGroup() {
      /**
       * If show active only is true then only show the group
       * if any active filters options are present
       * or if there is a group beneath that haven't emitted
       * itself to be hidden (and been added to the hiddenChildGroups object)
       * */
      let show = true;

      if (this.showActiveOnly) {
        show = this.visibleOptions.length > 0 || this.localGroup.groups !== undefined && this.localGroup.groups.length > Object.keys(this.hiddenChildGroups).length;
      }

      return show;
    },

    computedAnimationDelay() {
      return this.useAnimationDelay ? this.animationDelay : 0;
    }

  },
  watch: {
    group: {
      handler(updatedGroup) {
        this.localGroup = cloneDeep(updatedGroup);
      },

      deep: true
    },

    showGroup(show) {
      // See this.showGroup variable and this.updateVisibleChildGroups for more info
      this.$emit('groupVisibilityChange', {
        group: this.localGroup,
        show
      });
    }

  },

  mounted() {
    if (this.group) {
      this.localGroup = cloneDeep(this.group);
    }

    if (this.animationDelay && this.useAnimationDelay) {
      setTimeout(() => {
        this.useAnimationDelay = false;
      }, this.animationDelay);
    }

    this.$emit('groupVisibilityChange', {
      group: this.localGroup,
      show: this.showGroup
    });
  },

  methods: {
    toggleExpanded() {
      this.localGroup.expanded = !this.localGroup.expanded;
      this.emitUpdateGroup({
        expandedToggled: true
      });
    },

    toggleInclusive() {
      this.localGroup.exclusive = !this.localGroup.exclusive;
      this.emitUpdateGroup();
    },

    updateOption(payload, option) {
      const o = option;
      o.value = payload;
      this.emitUpdateGroup();
    },

    updateTextOption(text, option) {
      const o = option;
      o.name = text;

      if (text) {
        o.value = true;
      } else {
        o.value = false;
      }

      this.emitUpdateGroup();
    },

    updateGroup(payload) {
      if (this.localGroup.groups) {
        let i = 0;

        for (i; i < this.localGroup.groups.length; i++) {
          const g = this.localGroup.groups[i];

          if (g.name === payload.group.name) {
            break;
          }
        } // this.localGroup.groups[i] = payload;


        this.$set(this.localGroup.groups, i, payload.group);
      }

      this.emitUpdateGroup(payload);
    },

    emitUpdateGroup({
      expandedToggled = false
    } = {}) {
      /**
      * Emits the updated filter group to parent
      *
      * @event updateGroup
      * @type {Object}
      */
      if (Object.keys(this.localGroup).length > 0 && this.localGroup.constructor === Object) {
        this.$emit('updateGroup', {
          group: this.localGroup,
          expandedToggled
        });
      }
    },

    /**
     * Function that adds a group into the hiddenChildGroups variable
     * if the group that emitted "itself" doesn't contain any active filters
    * */
    updateVisibleChildGroups({
      group,
      show
    }) {
      if (!show) {
        // If hiddenChildgroups doesn't already contain the group to be hidden
        if (Object.keys(this.hiddenChildGroups).indexOf(group.name) === -1) {
          this.$set(this.hiddenChildGroups, group.name, group);
        }
      } else {
        delete this.hiddenChildGroups[group.name];
      }
    }

  }
};

/**
 * @description Filter component that emits an array of filters on changes
 * @since 1.0
 *
 * ***
 *
 * ###CSS-variables used:
 *
 * > **--info** color of filter elements with property `inclusive`.<br/>
 * > **--danger** color of filter elements with property `exclusive`.
 * Also background-color of "Clear" and "Reset" buttons.<br/>
 * > **--default** color to display "no active filters" message.<br/>
 * > **--primary** background-color of "Show all" and "Active" buttons.<br/>
 */

var VdtFilter = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('section', {
      staticClass: "vdt-filter__wrapper"
    }, [_c('header', {
      staticClass: "vdt-filter__header"
    }, [_vm._t("search-slot"), _vm._v(" "), _vm.activeButton ? _c('button', {
      staticClass: "vdt-button vdt-button--primary",
      class: {
        'vdt-button--primary--toggled': _vm.showActiveOnly
      },
      on: {
        "click": function ($event) {
          _vm.toggleActive();
        }
      }
    }, [_vm._v("Active")]) : _vm._e(), _vm._v(" "), _vm.clearButton ? _c('button', {
      staticClass: "vdt-button vdt-button--danger",
      on: {
        "click": _vm.clearFilters
      }
    }, [_vm._v("Clear")]) : _vm._e(), _vm._v(" "), _vm.resetButton ? _c('button', {
      staticClass: "vdt-button vdt-button--danger",
      on: {
        "click": _vm.resetFilters
      }
    }, [_vm._v("Reset")]) : _vm._e()], 2), _vm._v(" "), _vm._t("default"), _vm._v(" "), _vm._l(_vm.visibleGroups, function (group) {
      return _c('VdtFilterGroup', {
        key: group.name,
        attrs: {
          "inclusive-color": _vm.inclusiveColor,
          "exclusive-color": _vm.exclusiveColor,
          "group": group,
          "level": 1,
          "show-hits": group.showHits !== undefined ? group.showHits : _vm.showHits,
          "show-active-only": _vm.showActiveOnly,
          "animation-delay": _vm.animationDelay
        },
        on: {
          "updateGroup": function ($event) {
            _vm.update($event);
          },
          "groupVisibilityChange": function ($event) {
            _vm.updateVisibleChildGroups($event);
          }
        }
      });
    }), _vm._v(" "), _vm.isEmpty && _vm.showActiveOnly ? _c('div', {
      staticClass: "vdt-filter__no-active-message"
    }, [_c('h3', [_vm._v("No active filters to show")]), _vm._v(" "), _c('button', {
      staticClass: "vdt-button vdt-button--primary",
      on: {
        "click": _vm.toggleActive
      }
    }, [_vm._v("Show all")])]) : _vm._e()], 2);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-fe324ae2',
  name: 'VdtFilter',
  components: {
    VdtFilterGroup
  },
  props: {
    /**
     * Array of filters to use, see <a href="#filters-prop-description">this</a>
     * for further information and requirements
     */
    filters: {
      type: Array,
      required: true
    },

    /**
     * Will render a "Active" button if set to true (default)
     * Pressing the Active button will make only the active filters visible
     */
    activeButton: {
      type: Boolean,
      default: true,
      required: false
    },

    /**
     * Will render a "Clear" button if set to true (default)
     * Clear button will uncheck all checkbox filters, remove all date filters,
     * and set all range filters to full range (min/max)
     */
    clearButton: {
      type: Boolean,
      default: true,
      required: false
    },

    /**
     * Will render a "Reset" button if set to true.
     * Only use if you provide an array in the originalFilters prop.
     * Reset button will reset all filters to their initial value, as provided in the
     * 'originalFilters' prop on component initialization
     */
    resetButton: {
      type: Boolean,
      default: false,
      required: false
    },

    /**
     * Array used to be able to reset the filters to a preset value.
     * Should follow the structure of the filter prop.
     * Typically a deep copy of the filters array.
     */
    originalFilters: {
      type: Array,

      default() {
        return [];
      },

      required: false
    },

    /**
     * If you are using animations to hide/show the filter component and are
     * experiencing problems with sliders on the range filters, add your
     * animation time in ms to this prop.
     */
    animationDelay: {
      type: Number,
      default: 0,
      reqired: false
    },

    /**
     * Color code used in the filter to distinguish inclusive
     * from exclusive filtering (must be CSS syntax).
     */
    inclusiveColor: {
      type: String,
      required: false,
      default: 'var(--info, var(--inclusive))'
    },

    /**
     * Color code used in the filter to distinguish inclusive
     * from exclusive filtering (must be CSS syntax).
     */
    exclusiveColor: {
      type: String,
      required: false,
      default: 'var(--danger, var(--exclusive))'
    },

    /**
     * Will render a "hit counter" if set to true to any filter options
     * using the "count" key (which can be added by a vidispine search or similar).
     */
    showHits: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      localFilters: [],
      showActiveOnly: false,
      hiddenChildGroups: {}
    };
  },

  computed: {
    visibleGroups() {
      if (this.localFilters.length > 0 && this.showActiveOnly) {
        return this.localFilters.filter(filter$$1 => {
          const hidden = Object.keys(this.hiddenChildGroups).indexOf(filter$$1.name) > -1;
          return !hidden;
        });
      }

      return this.localFilters;
    },

    isEmpty() {
      return this.visibleGroups.length === 0;
    }

  },
  watch: {
    filters: {
      handler(updatedFilters) {
        this.localFilters = cloneDeep(updatedFilters);
      },

      deep: true
    }
  },

  mounted() {
    this.$nextTick(function cloneFilters() {
      this.localFilters = cloneDeep(this.filters);
    });
  },

  methods: {
    toggleActive() {
      this.showActiveOnly = !this.showActiveOnly;
    },

    resetFilters() {
      const resetArray = cloneDeep(this.originalFilters);

      this.localFilters = resetArray;
      this.showActiveOnly = false;
      this.emitFilterUpdates();
    },

    clearFilters() {
      const vm = this;

      function recursiveFunction(group) {
        if (group.options) {
          group.options.forEach(option => {
            const tempOption = option;

            if (option.value === true) {
              tempOption.value = false;
            }

            if (group.type === 'text') {
              tempOption.name = '';
            } else if (Array.isArray(option.value) && group.type === 'range') {
              const [range0, range1] = option.range;
              vm.$set(tempOption.value, 0, range0);
              vm.$set(tempOption.value, 1, range1);
            } else if (Array.isArray(option.value) && group.type === 'date') {
              vm.$set(tempOption.value, 0, null);
              vm.$set(tempOption.value, 1, null);
            }
          });
        }

        if (group.groups) {
          group.groups.forEach(childGroup => {
            recursiveFunction(childGroup);
          });
        }
      }

      this.localFilters.forEach(group => {
        recursiveFunction(group);
      });
      this.showActiveOnly = false;
      this.emitFilterUpdates();
    },

    update(payload) {
      // MUST loop since we cannot know which index the filter has in localFilters
      for (let i = 0; i < this.localFilters.length; i += 1) {
        if (this.localFilters[i].name === payload.group.name) {
          this.$set(this.localFilters, i, payload.group);
          this.emitFilterUpdates(payload);
        }
      }
    },

    emitFilterUpdates({
      expandedToggled = false
    } = {}) {
      if (expandedToggled) {
        /**
        * Emits the updated filter array,
        * this emit means that a filter group has been expanded.
        *
        * @event toggleExpanded
        * @type {Object}
        */
        this.$emit('toggleExpanded', this.localFilters);
      } else {
        /**
        * Emits the updated filter array.
        *
        * @event update:filters
        * @type {Object}
        */
        this.$emit('update:filters', this.localFilters);
      }
    },

    updateVisibleChildGroups({
      group,
      show
    }) {
      if (!show) {
        if (Object.keys(this.hiddenChildGroups).indexOf(group.name) === -1) {
          this.$set(this.hiddenChildGroups, group.name, group);
        }
      } else {
        delete this.hiddenChildGroups[group.name];
      }
    }

  }
};

/**
* @description Displays the image of an image-shape if it exists
* @since 0.9
*/
var VdtImagePreview = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-image-preview"
    }, [_c('img', {
      staticClass: "vdt-image-preview__image",
      class: _vm.imageOrientationClass,
      attrs: {
        "src": _vm.shapeSource,
        "alt": _vm.shapePath
      }
    })]);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-ba653122',
  name: 'VdtImagePreview',
  props: {
    /**
     * @description Shape of an item with the image you want to preview
     */
    shape: {
      type: Object,
      required: true
    }
  },
  computed: {
    shapeSource() {
      let src = '';

      if (this.shape && this.shape.containerComponent) {
        src = this.shape.containerComponent.file[0].uri;
      }

      return src;
    },

    shapePath() {
      let path = '';

      if (this.shape && this.shape.containerComponent) {
        [{
          path
        }] = this.shape.containerComponent.file;
      }

      return path;
    },

    imageOrientationClass() {
      if (this.shape.videoComponent.length && this.shape.videoComponent[0].metadata !== undefined) {
        const orientation = this.shape.videoComponent[0].metadata.find(metadata => metadata.key === 'image_exif_Orientation');
        return orientation ? `vdt-image-preview__image-orientation-${orientation.value}` : null;
      }

      return null;
    }

  }
};

var gridSize = {
  computed: {
    gridSize() {
      const grid = [];
      this.visibleColumns.forEach(column => {
        if (column.size === 'large') {
          const temp = '3fr';
          grid.push(temp);
        } else if (column.size === 'medium') {
          const temp = '2fr';
          grid.push(temp);
        } else if (column.size === 'small' && column.key !== 'thumbnail') {
          const temp = '1fr';
          grid.push(temp);
        } else if (column.size === 'x-small') {
          const temp = '0.5fr';
          grid.push(temp);
        } else if (column.key === 'thumbnail') {
          const temp = '5em';
          grid.push(temp);
        } else {
          const temp = '10em';
          grid.push(temp);
        }
      });

      if (this.checkbox) {
        const temp = '2.5em';
        grid.unshift(temp);
      }

      if (this.actionColumn && this.actions.length) {
        const temp = '4em';
        grid.push(temp);
      }

      const newGrid = grid.join(' ');
      return `grid-template-columns: ${newGrid}`;
    },

    gridSpan() {
      return this.visibleColumns.filter(c => c.key !== 'thumbnail').length; // return `grid-row: 1 /
      // ${this.visibleColumns.filter(c => c.key !== 'thumbnail').length} span`;
    }

  }
};

/**
 * @description Displays a responsive list header with configurable columns.
 * @since 0.9
 *
 * ***
 *
 * ###CSS-variables used:
 *
 * > **--contrast-color-0** background color for list header.<br/>
 * > **--secondary** text color for list column headers.<br/>
 */

var VdtListHeader = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-list__scroll"
    }, [_c('div', {
      staticClass: "vdt-list__wrapper"
    }, [_c('div', {
      staticClass: "vdt-list-header__wrapper",
      style: _vm.gridSize
    }, [_vm.showCheckbox ? _c('div', {
      staticClass: "vdt-list-header__column vdt-list-header__column-checkbox"
    }, [_vm.showCheckbox ? _vm._t("checkbox-slot", [_c('BulkCheckbox', {
      attrs: {
        "checked": _vm.selected,
        "track-by": `${_vm.listName}list-header`
      },
      on: {
        "change": function ($event) {
          _vm.toggleAll();
        }
      }
    })]) : _vm._e()], 2) : _vm._e(), _vm._v(" "), _vm._l(_vm.visibleColumns, function (column) {
      return _c('div', {
        key: column.label,
        staticClass: "vdt-list-header__column",
        class: {
          'vdt-list-header__sort': column.sort,
          'vdt-list-header__isSorted': _vm.isSorted(column.key)
        },
        on: {
          "click": function ($event) {
            column.sort !== undefined ? _vm.updateColumns(column.sort.field) : '';
          }
        }
      }, [_c('span', {
        staticClass: "vdt-list-header__column-value"
      }, [_vm._v(" " + _vm._s(column.type.toLowerCase() !== 'thumbnail' ? column.label : '') + " ")]), _vm._v(" "), column.sort ? _c('div', {
        key: column.sort.order,
        staticClass: "vdt-list-header__column-sort",
        class: `vdt-list-header__column-sort__${column.sort.order}`
      }, [column.sort.order === 'noop' ? _c('Noop', {
        attrs: {
          "title": "No sorting"
        }
      }) : _vm._e(), _vm._v(" "), column.sort.order === 'ascending' ? _c('AscIcon', {
        attrs: {
          "title": "Ascending"
        }
      }) : _vm._e(), _vm._v(" "), column.sort.order === 'descending' ? _c('DscIcon', {
        attrs: {
          "title": "Descending"
        }
      }) : _vm._e()], 1) : _vm._e()]);
    }), _vm._v(" "), _c('div', {
      staticClass: "vdt-list-header__single-sorting"
    }, [_vm._v(" Sorting: "), _c('div', {
      staticClass: "vdt-list-header__single-sorting__dropdown"
    }, [_vm.currentSorting !== null ? _c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.currentSorting,
        expression: "currentSorting"
      }],
      domProps: {
        "value": _vm.currentSorting.label
      },
      on: {
        "change": function ($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });
          _vm.currentSorting = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
        }
      }
    }, [_c('option', {
      attrs: {
        "disabled": "disabled"
      },
      domProps: {
        "value": _vm.newSort
      }
    }, [_vm._v(" " + _vm._s(_vm.newSort.label) + " ")]), _vm._v(" "), _vm._l(_vm.sortableColumns, function (column) {
      return _c('option', {
        key: column.label,
        domProps: {
          "value": column
        }
      }, [_vm._v(" " + _vm._s(column.label) + " ")]);
    })], 2) : _vm._e()]), _vm._v(" "), _vm.currentSorting.label !== 'Set order' ? _c('AscIcon', {
      staticClass: "vdt-list-header__single-sorting__order",
      class: `vdt-list-header__single-sorting__${_vm.currentSorting.sort.order}`,
      attrs: {
        "title": _vm.currentSorting.sort.order
      },
      nativeOn: {
        "click": function ($event) {
          _vm.updateColumns(_vm.currentSorting.sort.field, true);
        }
      }
    }) : _vm._e()], 1), _vm._v(" "), _vm.actionColumn && _vm.actions.length ? _c('div', {
      staticClass: "vdt-list-header__column"
    }, [_c('span', {
      staticClass: "vdt-list-header__column-value"
    }, [_vm._v(" Actions ")])]) : _vm._e(), _vm._v(" "), _vm._t("sorting-slot")], 2), _vm._v(" "), _c('div', {
      staticClass: "vdt-list-row__wrapper"
    }, [_vm._t("default")], 2)])]);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-06d68afc',
  name: 'VdtListHeader',
  components: {
    BulkCheckbox,
    Noop,
    AscIcon: sortIcon,
    DscIcon,
    MscIcon
  },
  mixins: [gridSize],
  props: {
    /**
     * @description Array with column-objects.
     */
    columns: {
      type: Array,
      required: true
    },

    /**
     * @description Show for a check-all checkbox, 'hidden' to only add an aesthetic space
     * Emits it's current value when clicked.
     */
    checkbox: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * @description Selected state being true if all shown assets are selected.
     */
    selected: {
      type: Boolean,
      reqired: false,
      default: false
    },

    /**
     * @description If you will use several lists in the same window, provide this unique string.
     */
    listName: {
      type: String,
      required: false,
      default: ''
    },

    /**
     * @description Array with actions per listRow.
     */
    actions: {
      type: Array,
      required: false,
      default: () => []
    },
    actionColumn: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data() {
    return {
      newSort: {
        label: 'Set order',
        sort: {
          field: 'none',
          order: 'noop'
        }
      }
    };
  },

  computed: {
    visibleColumns() {
      if (findIndex(this.columns, 'visible') > -1) {
        return this.columns.filter(column => column.visible === true);
      }

      return this.columns;
    },

    showCheckbox() {
      return this.checkbox && this.checkbox !== 'hidden';
    },

    sortableColumns() {
      return this.visibleColumns.filter(column => column.sort !== undefined);
    },

    currentSorting: {
      get() {
        const temp = this.sortableColumns.find(column => column.sort.order !== 'noop');
        return temp !== undefined ? temp : this.newSort;
      },

      set(newVal) {
        if (newVal !== undefined) {
          this.updateColumns(newVal.sort.field, true);
        } else {
          this.currentSorting = this.newSort;
        }
      }

    }
  },
  methods: {
    toggleAll() {
      /**
      * @event toggleAll
      * @description Emits when the Select all checkbox is toggled.
      * @type null
      */
      this.$emit('toggleAll');
    },

    updateColumns(field, ascDescOnly = false) {
      const newColumns = cloneDeep(this.columns);

      for (let i = 0; i < newColumns.length; i += 1) {
        if (newColumns[i].sort) {
          if (newColumns[i].sort.field === field) {
            if (ascDescOnly) {
              if (newColumns[i].sort.order === 'ascending') {
                newColumns[i].sort.order = 'descending';
              } else if (newColumns[i].sort.order === 'descending') {
                newColumns[i].sort.order = 'ascending';
              } else {
                newColumns[i].sort.order = 'ascending';
              }
            } else if (!ascDescOnly) {
              if (newColumns[i].sort.order === 'descending') {
                newColumns[i].sort.order = 'ascending';
              } else if (newColumns[i].sort.order === 'ascending') {
                newColumns[i].sort.order = 'noop';
              } else {
                newColumns[i].sort.order = 'descending';
              }
            }
          } else if (newColumns[i].sort) {
            newColumns[i].sort.order = 'noop';
          }
        }
      }
      /**
       * @event sortUpdate
       * @description Emits the changed list of columns.
       * @type {Array}
       */


      this.$emit('sortUpdate', newColumns);
    },

    isSorted(column) {
      if (this.currentSorting.key !== undefined) {
        return this.currentSorting.key.includes(column);
      }

      return false;
    }

  }
};

class Size {
  static formatSize(bytes = 0) {
    const units = ['Bytes', 'KB', 'MB', 'GB'];
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes} ${units[i]}`;
    return `${(bytes / 1024 ** i).toFixed(1)} ${units[i]}`;
  }

}

class Runtime {
  static formatTime(seconds) {
    const newTime = new Date(seconds * 1000).toISOString().substr(11, 12);
    return newTime;
  }

}

/**
 * @description Shows a responsive list row with configurable columns.
 * Can be used toghether with VdtListHeader
 * @since 0.9
 *
 * ***
 *
 * ###CSS-variables used:
 *
 * > **--contrast-color-1** border color, text color for selected items, selected row hover
 * effect with high opacity.<br/>
 * > **--contrast-color-9** text color for regular rows, action button :hover and .active
 * background.<br/>
 * > **--danger** text color of error columns.<br/>
 * > **--info** text color of link columns.<br/>
 * > **--secondary** background color for selected items, row hover effect with high
 * opacity.<br/>
 */

var VdtListRow = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-list-row",
      class: {
        'vdt-list-row__selected': _vm.selected,
        'disableClick': _vm.disableClick,
        'vdt-list-row__no-thumbnail': _vm.thumbnailColumnIndex === -1
      },
      style: _vm.gridSize,
      on: {
        "!contextmenu": function ($event) {
          _vm.contextMenu ? _vm.toggleContextMenu($event) : '';
        },
        "dblclick": function ($event) {
          if ($event.target !== $event.currentTarget) {
            return null;
          }

          _vm.dblClick();
        },
        "click": function ($event) {
          if ($event.target !== $event.currentTarget) {
            return null;
          }

          _vm.click($event);
        }
      }
    }, [_vm.checkbox ? _c('div', {
      staticClass: "vdt-list-row__column vdt-list-row__column--checkbox",
      style: `--gridSpan: ${_vm.gridSpan}`,
      attrs: {
        "data-label": 'Checkbox'
      }
    }, [_vm._t("checkbox-slot", [_c('BulkCheckbox', {
      attrs: {
        "checked": _vm.selected,
        "track-by": _vm.listName + _vm.trackBy
      },
      on: {
        "change": function ($event) {
          _vm.toggle();
        }
      }
    })])], 2) : _vm._e(), _vm._v(" "), _vm._l(_vm.filteredAsset, function (value, key, index) {
      return _c('div', {
        key: index,
        staticClass: "vdt-list-row__column",
        class: {
          'vdt-list-row__isSorted': _vm.isSorted(key)
        },
        style: `--gridSpan: ${_vm.gridSpan}`,
        attrs: {
          "data-label": _vm.getLabel(index)
        },
        on: {
          "dblclick": function ($event) {
            _vm.dblClick();
          },
          "click": function ($event) {
            _vm.click($event);
          }
        }
      }, [index === _vm.thumbnailColumnIndex ? _vm._o(_c('div', {
        staticClass: "vdt-list-row__column__thumbnail",
        class: {
          'no-thumbnail': !value
        },
        style: _vm.getThumbnail(value)
      }), 0, index) : index === _vm.errorColumnIndex ? _c('span', {
        staticClass: "vdt-list-row__column__error"
      }, [_vm._v(" " + _vm._s(value) + " ")]) : _c('span', {
        staticClass: "vdt-list-row__column__value",
        class: {
          'vdt-list-row__column__link': _vm.isLink(index)
        },
        on: {
          "click": function ($event) {
            _vm.isLink(index) ? _vm.$emit('linkClick', value) : '';
          }
        }
      }, [_vm._v(" " + _vm._s(value) + " ")])]);
    }), _vm._v(" "), _vm.actionColumn && _vm.actions.length ? _c('div', {
      staticClass: "vdt-list-row__column vdt-list-row__column--actions",
      style: `--gridSpan: ${_vm.gridSpan}`,
      attrs: {
        "data-label": "Actions"
      },
      on: {
        "mouseleave": function ($event) {
          _vm.showActions = false;
        }
      }
    }, [_c('button', {
      staticClass: "vdt-list-row__column--actions__button",
      class: {
        'active': _vm.showActions
      },
      on: {
        "click": function ($event) {
          _vm.showActions = !_vm.showActions;
        }
      }
    }, [_c('DotsHorizontal', {
      attrs: {
        "title": ""
      }
    })], 1), _vm._v(" "), _vm.showActions ? _c('ul', {
      staticClass: "vdt-list-row__column--actions__menu"
    }, _vm._l(_vm.actions, function (action) {
      return _c('li', {
        key: action.label,
        on: {
          "click": function ($event) {
            _vm.customAction(action.emit);
          }
        }
      }, [_vm._v(" " + _vm._s(action.label) + " ")]);
    })) : _vm._e()]) : _vm._e()], 2);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-187e227b',
  name: 'VdtListRow',
  components: {
    BulkCheckbox,
    DotsHorizontal
  },
  mixins: [gridSize, ToggleContextMenu],
  props: {
    /**
     * @description Single asset which keys (defined in columns) should be displayed.
     */
    asset: {
      type: Object,
      required: true
    },

    /**
     * @description Array with column-objects.
     */
    columns: {
      type: Array,
      required: true
    },

    /**
    * @description Make asset selectable with a checkbox on every row.
    */
    checkbox: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
    * @description State of the asset, toggled and emitted.
    */
    selected: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
    * @description Path of a unique identifier on the asset to help tracking it.
    */
    trackBy: {
      type: [String, Number],
      required: true
    },

    /**
    * @description If you will use several lists in the same window, provide this unique string.
    */
    listName: {
      type: String,
      required: false,
      default: ''
    },

    /**
    * @description Array containing custom actions, each object should contain a label and an emit.
    * The label will be displayed in the dropdown while the emit will be the name of the emit
    * sent by the component, i.e. the event to listen to in your wrapper.
    */
    actions: {
      type: Array,
      required: false,
      default: () => []
    },

    /**
     * @description Disable click events for assets.
     */
    disableClick: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * @description If true, enables a custom context menu with options defined in 'actions' prop.
     */
    contextMenu: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * @description If true, displays options defined in 'actions' in a separate column.
     */
    actionColumn: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data() {
    return {
      showActions: false
    };
  },

  computed: {
    visibleColumns() {
      if (findIndex(this.columns, 'visible') > -1) {
        return this.columns.filter(column => column.visible === true);
      }

      return this.columns;
    },

    /**
     * Filters columns-stuff from assets.
     * @params none
     */
    filteredAsset() {
      const filteredAsset = this.visibleColumns.reduce((acc, column) => {
        const map = acc;

        const key = last(column.key.split('.'));

        const value = get(this.asset, column.key);

        if (value === undefined && column.type.toLowerCase() === 'error') {
          map[key] = '';
          return map;
        }

        if (value === undefined && column.type.toLowerCase() !== 'thumbnail') {
          map[key] = '-';
          return map;
        }

        if (column.type.toLowerCase() === 'date') {
          map[key] = DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT);
        } else if (column.type.toLowerCase() === 'size') {
          map[key] = Size.formatSize(value);
        } else if (column.type.toLowerCase() === 'time') {
          map[key] = Runtime.formatTime(value);
        } else if (column.type.toLowerCase() === 'error') {
          if ([0, '', '0', null, undefined].includes(value)) {
            map[key] = '';
          } else {
            map[key] = value;
          }
        } else {
          map[key] = value;
        }

        return map;
      }, {});
      return filteredAsset;
    },

    thumbnailColumnIndex() {
      const types = this.visibleColumns.map(column => column.type.toLowerCase());
      return types.indexOf('thumbnail');
    },

    errorColumnIndex() {
      const types = this.visibleColumns.map(column => column.type.toLowerCase());
      return types.indexOf('error');
    },

    currentSorting() {
      const sortableColumns = this.columns.filter(column => column.sort !== undefined);
      return sortableColumns.find(column => column.sort.order !== 'noop');
    }

  },
  methods: {
    /**
     * @event openContextMenu
     * @description Emits an event to trigger a context menu when right clicked.
     * @type {Emit}
     */
    getLabel(index) {
      return index === this.thumbnailColumnIndex ? 'Thumbnail' : this.visibleColumns[index].label;
    },

    getThumbnail(url) {
      return {
        backgroundImage: `url(${!Array.isArray(url) ? url : url[0]})`
      };
    },

    isLink(index) {
      return this.columns[index].type.toLowerCase() === 'link';
    },

    click(event) {
      if (!this.disableClick) {
        /**
        * @event assetClick
        * @description Emits the asset clicked.
        * @type {Object}
        */
        const {
          ctrlKey,
          metaKey,
          shiftKey
        } = event;

        if (ctrlKey || metaKey) {
          this.toggle();
        } else if (shiftKey) {
          this.toggleMulti();
        } else {
          this.$emit('assetClick', this.asset);
        }
      }
    },

    dblClick() {
      if (!this.disableClick) {
        /**
         * @event assetDblClick
         * @description Emits the asset when double clicked.
         * @type {Object}
        */
        this.$emit('assetDblClick', this.asset);
      }
    },

    customAction(emit) {
      /**
       * @event customAction
       * @description Will emit two actions, one 'customAction' to be used by the List
       * wrapper, and one of the actual action to be used by a custom wrapper component.
       * @type {Object}
       */
      this.$emit('customAction', {
        emit,
        asset: this.asset
      });
      this.$emit(emit, this.asset);
    },

    toggle() {
      /**
       * @event checkboxToggled
       * @description Emits the asset that was toggled.
       * @type {Object}
       */
      if (this.checkbox) {
        this.$emit('checkboxToggled', {
          asset: this.asset
        });
      }
    },

    toggleMulti() {
      /**
       * @event checkboxToggleMulti
       * @description Emits the assets that were toggled.
       * @type {Object}
       */
      if (this.checkbox) {
        document.getSelection().removeAllRanges();
        this.$emit('checkboxToggleMulti', {
          asset: this.asset
        });
      }
    },

    isSorted(column) {
      if (this.currentSorting !== undefined) {
        return this.currentSorting.key.includes(column);
      }

      return false;
    }

  }
};

/**
 * @description Displays a list of [prop] assets with custom [prop] columns.
 * Ability to select assets with [prop] checkbox.
 * Wraps VdtListHeader and VdtListRow.
 * @since 1.0
 *
 * ***
 *
 * ###CSS-variables used:
 *
 * > **--contrast-color-0** background color for list header.<br/>
 * > **--contrast-color-1** border color, text color for selected items, selected row hover
 * effect with high opacity, active list row text color.<br/>
 * > **--contrast-color-9** text color for regular rows, action button :hover and .active
 * background.<br/>
 * > **--danger** text color of error columns.<br/>
 * > **--primary** background color of active rows.<br/>
 * > **--secondary** background color for selected items, row hover effect with high opacity,
 * text color for list column headers.<br/>
 */

var VdtList = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      directives: [{
        name: "responsive",
        rawName: "v-responsive",
        value: _vm.breakpoints,
        expression: "breakpoints"
      }],
      staticClass: "vdt-list"
    }, [_c('VdtListHeader', {
      attrs: {
        "columns": _vm.columns,
        "checkbox": _vm.checkbox,
        "selected": _vm.allChecked,
        "list-name": _vm.listName,
        "actions": _vm.actions,
        "action-column": false
      },
      on: {
        "toggleAll": function ($event) {
          _vm.checkAll($event);
        },
        "sortUpdate": function ($event) {
          _vm.$emit('sortUpdate', $event);
        }
      }
    }, [_c('transition-group', {
      attrs: {
        "name": "fade",
        "tag": "div"
      }
    }, _vm._l(_vm.assets, function (asset) {
      return _c('VdtListRow', {
        key: _vm.getKey(asset),
        class: {
          'vdt-list-row__active': _vm.activeAsset(asset)
        },
        attrs: {
          "track-by": _vm.getKey(asset),
          "asset": asset,
          "columns": _vm.columns,
          "checkbox": _vm.checkbox,
          "list-name": _vm.listName,
          "selected": _vm.isSelected(asset),
          "actions": _vm.actions,
          "action-column": _vm.actionColumn,
          "context-menu": _vm.contextMenu,
          "disable-click": _vm.disableClick
        },
        on: {
          "openContextMenu": function ($event) {
            _vm.$emit('openContextMenu', $event);
          },
          "contextMenuOption": function ($event) {
            _vm.$emit(`${$event.emit}`, $event.asset);
          },
          "assetClick": function ($event) {
            _vm.assetClicked(asset);
          },
          "assetDblClick": function ($event) {
            _vm.$emit('assetDblClick', asset);
          },
          "checkboxToggled": function ($event) {
            _vm.updateSelected($event);
          },
          "checkboxToggleMulti": function ($event) {
            _vm.updateSelectedMulti($event);
          },
          "customAction": function ($event) {
            _vm.customAction($event);
          },
          "linkClick": function ($event) {
            _vm.$emit('linkClick', $event);
          }
        }
      });
    }))], 1)], 1);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-87fbdfe2',
  name: 'VdtList',
  components: {
    VdtListHeader,
    VdtListRow,
    sortIcon
  },
  directives: {
    responsive: ResponsiveDirective
  },
  mixins: [BulkSelection],
  props: {
    /**
     * @description Array with assets.
     */
    assets: {
      type: Array,
      required: true
    },

    /**
     * @description Array with columns to be displayed.
     */
    columns: {
      type: Array,
      required: true
    },

    /**
     * @description If you will use several lists in the same window, provide this unique string.
     */
    listName: {
      type: String,
      required: false,
      default: ''
    },

    /**
     * @description Array containing custom actions, each object should contain a label and an emit.
     * The label will be displayed in the dropdown while the emit will be the name of the emit
     * sent by the component.
     */
    actions: {
      type: Array,
      required: false,
      default: () => []
    },

    /**
     * @description Pixel width breakpoint at which layout switches to adapt to smaller screens.
     */
    breakPointWidth: {
      type: Number,
      default: 800,
      required: false
    },

    /**
     * @description If true, enables a custom context menu with options defined in 'actions' prop.
     */
    contextMenu: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * @description If true, displays options defined in 'actions' in a separate column.
     */
    actionColumn: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    // visibleColumns() {
    //   if (_findIndex(this.columns, 'visible') > -1) {
    //     return this.columns.filter(column => column.visible === true);
    //   }
    //   return this.columns;
    // },
    breakpoints() {
      return {
        small: el => el.width <= this.breakPointWidth
      };
    }

  },
  methods: {
    customAction(event) {
      /**
       * @event customAction
       * @description Emits the asset along with a user supplied action.
       * @type {Object}
       */
      this.$emit(event.emit, event.asset);
    },

    getKey(asset) {
      return get(asset, this.trackBy);
    }

  }
};

/**
 * @description Shows a login prompt that makes an request to a custom loginUrl
 * that should handle the request.
 * @since 0.9
 *
 * ***
 *
 *  ###CSS-variables used:
 *
 * > **--contrast-color-9** text color of login prompt.<br/>
 * > **--contrast-color-0** background color of login prompt.<br/>
 * > **--danger** error message color.<br/>
 * > **--primary** input field border bottom color. Also background-color of "Login" button.<br/>
 */

var VdtLoginPrompt = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-login-prompt"
    }, [_c('h2', [_vm._v("Login")]), _vm._v(" "), _c('form', {
      staticClass: "vdt-login-prompt__form"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.data.username,
        expression: "data.username"
      }],
      ref: "vdt-login-prompt-username",
      staticClass: "vdt-login-prompt__form-input vdt-login-prompt__form-user",
      attrs: {
        "placeholder": _vm.usernamePlaceholder
      },
      domProps: {
        "value": _vm.data.username
      },
      on: {
        "keyup": function ($event) {
          if (!('button' in $event) && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) {
            return null;
          }

          return _vm.login($event);
        },
        "focus": function ($event) {
          $event.target.select();
        },
        "input": function ($event) {
          if ($event.target.composing) {
            return;
          }

          _vm.$set(_vm.data, "username", $event.target.value);
        }
      }
    }), _vm._v(" "), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.data.password,
        expression: "data.password"
      }],
      ref: "vdt-login-prompt-password",
      staticClass: "vdt-login-prompt__form-input vdt-login-prompt__form-pass",
      attrs: {
        "placeholder": _vm.passwordPlaceholder,
        "type": "password"
      },
      domProps: {
        "value": _vm.data.password
      },
      on: {
        "keyup": function ($event) {
          if (!('button' in $event) && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) {
            return null;
          }

          return _vm.login($event);
        },
        "focus": function ($event) {
          $event.target.select();
        },
        "input": function ($event) {
          if ($event.target.composing) {
            return;
          }

          _vm.$set(_vm.data, "password", $event.target.value);
        }
      }
    }), _vm._v(" "), _c('div', {
      staticClass: "vdt-login-prompt__footer"
    }, [_vm.errorMessage.length ? _c('span', {
      staticClass: "vdt-login-prompt__error-message"
    }, [_vm._v(" " + _vm._s(_vm.errorMessage) + " ")]) : _vm._e(), _vm._v(" "), _c('button', {
      staticClass: "vdt-login-prompt__form-login vdt-button vdt-button--primary",
      attrs: {
        "type": "button"
      },
      on: {
        "click": _vm.login
      }
    }, [_vm._v(" Login ")])])])]);
  },
  staticRenderFns: [],
  name: 'VdtLoginPrompt',
  props: {
    /**
    * @description String of the exact backend url to Login
    *
    * example:
    * :login-url="'/api/login'"
    * this will result in a Backend call to:
    * PUT '/api/login'
    *
    * containing a body with:
    * username, password, autoRefresh, seconds
    */
    loginUrl: {
      type: String,
      required: true
    },

    /**
    * @description If the token should be refreshed on every request.
    */
    refreshToken: {
      type: Boolean,
      default: false,
      required: false
    },

    /**
    * @description If true, username will be converted to lowercase before submitting.
    */
    caseinsensitive: {
      type: Boolean,
      default: false,
      required: false
    },

    /**
    * @description The placeholder string of the username field.
    */
    usernamePlaceholder: {
      type: String,
      default: 'Vidispine Username',
      required: false
    },

    /**
    * @description The placeholder string of the password field.
    */
    passwordPlaceholder: {
      type: String,
      default: 'Vidispine Password',
      required: false
    },

    /**
    * @description How many seconds before the token expires.
    */
    seconds: {
      type: Number,
      default: null,
      required: false
    }
  },

  data() {
    return {
      data: {
        username: '',
        password: '',
        autoRefresh: this.refreshToken,
        seconds: this.seconds
      },
      errorMessage: ''
    };
  },

  methods: {
    login() {
      if (this.caseinsensitive && this.data.username) {
        this.data.username = this.data.username.toLowerCase();
      }

      this.errorMessage = '';
      axios.put(this.loginUrl, this.data).then(response => {
        this.data = {
          username: '',
          password: ''
        };

        if (response.data.content === 'Successful login') {
          /**
           * @event loggedIn
           * @type null
           */
          this.$emit('loggedIn');
        }
      }).catch(error => {
        if (error.response.status === 401) {
          this.errorMessage = error.response.data.content;
          /**
          * @event error
          * @type object
          */

          this.$emit('error', error.response);
        }
      });
    }

  }
};

/**
 * @description Used to display single metadata fields depending on field type and editing options
 */

var VdtMetadataField = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return !_vm.form ? _c('div', {
      staticClass: "vdt-metadata__row"
    }, [_c('span', {
      staticClass: "vdt-metadata__row__key"
    }, [_vm._v(" " + _vm._s(_vm.field.key) + " ")]), _vm._v(" "), _vm.field.type === 'boolean' ? _c('div', {
      staticClass: "vdt-metadata__row__value"
    }, [_c('div', [_c('div', {
      staticClass: "vdt-metadata__row--input"
    }, [_vm.field.editable ? _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.pickedValue,
        expression: "pickedValue"
      }],
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "checked": Array.isArray(_vm.pickedValue) ? _vm._i(_vm.pickedValue, null) > -1 : _vm.pickedValue
      },
      on: {
        "change": [function ($event) {
          var $$a = _vm.pickedValue,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = null,
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && (_vm.pickedValue = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.pickedValue = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.pickedValue = $$c;
          }
        }, function ($event) {
          _vm.emitChanges();
        }]
      }
    }) : _c('span', [_vm._v(_vm._s(_vm.field.value))])])])]) : !_vm.editing && _vm.field.editable ? _c('div', {
      ref: _vm.field.field,
      staticClass: "vdt-metadata__row__value vdt-metadata__row__value--editable",
      class: {
        'vdt-metadata__row__mandatory': _vm.field.mandatory && !RegExp(_vm.field.mandatory).exec(_vm.pickedValue)
      },
      on: {
        "click": function ($event) {
          _vm.toggleEditing($event);
        }
      }
    }, [_c('span', [_vm._v(" " + _vm._s(_vm.value || _vm.valuePlaceholder) + " ")]), _vm._v(" "), _vm.field.tooltip && _vm.field.mandatory && !RegExp(_vm.field.mandatory).exec(_vm.pickedValue) ? _c('span', {
      staticClass: "vdt-metadata__row__mandatory-tooltip"
    }, [_vm._v(" " + _vm._s(_vm.field.tooltip) + " ")]) : _vm._e(), _vm._v(" "), _vm.field.type === 'counter' ? _c('div', {
      staticClass: "vdt-metadata__counter-edit"
    }, [_c('div', {
      staticClass: "vdt-metadata__counter-edit-icon",
      on: {
        "click": function ($event) {
          _vm.changeCount(1);
        }
      }
    }, [_vm._v("+")]), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata__counter-edit-icon",
      on: {
        "click": function ($event) {
          _vm.changeCount(-1);
        }
      }
    }, [_vm._v("-")])]) : _c('pencil', {
      staticClass: "vdt-metadata__row__edit",
      attrs: {
        "title": "Edit"
      }
    })], 1) : _vm.editing && _vm.field.options ? _c('div', {
      staticClass: "vdt-metadata__row__value",
      class: {
        editing: _vm.editing
      }
    }, [_c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.pickedValue,
        expression: "pickedValue"
      }],
      staticClass: "vdt-metadata__row--input",
      on: {
        "change": [function ($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });
          _vm.pickedValue = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
        }, function ($event) {
          _vm.emitChanges();
        }]
      }
    }, _vm._l(_vm.newOptions, function (option) {
      return _c('option', {
        key: option,
        domProps: {
          "value": option
        }
      }, [_vm._v(" " + _vm._s(option) + " ")]);
    })), _vm._v(" "), _c('close', {
      attrs: {
        "title": ""
      },
      nativeOn: {
        "click": function ($event) {
          _vm.dismissChanges();
        }
      }
    })], 1) : _vm.editing && (_vm.field.type === 'string' || _vm.field.type === 'link') && _vm.value.length < 100 ? _c('div', {
      staticClass: "vdt-metadata__row__value",
      class: {
        editing: _vm.editing,
        'vdt-metadata__row__mandatory': _vm.field.mandatory && !RegExp(_vm.field.mandatory).exec(_vm.pickedValue)
      }
    }, [_vm.field.tooltip && _vm.field.mandatory && !RegExp(_vm.field.mandatory).exec(_vm.pickedValue) ? _c('span', {
      staticClass: "vdt-metadata__row__editing-mandatory-tooltip"
    }, [_vm._v(" " + _vm._s(_vm.field.tooltip) + " ")]) : _vm._e(), _vm._v(" "), _c('div', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.pickedValue,
        expression: "pickedValue"
      }],
      staticClass: "vdt-metadata__row--input",
      attrs: {
        "type": "text"
      },
      domProps: {
        "value": _vm.pickedValue
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) {
            return;
          }

          _vm.pickedValue = $event.target.value;
        }
      }
    })]), _vm._v(" "), _c('check', {
      attrs: {
        "title": "Save"
      },
      nativeOn: {
        "click": function ($event) {
          _vm.emitChanges();
        }
      }
    }), _vm._v(" "), _c('close', {
      attrs: {
        "title": ""
      },
      nativeOn: {
        "click": function ($event) {
          _vm.dismissChanges();
        }
      }
    })], 1) : _vm.editing && _vm.field.type === 'date' ? _c('div', {
      staticClass: "vdt-metadata__row__value",
      class: {
        editing: _vm.editing
      }
    }, [_c('DateTimePicker', {
      attrs: {
        "value": _vm.field.value
      },
      on: {
        "updateValue": function ($event) {
          _vm.pickedValue = $event;
        }
      }
    }), _vm._v(" "), _c('check', {
      attrs: {
        "title": "Save"
      },
      nativeOn: {
        "click": function ($event) {
          _vm.emitChanges();
        }
      }
    }), _vm._v(" "), _c('close', {
      attrs: {
        "title": ""
      },
      nativeOn: {
        "click": function ($event) {
          _vm.dismissChanges();
        }
      }
    })], 1) : _vm.editing && _vm.field.type === 'time' ? _c('div', {
      staticClass: "vdt-metadata__row__value",
      class: {
        editing: _vm.editing,
        'vdt-metadata__row__mandatory': _vm.field.mandatory && !RegExp(_vm.field.mandatory).exec(_vm.pickedValue)
      }
    }, [_vm.field.tooltip && _vm.field.mandatory && !RegExp(_vm.field.mandatory).exec(_vm.pickedValue) ? _c('span', {
      staticClass: "vdt-metadata__row__editing-mandatory-tooltip"
    }, [_vm._v(" " + _vm._s(_vm.field.tooltip) + " ")]) : _vm._e(), _vm._v(" "), _c('div', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.pickedValue,
        expression: "pickedValue"
      }],
      staticClass: "vdt-metadata__row--input",
      attrs: {
        "placeholder": "seconds",
        "type": "number"
      },
      domProps: {
        "value": _vm.pickedValue
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) {
            return;
          }

          _vm.pickedValue = $event.target.value;
        }
      }
    })]), _vm._v(" "), _c('check', {
      attrs: {
        "title": "Save"
      },
      nativeOn: {
        "click": function ($event) {
          _vm.emitChanges();
        }
      }
    }), _vm._v(" "), _c('close', {
      attrs: {
        "title": ""
      },
      nativeOn: {
        "click": function ($event) {
          _vm.dismissChanges();
        }
      }
    })], 1) : _vm.editing && _vm.field.type === 'number' ? _c('div', {
      staticClass: "vdt-metadata__row__value",
      class: {
        editing: _vm.editing,
        'vdt-metadata__row__mandatory': _vm.field.mandatory && !RegExp(_vm.field.mandatory).exec(_vm.pickedValue)
      }
    }, [_vm.field.tooltip && _vm.field.mandatory && !RegExp(_vm.field.mandatory).exec(_vm.pickedValue) ? _c('span', {
      staticClass: "vdt-metadata__row__editing-mandatory-tooltip"
    }, [_vm._v(" " + _vm._s(_vm.field.tooltip) + " ")]) : _vm._e(), _vm._v(" "), _c('div', [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.pickedValue,
        expression: "pickedValue"
      }],
      staticClass: "vdt-metadata__row--input",
      attrs: {
        "placeholder": "seconds",
        "type": "number"
      },
      domProps: {
        "value": _vm.pickedValue
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) {
            return;
          }

          _vm.pickedValue = $event.target.value;
        }
      }
    })]), _vm._v(" "), _c('check', {
      attrs: {
        "title": "Save"
      },
      nativeOn: {
        "click": function ($event) {
          _vm.emitChanges();
        }
      }
    }), _vm._v(" "), _c('close', {
      attrs: {
        "title": ""
      },
      nativeOn: {
        "click": function ($event) {
          _vm.dismissChanges();
        }
      }
    })], 1) : _vm.editing && _vm.field.type === 'string' && _vm.value.length > 99 ? _c('div', {
      staticClass: "vdt-metadata__row__value",
      class: {
        editing: _vm.editing,
        'vdt-metadata__row__mandatory': _vm.field.mandatory && !RegExp(_vm.field.mandatory).exec(_vm.pickedValue)
      }
    }, [_vm.field.tooltip && _vm.field.mandatory && !RegExp(_vm.field.mandatory).exec(_vm.pickedValue) ? _c('span', {
      staticClass: "vdt-metadata__row__editing-mandatory-tooltip"
    }, [_vm._v(" " + _vm._s(_vm.field.tooltip) + " ")]) : _vm._e(), _vm._v(" "), _c('div', [_c('textarea', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.pickedValue,
        expression: "pickedValue"
      }],
      ref: "textarea",
      staticClass: "vdt-metadata__row--input",
      attrs: {
        "type": "text"
      },
      domProps: {
        "value": _vm.pickedValue
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) {
            return;
          }

          _vm.pickedValue = $event.target.value;
        }
      }
    })]), _vm._v(" "), _c('check', {
      attrs: {
        "title": "Save"
      },
      nativeOn: {
        "click": function ($event) {
          _vm.emitChanges();
        }
      }
    }), _vm._v(" "), _c('close', {
      attrs: {
        "title": ""
      },
      nativeOn: {
        "click": function ($event) {
          _vm.dismissChanges();
        }
      }
    })], 1) : _c('span', {
      staticClass: "vdt-metadata__row__value",
      on: {
        "click": function ($event) {
          _vm.field.type === 'link' ? _vm.$emit('linkClick', _vm.value) : '';
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.value) + " ")])]) : _c('div', {
      staticClass: "vdt-metadata__row"
    }, [_c('span', {
      staticClass: "vdt-metadata__row__key"
    }, [_vm._v(" " + _vm._s(_vm.field.key) + " ")]), _vm._v(" "), _vm.field.type === 'boolean' ? _c('div', {
      staticClass: "vdt-metadata__row__value"
    }, [_c('div', [_c('div', {
      staticClass: "vdt-metadata__row--input"
    }, [_vm.field.editable ? _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.pickedValue,
        expression: "pickedValue"
      }],
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "checked": Array.isArray(_vm.pickedValue) ? _vm._i(_vm.pickedValue, null) > -1 : _vm.pickedValue
      },
      on: {
        "change": [function ($event) {
          var $$a = _vm.pickedValue,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = null,
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && (_vm.pickedValue = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.pickedValue = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.pickedValue = $$c;
          }
        }, function ($event) {
          _vm.$emit('input', {
            field: _vm.field,
            value: $event.target.checked
          });
        }]
      }
    }) : _c('span', [_vm._v(_vm._s(_vm.field.value))])])])]) : _vm.field.editable && _vm.field.type === 'counter' ? _c('div', {
      staticClass: "vdt-metadata__row__value vdt-metadata__row__value--editable"
    }, [_c('span', [_vm._v(" " + _vm._s(_vm.pickedValue) + " ")]), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata__counter-edit"
    }, [_c('div', {
      staticClass: "vdt-metadata__counter-edit-icon",
      on: {
        "click": function ($event) {
          _vm.$emit('input', {
            field: _vm.field,
            value: _vm.getValue + 1
          });
        }
      }
    }, [_vm._v("+")]), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata__counter-edit-icon",
      on: {
        "click": function ($event) {
          _vm.$emit('input', {
            field: _vm.field,
            value: _vm.getValue - 1
          });
        }
      }
    }, [_vm._v("-")])])]) : _vm.field.editable && _vm.field.options ? _c('div', {
      staticClass: "vdt-metadata__row__value",
      class: {
        'vdt-metadata__row__mandatory': _vm.field.mandatory && !RegExp(_vm.field.mandatory).exec(_vm.pickedValue)
      }
    }, [_vm.field.tooltip && _vm.field.mandatory && !RegExp(_vm.field.mandatory).exec(_vm.pickedValue) ? _c('span', {
      staticClass: "vdt-metadata__row__editing-mandatory-tooltip"
    }, [_vm._v(" " + _vm._s(_vm.field.tooltip) + " ")]) : _vm._e(), _vm._v(" "), _c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.pickedValue,
        expression: "pickedValue"
      }],
      staticClass: "vdt-metadata__row--input",
      on: {
        "change": [function ($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });
          _vm.pickedValue = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
        }, function ($event) {
          _vm.$emit('input', {
            field: _vm.field,
            value: $event.target.value
          });
        }]
      }
    }, _vm._l(_vm.newOptions, function (option) {
      return _c('option', {
        key: option,
        domProps: {
          "value": option
        }
      }, [_vm._v(" " + _vm._s(option) + " ")]);
    }))]) : _vm.field.editable && _vm.field.type === 'string' ? _c('div', {
      staticClass: "vdt-metadata__row__value",
      class: {
        'vdt-metadata__row__mandatory': _vm.field.mandatory && !RegExp(_vm.field.mandatory).exec(_vm.pickedValue)
      }
    }, [_vm.field.tooltip && _vm.field.mandatory && !RegExp(_vm.field.mandatory).exec(_vm.pickedValue) ? _c('span', {
      staticClass: "vdt-metadata__row__editing-mandatory-tooltip"
    }, [_vm._v(" " + _vm._s(_vm.field.tooltip) + " ")]) : _vm._e(), _vm._v(" "), _c('div', [_c('input', {
      staticClass: "vdt-metadata__row--input",
      staticStyle: {
        "margin-bottom": "0"
      },
      attrs: {
        "type": "text"
      },
      domProps: {
        "value": _vm.pickedValue
      },
      on: {
        "input": function ($event) {
          _vm.$emit('input', {
            field: _vm.field,
            value: $event.target.value
          });
        }
      }
    })])]) : _vm.field.editable && _vm.field.type === 'date' ? _c('div', {
      staticClass: "vdt-metadata__row__value",
      class: {
        'vdt-metadata__row__mandatory': _vm.field.mandatory && !RegExp(_vm.field.mandatory).exec(_vm.pickedValue)
      }
    }, [_vm.field.tooltip && _vm.field.mandatory && !RegExp(_vm.field.mandatory).exec(_vm.pickedValue) ? _c('span', {
      staticClass: "vdt-metadata__row__editing-mandatory-tooltip"
    }, [_vm._v(" " + _vm._s(_vm.field.tooltip) + " ")]) : _vm._e(), _vm._v(" "), _c('div', [_c('input', {
      staticClass: "vdt-metadata__row--input",
      attrs: {
        "type": "datetime-local"
      },
      domProps: {
        "value": _vm.pickedValue
      },
      on: {
        "input": function ($event) {
          _vm.$emit('input', {
            field: _vm.field,
            value: $event.target.value
          });
        }
      }
    })])]) : _vm.field.editable && _vm.field.type === 'time' ? _c('div', {
      staticClass: "vdt-metadata__row__value",
      class: {
        'vdt-metadata__row__mandatory': _vm.field.mandatory && !RegExp(_vm.field.mandatory).exec(_vm.pickedValue)
      }
    }, [_vm.field.tooltip && _vm.field.mandatory && !RegExp(_vm.field.mandatory).exec(_vm.pickedValue) ? _c('span', {
      staticClass: "vdt-metadata__row__editing-mandatory-tooltip"
    }, [_vm._v(" " + _vm._s(_vm.field.tooltip) + " ")]) : _vm._e(), _vm._v(" "), _c('div', [_c('input', {
      staticClass: "vdt-metadata__row--input",
      attrs: {
        "placeholder": "seconds",
        "type": "number"
      },
      domProps: {
        "value": _vm.pickedValue
      },
      on: {
        "input": function ($event) {
          _vm.$emit('input', {
            field: _vm.field,
            value: $event.target.value
          });
        }
      }
    })])]) : _vm.field.editable && _vm.field.type === 'number' ? _c('div', {
      staticClass: "vdt-metadata__row__value",
      class: {
        'vdt-metadata__row__mandatory': _vm.field.mandatory && !RegExp(_vm.field.mandatory).exec(_vm.pickedValue)
      }
    }, [_vm.field.tooltip && _vm.field.mandatory && !RegExp(_vm.field.mandatory).exec(_vm.pickedValue) ? _c('span', {
      staticClass: "vdt-metadata__row__editing-mandatory-tooltip"
    }, [_vm._v(" " + _vm._s(_vm.field.tooltip) + " ")]) : _vm._e(), _vm._v(" "), _c('div', [_c('input', {
      staticClass: "vdt-metadata__row--input",
      attrs: {
        "placeholder": "seconds",
        "type": "number"
      },
      domProps: {
        "value": _vm.pickedValue
      },
      on: {
        "input": function ($event) {
          _vm.$emit('input', {
            field: _vm.field,
            value: $event.target.value
          });
        }
      }
    })])]) : _c('span', {
      staticClass: "vdt-metadata__row__value"
    }, [_vm._v(" " + _vm._s(_vm.value) + " ")])]);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-18bddbcc',
  name: 'VdtMetadataField',
  components: {
    pencil: Pencil,
    check: Check,
    close,
    DateTimePicker: VdtDateTimePicker // MetadataFieldDate,

  },
  props: {
    /**
     * @description Object with set of values to be displayed.
     * If editing is supported the object can contain input type
     * and arrays of pre-determined options.
     */
    field: {
      type: Object,
      requried: true,
      default: () => {}
    },
    editingField: {
      type: Object,
      requried: true,
      default: () => {}
    },
    form: {
      type: Boolean,
      requried: true,
      default: false
    },

    /**
     * @description String displayed when a value is empty
     */
    valuePlaceholder: {
      type: String,
      required: false,
      default: '-'
    }
  },

  data() {
    return {
      editing: false,
      pickedValue: cloneDeep(this.getValue())
    };
  },

  computed: {
    value() {
      return this.getValue();
    },

    /**
     * @description If the current value overlaps with any of the pre-determined
     * options it is removed from the array to avoid duplicates.
     */
    newOptions() {
      return cloneDeep(this.field.options);
    }

  },
  watch: {
    value() {
      this.pickedValue = cloneDeep(this.getValue());
    },

    editing(newVal) {
      if (newVal) {
        this.$emit('editing', this.field);
        document.addEventListener('keydown', this.handleKey);
      } else {
        document.removeEventListener('keydown', this.handleKey);
      }
    },

    editingField(newVal) {
      if (newVal !== this.field && this.editing) {
        this.editing = false;
      }
    }

  },
  methods: {
    getValue() {
      if (this.field.type === 'boolean') {
        return this.field.value === 'true';
      } else if (this.field.type === 'counter') {
        return parseInt(this.field.value, 10);
      }

      return this.field.value;
    },

    /**
     * Granted the new value is valid, an object containing the path and
     * the new value is emitted to the Metadata component.
     * Also resets the 'Editing' boolean to hide input elements.
     *
     * @event EditMetadata
     * @type {Array}
     */
    emitChanges() {
      if (this.pickedValue !== undefined && this.pickedValue !== this.field.value && this.field.field !== undefined) {
        if (this.field.mandatory && !RegExp(this.field.mandatory).exec(this.pickedValue)) {
          // eslint-disable-next-line
          console.log('The value did not match the regex');
          return;
        }

        const {
          field
        } = this.field;
        this.$emit('editMetadata', {
          field,
          value: this.pickedValue
        });
        this.editing = false;
      } else {
        // eslint-disable-next-line
        console.log('The value was not changed');
      }
    },

    changeCount(count) {
      this.pickedValue += count;
      this.emitChanges();
    },

    dismissChanges() {
      this.pickedValue = cloneDeep(this.field.value);
      this.editing = false;
    },

    toggleEditing() {
      const vm = this; // type counter should not be editable by toggling

      if (this.fieldType !== 'counter') {
        const target = vm.$el.querySelector('.vdt-metadata__row__value');
        const dimensions = target.getBoundingClientRect();
        const {
          width,
          height
        } = dimensions;

        if (this.field.type !== 'counter') {
          this.editing = true;
        }

        if (this.field.type === 'string' && this.value.length > 99) {
          vm.$nextTick(() => {
            const {
              textarea
            } = vm.$refs;
            textarea.style.width = `${width}px`;
            textarea.style.height = `${height}px`;
          });
        }
      }
    },

    handleKey(event) {
      const {
        key,
        shiftKey,
        ctrlKey,
        metaKey
      } = event;

      switch (key) {
        case 'Escape':
          this.dismissChanges();
          break;

        case 'Enter':
          if (!shiftKey && !ctrlKey && !metaKey) {
            this.emitChanges();
          }

          break;

        default:
          break;
      }
    }

  }
};

class PropError extends Error {
  constructor(messageObj, ...params) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...params); // Maintains proper stack trace for where our error was thrown (only available on V8)

    /*
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, PropError);
    }
    */
    // Custom debugging information

    this.message = `Missing required "${messageObj.name}" ${messageObj.type}` + ` in prop: ${messageObj.rootName} ${messageObj.rootType}`; // this.date = new Date();
  }

}

/**
 * @description Displays metadata of an asset with configurable rows
 * @since 0.9
 *
 * ***
 *
 * ###CSS-variables used:
 *
 * > **--danger** hover color on dismiss change icon.<br/>
 * > **--default** icon color (used when editing metadata field).<br/>
 * > **--info** hover color of link type rows.<br/>
 * > **--success** hover color on confirm change icon.<br/>
 *
 */
var VdtMetadata = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-metadata__wrapper"
    }, [_vm.header !== '' ? _c('header', {
      staticClass: "vdt-metadata__row--header"
    }, [_c('h2', {
      staticClass: "vdt-metadata__row--header"
    }, [_vm._v(" " + _vm._s(_vm.header) + " ")])]) : _vm._e(), _vm._v(" "), !_vm.form ? _c('div', {
      staticClass: "vdt-metadata__content"
    }, _vm._l(_vm.filteredRows, function (field) {
      return _c('MetadataField', {
        key: field.key,
        class: {
          'vdt-metadata__row--link': _vm.isLink(field)
        },
        attrs: {
          "field": field,
          "editing-field": _vm.editingField,
          "value-placeholder": _vm.valuePlaceholder
        },
        on: {
          "linkClick": function ($event) {
            _vm.$emit('linkClick', $event);
          },
          "editing": function ($event) {
            _vm.changeEditingField($event);
          },
          "editMetadata": function ($event) {
            _vm.$emit('editMetadata', $event);
          }
        }
      });
    })) : _c('form', {
      staticClass: "vdt-metadata__content",
      on: {
        "submit": function ($event) {
          $event.preventDefault();
          return null($event);
        }
      }
    }, _vm._l(_vm.filteredRows, function (field) {
      return _c('MetadataField', {
        key: field.key,
        class: {
          'vdt-metadata__row--link': _vm.isLink(field)
        },
        attrs: {
          "field": field,
          "editing-field": _vm.editingField,
          "value-placeholder": _vm.valuePlaceholder,
          "form": ""
        },
        on: {
          "input": function ($event) {
            _vm.$emit('input', $event);
          }
        }
      });
    }))]);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-4c0d4aaf',
  name: 'VdtMetadata',
  components: {
    MetadataField: VdtMetadataField
  },
  props: {
    /**
     * @description Array with assets of some kind, with keys matching the rows[].key.
     */
    asset: {
      type: Object,
      required: false,
      default: null // There should be one asset and the asset should be an object.
      // validator: asset => typeof asset === 'object',

    },

    /**
     * @description Array with row-objects.
     */
    rows: {
      type: Array,

      default() {
        return [];
      },

      required: true
    },

    /**
     * @description Header to be shown above the Metadata.
     */
    header: {
      type: String,
      required: false,
      default: ''
    },

    /**
     * @description Set this value to true in order to get a form,
     * the form will instead send @input events.
     */
    form: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * @description String displayed when a value is empty
     */
    valuePlaceholder: {
      type: String,
      required: false,
      default: '-'
    }
  },

  data() {
    return {
      editingField: {}
    };
  },

  computed: {
    /**
     * Filters rows-stuff from assets
     * @params none
     */
    filteredRows() {
      const filteredRows = [];

      if (!isEmpty(this.asset)) {
        this.rows.forEach((row, index) => {
          const value = get(this.asset, row.key);

          const item = {
            key: row.label,
            value,
            path: last(row.key.split('.'))
          };

          if (item.value === undefined) {
            if (row.type.toLowerCase() === 'date') {// let it be undefined
            } else if (row.type.toLowerCase() === 'time' || row.type.toLowerCase() === 'number' || row.type.toLowerCase() === 'counter') {
              item.value = 0;
            } else {
              item.value = '';
            }
          }

          if (row.type.toLowerCase() === 'date' && item.value && this.form) {// nothing;
          } else if (row.type.toLowerCase() === 'date' && item.value && !this.form) {
            item.value = DateTime.fromISO(item.value).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
          }

          if (row.type.toLowerCase() === 'time' && this.form) {// nothing;
          } else if (row.type.toLowerCase() === 'time' && !this.form) {
            item.value = Runtime.formatTime(item.value);
          }

          if (row.edit !== undefined) {
            if (row.edit.field === undefined) {
              throw new PropError({
                name: 'field',
                type: 'key',
                rootName: `rows[${index}].edit`,
                rootType: 'object'
              });
            }

            if (row.edit.mandatory) {
              item.mandatory = row.edit.mandatory;
              item.tooltip = row.edit.tooltip;

              try {
                RegExp(item.mandatory).exec(item.value);
              } catch (err) {
                throw new PropError({
                  name: 'field',
                  type: 'key',
                  rootName: `rows[${index}].edit.mandatory`,
                  rootType: 'object'
                });
              }
            }

            item.field = row.edit.field;

            if (row.edit) {
              item.editable = true;
            }

            if (row.edit.options) {
              item.options = row.edit.options;
            }
          }

          item.type = row.type.toLowerCase();
          filteredRows.push(item);
        });
      }

      return filteredRows;
    }

  },
  methods: {
    changeEditingField(field) {
      this.editingField = field;
    },

    isLink(field) {
      return field.type.toLowerCase() === 'link';
    }

  }
};

/**
 * @description The timeline to render MetadataTimelineEvents on
 * @since 0.9
 *
 * ***
 *
 * ###CSS-variables used:
 *
 * > **--contrast-color-3** color of the timeline.<br/>
 */
var VdtMetadataTimeline = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-metadata-timeline"
    }, [_vm._t("default")], 2);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-74ee38cf',
  name: 'VdtMetadataTimeline'
};

/**
 * @description Customizable event that is placed at a relativt position to the time of the timeline
 * @since 0.9
 *
 * ***
 *
 * ###CSS-variables used:
 *
 * > **--default** color of active timeline event.<br/>
 * > **--primary** color of hovered timeline event.<br/>
 * > **--contrast-color-5** default color of timeline events.<br/>
 */
var VdtMetadataTimelineEvent = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('span', {
      staticClass: "vdt-metadata-timeline-event",
      class: _vm.eventClasses,
      style: [_vm.leftPosition, _vm.range],
      attrs: {
        "tabindex": "0"
      },
      on: {
        "click": function ($event) {
          _vm.eventClick();
        }
      }
    }, [_vm._t("event", [_c('div', {
      staticClass: "vdt-metadata-timeline-event__icon"
    }, [_vm._t("event-icon-markup")], 2), _vm._v(" "), _vm.showRange ? _c('div', {
      staticClass: "vdt-metadata-timeline-event__range"
    }) : _vm._e()], {
      active: _vm.isActive
    })], 2);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-7a072c3b',
  name: 'VdtMetadataTimelineEvent',
  props: {
    /**
     * The event that should be shown.
     * Requires start and end values on it
     */
    event: {
      type: Object,
      required: true
    },

    /**
     * Duration of the media (seconds), required to position the event
     */
    duration: {
      type: Number,
      required: true
    },

    /**
     * The current time of the media playing (seconds)
     * To set active state on an event
     */
    currentTime: {
      type: Number,
      required: false,
      default: 0
    },

    /**
     * When to show the range for the events timespan.
     * Valid options (in any order, and mix): ['always', 'active', 'never', 'focus', 'hover']
     * Note: 'active' applies to when the event is active against the currentTime, not the css-state
     */
    showTimespan: {
      type: Array,
      required: false,
      default: () => ['hover', 'active']
    }
  },
  computed: {
    isActive() {
      return this.currentTime >= this.event.start && this.currentTime <= this.event.end;
    },

    leftPosition() {
      const position = this.event.start / this.duration;

      if (position > 1) {
        // eslint-disable-next-line
        throw `Event is out of range: event.start ${this.event.start}, duration: ${this.duration}`;
      }

      return {
        left: `${position * 100}%`
      };
    },

    range() {
      const range$$1 = (this.event.end - this.event.start) / this.duration;

      if (range$$1 < 0 || range$$1 > 1) {
        // eslint-disable-next-line
        throw `Faulty range: Length: ${range$$1 * 100}%`;
      }

      return {
        width: `${range$$1 * 100}%`
      };
    },

    showRange() {
      return this.showTimespan.indexOf('never') === -1;
    },

    eventClasses() {
      const classes = {};
      this.showTimespan.forEach(trigger => {
        classes[`vdt-metadata-timeline-event--${trigger}`] = true;
      });
      classes.active = this.isActive;
      return classes;
    }

  },
  methods: {
    /**
     * @event eventClick
     * @description Triggered when an timeline-event is clicked
     */
    eventClick() {
      this.$emit('eventClick', this.event);
    }

  }
};

/**
 * @description Displays a bar with buttons for pagination based navigation
 * @since 0.9
 *
 * ***
 *
 * ###CSS-variables used:
 *
 * > **--contrast-color-1** text color of selected button.<br/>
 * > **--default** background color on selected page button,
 * text color on all other page buttons.<br/>
 *
 */

var VdtPaging = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-paging"
    }, [_c('div', {
      staticClass: "vdt-paging__nav"
    }, [_vm.enableFirstLast ? _c('div', {
      staticClass: "vdt-paging__nav-button vdt-paging__nav-button__first"
    }, [_c('button', {
      attrs: {
        "disabled": _vm.page - 1 <= 0
      },
      on: {
        "click": function ($event) {
          _vm.gotoPage(1);
        }
      }
    }, [_vm._v(" First ")])]) : _vm._e(), _vm._v(" "), _c('div', {
      staticClass: "vdt-paging__nav-button vdt-paging__nav-button__prev"
    }, [_c('button', {
      attrs: {
        "disabled": _vm.page - 1 <= 0
      },
      on: {
        "click": function ($event) {
          _vm.gotoPage(_vm.page - 1);
        }
      }
    }, [_vm._v(" Previous ")])]), _vm._v(" "), _vm._l(_vm.range(), function (index) {
      return _c('div', {
        key: index,
        staticClass: "vdt-paging__nav-button vdt-paging__nav-button__page",
        class: _vm.active(index)
      }, [_c('button', {
        on: {
          "click": function ($event) {
            _vm.gotoPage(index);
          }
        }
      }, [_vm._v(" " + _vm._s(index) + " ")])]);
    }), _vm._v(" "), _c('div', {
      staticClass: "vdt-paging__nav-button vdt-paging__nav-button__next"
    }, [_c('button', {
      attrs: {
        "disabled": _vm.page + 1 > _vm.pages
      },
      on: {
        "click": function ($event) {
          _vm.gotoPage(_vm.page + 1);
        }
      }
    }, [_vm._v("Next ")])]), _vm._v(" "), _vm.enableFirstLast ? _c('div', {
      staticClass: "vdt-paging__nav-button vdt-paging__nav-button__last"
    }, [_c('button', {
      attrs: {
        "disabled": _vm.page + 1 > _vm.pages
      },
      on: {
        "click": function ($event) {
          _vm.gotoPage(_vm.pages);
        }
      }
    }, [_vm._v(" Last ")])]) : _vm._e()], 2)]);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-4557c7e2',
  name: 'VdtPaging',
  props: {
    /**
     * First asset to show
     */
    first: {
      type: Number,
      default: 1,
      required: true
    },

    /**
     * Number of assets per page
     */
    number: {
      type: Number,
      default: 0,
      required: true
    },

    /**
     * Number of assets to split up into pages
     */
    hits: {
      type: Number,
      default: 0,
      required: true
    },

    /**
     * Current page / page to start at
     */
    page: {
      type: Number,
      default: 1,
      required: true
    },

    /**
     * Add links to the first and last pages.
     */
    enableFirstLast: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      firstPage: 1,
      lastPage: 5
    };
  },

  computed: {
    pages() {
      return Math.ceil(this.hits / this.number);
    }

  },
  methods: {
    range() {
      this.correctedFirstPage();
      this.correctedLastPage();
      return range(this.firstPage, this.lastPage + 1);
    },

    correctedFirstPage() {
      const first = this.page - 2 >= 1 ? this.page - 2 : 1;

      if (this.lastPage === this.pages) {
        if (this.pages < 5) {
          this.firstPage = 1;
        } else {
          this.firstPage = this.pages - 4;
        }
      } else {
        this.firstPage = first;
      }
    },

    correctedLastPage() {
      const last$$1 = this.page + 2 <= this.pages ? this.page + 2 : this.pages;

      if (this.firstPage === 1) {
        if (this.pages < 5) {
          this.lastPage = this.pages;
        } else {
          this.lastPage = 5;
        }
      } else {
        this.lastPage = last$$1;
      }
    },

    pageDict(pageNr) {
      const oldPage = this.page;
      let newPage = parseInt(pageNr, 10);
      newPage = newPage >= 1 ? newPage : 1;
      newPage = newPage <= this.pages ? newPage : this.pages;
      let newFirst = this.first + (newPage - oldPage) * this.number;
      newFirst = newFirst >= 1 ? newFirst : 0;
      newFirst = newFirst <= this.hits ? newFirst : this.hits;
      return {
        newFirst,
        newPage
      };
    },

    active(pageNr) {
      return pageNr === this.page ? 'vdt-paging__nav-button__active' : '';
    },

    /**
    * Goto page
    *
    * @event gotoPage
    * @type {String}
    */
    gotoPage(pageNr) {
      this.$emit('gotoPage', this.pageDict(pageNr));
    }

  }
};

/**
 * @description Dynamic progress bar usable with any numeric input
 * @since 0.9
 *
 * ***
 *
 * ###CSS-variables used:
 *
 * > **--contrast-color-1** background color of progress bar wrapper, text color of label.<br/>
 * > **--contrast-color-9** background color of label.<br/>
 * > **--danger** color of progress bar with status 'failed'.<br/>
 * > **--default** default color of progress bar.<br/>
 * > **--primary** color of progress bar with status 'progress'.<br/>
 * > **--success** color of progress bar with status 'success'.<br/>
 */
var VdtProgressBar = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-progress"
    }, [_c('div', {
      staticClass: "vdt-progress-bar",
      class: [_vm.isAnimated, _vm.isStriped, _vm.status],
      style: {
        width: `${_vm.progress}%`
      }
    }, [_vm.label !== null && _vm.progress > 0 ? _c('div', {
      staticClass: "vdt-progress-bar__label"
    }, [_c('span', {
      staticClass: "vdt-progress-bar__label__value"
    }, [_vm._v(" " + _vm._s(_vm.label) + " ")])]) : _vm._e()])]);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-8d4e17c6',
  name: 'VdtProgressBar',
  props: {
    /**
     * @description Boolean determining if animation is activated.
     */
    animated: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * @description String displayed in a label beneath the progress bar.
     */
    label: {
      type: [String, Number],
      required: false,
      default: null
    },

    /**
     * @description The value of the current progress. Numberic value between 0-100.
     */
    progress: {
      type: Number,
      requried: false,
      default: 0
    },

    /**
     * @description Status of the progress, could be used to display finished state.
     */
    status: {
      type: String,
      required: false,
      default: ''
    },

    /**
     * @description Boolean determining if striped styling is activated.
     */
    striped: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    isAnimated() {
      return this.animated ? 'animated' : '';
    },

    isStriped() {
      return this.striped ? 'striped' : '';
    }

  },
  methods: {}
};

var Autocomplete = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-search__autocomplete"
    }, _vm._l(_vm.acWithFreeText, function (autocomplete, autocompleteIndex) {
      return _c('div', {
        key: autocomplete.field,
        staticClass: "vdt-search__autocomplete-row",
        style: {
          'border-color': autocomplete.map.color || 'light-grey'
        }
      }, [_c('h4', [_vm._v(_vm._s(autocomplete.map.title || autocomplete.field))]), _vm._v(" "), _c('ul', {
        staticClass: "vdt-search__autocomplete-suggestions"
      }, _vm._l(autocomplete.suggestion, function (suggestion, suggestionIndex) {
        return _c('li', {
          key: suggestion,
          style: _vm.activeSuggestionStyle({
            autocompleteIndex: autocompleteIndex,
            suggestionIndex: suggestionIndex
          }),
          on: {
            "mousedown": function ($event) {
              $event.preventDefault();
            },
            "click": function ($event) {
              _vm.pickAutocomplete(autocomplete, suggestion);
            }
          }
        }, [_vm._v(" " + _vm._s(suggestion) + " ")]);
      }))]);
    }));
  },
  staticRenderFns: [],
  _scopeId: 'data-v-0484a420',
  name: 'Autocomplete',
  props: {
    /**
     * List of Autocomplete's and suggestions to show, raw form from vidispine
     */
    autocompletes: {
      type: Array,
      required: false,
      default: () => []
    },

    /**
     * Object that allows mapping custom titles and styles to the fields in autocomplete
     * {
     *   originalFilename: {
     *     title: 'Oringal Filename',
     *     color: #ff0,
     *   },
     * }
     *
     * The autocomplete's free text search can be styled using 'freeText' key:
     *
     * {
     *  freeText: {
     *    title: 'Search Query', // default 'Free Text'
     *    color: 'orange', // default '#536e7a'
     *  },
     * }
     */
    autocompleteMap: {
      type: Object,
      required: false,
      default: () => {}
    },
    query: {
      type: String,
      required: true,
      default: ''
    },
    disableFreetextAutocomplete: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      queryList: [],
      active: {
        autocomplete: 0,
        suggestion: 0
      },
      keys: {
        UP: 38,
        DOWN: 40,
        ENTER: 13,
        BACKSPACE: 8
      }
    };
  },

  computed: {
    acWithFreeText() {
      if (!this.disableFreetextAutocomplete) {
        const defaultFreeTextMap = {
          color: '#536e7a',
          title: 'Free Text'
        };
        const freeText = {
          field: 'freeText',
          suggestion: [this.query],
          map: {
            color: '#536e7a',
            title: 'Free text'
          }
        };
        freeText.map = this.autocompleteMap.freeText || defaultFreeTextMap;

        const acWithFreeText = clone(this.autocompletes);

        acWithFreeText.unshift(freeText);
        return acWithFreeText;
      }

      return this.autocompletes;
    },

    currentAutocomplete() {
      return this.acWithFreeText[this.active.autocomplete];
    }

  },
  watch: {
    autocompletes() {
      this.resetAutocompleteStep();
    },

    showAutosuggestions() {
      this.resetAutocompleteStep();
    }

  },

  mounted() {
    window.addEventListener('keydown', this.keyPressed);
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.keyPressed);
  },

  methods: {
    pickAutocomplete(autocomplete, query) {
      /**
      * Emits the autocomplete suggestion that was clicked
      *
      * @event pickedAutocomplete
      * @type {Object}
      */
      this.$emit('pickedAutocomplete', {
        autocomplete,
        query
      });
    },

    isActiveSuggestion({
      autocompleteIndex = 0,
      suggestionIndex = 0
    }) {
      if (this.active.autocomplete === autocompleteIndex && this.active.suggestion === suggestionIndex) {
        return true;
      }

      return false;
    },

    activeSuggestionStyle({
      autocompleteIndex = 0,
      suggestionIndex = 0
    }) {
      if (this.isActiveSuggestion({
        autocompleteIndex,
        suggestionIndex
      })) {
        return {
          'background-color': this.acWithFreeText[autocompleteIndex].map.color,
          color: 'white'
        };
      }

      return null;
    },

    prevSuggestion() {
      if (this.active.suggestion > 0) {
        this.active.suggestion = this.active.suggestion - 1;
      } else if (this.active.suggestion === 0 && this.active.autocomplete > 0) {
        if (this.active.autocomplete > 0) {
          this.active.autocomplete = this.active.autocomplete - 1;
          this.active.suggestion = this.currentAutocomplete.suggestion.length - 1;
        }
      }
    },

    nextSuggestion() {
      if (this.active.suggestion >= this.currentAutocomplete.suggestion.length - 1) {
        if (this.active.autocomplete < this.acWithFreeText.length - 1) {
          this.active.autocomplete = this.active.autocomplete + 1;
          this.active.suggestion = 0;
        }
      } else if (this.active.suggestion < this.currentAutocomplete.suggestion.length - 1) {
        this.active.suggestion = this.active.suggestion + 1;
      }
    },

    resetAutocompleteStep() {
      this.active.autocomplete = 0;
      this.active.suggestion = 0;
    },

    keyPressed(event) {
      if (this.currentAutocomplete) {
        switch (event.keyCode) {
          case this.keys.UP:
            this.prevSuggestion();
            break;

          case this.keys.DOWN:
            this.nextSuggestion();
            break;

          case this.keys.ENTER:
            this.pickAutocomplete(this.currentAutocomplete, this.currentAutocomplete.suggestion[this.active.suggestion]);
            break;

          default: // Do nothing

        }
      }
    }

  }
};

var SearchTag = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-search-tag",
      class: {
        'vdt-search-tag--multiple': _vm.multiple
      },
      style: _vm.backgroundStyle
    }, [_vm.include && _vm.numberOfQueries === 1 && !_vm.hideControls ? _c('div', {
      staticClass: "vdt-search-tag__operator",
      on: {
        "click": _vm.toggleOperator
      }
    }, [_c('div', {
      staticClass: "vdt-search-tag__operator__icon"
    }, [_c('IncludeIcon', {
      attrs: {
        "title": "Exclude"
      }
    })], 1)]) : _vm._e(), _vm._v(" "), !_vm.include ? _c('div', {
      staticClass: "vdt-search-tag__operator vdt-search-tag__operator--exclude",
      on: {
        "click": _vm.toggleOperator
      }
    }, [_c('div', {
      staticClass: "vdt-search-tag__operator__icon"
    }, [_c('ExcludeIcon', {
      attrs: {
        "title": "Include"
      }
    })], 1)]) : _vm._e(), _vm._v(" "), _vm.numberOfQueries <= 2 ? _c('div', {
      staticClass: "vdt-search-tag__query-container"
    }, [_c('div', {
      staticClass: "vdt-search-tag__hidden-container"
    }, [_c('div', {
      staticClass: "vdt-search-tag__query-container vdt-search-tag__query-container--hidden"
    }, _vm._l(_vm.tag.queries, function (query, index) {
      return _c('span', {
        key: query.index,
        staticClass: "vdt-search-tag__value--more",
        style: _vm.queryTextColor(index, true)
      }, [_c('span', {
        staticClass: "value"
      }, [_vm._v(_vm._s(query.query))]), _vm._v(" "), _vm.multiple && index !== _vm.numberOfQueries - 1 ? _c('span', {
        staticClass: "vdt-search-tag__value__seperator"
      }, [_vm._v(" or ")]) : _vm._e(), _vm._v(" "), !_vm.hideRemove && _vm.multiple ? _c('span', {
        staticClass: "vdt-search-tag__remove vdt-search-tag__remove--tooltip",
        on: {
          "click": function ($event) {
            _vm.$emit('removeQuery', {
              tag: _vm.tag,
              index: index
            });
          }
        }
      }, [_vm._v(" × ")]) : _vm._e()]);
    }))]), _vm._v(" "), _vm._l(_vm.tag.queries, function (query, index) {
      return _c('span', {
        key: query.id,
        staticClass: "vdt-search-tag__value",
        style: _vm.queryTextColor(index)
      }, [_c('span', {
        staticClass: "value"
      }, [_vm._v(_vm._s(query.query))]), _vm._v(" "), _vm.multiple && index !== _vm.numberOfQueries - 1 ? _c('span', {
        staticClass: "vdt-search-tag__value__seperator"
      }, [_vm._v(" or ")]) : _vm._e()]);
    })], 2) : _c('div', {
      staticClass: "vdt-search-tag__query-container"
    }, [_c('div', {
      staticClass: "vdt-search-tag__hidden-container"
    }, [_c('div', {
      staticClass: "vdt-search-tag__query-container vdt-search-tag__query-container--hidden"
    }, _vm._l(_vm.tag.queries, function (query, index) {
      return _c('span', {
        key: query.id,
        staticClass: "vdt-search-tag__value--more",
        style: _vm.queryTextColor(index)
      }, [_c('span', {
        staticClass: "value"
      }, [_vm._v(" " + _vm._s(query.query) + " ")]), _vm._v(" "), _vm.multiple && index !== _vm.numberOfQueries - 1 ? _c('span', {
        staticClass: "vdt-search-tag__value__seperator"
      }, [_vm._v("or ")]) : _vm._e(), _vm._v(" "), !_vm.hideRemove ? _c('span', {
        staticClass: "vdt-search-tag__remove vdt-search-tag__remove--tooltip",
        on: {
          "click": function ($event) {
            _vm.$emit('removeQuery', {
              tag: _vm.tag,
              index: index
            });
          }
        }
      }, [_vm._v(" × ")]) : _vm._e()]);
    }))]), _vm._v(" "), _c('span', {
      staticClass: "vdt-search-tag__value",
      style: _vm.queryTextColor(0)
    }, [_c('span', {
      staticClass: "value"
    }, [_vm._v(_vm._s(_vm.tag.queries[0].query) + " ")]), _vm._v(" "), _vm.multiple && _vm.index !== _vm.numberOfQueries - 1 ? _c('span', {
      staticClass: "vdt-search-tag__value__seperator"
    }, [_vm._v(" or ")]) : _vm._e()]), _vm._v(" "), _c('span', {
      staticClass: "vdt-search-tag__value vdt-search-tag__more"
    }, [_vm._v(" " + _vm._s(_vm.numberOfQueries - 1 + ' more...') + " ")])]), _vm._v(" "), _vm.include && !_vm.hideControls ? _c('div', {
      staticClass: "vdt-search-tag__add-search",
      class: {
        active: _vm.tagActive
      },
      on: {
        "click": _vm.toggleActiveTag
      }
    }, [_c('span', [_vm._v("or")])]) : _vm._e(), _vm._v(" "), !_vm.hideRemove ? _c('span', {
      staticClass: "vdt-search-tag__remove",
      on: {
        "click": function ($event) {
          _vm.$emit('remove', _vm.tag);
        }
      }
    }, [_vm._v(" × ")]) : _vm._e(), _vm._v(" "), _vm._t("default")], 2);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-3f5590fa',
  name: 'SearchTag',
  components: {
    IncludeIcon,
    ExcludeIcon
  },
  props: {
    /**
     * Object with atleast query key
     * {
     *  query: 'myTag'
     * }
     */
    tag: {
      type: Object,
      required: true
    },

    /**
     * The tag that is currently selected to be extended with OR logic, if any
     * used to see if this is that tag
     */
    activeTag: {
      type: Object,
      required: true
    },

    /**
     * Hide the remove 'x' button from tag content
     */
    hideRemove: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * Hide boolean search controls
     */
    hideControls: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      include: true,
      tagActive: false
    };
  },

  computed: {
    backgroundStyle() {
      if (this.multiple) {
        return null;
      }

      return {
        'background-color': this.tag.queries[0].color
      };
    },

    numberOfQueries() {
      return this.tag.queries.length;
    },

    multiple() {
      if (this.numberOfQueries > 1) {
        return true;
      }

      return false;
    }

  },
  watch: {
    activeTag(newVal) {
      if (newVal !== this.tag) {
        this.tagActive = false;
      }
    }

  },

  mounted() {
    this.include = this.tag.operator !== 'NOT';
  },

  methods: {
    toggleOperator() {
      this.include = !this.include;
      const operator = this.include ? 'AND' : 'NOT';
      this.$emit('changeOperator', operator);
    },

    toggleActiveTag() {
      this.tagActive = !this.tagActive;
      const emitObject = {
        active: this.tagActive,
        tag: this.tag
      };
      this.$emit('activeTag', emitObject);
    },

    queryTextColor(index, hidden) {
      if (this.multiple || hidden) {
        return {
          color: this.tag.queries[index].color
        };
      }

      return null;
    }

  }
};

/**
 * @description Displays a search-input that emit a search-string
 * @since 0.9
 *
 * ***
 *
 * ###CSS-variables used:
 *
 * > **--contrast-color-0**<br/>
 * Background color of the input field (focused/active).<br>
 * Background color of hidden search tags popover.<br>
 * Text and icon color used on search tags with a single search term<.br>
 * Background color on search tags with multiple search terms.<br/>
 * Background color in autocomplete container.
 *
 * > **--contrast-color-1**<br/>
 * Background color of the input field (inactive).<br/>
 * Hover color on autocomplete choises.
 *
 * > **--contrast-color-4**<br/>
 * Text color used on boolean words to seperate search terms/tags (and/or).<br>
 * Remove search term/tag icon color, when multiple search terms.<br>
 * Border color on search tags with multiple search tems.
 *
 * > **--contrast-color-6**<br/>
 * Search/clear icon color.
 *
 * > **--contrast-color-7**<br/>
 * Text color used on the "x more..." label on search tags with more than two search terms.
 *
 *
 * > **--contrast-color-9**<br/>
 * Input text color.<br/>
 * Text color used in search tags with multiple search terms (if no type color is provided).<br/>
 * Heading color for autocomplete categories.
 *
 * > **--default**<br/>
 * Background color used on search tags (if no type color is provided).
 *
 * > **--primary**<br/>
 * Border color of focused input field.
 *
 * > **--danger**<br/>
 * Background color to indicate exclusive (NOT) search.
 */

var VdtSearch = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      ref: "searchWrapper",
      staticClass: "vdt-search__wrapper"
    }, [_c('form', {
      staticClass: "vdt-search__form",
      class: _vm.focusClass,
      on: {
        "click": function ($event) {
          _vm.setFocusOnInput();
        },
        "submit": function ($event) {
          $event.preventDefault();

          _vm.emitEnter();
        }
      }
    }, [_c('transition-group', {
      staticClass: "vdt-search__query-field-wrapper",
      class: {
        multiple: _vm.queryList.length > 1
      },
      attrs: {
        "name": "fade-in",
        "tag": "span"
      }
    }, [_vm._l(_vm.visibleTags, function (queryField, index) {
      return _c('div', {
        key: queryField.id,
        staticClass: "vdt-search__tag-wrapper",
        class: {
          fade: _vm.isActiveTag && _vm.activeTag.id !== queryField.id
        }
      }, [_c('SearchTag', {
        staticClass: "vdt-search__tag",
        attrs: {
          "tag": queryField,
          "active-tag": _vm.activeTag,
          "hide-controls": _vm.hideControls
        },
        on: {
          "changeOperator": function ($event) {
            _vm.changeOperator($event, index);
          },
          "activeTag": function ($event) {
            _vm.changeActiveTag($event);
          },
          "remove": function ($event) {
            _vm.removeQueryAtIndex(index);
          },
          "removeQuery": function ($event) {
            _vm.removeQueryInQuery($event);
          }
        }
      }), _vm._v(" "), _c('span', {
        staticClass: "vdt-search__tag-seperator"
      }, [_vm._v("and")])], 1);
    }), _vm._v(" "), _vm.showAutocomplete && _vm.hiddenTags.length ? _c('div', {
      key: "hiddenTags",
      attrs: {
        "name": "fade-in"
      }
    }, [_c('div', {
      staticClass: "vdt-search__query-tag--hidden-container"
    }, [_c('SearchTag', {
      staticClass: "vdt-search__query-tag--hidden-toggle",
      class: {
        fade: _vm.isActiveTag
      },
      attrs: {
        "tag": {
          queries: [{
            query: _vm.morePlaceholder
          }]
        },
        "hide-remove": true,
        "hide-controls": true,
        "active-tag": _vm.activeTag
      },
      nativeOn: {
        "click": function ($event) {
          return _vm.toggleHidden($event);
        }
      }
    }), _vm._v(" "), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.showHidden,
        expression: "showHidden"
      }],
      staticClass: "vdt-search__query--hidden-tags"
    }, _vm._l(_vm.hiddenTags, function (queryField, index) {
      return _c('SearchTag', {
        key: queryField.id,
        attrs: {
          "tag": queryField,
          "active-tag": _vm.activeTag,
          "hide-controls": _vm.hideControls
        },
        on: {
          "changeOperator": function ($event) {
            _vm.changeOperator($event, _vm.visibleTags.length + index);
          },
          "activeTag": function ($event) {
            _vm.changeActiveTag($event, _vm.tagsVisible - 1 + index);
          },
          "remove": function ($event) {
            _vm.removeQueryAtIndex(_vm.tagsVisible - 1 + index);
          },
          "removeQuery": function ($event) {
            _vm.removeQueryInQuery($event);
          }
        }
      });
    }))], 1)]) : _vm._e(), _vm._v(" "), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.query,
        expression: "query"
      }],
      key: "searchinput",
      ref: "search",
      staticClass: "vdt-search__input",
      attrs: {
        "placeholder": _vm.currentPlaceholder
      },
      domProps: {
        "value": _vm.query
      },
      on: {
        "input": [function ($event) {
          if ($event.target.composing) {
            return;
          }

          _vm.query = $event.target.value;
        }, function ($event) {
          _vm.emitSearch($event);
        }],
        "keydown": function ($event) {
          if (!('button' in $event) && _vm._k($event.keyCode, "delete", [8, 46], $event.key, ["Backspace", "Delete"])) {
            return null;
          }

          _vm.removeLastQuery();
        },
        "submit": function ($event) {
          $event.preventDefault();

          _vm.emitEnter();
        }
      }
    }), _vm._v(" "), _c('button', {
      key: "searchicon",
      staticClass: "vdt-search__icon"
    }, [_c('span', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !_vm.isSearching && _vm.queryList.length == 0,
        expression: "!isSearching && queryList.length == 0"
      }],
      staticClass: "vdt-search__search-icon"
    }, [_vm._t("search-icon", [_c('SearchIcon', {
      attrs: {
        "title": ""
      }
    })])], 2), _vm._v(" "), _c('span', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.isSearching || _vm.queryList.length > 0,
        expression: "isSearching || queryList.length > 0"
      }],
      staticClass: "vdt-search__clear-icon",
      on: {
        "click": _vm.clear
      }
    }, [_vm._t("clear-icon", [_c('ClearIcon', {
      attrs: {
        "title": "Clear Search"
      }
    })])], 2)])], 2)], 1), _vm._v(" "), _c('transition', {
      attrs: {
        "name": "autocomplete-animation"
      }
    }, [_vm.showAutosuggestions ? _c('Autocomplete', {
      attrs: {
        "autocompletes": _vm.computedAutocompletes,
        "autocomplete-map": _vm.autocompleteMap,
        "query": _vm.query,
        "disable-freetext-autocomplete": _vm.disableFreetextAutocomplete
      },
      on: {
        "pickedAutocomplete": function ($event) {
          _vm.addQuery($event);
        }
      }
    }) : _vm._e()], 1)], 1);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-7b59048f',
  name: 'VdtSearch',
  components: {
    Autocomplete,
    SearchTag,
    SearchIcon,
    ClearIcon: close
  },
  props: {
    /**
     * @description Placeholder for the search input
     */
    placeholder: {
      type: String,
      default: 'Search...',
      required: false
    },

    /**
     * @description List of queries, needed if you save the queries outside of the component
     */
    queryList: {
      type: Array,
      required: false,
      default: () => []
    },

    /**
     * @description List of Autocomplete's, raw form from vidispine
     */
    autocompletes: {
      type: Array,
      required: false,
      default: () => []
    },

    /**
     * @description Object that allows mapping custom titles
     * and styles to the fields in autocomplete.
     * {
     *   originalFilename: {
     *     title: 'Oringal Filename',
     *     color: #ff0,
     *   },
     * }
     *
     * The autocomplete's free text search can be styled using 'freeText' key:
     *
     * {
     *  freeText: {
     *    title: 'Search Query', // default 'Free Text'
     *    color: 'orange', // default '#536e7a'
     *  },
     * }
     */
    autocompleteMap: {
      type: Object,
      required: false,
      default: () => {}
    },

    /**
     * @description How many tags should be visible in search-bar
     * before grouping them together
     */
    tagsVisible: {
      type: Number,
      required: false,
      default: 2
    },

    /**
     * @description Hide boolean search controls
     */
    hideControls: {
      type: Boolean,
      required: false,
      default: false
    },
    disableFreetextAutocomplete: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      showHidden: false,
      query: '',
      searchIsFocused: false,
      // Serach tag that is currently selected to be extended with OR logic, if any
      activeTag: {},
      keys: {
        UP: 38,
        DOWN: 40,
        ENTER: 13,
        BACKSPACE: 8
      }
    };
  },

  computed: {
    isSearching() {
      return this.query.length > 0;
    },

    showAutocomplete() {
      return this.queryList.length > 0 || this.autocompletes.length > 0;
    },

    showAutosuggestions() {
      return this.searchIsFocused && this.isSearching && this.showAutocomplete;
    },

    computedAutocompletes() {
      return this.autocompletes.filter(autocomplete => autocomplete.suggestion.length > 0).map(autocomplete => {
        const ac = autocomplete;
        ac.map = this.autocompleteMap[ac.field];
        return ac;
      });
    },

    visibleTags() {
      return this.queryList.slice(0, this.tagsVisible);
    },

    hiddenTags() {
      return this.queryList.slice(this.tagsVisible, this.queryList.length);
    },

    focusClass() {
      return this.searchIsFocused ? 'vdt-search__form--focused' : '';
    },

    morePlaceholder() {
      return `${this.hiddenTags.length} more...`;
    },

    isActiveTag() {
      if (Object.keys(this.activeTag).length !== 0) {
        return true;
      }

      return false;
    },

    currentPlaceholder() {
      if (this.isActiveTag) {
        return 'or...';
      } else if (this.queryList.length) {
        return 'and...';
      }

      return this.placeholder;
    }

  },
  watch: {
    hiddenTags(val) {
      if (val.length === 0) {
        this.showHidden = false;
      }
    }

  },

  mounted() {
    this.$refs.searchWrapper.addEventListener('focusin', this.focusChanged);
    this.$refs.searchWrapper.addEventListener('focusout', this.focusChanged);
  },

  beforeDestroy() {
    this.$refs.searchWrapper.removeEventListener('focusin', this.focusChanged);
    this.$refs.searchWrapper.removeEventListener('focusout', this.focusChanged);
  },

  methods: {
    /**
    * Clear the search
    *
    * @event clear
    * @type {String}
    */
    clear() {
      this.query = '';
      this.$emit('clear');
    },

    emitEnter() {
      /**
      * Emits the query on Enter keypress ( key:13 )
      * if no autocomplete is active
      *
      * @event enter
      * @type {String}
      */
      if (!this.showAutocomplete) {
        this.$emit('enter', this.query);
      }
    },

    emitSearch($event) {
      /**
      * Search input as it's typed for other parties
      * Only Emits if no autocomplete is passed as prop
      *
      * @event search
      * @type {String}
      */
      this.$emit('search', $event.target.value);
    },

    setFocusOnInput() {
      this.$refs.search.focus();
    },

    focusChanged() {
      this.searchIsFocused = !this.searchIsFocused;
    },

    addQuery({
      autocomplete,
      query
    }) {
      this.query = '';

      const returnList = cloneDeep(this.queryList);

      if (Object.keys(this.activeTag).length !== 0) {
        const vm = this;
        const tagToUpdate = returnList.find(obj => obj.id === vm.activeTag.id);
        tagToUpdate.operator = 'OR';
        tagToUpdate.queries.push({
          id: uniqueId('query_'),
          field: autocomplete.field,
          query,
          color: autocomplete.map.color
        });
      } else {
        returnList.push({
          operator: 'AND',
          id: uniqueId('tag_'),
          queries: [{
            id: uniqueId('query_'),
            field: autocomplete.field,
            query,
            color: autocomplete.map.color
          }]
        });
      }

      this.$emit('pickedAutocomplete', returnList);
    },

    removeQueryInQuery(event) {
      const returnList = cloneDeep(this.queryList);

      const tagToUpdate = returnList.find(obj => obj.id === event.tag.id);
      tagToUpdate.queries.splice(event.index, 1);
      this.$emit('pickedAutocomplete', returnList);
    },

    removeQueryAtIndex(index) {
      const returnList = cloneDeep(this.queryList);

      returnList.splice(index, 1);
      this.$emit('pickedAutocomplete', returnList);
      this.setFocusOnInput();
      this.activeTag = {};
    },

    changeOperator(operator, index) {
      const returnList = cloneDeep(this.queryList);

      returnList[index].operator = operator;
      this.$emit('pickedAutocomplete', returnList);
      this.setFocusOnInput();
    },

    changeActiveTag(event) {
      if (event.active) {
        this.activeTag = event.tag;
      } else {
        this.activeTag = {};
      }
    },

    removeLastQuery() {
      if (this.query.length === 0) {
        if (this.isActiveTag) {
          this.activeTag = {};
        } else {
          this.removeQueryAtIndex(this.queryList.length - 1);
        }
      }
    },

    toggleHidden() {
      this.showHidden = !this.showHidden; // Remove activeTag if the active tag is in the hidden container when
      // hidden container is toggled off

      if (!this.showHidden && this.isActiveTag) {
        const activeTagHiddenIndex = this.hiddenTags.indexOf(this.activeTag);

        if (activeTagHiddenIndex !== -1) {
          this.activeTag = {};
        }
      }
    }

  }
};

/**
 * @description Displays metadata related to one specific shape
 * @since 0.9
 */

var VdtShape = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-shape__wrapper"
    }, [_c('header', {
      staticClass: "vdt-shape__header"
    }, [_vm.title && _vm.displayTitle !== '' ? _c('h2', [_vm._v(" " + _vm._s(_vm.displayTitle))]) : _vm._e(), _vm._v(" "), _c('div', {
      staticClass: "vdt-shape__actions-wrapper"
    }, [_vm._t("shape-actions")], 2)]), _vm._v(" "), _c('div', {
      staticClass: "vdt-shape__metadata"
    }, _vm._l(_vm.allFields, function (field) {
      return _c('div', {
        key: field.key,
        staticClass: "vdt-shape__metadata__list"
      }, [_vm.printProps(field.source, field.value) !== undefined ? _c('div', {
        staticClass: "vdt-shape__metadata__list__wrapper"
      }, [_c('span', {
        staticClass: "key"
      }, [_vm._v(" " + _vm._s(field.key) + " ")]), _vm._v(" "), _c('span', {
        staticClass: "value"
      }, [_vm._v(" " + _vm._s(_vm.printProps(field.source, field.value)) + " ")])]) : _vm._e()]);
    }))]);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-7cbab413',
  name: 'VdtShape',
  props: {
    /**
     * One shape, must be in the raw JSON structure returned from Vidispine,
     * only used for "plug-and-play".
     *
     * NOTE: The raw JSON shape is only needed when displaying the default metadata.
     * If you use, source: "_custom", then you can simply insert the prop shape as showed below
     * in order to get a title to the shape:
     *
     * example:
     * { id: theShapeId, tag: theShapeTag }
     */
    shape: {
      type: Object,
      required: false,
      default: () => {}
    },

    /**
     * If you want to show any other metadata-fields than the "plug-and-play" ones, use this array.
     *
     * The array must use the source: "_custom" as such:
     *
     * example:
     * { key: "Custom Title", source: "_custom", value: "anyValue" }
     */
    fields: {
      type: Array,
      required: false,
      default: () => []
    },

    /**
     * Use this array to extend the array of default metadata fields.
     *
     * Expects an additional key 'mediaType', indicating which mediatype
     * this field is associated with.
     *
     * example:
     * { key: 'Format', source: 'contentProps', value: 'Codec', mediaType: 'video' },
     */
    extendDefaultFields: {
      type: Array,
      required: false,
      default: () => []
    },

    /**
     * The item mediaType, this variable is only needed when the VdtShape
     * is used for "plug-and-play" and you insert the raw shape JSON.
     *
     * NOTE: This property is not needed when displaying the custom metadata.
     */
    mediaType: {
      type: String,
      required: false,
      default: 'image'
    },

    /**
     * @description Used to override default title or to not display any title,
     * String value will be used as title,
     * false will disable the title
     */
    title: {
      type: [String, Boolean],
      required: false,
      default: true
    }
  },

  data() {
    return {
      allFields: [],
      videos: [{
        key: 'resolution',
        source: 'resolution'
      }, {
        key: 'file size',
        source: 'size'
      }, {
        key: 'fps',
        source: 'videoProps',
        value: 'Framerate'
      }, {
        key: 'Format',
        source: 'contentProps',
        value: 'Codec'
      }, {
        key: 'video format',
        source: 'videoProps',
        value: 'Format'
      }, {
        key: 'video bitrate',
        source: 'videoProps',
        value: 'Bitrate'
      }, {
        key: 'audio format',
        source: 'audioProps',
        value: 'Format'
      }, {
        key: 'audio bitrate',
        source: 'audioProps',
        value: 'Bitrate'
      }],
      images: [{
        key: 'resolution',
        source: 'resolution'
      }, {
        key: 'file size',
        source: 'size'
      }, {
        key: 'image format',
        source: 'format',
        value: 'Format'
      }, {
        key: 'bit depth',
        source: 'imageProps',
        value: 'image_depth'
      }, {
        key: 'exposure time',
        source: 'imageProps',
        value: 'image_exif_ExposureTime'
      }, {
        key: 'f-number',
        source: 'imageProps',
        value: 'image_exif_FNumber'
      }, {
        key: 'iso',
        source: 'imageProps',
        value: 'image_exif_ISOSpeedRatings'
      }, {
        key: 'camera',
        source: 'imageProps',
        value: 'image_exif_Make'
      }, {
        key: 'camera model',
        source: 'imageProps',
        value: 'image_exif_Model'
      }],
      audio: [{
        key: 'file size',
        source: 'size'
      }, {
        key: 'audio format',
        source: 'audioProps',
        value: 'Format'
      }, {
        key: 'audio bitrate',
        source: 'audioProps',
        value: 'Bitrate'
      }],
      pdf: [{
        key: 'file size',
        source: 'size'
      }, {
        key: 'resolution',
        source: 'resolution'
      }, {
        key: 'pages',
        source: 'videoMetadata',
        value: 'image_pages'
      }, {
        key: 'colorspace',
        source: 'videoMetadata',
        value: 'image_colorspace'
      }]
    };
  },

  computed: {
    shapeTagString() {
      if (this.shape.tag) {
        return `(${this.shape.tag})`;
      }

      return '';
    },

    displayTitle() {
      if (this.title && typeof this.title === 'string') {
        return this.title;
      } else if (this.shape.id) {
        return `${this.shape.id} ${this.shapeTagString}`;
      }

      return '';
    },

    contentProps() {
      let object = [];

      if (this.shape.containerComponent && this.shape.containerComponent.mediaInfo) {
        const props = this.shape.containerComponent.mediaInfo.property;
        object = this.getShapeProps(props);
      }

      return object;
    },

    videoProps() {
      let object = {};

      if (this.shape.videoComponent && this.shape.videoComponent[0].mediaInfo) {
        const props = this.shape.videoComponent[0].mediaInfo.property;
        object = this.getShapeProps(props);
      }

      return object;
    },

    videoMetadata() {
      let object = [];

      if (this.shape.videoComponent && this.shape.videoComponent[0].metadata) {
        const props = this.shape.videoComponent[0].metadata;
        object = this.getShapeProps(props);
      }

      return object;
    },

    imageProps() {
      let object = [];

      if (this.shape.videoComponent && this.shape.videoComponent[0].metadata) {
        const props = this.shape.videoComponent[0].metadata;
        object = this.getShapeProps(props);
      }

      return object;
    },

    audioProps() {
      let object = [];

      if (this.shape.audioComponent && this.shape.audioComponent[0].mediaInfo) {
        const props = this.shape.audioComponent[0].mediaInfo.property;
        object = this.getShapeProps(props);
      }

      return object;
    },

    formatSize() {
      let size;

      if (this.shape.containerComponent && this.shape.containerComponent.file) {
        size = Size.formatSize(this.shape.containerComponent.file[0].size);
      }

      return size;
    },

    formatResolution() {
      if (this.shape.videoComponent && this.shape.videoComponent[0].resolution) {
        const {
          width,
          height
        } = this.shape.videoComponent[0].resolution;
        return `${width}x${height} (${(width * height / 1000000).toFixed(1)}M)`;
      }

      return undefined;
    }

  },

  mounted() {
    // Set the default fields
    if (this.fields.length !== 0) {
      this.allFields = this.fields;
    } else if (this.mediaType === 'image') {
      this.allFields = this.images;
    } else if (this.mediaType === 'video') {
      this.allFields = this.videos;
    } else if (this.mediaType === 'audio') {
      this.allFields = this.audio;
    } else if (this.mediaType === 'pdf') {
      this.allFields = this.pdf;
    } else {
      this.allFields = [];
    } // Add extra default fields with this mediatype


    this.allFields = this.allFields.concat(this.extendDefaultFields.filter(f => f.mediaType === this.mediaType));
  },

  methods: {
    getShapeProps(props = []) {
      const object = {};

      for (let i = 0; i < props.length; i += 1) {
        const key = props[i].key.replace(/\s/g, '');
        object[key] = props[i].value;
      }

      return object;
    },

    formatBitrate(bitrate) {
      const bitrateInt = Number(bitrate);

      if (bitrateInt >= 5000 && bitrateInt < 5000000) {
        return `${Math.floor(bitrateInt / 1000)} kbit/s`;
      } else if (bitrateInt >= 5000000) {
        return `${Math.floor(bitrateInt / 1000000)} Mbit/s`;
      }

      return `${bitrateInt} bit/s`;
    },

    printProps(source, value) {
      if (source === 'contentProps') {
        // console.log(`value ${value}`);
        // console.log(`ContentPropValue ${this.contentProps[value]}`);
        return this.contentProps[value];
      } else if (source === 'audioProps') {
        if (value === 'Bitrate' && this.shape.audioComponent) {
          return this.formatBitrate(this.shape.audioComponent[0].bitrate);
        }

        return this.audioProps[value];
      } else if (source === 'imageProps') {
        // console.log(`value ${value}`);
        // console.log(`imagePropValue ${this.imageProps[value]}`);
        return this.imageProps[value];
      } else if (source === 'videoProps') {
        if (value === 'Bitrate' && this.shape.videoComponent) {
          return this.formatBitrate(this.shape.videoComponent[0].bitrate);
        }

        return this.videoProps[value];
      } else if (source === 'videoMetadata') {
        return this.videoMetadata[value];
      } else if (source === 'resolution') {
        return this.formatResolution;
      } else if (source === 'size') {
        return this.formatSize;
      } else if (source === 'format') {
        return this.shape.containerComponent.format;
      } else if (source === '_custom') {
        if (typeof value === 'function') {
          return value(this.shape);
        }

        return value;
      }

      return 'error';
    }

  }
};

class JobPollService {
  constructor({
    jobId,
    milliseconds = 2000,
    asset,
    jobStatusUrl = '/api/job/',
    progressCallback,
    successCallback,
    failedCallback
  }) {
    this.jobId = jobId;
    this.milliseconds = milliseconds;
    this.asset = asset;
    this.jobStatusUrl = jobStatusUrl;
    this.progressCallback = progressCallback;
    this.successCallback = successCallback;
    this.failedCallback = failedCallback;
    this.interval = null;
  }

  startInterval() {
    this.interval = setInterval(() => {
      axios.get(`${this.jobStatusUrl}${this.jobId}`).then(jobUpdate => {
        if (typeof this.progressCallback === 'function') {
          this.progressCallback(jobUpdate.data, this.asset);
        }

        if (jobUpdate.data.status.includes('FINISHED')) {
          if (typeof this.successCallback === 'function') {
            this.successCallback(jobUpdate.data, this.asset);
          }

          this.stopInterval();
        } else if (jobUpdate.data.status.includes('FAILED') || jobUpdate.data.status.includes('ABORTED')) {
          if (typeof this.failedCallback === 'function') {
            this.failedCallback(jobUpdate.data, this.asset);
          }

          this.stopInterval();
        }
      });
    }, this.milliseconds);
  }

  stopInterval() {
    clearInterval(this.interval);
  }

}

const STATUS_INITIAL = 0;
const STATUS_PROGRESS = 1;
const STATUS_SUCCESS = 2;
const STATUS_FAILED = 3;
/**
 * @description Shows an export-prompt that
 * makes an internal request /api/export-location that handles the request.
 *
 * @since 0.9
 *
 * ***
 *
 * ###CSS-variables used:
 *
 * > **--primary** background-color of "Export File" button.<br/>
 * > **--danger** background-color of "Start over" and "Abort" buttons.<br/>
 * > **--success** background-color of "Start over" button.<br/>
 */

var VdtShapeExport = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-shape-export"
    }, [_c('div', {
      staticClass: "vdt-shape-export__form"
    }, [_vm.isInitial ? _c('form', {
      on: {
        "submit": function ($event) {
          $event.preventDefault();
        }
      }
    }, [_c('div', {
      staticClass: "vdt-shape-export__form__input"
    }, [_vm.tag ? _c('span', [_vm._v("Choose export location for " + _vm._s(_vm.shapeId) + " (" + _vm._s(_vm.tag) + "): ")]) : _vm._e(), _vm._v(" "), !_vm.tag ? _c('span', [_vm._v("Choose export location for " + _vm._s(_vm.shapeId) + ": ")]) : _vm._e(), _vm._v(" "), _c('div', {
      staticClass: "vdt-shape-export__form__input-container"
    }, [_c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedLocation,
        expression: "selectedLocation"
      }],
      on: {
        "change": function ($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });
          _vm.selectedLocation = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
        }
      }
    }, [_c('option', {
      attrs: {
        "value": "",
        "disabled": "disabled"
      }
    }, [_vm._v("Choose a location")]), _vm._v(" "), _vm._l(_vm.exportLocations, function (location) {
      return _c('option', {
        key: location.name,
        domProps: {
          "value": location
        }
      }, [_vm._v(" " + _vm._s(location.name) + " ")]);
    })], 2)]), _vm._v(" "), _c('button', {
      staticClass: "vdt-button vdt-button--primary",
      attrs: {
        "disabled": _vm.selectedLocation === ''
      },
      on: {
        "click": function ($event) {
          _vm.startExportJob();
        }
      }
    }, [_vm._v(" Export File ")])])]) : _vm.isStarted ? _c('div', [_c('div', {
      staticClass: "vdt-shape-export__progress"
    }, [_c('div', {
      staticClass: "spinner"
    }), _vm._v(" "), _c('span', [_vm._v("In progress")]), _vm._v(" "), _vm.exportStatus !== '' ? _c('pre', [_vm._v(_vm._s(_vm.exportStatus) + " - Step " + _vm._s(_vm.currentStep) + " of " + _vm._s(_vm.totalSteps))]) : _vm._e(), _vm._v(" "), _c('button', {
      staticClass: "vdt-button vdt-button--danger",
      attrs: {
        "disabled": _vm.isFailed
      },
      on: {
        "click": _vm.abortExport
      }
    }, [_vm._v("Abort")])])]) : _vm.isSuccess || _vm.isFailed ? _c('div', {
      staticClass: "vdt-shape-export__container"
    }, [_vm.isSuccess ? _c('div', {
      staticClass: "vdt-shape-export__container__message"
    }, [!_vm.filename ? _c('span', [_vm._v("Shape successfully exported to " + _vm._s(_vm.selectedLocation.name) + ".")]) : _vm._e(), _vm._v(" "), _c('pre', [_vm._v("Export job ID: " + _vm._s(_vm.job.jobId))]), _vm._v(" "), _c('button', {
      staticClass: "vdt-button vdt-button--success",
      on: {
        "click": function ($event) {
          _vm.reset(false);
        }
      }
    }, [_vm._v(" Start over ")])]) : _vm._e(), _vm._v(" "), _vm.isFailed ? _c('div', {
      staticClass: "vdt-shape-export__container__message"
    }, [_c('span', [_vm._v("Export failed")]), _vm._v(" "), _c('pre', [_vm._v(_vm._s(_vm.errorMessage))]), _vm._v(" "), _c('button', {
      staticClass: "vdt-button vdt-button--danger",
      on: {
        "click": function ($event) {
          _vm.reset(false);
        }
      }
    }, [_vm._v(" Start over ")])]) : _vm._e()]) : _vm._e()])]);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-30860ebb',
  name: 'VdtShapeExport',
  props: {
    /**
    * @description String of the exact backend url to abort a job.<br/>
    * **:abort-job-url="'/api/job/'"**<br/>
    * Result is a backend call to: **DELETE '/api/job/<str:job_id>'**
    */
    abortJobUrl: {
      type: String,
      required: true
    },

    /**
    * @description String of the exact backend url to start the Shape Export.<br/>
    * **:export-location-url="'/api/export-location'"**<br/>
    * Result is a backend call to: **GET '/api/export-location'**
    */
    exportLocationUrl: {
      type: String,
      required: true
    },

    /**
    * @description The ID of the item.
    */
    itemId: {
      type: String,
      required: true
    },

    /**
    * @description String of the exact backend url to poll the job status.<br/>
    * **:job-status-url="'/api/job/'"**<br/>
    * Result is a backend call to: **GET '/api/job/<str:job_id>'**
    */
    jobStatusUrl: {
      type: String,
      required: true
    },

    /**
    * @description How often the job progress should be polled.
    */
    pollInterval: {
      type: Number,
      default: 2000,
      required: false
    },

    /**
    * @description The id of the shape that should be exported.
    */
    shapeId: {
      type: String,
      required: true
    },

    /**
    * @description String of the exact backend url to start the Shape Export.<br/>
    * **:start-export-url="'/api/shape-export'"**<br/>
    * Result is a backend call to: **POST '/api/shape-export'**<br/>
    * With body containing itemId, shapeId, locationName and useOriginalFilename.
    */
    startExportUrl: {
      type: String,
      required: true
    },

    /**
    * @description The shape tag, it is only used for better UX.
    */
    tag: {
      type: String,
      required: false,
      default: null
    }
  },

  data() {
    return {
      exportLocations: [],
      selectedLocation: '',
      exportStatus: '',
      currentStep: '',
      errorMessage: '',
      job: {},
      abortInterval: null,
      currentStatus: null,
      pollJob: null
    };
  },

  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },

    isStarted() {
      return this.currentStatus === STATUS_PROGRESS;
    },

    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },

    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    }

  },

  mounted() {
    this.getExportLocations();
    this.currentStatus = 0;
  },

  destroyed() {
    if (this.pollJob) {
      this.pollJob.stopInterval();
    }

    if (this.abortInterval) {
      this.stopInterval(this.abortInterval);
    }
  },

  methods: {
    reset() {
      // Reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.abortInterval = null;
      this.pollJob = null;
      this.selectedLocation = '';
      this.exportStatus = '';
      this.currentStep = '';
      this.errorMessage = '';
      this.job = {};
    },

    getExportLocations() {
      axios.get(this.exportLocationUrl).then(response => {
        this.exportLocations = response.data.exportLocation;
      });
    },

    startExportJob() {
      if (this.currentStatus === STATUS_PROGRESS) return;
      this.currentStatus = STATUS_PROGRESS;
      const data = {
        itemId: this.itemId,
        shapeId: this.shapeId,
        locationName: this.selectedLocation.name,
        useOriginalFilename: 'true'
      };
      axios.post(this.startExportUrl, data).then(response => {
        this.job = response.data;
        this.startPollJob(this.job.jobId, this.itemId);
      });
    },

    startPollJob(jobId, itemId) {
      this.pollJob = new JobPollService({
        jobId,
        milliseconds: this.pollInterval,
        asset: itemId,
        jobStatusUrl: this.jobStatusUrl,
        progressCallback: this.jobPoll,
        successCallback: this.jobSuccess,
        failedCallback: this.jobFailed
      });
      this.pollJob.startInterval();
    },

    jobPoll(job) {
      this.exportStatus = job.status;
      this.currentStep = job.currentStep.number;
      this.totalSteps = job.totalSteps;
    },

    jobSuccess(job) {
      this.currentStatus = STATUS_SUCCESS;
      /**
      * @event exportSuccess
      * @description Emits the job when the job is successfull
      * @type {Object} the successfull job
      */

      this.$emit('exportSuccess', job);
    },

    jobFailed(job) {
      this.currentStatus = STATUS_FAILED;
      const error = job.data.find(o => o.key === 'errorMessage');

      if (error !== undefined) {
        this.errorMessage = error.value;
      }
      /**
      * @event exportFailed
      * @description Emits the job when the job has failed
      * @type {Object} the failed job
      */


      this.$emit('exportFailed', job);
    },

    stopInterval(interval) {
      clearInterval(interval);
    },

    abortExport() {
      if (!this.abortInterval) {
        this.abortInterval = setInterval(() => {
          if (this.job.jobId) {
            this.stopInterval(this.abortInterval);
            axios.delete(`${this.abortJobUrl}${this.job.jobId}`);
          }
        }, 500);
      }
    }

  }
};

class TimeCode {
  static getSecond(timeCode) {
    if (timeCode.indexOf('@') > -1) {
      const timeCodeArray = timeCode.split('@'); // ["sample", "denominator{:numerator}"]

      if (timeCodeArray.length > 2) {
        throw Error('Invalid time representation');
      } // Set the time sample


      const sample = parseFloat(timeCodeArray[0], 10);
      const denominatorTimeBase = timeCodeArray[1];
      let timeBase = null; // Format the time base

      switch (denominatorTimeBase) {
        case 'PAL':
          timeBase = 25;
          break;

        case 'NTSC':
          timeBase = 30000 / 1001;
          break;

        case 'NTSC30':
          timeBase = 30;
          break;

        default:
          // if denominator:numerator
          if (denominatorTimeBase.indexOf(':') > -1) {
            const timebaseArray = denominatorTimeBase.split(':');
            const denominator = parseInt(timebaseArray[0], 10);
            const numerator = parseInt(timebaseArray[1], 10);
            timeBase = denominator / numerator;
          } else {
            // if only denominator
            timeBase = parseInt(denominatorTimeBase, 10);
          }

          break;
      }

      return sample / timeBase;
    }

    return parseFloat(timeCode);
  }

}

/**
 * @description Internal component for the player to display scrubbing thumbnial preview
 * @since 1.0
 */

var ThumbnailScrub = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.showThumbnail,
        expression: "showThumbnail"
      }],
      staticClass: "vdt-video__thumbnail",
      style: _vm.currentThumbnail.style
    }, [_c('span', {
      staticClass: "formatted-time"
    }, [_vm._v(" " + _vm._s(_vm.currentThumbnail.time) + " ")])]);
  },
  staticRenderFns: [],
  name: 'ThumbnailScrub',
  props: {
    /**
    * @description the video.js player object to attach the component to
    */
    player: {
      type: Object,
      required: true
    },

    /**
     * The thumbnails that will be shown while scrubbing
     */
    thumbnails: {
      type: Array,
      required: true,
      default: () => []
    },

    /**
     * Interval of the thumbnails
     */
    thumbnailsInterval: {
      type: Number,
      required: true,
      default: 0
    }
  },
  data: () => ({
    showThumbnail: false,
    currentThumbnail: {
      style: {},
      time: 0
    }
  }),

  mounted() {
    this.player.on(this.player.controlBar.progressControl.el(), 'mousemove', throttle(this.isHovering, 16));
    this.player.on(this.player.controlBar.progressControl.el(), 'mouseleave', () => {
      this.showThumbnail = false;
    });
  },

  destroyed() {
    this.player.off(this.player.controlBar.progressControl.el(), 'mousemove', throttle(this.isHovering, 16));
    this.player.off(this.player.controlBar.progressControl.el(), 'mouseleave', () => {
      this.showThumbnail = false;
    });
  },

  methods: {
    isHovering(event) {
      const relativePosition = event.offsetX / event.currentTarget.clientWidth;
      const time = relativePosition * this.player.duration();
      const left = this.getLeftPosition(time, relativePosition, event.currentTarget.clientWidth);
      this.setCurrentThumbnail({
        time,
        leftPosition: left
      });
      this.showThumbnail = true;
    },

    setCurrentThumbnail({
      time = null,
      leftPosition = '0px'
    }) {
      if (time === null || time === undefined) return;

      for (let i = 0; i < this.thumbnails.length; i += 1) {
        if (time + this.thumbnailsInterval / 2 > this.thumbnails[i].time && time - this.thumbnailsInterval / 2 < this.thumbnails[i].time) {
          this.currentThumbnail = {
            style: {
              'background-image': `url(${this.thumbnails[i].thumbnail}`,
              left: leftPosition,
              'will-change': 'left'
            },
            time: videojs.formatTime(time)
          };
          break;
        }
      }
    },

    getLeftPosition(time, relativePosition, width) {
      // If on left or right edge of controllbar
      // lock the left position to prevent the thumbnail to overflow
      if (width * relativePosition < 100) {
        return '100px';
      } else if (width - width * relativePosition < 100) {
        return `${width - 100}px`;
      }

      return `${time / this.player.duration() * 100}%`;
    }

  }
};

const DEFAULT_EVENTS = ['loadeddata', 'loadedmetadata', 'canplay', 'canplaythrough', 'play', 'pause', 'waiting', 'playing', 'ended', 'error'];
/**
* @event loadeddata
* @type {Object}
* @description When data for current playback position is ready.
*/

/**
/**
* @event loadedmetadata
* @type {Object}
* @description Fired when the player has initial duration and dimension information.
*/

/**
* @event canplay
* @type {Object}
* @description When media can be played.
*/

/**
* @event canplaythrough
* @type {Object}
* @description When calculated that media can be played to its end without buffering.
*/

/**
* @event play
* @type {Object}
* @description When 'paused' property is changed from true to false.
*/

/**
* @event pause
* @type {Object}
* @description When playback has been paused.
*/

/**
* @event waiting
* @type {Object}
* @description When playback stops due to lack of data.
*/

/**
* @event playing
* @type {Object}
* @description When playback is resumed after waiting
*/

/**
* @event ended
* @type {Object}
* @description When video playback ends.
*/

/**
* @event error
* @type {Object}
* @description When error occurs.
*/

/**
 * @description Customizable Video Player wrapping video.js player
 * @since 0.9
 *
 * ***
 *
 * ###CSS-variables used:
 *
 * > **--contrast-color-1** text, icons, hover states.<br/>
 * > **--contrast-color-11** player backdrop color.<br/>
 * > **--primary** load progress color.<br/>
 */

var VdtVideoPlayer = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-video__wrapper"
    }, [_c('video', {
      ref: "videoNode",
      staticClass: "video-js vdt-video-player"
    }, [_vm._t("sources")], 2)]);
  },
  staticRenderFns: [],
  name: 'VdtVideoPlayer',
  props: {
    /**
     * @description A set of options configuring the video.js player.
     * Read more https://github.com/videojs/video.js/blob/master/docs/guides/options.md
     */
    globalOptions: {
      type: Object,
      required: false,
      default: () => ({})
    },

    /**
     * @description One shape whos source will be tried to play. Don't use together with src-prop.
     */
    shape: {
      type: Object,
      required: false,
      default: null
    },

    /**
     * @description The source URL to a video source to embed
     * Shorthand for globaloptions.src
     * Don't use together with shape-prop.
     */
    src: {
      type: String,
      required: false,
      default: ''
    },

    /**
     * @description Array of thumbnails used for timeline scrubbing.
     * Should be generated through a thumbnail job in Vidispine.
     * See http://apidoc.vidispine.com/latest/ref/item/thumbnail.html#start-a-thumbnail-job
     */
    thumbnails: {
      type: Array,
      required: false,
      default: () => []
    },

    /**
     * @description Interval in seconds of each scrubbing thumbnail
     */
    thumbnailsInterval: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data: () => ({
    player: null,

    /**
    * The playerOptions passed to the player.
    */
    playerOptions: {
      autoplay: false,
      controls: true,
      fluid: true,
      loop: false,
      muted: false,
      poster: '',
      src: '',
      sources: [],
      preload: 'auto',
      playbackRates: [0.5, 1, 1.5, 2],
      plugins: [],
      width: '720'
    },
    scrubThumbnails: {
      enable: false,
      filtered: []
    }
  }),
  watch: {
    shape() {
      this.setSource();
    },

    src(newSrc) {
      if (newSrc.length) {
        this.setSource();
      }
    }

  },

  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },

  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
      this.player = null;
    }
  },

  methods: {
    init() {
      merge(this.playerOptions, this.globalOptions);

      const vm = this; // For use inside of onPlayerReady scope

      if (this.player) {
        this.player.dispose();
      } // Filter out and format the thumbnails that match the specified interval


      for (let i = 0; i < this.thumbnails.length; i += 1) {
        const timecode = this.thumbnails[i].substring(this.thumbnails[i].lastIndexOf('/') + 1, this.thumbnails[i].lastIndexOf('?hash'));
        const time = Math.round(TimeCode.getSecond(timecode));

        if (!(time % this.thumbnailsInterval)) {
          this.scrubThumbnails.filtered.push({
            thumbnail: this.thumbnails[i],
            time
          });
        }
      }
      /**
      * Should thumbnails be enabled?
      * Disabled on mobile devices and if no matched thumbnails exist.
      * */


      this.scrubThumbnails.enable = this.scrubThumbnails.filtered.length > 0 && !vm.isMobileDevice(); // If we show thumbnails remove the original mousetime display in favor for our custom one

      if (this.scrubThumbnails.enable) {
        this.playerOptions.controlBar = {
          progressControl: {
            seekBar: {
              mouseTimeDisplay: false
            }
          }
        };
      } // thumbnail config end


      this.player = new videojs(this.$refs.videoNode, this.playerOptions, function onPlayerReady() {
        /**
         *  Note: this. is referencing to the player
         *  Use vm to reference the Vue-instance
        */
        if (!this.src().length) {
          vm.setSource();
        } // Append the ScrubThumbnail component into the player
        // Need to be appended to the Player-element after init to be visible in fullscreen


        if (vm.scrubThumbnails.enable) {
          const ThumbnailScrubClass = Vue.extend(ThumbnailScrub);
          const ThumbnailScrubInstance = new ThumbnailScrubClass({
            propsData: {
              player: this,
              thumbnails: vm.scrubThumbnails.filtered,
              thumbnailsInterval: vm.thumbnailsInterval
            }
          });
          ThumbnailScrubInstance.$mount();
          this.el().appendChild(ThumbnailScrubInstance.$el);
        } // Register event-listerners for all default events
        // http://docs.videojs.com/docs/api/player.html#Eventsended


        for (let i = 0; i < DEFAULT_EVENTS.length; i++) {
          (event => {
            this.on(event, () => {
              vm.$emit(event, this);
            });
          })(DEFAULT_EVENTS[i]);
        } // eslint-disable-next-line func-names


        this.on('timeupdate', function () {
          /**
           * @event timeupdate
           * @type {String}
           * @description When time is updated.
          */
          vm.$emit('timeupdate', this.currentTime());
        });
        /**
         * @event ready
         * @type {Object}
         * @description When player is ready.
         */

        vm.$emit('ready', this);
      });
    },

    getSource() {
      if (this.shape && this.src) {
        throw Error("Can't set source from both Shape and src");
      }

      let src = '';

      if (this.shape && this.shape.containerComponent) {
        if (this.shape.containerComponent.file[0].state === 'LOST' || this.shape.containerComponent.file[0].state === 'MISSING' || this.shape.containerComponent.file[0].state === 'TO_BE_DELETED') {
          // eslint-disable-next-line
          console.log('THE FILE IS LOST!');
          return '';
        }

        [src] = this.shape.containerComponent.file[0].uri;
      } else if (this.src) {
        ({
          src
        } = this);
      } else {
        throw Error("Can't find suitable source, check prop-bindings");
      }

      return src;
    },

    setSource() {
      const src = this.getSource();
      this.player.src(src);
    },

    isMobileDevice() {
      return typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1;
    }

  }
};

/**
 * @description Showscost estmiates for a resrouces and a button to select the displayed resrouce
 * @since 0.9
 *
 * ***
 *
 * ###CSS-variables used:
 *
 * > **--primary** background color of cost estimation label.<br/>
 * > **--contrast-color-1** text color  of cost estimation label,
 * background color of cost card.<br/>
 */
var VidinetCostCard = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-vidinet-cost-card"
    }, [_c('div', {
      staticClass: "vdt-vidinet-cost-card__wrapper"
    }, [_c('header', [_c('span', {
      staticClass: "vdt-vidinet-cost-card__name"
    }, [_vm._v(" " + _vm._s(_vm.estimate.name) + " ")]), _vm._v(" "), _c('span', {
      staticClass: "vdt-vidinet-cost-card__id"
    }, [_vm._v(" (" + _vm._s(_vm.estimate.resource) + ") ")])]), _vm._v(" "), _c('footer', [_c('span', {
      staticClass: "vdt-vidinet-cost-card__wrapper__label"
    }, [_vm._v("Estimated cost: ")]), _vm._v(" "), _c('span', {
      staticClass: "vdt-vidinet-cost-card__wrapper__value"
    }, [_vm._v(" " + _vm._s(_vm.estimate.cost.unit) + " " + _vm._s(_vm.cost) + " ")])]), _vm._v(" "), _c('div', {
      staticClass: "vdt-vidinet-cost-card__trigger"
    }, [_c('button', {
      staticClass: "vdt-button vdt-button--primary",
      on: {
        "click": function ($event) {
          _vm.selectResource();
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.buttonLabel) + " ")])])])]);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-5f0aaed5',
  name: 'VdtVidinetCostCard',
  props: {
    /**
     * @description A valid cost estimate for a resource from Vidispine
     */
    estimate: {
      type: Object,
      required: true
    },

    /**
     * @description The label for the select resource button
     */
    buttonLabel: {
      type: String,
      required: false,
      default: 'Start'
    }
  },
  computed: {
    cost() {
      const rounded = Math.round(this.estimate.cost.value * 100) / 100;
      return rounded.toFixed(2);
    }

  },
  methods: {
    selectResource() {
      /**
      * Emits the resource when Start-button is clicked
      *
      * @event resourceSelected
      * @type {Object}
      */
      this.$emit('resourceSelected', this.estimate);
    }

  }
};

const STATUS_INITIAL$1 = 0;
const STATUS_SAVING = 1;
const STATUS_COST = 2;
const STATUS_SUCCESS$1 = 3;
const STATUS_FAILED$1 = 4;
const STATUS_QC = 5;
const STATUS_ERROR = 6;
/**
 * @description Start a Vidinet Quality Control Job from selected shape
 * @since 0.9
 *
 * ***
 *
 * ###CSS-variables used:
 *
 * > **--primary** background-color of "Start QC" button.<br/>
 * > **--danger** background-color of "Start over" and "Abort" buttons.<br/>
 * > **--success** background-color of "Start over" button.<br/>
 */

var VdtVidinetQC = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-vidinet-qc"
    }, [_vm.vidinetResources.length > 0 && _vm.filteredResources ? _c('div', {
      staticClass: "vdt-vidinet-qc__form"
    }, [!_vm.isFailed && !_vm.isSuccess ? _c('form', {
      attrs: {
        "enctype": "multipart/form-data",
        "novalidate": ""
      }
    }, [_c('div', {
      staticClass: "vdt-vidinet-qc__form__input"
    }, [_c('span', [_vm._v("Shape:")]), _vm._v(" "), _c('span', [_vm._v(_vm._s(_vm.shapeTag))])]), _vm._v(" "), _c('div', {
      staticClass: "vdt-vidinet-qc__form__input"
    }, [_c('span', [_vm._v("Aurora:")]), _vm._v(" "), _vm.vidinetResources.length > 1 ? _c('div', {
      staticClass: "vdt-vidinet-qc__form__input-container"
    }, [_c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedResource,
        expression: "selectedResource"
      }],
      attrs: {
        "disabled": _vm.isSaving || _vm.isQc
      },
      on: {
        "change": [function ($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });
          _vm.selectedResource = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
        }, _vm.getCostEstimate]
      }
    }, [_c('option', {
      attrs: {
        "disabled": "disabled",
        "value": ""
      }
    }, [_vm._v("Select service:")]), _vm._v(" "), _vm._l(_vm.vidinetResources, function (resource) {
      return _c('option', {
        key: resource.id,
        domProps: {
          "value": resource.id
        }
      }, [_vm._v(" " + _vm._s(resource.vidinet.name) + " ")]);
    })], 2)]) : _vm._e(), _vm._v(" "), _vm.vidinetResources.length == 1 ? _c('span', [_vm._v(" " + _vm._s(_vm.vidinetResources[0].vidinet.name) + " ")]) : _vm._e()]), _vm._v(" "), _vm.qcType == 'none' ? _c('div', {
      staticClass: "vdt-vidinet-qc__form__input"
    }, [_c('span', [_vm._v("QC Type:")]), _vm._v(" "), _c('div', {
      staticClass: "vdt-vidinet-qc__form__input-container"
    }, [_c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedQcType,
        expression: "selectedQcType"
      }],
      attrs: {
        "disabled": _vm.isSaving || _vm.isQc
      },
      on: {
        "change": [function ($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });
          _vm.selectedQcType = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
        }, _vm.getCostEstimate]
      }
    }, [_c('option', {
      attrs: {
        "disabled": "disabled",
        "value": ""
      }
    }, [_vm._v("Select template")]), _vm._v(" "), _vm._l(_vm.qcTypes, function (qcType) {
      return _c('option', {
        key: qcType,
        domProps: {
          "value": qcType
        }
      }, [_vm._v(" " + _vm._s(qcType) + " ")]);
    })], 2)])]) : _vm._e(), _vm._v(" "), _vm.qcType != 'none' ? _c('div', {
      staticClass: "vdt-vidinet-qc__form__input"
    }, [_c('span', [_vm._v("QC to be perfomed:")]), _vm._v(" "), _c('a', [_vm._v(_vm._s(_vm.qcType))])]) : _vm._e()]) : _vm._e(), _vm._v(" "), !_vm.disableEstimate && _vm.isReadyToStart ? _c('div', {
      staticClass: "vdt-vidinet-qc__cost-card-container"
    }, [_vm.costEstimates ? _c('div', _vm._l(_vm.costEstimates, function (estimate) {
      return _c('VidinetCostCard', {
        key: estimate.resource,
        attrs: {
          "estimate": estimate
        },
        on: {
          "resourceSelected": function ($event) {
            _vm.startQc($event);
          }
        }
      });
    })) : _vm._e()]) : _vm.disableEstimate && _vm.isReadyToStart ? _c('div', {
      staticClass: "vdt-vidinet-qc__cost-card-container--no-estimate"
    }, [_c('button', {
      staticClass: "vdt-button vdt-button--primary",
      on: {
        "click": function ($event) {
          _vm.startQc({
            resource: _vm.selectedResource
          });
        }
      }
    }, [_vm._v(" Start QC ")])]) : _vm._e(), _vm._v(" "), _vm.selectedResource != '' && _vm.selectedQcType != '' && _vm.isError ? _c('div', {
      staticClass: "vdt-vidinet-qc__container"
    }, [_vm._m(0)]) : _vm._e(), _vm._v(" "), _vm.isSaving ? _c('div', {
      staticClass: "vdt-vidinet-qc__container"
    }, [_vm._m(1)]) : _vm._e(), _vm._v(" "), _vm.isQc ? _c('div', {
      staticClass: "vdt-vidinet-qc__container"
    }, [_c('div', {
      staticClass: "vdt-vidinet-qc__container__message"
    }, [_c('div', {
      staticClass: "spinner"
    }), _vm._v(" "), _c('span', [_vm._v("QC in progress - " + _vm._s(_vm.currentState))]), _vm._v(" "), _c('button', {
      staticClass: "vdt-button vdt-button--danger",
      attrs: {
        "disabled": _vm.currentState === 'ABORTED_PENDING'
      },
      on: {
        "click": _vm.abortJob
      }
    }, [_vm._v("Abort")])])]) : _vm._e(), _vm._v(" "), _vm.isSuccess || _vm.isFailed ? _c('div', {
      staticClass: "vdt-vidinet-qc__container"
    }, [_vm.isSuccess ? _c('div', {
      staticClass: "vdt-vidinet-qc__container__message"
    }, [_c('span', [_vm._v("Vidinet QC " + _vm._s(_vm.qced.jobId) + " was successful.")]), _vm._v(" "), _c('a', {
      attrs: {
        "href": "http://apidoc.vidispine.com/latest/ref/metadata/bulky.html#bulky-metadata",
        "title": "Retrieve bulky metadata - Vidispine API"
      }
    }, [_c('pre', [_vm._v("GET /item/" + _vm._s(_vm.itemId) + "/shape/" + _vm._s(_vm.shapeId) + "/metadata/bulky/")])]), _vm._v(" "), _c('span', [_vm._v(" Make a request to find the details about this QC job. ")]), _vm._v(" "), _c('button', {
      staticClass: "vdt-button vdt-button--success",
      on: {
        "click": function ($event) {
          _vm.reset(false);
        }
      }
    }, [_vm._v("Start over")])]) : _vm._e(), _vm._v(" "), _vm.isFailed ? _c('div', {
      staticClass: "vdt-vidinet-qc__container__message"
    }, [_c('span', [_vm._v("Vidinet QC Failed.")]), _vm._v(" "), _c('pre', [_vm._v(_vm._s(_vm.qcError))]), _vm._v(" "), _c('button', {
      staticClass: "vdt-button vdt-button--danger",
      on: {
        "click": function ($event) {
          _vm.reset(false);
        }
      }
    }, [_vm._v("Start over")])]) : _vm._e()]) : _vm._e()]) : _c('p', [_vm._v(" Did you forget to insert the resources? If you inserted your resources, that means you don't have an Aurora Service connected. Add a \"File-based QC by Aurora\" service through Vidinet to get started with Quality Control! ")])]);
  },
  staticRenderFns: [function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-vidinet-qc__container__message"
    }, [_c('span', [_vm._v("Cost estimate unavailable, please select a different service.")])]);
  }, function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-vidinet-qc__container__message"
    }, [_c('div', {
      staticClass: "spinner"
    }), _vm._v(" "), _c('span', [_vm._v("Getting cost estimate")])]);
  }],
  _scopeId: 'data-v-03295c3f',
  name: 'VdtVidinetQC',
  components: {
    VidinetCostCard
  },
  props: {
    /**
    * @description String of the exact backend url to abort a job.<br/>
    * **:abort-job-url="'/api/job/'"**<br/>
    * Result is a backend call to: **DELETE '/api/job/<str:job_id>'**
    */
    abortJobUrl: {
      type: String,
      required: true
    },

    /**
    * @description String of the exact backend url to get a cost estimate.<br/>
    * **:cost-estimate-url="'/api/vidinet/qc'"**<br/>
    * Result is a backend call to: **GET '/api/vidinet/qc?itemId=...&tag=...'**<br/>
    * Will also poll the backend: **GET '/api/vidinet/qc?existingEstimate=...'**<br/>
    * Towards the same backend url, but just use it as such:<br/>
    * **GET (the raw) existingEstimate in the backend**
    */
    costEstimateUrl: {
      type: String,
      required: true
    },

    /**
     * @description Disable the cost estimate. Set to true when you are not using Vidinet.
     * If you use Vidinet it will still cost you, but you wont get an estimate.
     */
    disableEstimate: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * @description ID of the item to be QC'ed.
     */
    itemId: {
      type: String,
      required: true
    },

    /**
     * @description String of the exact backend url to poll the job status.<br/>
    * **:job-status-url="'/api/job/'"**<br/>
    * Result is a backend call to: **GET '/api/job/<str:job_id>'**
    */
    jobStatusUrl: {
      type: String,
      required: true
    },

    /**
    * @description Prop that can be used if component is used in a modal, to react on modal state.
    */
    modalOpen: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * @description Custom interval to check job status, in milliseconds.
     */
    pollInterval: {
      type: Number,
      default: 2000,
      required: false
    },

    /**
     * @description Which QC type to perform.
     * Setting this prop will disable the dropdown for choosing what type of QC to perform.
     *
     * The different QC's are:<br/>
     * _as10 Restrictions,
     * atsc Broadcast,
     * Codec and Container Syntax,
     * dpp,
     * dvb-t Broadcast,
     * filmanalog ingest,
     * japan broadcast,
     * Netflix,
     * Quality Test,
     * Quick Scan,
     * Stream Summary Only,
     * Tape Ingest,
     * xdcam,_
     */
    qcType: {
      type: String,
      required: false,
      default: 'none'
    },

    /**
     * @description Which resources to choose from.
     * If the array only contains one resource, no dropdown will be shown.<br/>
     * If you want to use Vidinet supply the JSON response from: **GET /API/resource/vidinet**
     */
    resources: {
      type: Array,
      required: true
    },

    /**
     * @description ID of the shape to be QC'ed.
     */
    shapeId: {
      type: String,
      required: true
    },

    /**
     * @description Which shape to run Quality Control on, is only used for UX.
     */
    shapeTag: {
      type: String,
      required: true
    },

    /**
    * @description String of the exact backend url to start the QC.<br/>
    * **:start-qc-url="'/api/vidinet/qc'"**<br/>
    * Result is a Backend call to: **POST '/api/vidinet/qc'**
    *
    * Containing a body with:
    * _itemId, shapeId, resourceId, jobmetadata: 'auroraTemplate=(this.selectedQcType)'_
    */
    startQcUrl: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      qced: {},
      qcError: null,
      currentStatus: null,
      currentState: null,
      costEstimates: null,
      selectedResource: '',
      selectedQcType: '',
      qcJobId: null,
      abortInterval: null,
      qcTypes: ['as10 Restrictions', 'atsc Broadcast', 'Codec and Container Syntax', 'dpp', 'dvb-t Broadcast', 'film_analog ingest', 'japan broadcast', 'Netflix', 'Quality Test', 'Quick Scan', 'Stream Summary Only', 'Tape Ingest', 'xdcam'],
      vidinetResources: [],
      filteredResources: false,
      pollJob: null
    };
  },

  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL$1;
    },

    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },

    foundEstimate() {
      return this.currentStatus === STATUS_COST;
    },

    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS$1;
    },

    isFailed() {
      return this.currentStatus === STATUS_FAILED$1;
    },

    isQc() {
      return this.currentStatus === STATUS_QC;
    },

    isError() {
      return this.currentStatus === STATUS_ERROR;
    },

    isReadyToStart() {
      return this.selectedResource !== '' && this.selectedQcType !== '' && !this.isSaving && !this.isQc && !this.isSuccess && !this.isFailed && this.foundEstimate;
    }

  },
  watch: {
    modalOpen(newVal) {
      if (newVal === false && !this.isQc && !this.isSaving) {
        this.reset(true);
      }
    }

  },

  mounted() {
    this.reset(false);
  },

  destroyed() {
    if (this.costEstimates) {
      this.stopInterval(this.costEstimates.interval);
    }

    if (this.pollJob) {
      this.pollJob.stopInterval();
    }
  },

  methods: {
    reset(shutdown) {
      // Reset form to initial state
      this.filteredResources = false;
      this.currentStatus = STATUS_INITIAL$1;
      this.currentState = null;
      this.qced = {};
      this.qcError = null;
      this.costEstimates = null;
      this.selectedResource = '';
      this.selectedQcType = '';
      this.qcJobId = null;
      this.pollJob = null;
      this.abortInterval = null;

      if (!shutdown && this.resources) {
        this.vidinetResources = this.resources.filter(resource => resource.vidinet.type === 'AURORA');
        this.filteredResources = true;

        if (this.vidinetResources.length === 1 && this.qcType !== 'none') {
          this.getCostEstimate();
        }
      }
    },

    startQc(resource) {
      if (this.isQc) return;
      this.currentStatus = STATUS_QC;
      const data = {
        itemId: this.itemId,
        shapeId: this.shapeId,
        resourceId: resource.resource,
        jobmetadata: `auroraTemplate=${this.selectedQcType}`
      };
      axios.post(this.startQcUrl, data).then(qc => {
        const newShape = {};
        newShape.state = qc.data.status;
        newShape.jobId = qc.data.jobId;
        this.currentState = newShape.state;
        this.qcJobId = newShape.jobId;
        this.startPollJob(newShape.jobId, newShape);
      }).catch(() => {
        this.reset(false);
      });
    },

    startPollJob(jobId, shape) {
      this.pollJob = new JobPollService({
        jobId,
        milliseconds: this.pollInterval,
        asset: shape,
        jobStatusUrl: this.jobStatusUrl,
        progressCallback: this.jobPoll,
        successCallback: this.jobSuccess,
        failedCallback: this.jobFailed
      });
      this.pollJob.startInterval();
    },

    jobPoll(job, shape) {
      const newShape = shape;
      newShape.progress = job.log.task[0].progress;
      newShape.state = job.status;
      this.currentState = newShape.state;
      this.qced = newShape;
    },

    jobSuccess(job) {
      this.currentStatus = STATUS_SUCCESS$1;
      /**
      * @event qcSuccess
      * @description Emits the job when the job is successfull
      * @type {Object} the successfull job
      */

      this.$emit('qcSuccess', job);
    },

    jobFailed(job, shape) {
      this.currentStatus = STATUS_FAILED$1;
      let message = '';

      if (job.data.find(o => o.key === 'errorMessage')) {
        message = ` - ${job.data.find(o => o.key === 'errorMessage').value}`;
      }

      this.qcError = `The job finished with status: ${shape.state}${message}`;
      /**
      * @event qcFailed
      * @description Emits the job when the job has failed
      * @type {Object} the failed job
      */

      this.$emit('qcFailed', job);
    },

    getCostEstimate() {
      if (this.qcType !== 'none') {
        this.selectedQcType = this.qcType;
      }

      if (this.vidinetResources.length === 1) {
        this.selectedResource = this.vidinetResources[0].id;
      }

      if (!this.disableEstimate && this.selectedResource !== '' && this.selectedQcType !== '') {
        const jobmetadata = `auroraTemplate=${this.selectedQcType}`;
        const url = `${this.costEstimateUrl}?itemId=${this.itemId}&shapeId=${this.shapeId}&resourceId=${this.selectedResource}&jobmetadata=${jobmetadata}`;
        this.currentStatus = STATUS_SAVING;
        axios.get(url).then(cost => {
          this.estimate(cost, url);
        }).catch(() => {
          this.currentStatus = STATUS_ERROR;
          this.qcError = 'Could not get cost estimate';
        });
      } else if (this.disableEstimate && this.selectedResource !== '' && this.selectedQcType !== '') {
        this.currentStatus = STATUS_COST;
      }
    },

    estimate(cost, url) {
      const costEstimateData = cost.data;
      costEstimateData.interval = setInterval(() => {
        axios.get(`${url}&existingEstimate=${costEstimateData.url}`).then(response => {
          costEstimateData.resourceState = find(response.data.service, {
            state: 'FINISHED'
          });
          costEstimateData.state = response.data.state;

          if (costEstimateData.state.includes('FINISHED') && costEstimateData.resourceState && costEstimateData.resourceState.state === 'FINISHED') {
            this.stopInterval(costEstimateData.interval);
            this.costEstimates = response.data.service;
            this.currentStatus = STATUS_COST;
          } else if (costEstimateData.state.includes('FAILED') || costEstimateData.state.includes('ABORTED')) {
            this.stopInterval(costEstimateData.interval);
            this.costEstimates = response.data.service;
            this.currentStatus = STATUS_FAILED$1;
          } else if (costEstimateData.state.includes('FINISHED') && !costEstimateData.resourceState) {
            this.stopInterval(costEstimateData.interval);
            this.costEstimates = response.data.service;
            this.currentStatus = STATUS_FAILED$1;
          }
        }).catch(() => {
          this.qcError = 'Could not get cost estimate';
        });
      }, 500);
    },

    stopInterval(interval) {
      clearInterval(interval);
    },

    abortJob() {
      if (!this.abortInterval) {
        this.abortInterval = setInterval(() => {
          if (this.qcJobId) {
            this.stopInterval(this.abortInterval); // Abort the job

            axios.delete(`${this.abortJobUrl}${this.qcJobId}`);
          }
        }, 500);
      }
    }

  }
};

const STATUS_INITIAL$2 = 0;
const STATUS_ESTIMATING = 1;
const STATUS_COST$1 = 2;
const STATUS_SUCCESS$2 = 3;
const STATUS_FAILED$2 = 4;
const STATUS_ANALYZING = 5;
var VdtCognitive = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-vidinet-cognitive"
    }, [_c('div', {
      staticClass: "vdt-vidinet-cognitive__form"
    }, [!_vm.isEstimating && !_vm.isAnalyzing && !_vm.isSuccess && !_vm.isFailed && _vm.filteredResources.length > 0 && _vm.filteredShapes.length > 0 ? _c('form', [_c('div', {
      staticClass: "vdt-vidinet-cognitive__form__input"
    }, [_c('span', [_vm._v("Shape:")]), _vm._v(" "), _c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedShapeTag,
        expression: "selectedShapeTag"
      }],
      key: _vm.selectedShapeTag,
      on: {
        "change": function ($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });
          _vm.selectedShapeTag = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
        }
      }
    }, _vm._l(_vm.filteredShapes, function (shape) {
      return _c('option', {
        key: shape.id,
        domProps: {
          "value": shape.tag
        }
      }, [_vm._v(" " + _vm._s(shape.id) + " (" + _vm._s(shape.tag) + ") ")]);
    }))]), _vm._v(" "), _c('div', {
      staticClass: "vdt-vidinet-cognitive__form__input"
    }, [_c('span', [_vm._v("Cognitive Service:")]), _vm._v(" "), _c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedResource,
        expression: "selectedResource"
      }],
      key: _vm.selectedResource,
      on: {
        "change": [function ($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });
          _vm.selectedResource = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
        }, _vm.getCostEstimate]
      }
    }, [_c('option', {
      attrs: {
        "disabled": "disabled",
        "value": "null"
      }
    }, [_vm._v("Select Cognitive Service")]), _vm._v(" "), _vm._l(_vm.filteredResources, function (resource) {
      return _c('option', {
        key: resource.id,
        domProps: {
          "value": resource
        }
      }, [_vm._v(" " + _vm._s(resource.vidinet.name) + " ")]);
    })], 2)])]) : _vm._e(), _vm._v(" "), _vm.filteredResources.length == 0 ? _c('div', {
      staticClass: "vdt-vidinet-cognitive__container__message"
    }, [_c('span', [_vm._v("No available COGNITIVE_SERVICE resource found.")])]) : _vm.filteredShapes.length == 0 ? _c('div', {
      staticClass: "vdt-vidinet-cognitive__container__message"
    }, [_c('span', [_vm._v("No usable shape found.")])]) : _vm._e(), _vm._v(" "), _vm.hasFoundCost && _vm.selectedResource !== null && !_vm.disableEstimate && !_vm.isEstimating && !_vm.isAnalyzing && !_vm.isSuccess && !_vm.isFailed ? _c('div', {
      staticClass: "vdt-vidinet-cognitive__cost-card-container"
    }, [_c('div', {
      directives: [{
        name: "id",
        rawName: "v-id",
        value: _vm.costEstimates,
        expression: "costEstimates"
      }]
    }, [_c('span', [_vm._v("Available Analyzers")]), _vm._v(" "), _vm._l(_vm.costEstimates, function (estimate) {
      return _c('VidinetCostCard', {
        key: estimate.resource,
        attrs: {
          "estimate": estimate
        },
        on: {
          "resourceSelected": function ($event) {
            _vm.startCognitiveJob($event);
          }
        }
      });
    })], 2)]) : _vm._e(), _vm._v(" "), _vm.disableEstimate && _vm.selectedResource !== null && _vm.hasFoundCost && !_vm.isEstimating && !_vm.isAnalyzing && !_vm.isSuccess && !_vm.isFailed ? _c('div', {
      staticClass: "vdt-vidinet-cognitive__cost-card-container--no-estimate"
    }, [_c('button', {
      staticClass: "vdt-button vdt-button--primary",
      on: {
        "click": function ($event) {
          _vm.startCognitiveJob(_vm.selectedResource);
        }
      }
    }, [_vm._v(" Start job ")])]) : _vm._e(), _vm._v(" "), _vm.isEstimating ? _c('div', {
      staticClass: "vdt-vidinet-cognitive__container"
    }, [_vm._m(0)]) : _vm.isAnalyzing ? _c('div', {
      staticClass: "vdt-vidinet-cognitive__container"
    }, [_c('div', {
      staticClass: "vdt-vidinet-cognitive__container__message"
    }, [_c('div', {
      staticClass: "spinner"
    }), _vm._v(" "), _c('span', [_vm._v("Analyzing "), _vm.stepDescription.length ? _c('span', [_vm._v(" - " + _vm._s(_vm.stepDescription) + " - " + _vm._s(_vm.cognitiveJob.state) + " ")]) : _vm._e()]), _vm._v(" "), _c('button', {
      staticClass: "vdt-button vdt-button--danger",
      attrs: {
        "disabled": _vm.currentState === 'ABORTED_PENDING'
      },
      on: {
        "click": _vm.abortJob
      }
    }, [_vm._v("Abort")])])]) : _vm.isSuccess || _vm.isFailed ? _c('div', {
      staticClass: "vdt-vidinet-cognitive__container"
    }, [_vm.isSuccess ? _c('div', {
      staticClass: "vdt-vidinet-cognitive__container__message"
    }, [_c('span', [_vm._v("Analysis completed")]), _vm._v(" "), _c('pre', [_vm._v(_vm._s(_vm.selectedResource.vidinet.name))]), _vm._v(" "), _c('button', {
      staticClass: "vdt-button vdt-button--success",
      on: {
        "click": function ($event) {
          _vm.reset(false);
        }
      }
    }, [_vm._v(" Start over ")])]) : _vm.isFailed ? _c('div', {
      staticClass: "vdt-vidinet-cognitive__container__message"
    }, [_c('span', [_vm._v("Analysis failed")]), _vm._v(" "), _c('pre', [_vm._v(_vm._s(_vm.cognitiveError))]), _vm._v(" "), _c('button', {
      staticClass: "vdt-button vdt-button--danger",
      on: {
        "click": function ($event) {
          _vm.reset(false);
        }
      }
    }, [_vm._v(" Start over ")])]) : _vm._e()]) : _vm._e()])]);
  },
  staticRenderFns: [function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-vidinet-cognitive__container__message"
    }, [_c('div', {
      staticClass: "spinner"
    }), _vm._v(" "), _c('span', [_vm._v("Getting cost estimate")])]);
  }],
  _scopeId: 'data-v-113d7245',
  name: 'VdtCognitive',
  components: {
    VidinetCostCard
  },
  props: {
    /**
     * @description The item that should be analyzed
     */
    item: {
      type: Object,
      required: true
    },

    /**
     * @description Disable the cost estmiate.
     * The cost will be hidden,
     * but the job will be charged as usual.
     */
    disableEstimate: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * @description String of the exact backend url to get a cost estimate.<br/>
     * **:cost-estimate-url="'/api/vidinet/analyze'"**<br/>
     * Result is a backend call to:
     * **GET '/API/cost/item/<str:item_id>/analyze?resourceId=...&tag=...'**<br/>
     * Will also poll backend: **GET '/api/vidinet/analyze?existingEstimate=...'**
     */
    costEstimateUrl: {
      type: String,
      required: true
    },

    /**
     * @description String of the exact backend url to start the analyze.<br/>
     * **:start-analyze-url="'/api/vidinet/analyze'"**<br/>
     * Result is a backend call to:
     * **POST '/API/item/<str:item_id>/analyze?resourceId=...&tag=...'**
     */
    startAnalyzeUrl: {
      type: String,
      required: true
    },

    /**
     * @description Which resources to choose from.
     * If you want to use Vidinet supply the JSON response from: **GET /API/resource/vidinet**
     */
    resources: {
      type: Array,
      required: true
    },

    /**
     * @description How often the jobs should be polled while analyzing.
    */
    pollInterval: {
      type: Number,
      default: 2000,
      required: false
    },

    /**
     * @description String of the exact backend url to poll the job status.<br/>
     * **:job-status-url="'/api/job/'"**<br/>
     * Result is a backend call to: **GET '/api/job/<str:job_id>'**
     */
    jobStatusUrl: {
      type: String,
      required: true
    },

    /**
     * @description String of the exact backend url to abort a job.<br/>
     * **:abort-job-url="'/api/job/'"**<br/>
     * Result is a backend call to: **DELETE '/api/job/<str:job_id>'**
     */
    abortJobUrl: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      cognitiveJob: {},
      stepDescription: '',
      popoverShow: false,
      currentStatus: null,
      currentState: null,
      costEstimates: null,
      selectedResource: null,
      selectedShapeTag: '',
      cognitiveError: null,
      pollJob: null,
      abortInterval: null
    };
  },

  computed: {
    itemId() {
      return this.item.metadata.id;
    },

    mediaType() {
      let mediaType = null;

      if (this.item !== undefined && this.item.metadata !== undefined) {
        ({
          mediaType
        } = this.item.metadata);
      }

      return mediaType;
    },

    isInitial() {
      return this.currentStatus === STATUS_INITIAL$2;
    },

    isEstimating() {
      return this.currentStatus === STATUS_ESTIMATING;
    },

    hasFoundCost() {
      return this.currentStatus === STATUS_COST$1;
    },

    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS$2;
    },

    isFailed() {
      return this.currentStatus === STATUS_FAILED$2;
    },

    isAnalyzing() {
      return this.currentStatus === STATUS_ANALYZING;
    },

    isStartable() {
      return true;
    },

    filteredResources() {
      if (this.resources != null) {
        return this.resources.filter(resource => resource.vidinet.type === 'COGNITIVE_SERVICE' && resource.vidinet.state === 'ONLINE');
      }

      return [];
    },

    filteredShapes() {
      if (this.mediaType === 'image') {
        return this.item.shapes.filter(shape => shape.mimeType === 'image/jpeg' || shape.mimeType === 'image/png');
      } else if (this.mediaType === 'video') {
        return this.item.shapes.filter(shape => shape.mimeType === 'video/mp4' && shape.videoComponent[0].codec === 'h264');
      }

      return [];
    }

  },
  watch: {},

  mounted() {
    this.reset(false);
  },

  destroyed() {
    if (this.costEstimates) {
      this.stopInterval(this.costEstimates.interval);
    }

    if (this.pollJob) {
      this.pollJob.stopInterval();
    }
  },

  methods: {
    reset(shutdown) {
      this.currentStatus = STATUS_INITIAL$2;
      this.currentState = null;
      this.costEstimates = null;
      this.cognitiveError = '';
      this.pollJob = null;
      this.abortInterval = null;
      this.stepDescription = '';

      if (!shutdown) {
        this.selectedResource = null;

        if (this.filteredShapes.length > 0) {
          this.selectedShapeTag = this.filteredShapes[0].tag;
        }
      }
    },

    startCognitiveJob(resource) {
      if (this.currentStatus === STATUS_ANALYZING) {
        return;
      }

      this.currentStatus = STATUS_ANALYZING;
      const data = {
        itemId: this.itemId,
        resourceId: this.disableEstimate ? resource.id : resource.resource,
        shapeTag: this.selectedShapeTag
      };
      axios.post(this.startAnalyzeUrl, data).then(response => {
        this.cognitiveJob.state = response.data.status;
        this.cognitiveJob.jobId = response.data.jobId;
        this.startPollJob(this.cognitiveJob.jobId, this.cognitiveJob);
      });
    },

    startPollJob(jobId, cognitiveJob) {
      this.pollJob = new JobPollService({
        jobId,
        milliseconds: this.pollInterval,
        asset: cognitiveJob,
        jobStatusUrl: this.jobStatusUrl,
        progressCallback: this.jobPoll,
        successCallback: this.jobSuccess,
        failedCallback: this.jobFailed
      });
      this.pollJob.startInterval();
    },

    jobPoll(job) {
      this.cognitiveJob.state = job.status;
      this.stepDescription = `Step: ${last(job.log.task).step}: ${last(job.log.task).description.replace('.', '')}`;
      this.currentState = job.status;
    },

    jobSuccess(job) {
      this.currentStatus = STATUS_SUCCESS$2;
      /**
       * @event cognitiveJobSuccess
       * @description Emits the job when the job is successfull
       * @type {Object} the successfull job
       */

      this.$emit('cognitiveJobSuccess', job);
    },

    jobFailed(job, cognitiveJob) {
      this.currentStatus = STATUS_FAILED$2;
      let message = '';

      if (job.data.find(o => o.key === 'errorMessage')) {
        message = ` - ${job.data.find(o => o.key === 'errorMessage').value}`;
      }

      this.cognitiveError = `The job finished with status: ${cognitiveJob.state}${message}`;
      this.currentState = cognitiveJob.state;
      this.pollJob.stopInterval();
      /**
       * @event cognitiveJobFailed
       * @description Emits the job when the job has failed
       * @type {Object} the failed job
       */

      this.$emit('cognitiveJobFailed', job);
    },

    getCostEstimate() {
      if (this.selectedResource === null || this.selectedShapeTag === '') {
        return;
      }

      if (!this.disableEstimate) {
        const url = `${this.costEstimateUrl}?itemId=${this.itemId}&resourceId=${this.selectedResource.id}&shapeTag=${this.selectedShapeTag}`;
        this.currentStatus = STATUS_ESTIMATING;
        axios.get(url).then(cost => {
          this.estimate(cost, url);
        }).catch(() => {
          this.currentStatus = STATUS_FAILED$2;
          this.cognitiveError = 'Could not get cost estimate';
        });
      } else if (this.disableEstimate) {
        this.currentStatus = STATUS_COST$1;
      }
    },

    estimate(cost, url) {
      const costEstimateData = cost.data;
      costEstimateData.interval = setInterval(() => {
        axios.get(`${url}&existingEstimate=${costEstimateData.url}`).then(response => {
          costEstimateData.resourceState = find(response.data.service, {
            state: 'FINISHED'
          });
          costEstimateData.state = response.data.state;

          if (costEstimateData.state.includes('FINISHED') && costEstimateData.resourceState && costEstimateData.resourceState.state === 'FINISHED') {
            this.stopInterval(costEstimateData.interval);
            this.costEstimates = response.data.service;
            this.currentStatus = STATUS_COST$1;
          } else if (costEstimateData.state.includes('FAILED') || costEstimateData.state.includes('ABORTED')) {
            this.stopInterval(costEstimateData.interval);
            this.costEstimates = response.data.service;
            this.currentStatus = STATUS_FAILED$2;
          } else if (costEstimateData.state.includes('FINISHED') && !costEstimateData.resourceState) {
            this.stopInterval(costEstimateData.interval);
            this.costEstimates = response.data.service;
            this.currentStatus = STATUS_FAILED$2;
          }
        }).catch(() => {
          this.cognitiveError = 'Could not get cost estimate';
        });
      }, 500);

      if (cost === 'placeholder') {
        axios.get(this.costEstimateUrl);
      }
    },

    stopInterval(interval) {
      clearInterval(interval);
    },

    abortJob() {
      if (!this.abortInterval) {
        this.abortInterval = setInterval(() => {
          this.stopInterval(this.abortInterval); // Abort the job

          axios.delete(`${this.abortJobUrl}${this.cognitiveJob.jobId}`);
        }, 500);
      }
    }

  }
};

const STATUS_INITIAL$3 = 0;
const STATUS_SAVING$1 = 1;
const STATUS_COST$2 = 2;
const STATUS_SUCCESS$3 = 3;
const STATUS_FAILED$3 = 4;
const STATUS_TRANSCODING = 5;
/**
 * @description Start a Transcode Job with a provided Item ID.
 * @since 0.9
 *
 * ***
 *
 * ###CSS-variables used:
 *
 * > **--default** background color of tags when 'transcodeAllTags' is set to true.<br/>
 * > **--contrast-color-1** text color of tags when 'transcodeAllTags is set to true.<br/>
 * > **--primary** background-color of "Start Transcode" button.<br/>
 * > **--danger** background-color of "Start over" and "Abort" buttons.<br/>
 * > **--success** background-color of "Start over" button.<br/>
 */

var VdtTranscode = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-vidinet-transcode"
    }, [_c('div', {
      staticClass: "vdt-vidinet-transcode__form"
    }, [!_vm.isFailed && !_vm.isSuccess ? _c('form', {
      attrs: {
        "enctype": "multipart/form-data",
        "novalidate": ""
      }
    }, [!_vm.transcodeAllTags ? _c('div', {
      staticClass: "vdt-vidinet-transcode__form__input"
    }, [_c('span', [_vm._v("To file format:")]), _vm._v(" "), _c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedTag,
        expression: "selectedTag"
      }],
      key: _vm.selectedTag,
      attrs: {
        "disabled": _vm.isSaving || _vm.isTranscoding
      },
      on: {
        "change": [function ($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });
          _vm.selectedTag = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
        }, _vm.getCostEstimate]
      }
    }, [_c('option', {
      attrs: {
        "disabled": "disabled",
        "value": ""
      }
    }, [_vm._v("Select format")]), _vm._v(" "), _vm._l(_vm.tags, function (tag) {
      return _c('option', {
        key: tag,
        domProps: {
          "value": tag
        }
      }, [_vm._v(" " + _vm._s(tag) + " ")]);
    })], 2)]) : _c('div', {
      staticClass: "vdt-vidinet-transcode__form__transcode-all"
    }, [_c('h3', [_vm._v("The asset will be transcoded to:")]), _vm._v(" "), _c('div', _vm._l(_vm.selectedTag, function (tag) {
      return _c('span', {
        key: tag
      }, [_vm._v(" " + _vm._s(tag) + " ")]);
    }))]), _vm._v(" "), _vm.storages ? _c('div', {
      staticClass: "vdt-vidinet-transcode__form__input"
    }, [_c('span', [_vm._v("Select a storage:")]), _vm._v(" "), _c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedStorage,
        expression: "selectedStorage"
      }],
      key: _vm.selectedStorage,
      attrs: {
        "disabled": _vm.isSaving || _vm.isTranscoding
      },
      on: {
        "change": function ($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });
          _vm.selectedStorage = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
        }
      }
    }, [_c('option', {
      domProps: {
        "value": _vm.undifined
      }
    }, [_vm._v("Default")]), _vm._v(" "), _vm._l(_vm.storages, function (storage) {
      return _c('option', {
        key: storage.data.id,
        domProps: {
          "value": storage.data.id
        }
      }, [_vm._v(" " + _vm._s(storage.data.name) + " ")]);
    })], 2)]) : _vm._e()]) : _vm._e(), _vm._v(" "), _vm.items.length && !_vm.itemId ? _c('div', {
      staticClass: "vdt-vidinet-transcode__item-list"
    }, _vm._l(_vm.items, function (item, index) {
      return _c('div', {
        key: index
      }, [_c('div', [_c('span', [_vm._v("Asset:")]), _vm._v(" "), _c('span', [_vm._v(_vm._s(_vm.getItemDetails(item)))])]), _vm._v(" "), _c('div', [_c('span', [_vm._v("Status:")]), _vm._v(" "), _c('span', [_vm._v(_vm._s(item.status || '-'))])]), _vm._v(" "), _c('div', [_c('span', [_vm._v("Details:")]), _vm._v(" "), _c('span', [_vm._v(_vm._s(item.description || '-'))])]), _vm._v(" "), _c('div', {
        staticClass: "prog-bar"
      }, [_c('ProgressBar', {
        attrs: {
          "animated": _vm.getStatus(item) === 'progress',
          "striped": _vm.getStatus(item) === 'progress',
          "status": _vm.getStatus(item),
          "progress": _vm.getProgress(item)
        }
      })], 1)]);
    })) : _vm._e(), _vm._v(" "), _vm.foundCost && _vm.selectedTag !== '' && !_vm.disableEstimate && !_vm.isSaving && !_vm.isTranscoding && !_vm.isSuccess && !_vm.isFailed ? _c('div', {
      staticClass: "vdt-vidinet-transcode__cost-card-container"
    }, [_vm.costEstimates ? _c('div', [_c('span', [_vm._v("Available transcoders")]), _vm._v(" "), _vm._l(_vm.costEstimates, function (estimate) {
      return _c('VidinetCostCard', {
        key: estimate.resource,
        attrs: {
          "estimate": estimate
        },
        on: {
          "resourceSelected": function ($event) {
            _vm.startTranscode($event);
          }
        }
      });
    })], 2) : _vm._e()]) : _vm._e(), _vm._v(" "), _vm.disableEstimate && !_vm.isSaving && !_vm.isTranscoding && !_vm.isSuccess && !_vm.isFailed ? _c('div', {
      staticClass: "vdt-vidinet-transcode__cost-card-container--no-estimate"
    }, [_c('button', {
      staticClass: "vdt-button vdt-button--primary",
      attrs: {
        "disabled": _vm.selectedTag === '' || !_vm.foundCost
      },
      on: {
        "click": function ($event) {
          _vm.startTranscode({
            resource: ''
          });
        }
      }
    }, [_vm._v(" Start Transcode ")])]) : _vm._e()]), _vm._v(" "), _vm.isSaving ? _c('div', {
      staticClass: "vdt-vidinet-transcode__container"
    }, [_vm._m(0)]) : _vm.isTranscoding ? _c('div', {
      staticClass: "vdt-vidinet-transcode__container"
    }, [_c('div', {
      staticClass: "vdt-vidinet-transcode__container__message"
    }, [_c('div', {
      staticClass: "spinner"
    }), _vm._v(" "), _c('span', [_vm._v(_vm._s(_vm.abortInterval ? 'Aborting' : 'Transcoding'))]), _vm._v(" "), _c('button', {
      staticClass: "vdt-button vdt-button--danger",
      attrs: {
        "disabled": _vm.abortInterval !== null
      },
      on: {
        "click": _vm.abortJob
      }
    }, [_vm._v("Abort")])])]) : _vm.isSuccess || _vm.isFailed ? _c('div', {
      staticClass: "vdt-vidinet-transcode__container"
    }, [_vm.isSuccess ? _c('div', {
      staticClass: "vdt-vidinet-transcode__container__message"
    }, [_c('span', [_vm._v("Transcoding " + _vm._s(_vm.filename ? ` of ${_vm.filename} ` : '') + " successful!")]), _vm._v(" "), _vm.itemId ? _c('div', [_c('span', [_vm._v(_vm._s(`Job ID: ${_vm.completedTranscodes[0].jobId}`))])]) : _c('div', _vm._l(_vm.completedTranscodes, function (item) {
      return _c('span', {
        key: item.jobId
      }, [_vm._v(" " + _vm._s(`Job ID: ${item.jobId}`) + " ")]);
    })), _vm._v(" "), _c('button', {
      staticClass: "vdt-button vdt-button--success",
      on: {
        "click": function ($event) {
          _vm.reset(false);
        }
      }
    }, [_vm._v(" Start over ")])]) : _vm.isFailed ? _c('div', {
      staticClass: "vdt-vidinet-transcode__container__message"
    }, [_c('span', [_vm._v("Transcoding " + _vm._s(_vm.filename ? ` of ${_vm.filename} ` : '') + " failed!")]), _vm._v(" "), _vm.itemId ? _c('div', [_c('span', [_vm._v(_vm._s(`Job ID: ${_vm.failedTranscodes[0].jobId}`))])]) : _c('div', _vm._l(_vm.failedTranscodes, function (item) {
      return _c('span', {
        key: item.jobId
      }, [_vm._v(" " + _vm._s(`Job ID: ${item.jobId}`) + " ")]);
    })), _vm._v(" "), _c('button', {
      staticClass: "vdt-button vdt-button--danger",
      on: {
        "click": function ($event) {
          _vm.reset(false);
        }
      }
    }, [_vm._v(" Start over ")])]) : _vm._e()]) : _vm._e()]);
  },
  staticRenderFns: [function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-vidinet-transcode__container__message"
    }, [_c('div', {
      staticClass: "spinner"
    }), _vm._v(" "), _c('span', [_vm._v("Getting cost estimate")])]);
  }],
  _scopeId: 'data-v-85efb00a',
  name: 'VdtTranscode',
  components: {
    VidinetCostCard,
    ProgressBar: VdtProgressBar
  },
  props: {
    /**
    * @description String of the exact backend url to abort a job.<br/>
    * **:abort-job-url="'/api/job/'"**<br/>
    * Result is a backend call to: **DELETE '/api/job/<str:job_id>'**
    *
    */
    abortJobUrl: {
      type: String,
      required: true
    },

    /**
    * @description String of the exact backend url to get a cost estimate.<br/>
    * **:cost-estimate-url="'/api/vidinet/transcode'"**<br/>
    * Result is a backend call to: **GET '/api/vidinet/transcode?itemId=...&tag=...'**<br/>
    * Will also poll backend: **GET '/api/vidinet/transcode?existingEstimate=...'**
    *
    */
    costEstimateUrl: {
      type: String,
      required: true
    },

    /**
     * @description Disable the cost estmiate.
     * Set to true if you are not using Vidinet.
     * If used with Vidinet-transcoder the cost will be hidden,
     * but the job will be charged as usual.
     */
    disableEstimate: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
    * @description Filename of item you wish to transcode.
     */
    filename: {
      type: String,
      required: false,
      default: null
    },

    /**
    * @description Path to uniqe item ID of the items you wish to transcode.
     */
    idpath: {
      type: String,
      required: false,
      default: null
    },

    /**
     * @description Array of items you wish to transcode.
     */
    items: {
      type: Array,
      required: false,
      default: () => []
      /**
       * @description ID of the item you wish to transcode.
       */

    },
    itemId: {
      type: String,
      required: false,
      default: ''
    },

    /**
    * @description String of the exact backend url to poll the job status.<br/>
    * **:job-status-url="'/api/job/'"**<br/>
    * Result is a backend call to: **GET '/api/job/<str:job_id>'**
    *
     */
    jobStatusUrl: {
      type: String,
      required: true
    },

    /**
     * @description Prop that can be used if component is used in a modal,
     * to react on modal state.
     */
    modalOpen: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * @description How often the jobs should be polled while uploading.
     * Changes to interval during upload are watched.
    */
    pollInterval: {
      type: Number,
      default: 2000,
      required: false
    },

    /**
    * @description String of the exact backend url to start the transcode.<br/>
    * **:start-transcode-url="'/api/vidinet/transcode'"**<br/>
    * Result is a backend call to: **POST '/api/vidinet/transcode'**
    *
    */
    startTranscodeUrl: {
      type: String,
      required: true
    },

    /**
     * @description Array with selectable file formats.
    */
    tags: {
      type: Array,
      required: true
    },

    /**
     * @description Array with selectable storages.
    */
    storages: {
      type: Array,
      required: false,
      default: undefined
    },

    /**
     * @description If true, the asset will be transcoded to all supplied tags.
     * The shape-tag dropdown will be disabled.
    */
    transcodeAllTags: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      stepDescription: '',
      transcodeError: null,
      currentStatus: 0,
      currentState: null,
      selectedTag: '',
      selectedStorage: undefined,
      costEstimates: null,
      abortInterval: null,
      transcodeFinished: false,
      pollJobs: [],
      completedTranscodes: [],
      failedTranscodes: [],
      cloneItems: []
    };
  },

  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL$3;
    },

    isSaving() {
      return this.currentStatus === STATUS_SAVING$1;
    },

    foundCost() {
      return this.currentStatus === STATUS_COST$2;
    },

    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS$3;
    },

    isFailed() {
      return this.currentStatus === STATUS_FAILED$3;
    },

    isTranscoding() {
      return this.currentStatus === STATUS_TRANSCODING;
    }

  },
  watch: {
    modalOpen(newVal) {
      if (newVal === false && !this.isTranscoding && !this.isSaving) {
        this.reset(true);
      }
    }

  },

  mounted() {
    this.copyMyItemsOncePlease();
    this.reset(false);
  },

  destroyed() {
    if (this.costEstimates) {
      this.stopInterval(this.costEstimates.interval);
    }

    if (this.pollJob) {
      this.pollJob.stopInterval();
    }
  },

  methods: {
    copyMyItemsOncePlease() {
      this.cloneItems = cloneDeep(this.items);
    },

    getItemDetails(item) {
      return get(item, this.idpath);
    },

    getStatus(item = {}) {
      if (item.status) {
        switch (item.status) {
          case 'FINISHED':
          case 'FINISHED_WARNING':
            return 'finished';

          case 'FAILED':
          case 'FAILED_TOTAL':
          case 'ABORTED':
          case 'ABORTED_PENDING':
            return 'failed';

          default:
            return 'progress';
        }
      }

      return 'idle';
    },

    getDetails(item) {
      if (item.description) {
        return item.description;
      }

      return 'none';
    },

    getProgress(item) {
      if (item.totalSteps && item.currentStep) {
        return item.currentStep / item.totalSteps * 100;
      }

      return 0;
    },

    reset(shutdown) {
      // Reset form to initial state
      this.currentStatus = STATUS_INITIAL$3;
      this.currentState = null;
      this.selectedTag = '';
      this.costEstimates = null;
      this.abortInterval = null;
      this.pollJob = null;
      this.completedTranscodes = [];
      this.failedTranscodes = [];
      this.resetItems();

      if (!shutdown && this.transcodeAllTags) {
        this.selectedTag = this.tags;
        this.getCostEstimate();
      }
    },

    resetItems() {
      const newCloneItems = cloneDeep(this.cloneItems);

      if (this.itemId && this.items.length) {
        this.items.splice(0, 1);
      } else if (this.items.length) {
        for (let i = 0; i < this.items.length; i += 1) {
          this.$set(this.items, i, newCloneItems[i]);
        }
      }
    },

    startTranscode(resource) {
      // Don't start another job if one is already in progress
      if (this.currentStatus === STATUS_TRANSCODING) return;
      this.currentStatus = STATUS_TRANSCODING;

      if (this.itemId) {
        const data = {
          itemId: this.itemId,
          tag: this.selectedTag,
          resourceId: resource.resource,
          storageId: this.selectedStorage
        };
        axios.post(this.startTranscodeUrl, data).then(({
          data: jobData = {}
        }) => {
          const {
            jobId = ''
          } = jobData;
          const dummyItem = {
            itemId: this.itemId,
            jobId,
            index: 0
          };
          this.items.push(dummyItem);
          this.startPollJob(dummyItem);
        }).catch(() => {
          this.reset(false);
        });
      } else if (this.items.length) {
        this.items.forEach((item, index) => {
          let newItem = item;
          const data = {
            itemId: get(item, this.idpath),
            tag: this.selectedTag,
            storageId: this.selectedStorage,
            resourceId: resource.resource
          };
          axios.post(this.startTranscodeUrl, data).then(({
            data: jobData = {}
          }) => {
            const {
              jobId = '',
              status = ''
            } = jobData;
            newItem = Object.assign(newItem, {
              jobId,
              status,
              index
            });
            this.startPollJob(newItem);
          });
        });
      }
    },

    startPollJob(item) {
      const {
        jobId
      } = item;
      const pollJob = new JobPollService({
        jobId,
        milliseconds: this.pollInterval,
        asset: item,
        jobStatusUrl: this.jobStatusUrl,
        progressCallback: this.jobPoll,
        successCallback: this.jobSuccess,
        failedCallback: this.jobFailed
      });
      pollJob.startInterval();
      this.pollJobs.push(pollJob);
    },

    jobPoll(job, item) {
      const {
        status,
        totalSteps,
        log: {
          task
        }
      } = job;
      const {
        index
      } = item;

      const description = last(task).description.replace('.', '');

      const newItem = Object.assign(item, {
        status,
        description,
        totalSteps,
        currentStep: task.length
      });
      this.$set(this.items, index, newItem);
    },

    jobSuccess(job, item) {
      const {
        status
      } = job;
      const {
        index,
        totalSteps
      } = item;
      const newItem = Object.assign(item, {
        status,
        transcoded: true,
        currentStep: totalSteps
      });
      this.$set(this.items, index, newItem);
      this.completedTranscodes.push(newItem);
      this.$emit('transcodeSuccess', {
        item: newItem,
        job
      });
      this.checkComplete();
    },

    jobFailed(job, item) {
      let {
        status
      } = job;
      const {
        data = []
      } = job;
      const {
        index
      } = item;
      let message;

      if (status === 'ABORTED_PENDING') {
        status = 'ABORTED';
      }

      const error = data.find(o => o.key === 'errorMessage');

      if (error) {
        message = error.value;
      }

      const newItem = Object.assign(item, {
        status,
        transcoded: false,
        message
      });
      this.$set(this.items, index, newItem);
      this.failedTranscodes.push(newItem);
      this.$emit('uploadFailed', {
        item: newItem,
        job
      });
      this.checkComplete();
    },

    checkComplete() {
      if (this.items.length) {
        const total = this.items.length;
        const completed = this.completedTranscodes.length;
        const failed = this.failedTranscodes.length;

        if (failed === total) {
          this.currentStatus = STATUS_FAILED$3;
        } else if (failed + completed === total) {
          this.currentStatus = STATUS_SUCCESS$3;
        }
      } else if (this.completedTranscodes.length) {
        this.currentStatus = STATUS_SUCCESS$3;
      } else if (this.failedTranscodes.length) {
        this.currentStatus = STATUS_FAILED$3;
      }
    },

    getCostEstimate() {
      if (!this.disableEstimate && this.selectedTag !== '') {
        const url = `${this.costEstimateUrl}?itemId=${this.itemId}&tag=${this.selectedTag}`;
        this.currentStatus = STATUS_SAVING$1;
        axios.get(url).then(cost => {
          this.estimate(cost, url);
        }).catch(() => {
          this.failedTranscodes.push({
            jobId: 'Cost estimate'
          });
          this.currentStatus = STATUS_FAILED$3;
          this.transcodeError = 'Could not get cost estimate';
        });
      } else if (this.disableEstimate && this.selectedTag !== '') {
        this.currentStatus = STATUS_COST$2;
      }
    },

    estimate(cost, url) {
      const costEstimateData = cost.data;
      costEstimateData.interval = setInterval(() => {
        axios.get(`${url}&existingEstimate=${costEstimateData.url}`).then(response => {
          costEstimateData.resourceState = find(response.data.service, {
            state: 'FINISHED'
          });
          costEstimateData.state = response.data.state;

          if (costEstimateData.state.includes('FINISHED') && costEstimateData.resourceState && costEstimateData.resourceState.state === 'FINISHED') {
            this.stopInterval(costEstimateData.interval);
            this.costEstimates = response.data.service;
            this.currentStatus = STATUS_COST$2;
          } else if (costEstimateData.state.includes('FAILED') || costEstimateData.state.includes('ABORTED')) {
            this.stopInterval(costEstimateData.interval);
            this.costEstimates = response.data.service;
            this.currentStatus = STATUS_FAILED$3;
          } else if (costEstimateData.state.includes('FINISHED') && !costEstimateData.resourceState) {
            this.stopInterval(costEstimateData.interval);
            this.costEstimates = response.data.service;
            this.currentStatus = STATUS_FAILED$3;
          }
        }).catch(() => {
          this.transcodeError = 'Could not get cost estimate';
        });
      }, 500);
    },

    stopInterval(interval) {
      clearInterval(interval);
    },

    abortJob() {
      const vm = this;

      if (!this.abortInterval) {
        this.abortInterval = setInterval(() => {
          this.stopInterval(this.abortInterval);

          if (this.items.length) {
            this.items.forEach(item => {
              const {
                jobId = ''
              } = item;
              axios.delete(`${vm.abortJobUrl}${jobId}`);
            });
          }
        }, 500);
      }
    }

  }
};

const STATUS_INITIAL$4 = 0;
const STATUS_SAVING$2 = 1; // const STATUS_UPLOADING = 2;

const STATUS_COMPLETED = 2;
/**
 * @description Upload prompt with drag and drop support that allows
 * for customization of shape tags
 * @since 0.9
 *
 * ***
 *
 * ###CSS-variables used:
 *
 * > **--danger** background color of label for 'failed' status.<br/>
 * > **--primary** hover text color for drop zone.
 * Also background-color for "Start Upload" and "Reset" button.<br/>
 * > **--success** background color of label for 'finished' status.<br/>
 * > **--contrast-color-1** color of borders, text color of status labels.<br/>
 * > **--contrast-color-2** hover background color for drop zone.<br/>
 * > **--contrast-color-9** general text color of compononent.<br/>
 */

var VdtWebupload = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-webupload"
    }, [_c('div', {
      staticClass: "vdt-webupload__selection"
    }, [_c('form', {
      attrs: {
        "enctype": "multipart/form-data",
        "novalidate": ""
      }
    }, [!_vm.selectedFiles.length ? _c('div', {
      staticClass: "vdt-webupload__dropbox",
      class: {
        'vdt-webupload__dropbox--hover': _vm.dragOver
      },
      on: {
        "dragenter": function ($event) {
          _vm.dragOver = true;
        },
        "dragleave": function ($event) {
          _vm.dragOver = false;
        },
        "drop": function ($event) {
          _vm.dragOver = false;
        }
      }
    }, [_c('input', {
      staticClass: "vdt-webupload__dropbox__input-file",
      attrs: {
        "id": "uploadFile",
        "type": "file",
        "multiple": "multiple",
        "accept": "*"
      },
      on: {
        "change": function ($event) {
          _vm.filesSelected($event.target.files);
        }
      }
    }), _vm._v(" "), _vm._m(0)]) : _vm._e(), _vm._v(" "), _vm.selectedFiles != 0 ? _c('div', {
      staticClass: "vdt-webupload__selected-files"
    }, _vm._l(_vm.selectedFiles, function (selected, index) {
      return _c('div', {
        key: selected.id,
        staticClass: "vdt-webupload__selected-files__input"
      }, [_c('div', {
        directives: [{
          name: "responsive",
          rawName: "v-responsive",
          value: _vm.breakpoints,
          expression: "breakpoints"
        }],
        staticClass: "vdt-webupload__selected-files__input-container"
      }, [_c('div', {
        staticClass: "input-container__thumb"
      }, [selected.preview === 'image' ? _c('img', {
        attrs: {
          "src": selected.thumbnail
        }
      }) : selected.preview === 'video' ? _c('video', {
        attrs: {
          "preload": "metadata"
        }
      }, [_c('source', {
        attrs: {
          "src": selected.thumbnail,
          "type": "video/mp4"
        }
      })]) : _c('div', {
        staticClass: "input-container__no-thumb"
      })]), _vm._v(" "), _c('div', {
        staticClass: "input-container__title"
      }, [_c('span', {
        staticClass: "label"
      }, [_vm._v("Filename:")]), _vm._v(" "), _c('span', {
        staticClass: "value"
      }, [_vm._v(_vm._s(selected.name))])]), _vm._v(" "), _c('div', {
        staticClass: "input-container__size"
      }, [_c('span', {
        staticClass: "label"
      }, [_vm._v("Size:")]), _vm._v(" "), _c('span', {
        staticClass: "value"
      }, [_vm._v(_vm._s(_vm.formatSize(selected.size)))])]), _vm._v(" "), _vm.tags && _vm.tags.length > 0 ? _c('div', {
        staticClass: "input-container__format"
      }, [_c('span', {
        staticClass: "label"
      }, [_vm._v("Format:")]), _vm._v(" "), _c('select', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: selected.tag,
          expression: "selected.tag"
        }],
        staticClass: "input-container__format-select",
        attrs: {
          "disabled": !_vm.isInitial,
          "name": selected.id
        },
        on: {
          "change": function ($event) {
            var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
              return o.selected;
            }).map(function (o) {
              var val = "_value" in o ? o._value : o.value;
              return val;
            });

            _vm.$set(selected, "tag", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
          }
        }
      }, _vm._l(_vm.tags, function (tag) {
        return _c('option', {
          key: tag,
          domProps: {
            "value": tag
          }
        }, [_vm._v(" " + _vm._s(tag) + " ")]);
      }))]) : _vm._e(), _vm._v(" "), _c('div', {
        staticClass: "input-container__metadata"
      }, [_c('VdtMetadata', {
        staticClass: "vdt-webupload__add-metadata",
        attrs: {
          "header": '',
          "asset": _vm.assetMetadataRows[index],
          "rows": _vm.metadataRowsWithKeys,
          "form": ""
        },
        on: {
          "input": function ($event) {
            _vm.updateMetadata($event, index);
          }
        }
      })], 1), _vm._v(" "), _c('div', {
        staticClass: "input-container__status"
      }, [_c('span', {
        staticClass: "label"
      }, [_vm._v("Status:")]), _vm._v(" "), _vm.isProgress(selected) ? _c('div', {
        staticClass: "spinner"
      }) : _vm._e(), _vm._v(" "), _c('span', {
        staticClass: "value",
        class: _vm.getStatus(selected)
      }, [_vm._v(" " + _vm._s(_vm.getStatus(selected) || 'IDLE') + " ")])]), _vm._v(" "), _vm.getStatus(selected) !== 'FINISHED' && _vm.getStatus(selected) !== 'ABORTED' ? _c('button', {
        staticClass: "single-remove",
        attrs: {
          "disabled": _vm.isFailed(selected),
          "type": "button"
        },
        on: {
          "click": function ($event) {
            _vm.singleAbort(selected, index);
          }
        }
      }, [_c('CloseCircleIcon', {
        attrs: {
          "title": ""
        }
      })], 1) : _vm._e()]), _vm._v(" "), _vm.isSaving || _vm.isCompleted ? _c('VdtProgressBar', {
        attrs: {
          "animated": _vm.isProgress(selected),
          "striped": _vm.isProgress(selected),
          "status": _vm.getVariant(selected),
          "progress": selected.uploadProgress
        }
      }) : _vm._e()], 1);
    })) : _vm._e(), _vm._v(" "), _vm.selectedFiles.length > 0 ? _c('div', {
      staticClass: "vdt-webupload__selected-files__add"
    }, [_c('input', {
      staticClass: "vdt-webupload__selected-files__add-more",
      attrs: {
        "id": "input-small",
        "type": "file",
        "multiple": "multiple",
        "accept": "*"
      },
      on: {
        "change": function ($event) {
          _vm.filesSelected($event.target.files);
        }
      }
    }), _vm._v(" "), _c('label', {
      attrs: {
        "for": "input-small"
      }
    }, [_vm._v("Add more file(s)")])]) : _vm._e()]), _vm._v(" "), _c('div', {
      staticClass: "vdt-webupload__form-submit"
    }, [_vm.selectedFiles.length > 0 && !_vm.isCompleted ? _c('button', {
      staticClass: "vdt-button vdt-button--primary",
      attrs: {
        "disabled": _vm.isSaving || !_vm.satisfiedMetadata
      },
      on: {
        "click": function ($event) {
          _vm.startUpload();
        }
      }
    }, [_vm._v(" Start upload ")]) : _vm.isCompleted ? _c('button', {
      staticClass: "vdt-button vdt-button--primary",
      on: {
        "click": function ($event) {
          _vm.reset();
        }
      }
    }, [_vm._v(" Reset ")]) : _vm._e()])])]);
  },
  staticRenderFns: [function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('label', {
      attrs: {
        "for": "uploadFile"
      }
    }, [_vm._v(" Drag your file(s) here to begin"), _c('br'), _vm._v("or click to browse... ")]);
  }],
  _scopeId: 'data-v-39c4337b',
  name: 'VdtWebupload',
  components: {
    CloseCircleIcon,
    CompletedIcon,
    FailedIcon,
    UploadIcon,
    VdtProgressBar,
    VdtMetadata
  },
  directives: {
    responsive: ResponsiveDirective
  },
  props: {
    /**
    * @description String of the exact backend url to abort a job.<br/>
    * **:abort-job-url="'/api/job/'"**<br/>
    * Result is a backend call to: **DELETE '/api/job/<str:job_id>'**
    */
    abortJobUrl: {
      type: String,
      required: true
    },

    /**
     * @description Pixel width breakpoint at which layout switches to adapt to smaller screens.
     */
    breakPointWidth: {
      type: Number,
      required: false,
      default: 500
    },

    /**
    * @description Default tags to be selected in tag-dropdown.<br/>
    * Default: {image: **'__jpeg'**, video: **'__mp4'**, fallback: **'original'**}
    */
    defaultTags: {
      type: Object,
      required: false,
      default: () => ({
        image: '__jpeg',
        video: '__mp4',
        fallback: 'original'
      })
    },

    /**
    * @description String of the exact backend url to poll the job status.<br/>
    * **:job-status-url="'/api/job/'"**<br/>
    * Result is a backend call to: **GET '/api/job/<str:job_id>'**
    */
    jobStatusUrl: {
      type: String,
      required: true
    },

    /**
     * @description How often the jobs should be polled while uploading.
     * Changes to interval during upload are watched.
     */
    pollInterval: {
      type: Number,
      default: 2000,
      required: false
    },

    /**
     * @description Whether or not to poll the jobs, good for preveting
     * polling when the component is hidden.
     */
    pollJobs: {
      type: Boolean,
      default: true,
      required: false
    },

    /**
    * @description String of the exact backend url to start the webupload.<br/>
    * **:start-upload-url="'/apinoauth/import/raw'"**<br/>
    * Result is a backend call to
    * : **POST '/apinoauth/import/raw?passkey=...&transferId=...&filename=...'**
    *
    * Redirect this call with ex. NGINX in order to do a cross-origin call to Vidispine.<br/>
    * You can find an example in **vidispine-content-viewer/packaging/nginx.conf**.
    */
    startUploadUrl: {
      type: String,
      required: true
    },

    /**
    * @description Array of Shape-tags avaliable to select.
    */
    tags: {
      type: Array,
      default: null,
      required: false
    },

    /**
    * @description String of the exact backend url to get a upload passkey.<br/>
    * **:upload-passkey-url="'/api/upload/passkey'"**<br/>
    * Result is a backend call to
    * : **GET '/api/upload/passkey?filename=...&filesize=...(if tag => &tag=...)'**<br/>
    * <br/>
    * **IMPORTANT:**<br/>
    * The structure returned by this apicall must be exactly like this:<br/>
    * { passkey: "the-passkey", transferId: "the-transfer-id" }
    */
    uploadPasskeyUrl: {
      type: String,
      required: true
    },

    /**
    * @description Array of rows of metadata, see the VdtMetadata prop called
    * "rows" to see how the rows should be defined.
    */
    metadataRows: {
      type: Array,
      required: false,
      default: () => []
    },

    /**
     * @description Maximum file size of each file in the webupload, in bytes, default is 2Gb.
    */
    maximumFileSize: {
      type: Number,
      default: 2000000000,
      required: false
    },

    /**
    * @description Parameters for uploading file.<br/>
    * Example: { path: "folder1/folder2", storageId: "VX-1" }
    */
    parameters: {
      type: Object,
      required: false,
      default: () => ({})
    },

    /**
    * @description Add the parameter path to the file name.<br/>
    * This is needed since some endpoints does not accept path as parameter.
    */
    addPathToFilename: {
      type: Boolean,
      default: false,
      required: false
    },

    /**
     * @description Chunk size.<br/>
     * Files are uploaded in chunks and this prop can be used to change the size of each chunk<br/>
     * (except the last one), in bytes.<br/>
     * Default value is 256MB.
     */
    chunkSize: {
      type: Number,
      default: 256 * 1024 * 1024,
      required: false
    },

    /**
     * @description Generic job status.<br/>
     * If this prop is set to true then internal job statuses like STARTED, WAITING or<br/>
     * TRANSCODING are replaced with the more generic status PROCESSING.
     * Default value is false.
     */
    genericJobStatus: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  data() {
    return {
      fileCount: 0,
      currentStatus: null,
      selectedFiles: [],
      dragOver: false,
      completedUploads: [],
      failedUploads: [],
      jobIntervals: [],
      cancelUpload: null,
      assetMetadataRows: []
    };
  },

  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL$4;
    },

    isSaving() {
      return this.currentStatus === STATUS_SAVING$2;
    },

    isCompleted() {
      return this.currentStatus === STATUS_COMPLETED;
    },

    breakpoints() {
      return {
        small: el => el.width < this.breakPointWidth
      };
    },

    metadataRowsWithKeys() {
      const metadataRowsWithKeys = [];

      for (let i = 0; i < this.metadataRows.length; i += 1) {
        const aRow = cloneDeep(this.metadataRows[i]);

        aRow.key = `asset.${aRow.edit.field}`;
        metadataRowsWithKeys.push(aRow);
      }

      return metadataRowsWithKeys;
    },

    satisfiedMetadata() {
      for (let i = 0; i < this.assetMetadataRows.length; i += 1) {
        if (!this.satisfiedMetadataRows(i)) {
          return false;
        }
      }

      return true;
    }

  },
  watch: {
    // Toggle jobIntervals on/off
    pollJobs(poll) {
      if (!poll) {
        this.jobIntervals.forEach(interval => {
          interval.stopInterval();
        });
        this.jobIntervals = [];
      } else {
        this.selectedFiles.forEach(file => {
          if (file.jobId && file.transcodeProgress !== 100) {
            this.startPollJob(file);
          }
        });
      }
    },

    // Restart the jobIntervals on pollInterval change
    pollInterval(newInterval, oldInterval) {
      if (newInterval !== oldInterval) {
        this.jobIntervals.forEach(interval => {
          interval.stopInterval();
        });
        this.jobIntervals = [];
        this.selectedFiles.forEach(file => {
          if (file.jobId && file.transcodeProgress !== 100) {
            this.startPollJob(file);
          }
        });
      }
    }

  },

  mounted() {
    this.reset();
  },

  destroyed() {
    this.jobIntervals.forEach(interval => {
      interval.stopInterval();
    });
  },

  methods: {
    /**
    * Resets variables and arrays
    */
    reset() {
      // Reset form to initial state
      this.currentStatus = STATUS_INITIAL$4;
      this.selectedFiles = [];
      this.completedUploads = [];
      this.failedUploads = [];
      this.dragOver = false;
      this.assetMetadataRows = [];
    },

    /**
    * Creates array of files
    * Constructs an array containing the files selected for upload
    * and sets the format to original per default
    */
    filesSelected(selectedFiles) {
      this.fileCount = selectedFiles.length;

      for (let i = 0; i < selectedFiles.length; i++) {
        const selected = selectedFiles[i];

        if (selected.type.indexOf('video') > -1 || selected.type.indexOf('mxf') > -1 || selected.name.indexOf('.mxf') > -1 || selected.name.indexOf('.mov') > -1) {
          selected.tag = this.defaultTags.video;
          selected.preview = 'video';
        } else if (selected.type.indexOf('image') > -1) {
          selected.tag = this.defaultTags.image;
          selected.preview = 'image';
        } else {
          selected.tag = this.defaultTags.fallback;
          selected.preview = 'empty';
        }

        this.currentStatus = STATUS_INITIAL$4;

        if (selected !== undefined) {
          selected.thumbnail = URL.createObjectURL(selected);
          this.assetMetadataRows.push({
            edit: false,
            asset: {
              notEmpty: true
            }
          });
          this.selectedFiles.push(selected);
        }
      }
    },

    /**
    * Initiates file upload
    * Initiates an upload function for each of the files selected
    * and sets the status of the upload to 'isSaving'
    */
    startUpload() {
      this.currentStatus = STATUS_SAVING$2;

      for (let i = 0; i < this.selectedFiles.length; i++) {
        const file = this.selectedFiles[i];

        if (file !== undefined && file.$error !== true && file.uploading === undefined && (file.size <= this.maximumFileSize || this.maximumFileSize === 0)) {
          this.startWebupload(file, i);
        } else if (file && file.size && file.size > this.maximumFileSize) {
          const maxSizeInMb = Math.round(this.maximumFileSize / 1000000);
          file.state = `ERROR: file size larger than: ${maxSizeInMb} MB.`;
        } else if (file && !file.state) {
          file.state = 'ERROR.';
        }
      }

      return null;
    },

    startWebupload(file, index) {
      this.getUploadKey(file, index).then(key => {
        this.uploadFile(key, file, index).then(newFile => {
          this.startPollJob(newFile);
        }).catch(() => {
          // Set error based on if the upload was aborted or failed due to e.g. network issues
          if (file.pendingAbort) {
            this.createError({
              file,
              state: 'ABORTED',
              errorMessage: ''
            });
          } else {
            this.createError({
              file,
              state: 'UPLOAD FAILED',
              errorMessage: ''
            });
          }
        });
      }).catch(() => {
        this.createError({
          file,
          state: 'UPLOAD FAILED',
          errorMessage: ''
        });
      });
    },

    getUploadKey(file, index) {
      // Clone
      const parameters = JSON.parse(JSON.stringify(this.parameters));

      if (!parameters.filename) {
        parameters.filename = file.name;
      } // Since upload files does not support path parameter, we add it to the file name.


      if (this.addPathToFilename && parameters.path && parameters.path.length > 0) {
        const lastChar = parameters.path.charAt(parameters.path.length - 1);
        const separetor = '/\\'.includes(lastChar) ? '' : '/';
        parameters.filename = `${parameters.path}${separetor}${parameters.filename}`;
        delete parameters.path;
      }

      if (this.metadataRows.length) {
        Object.keys(this.assetMetadataRows[index].asset).forEach(key => {
          parameters[key] = this.assetMetadataRows[index].asset[key];
        });
      } // This because false value should be accepted


      if (this.tags && this.tags.length > 0 && file.tag !== undefined && file.tag !== null) {
        parameters.tag = file.tag;
      }

      parameters.filesize = file.size;
      let queryParams = this.getParametersAsString(parameters);
      queryParams = queryParams === '' ? queryParams : `?${queryParams}`; // TODO: If inside collection, then use this!
      // if (this.collection != null) {
      //   url += '&collectionId=' + this.collection.id;
      // }

      if (!file.size) {
        this.createError({
          file,
          state: 'FILE_NOT_FOUND',
          errorMessage: 'File could not be found on local system, has it been moved?'
        });
        return null;
      }

      const url = `${this.uploadPasskeyUrl}${queryParams}`;
      return axios.get(url, {
        timeout: 10000
      }).then(noauthKeys => noauthKeys.data).catch(error => {
        const errorObj = {
          message: 'Failed to fetch token',
          error,
          file
        };
        throw errorObj;
      });
    },

    uploadFile(key, file, index) {
      // Setup file for chunk upload
      const newFile = file;
      newFile.pendingAbort = false;
      newFile.bytesUploaded = 0;
      newFile.numberOfErrors = 0;
      return this.uploadFileChunk(key, newFile, index);
    },

    uploadFileChunk(key, file, index) {
      const {
        CancelToken
      } = axios;
      const {
        passkey,
        transferId
      } = key;
      const newFile = file;
      newFile.uploadProgress = 0;
      newFile.transcodeProgress = 0;
      newFile.uploading = true;
      newFile.index = index;
      newFile.state = 'UPLOADING'; // Calculate and setup chunk to be uploaded

      const start = newFile.bytesUploaded;
      const end = Math.min(start + this.chunkSize, newFile.size);
      const currentChunkSize = end - start; // can be less than this.chunkSize if last chunk

      const blob = newFile.slice(start, end);
      const config = {
        timeout: 10 * 60 * 1000,
        headers: {
          size: newFile.size,
          index: start
        },
        onUploadProgress: progressEvent => {
          if (newFile.pendingAbort) {
            newFile.cancelUpload();
            return;
          }

          if (newFile.state !== 'ABORTED') {
            const currentChunkProgress = progressEvent.loaded / progressEvent.total;
            const totalBytesSent = start + currentChunkSize * currentChunkProgress;
            newFile.uploadProgress = Math.round(totalBytesSent / newFile.size * 100);
            this.$set(this.selectedFiles, newFile.index, newFile);

            if (totalBytesSent >= file.size) {
              newFile.state = 'UPLOADED';
            }
          }
        },
        cancelToken: new CancelToken(c => {
          // An executor function receives a cancel function as a parameter
          newFile.cancelUpload = c;
        })
      };
      return axios.post(`${this.startUploadUrl}` + `?passkey=${passkey}&transferId=${transferId}` + `&filename=${newFile.name}`, blob, config).then(upload => {
        newFile.bytesUploaded += currentChunkSize;

        if (newFile.bytesUploaded >= newFile.size) {
          newFile.jobId = upload.data.jobId;
          newFile.state = upload.data.status;
          return newFile;
        } // Send next chunk


        return this.uploadFileChunk(key, newFile, index);
      }).catch(error => {
        // If the upload was not aborted by the user, and we haven't already retried three times
        // then retry the upload
        if (!newFile.pendingAbort && newFile.numberOfErrors < 3) {
          return new Promise(_ => setTimeout(_, 5000)).then(() => {
            newFile.numberOfErrors += 1;
            return this.uploadFileChunk(key, newFile, index);
          });
        } // Otherwise just re-throw the exception


        throw error;
      });
    },

    getParametersAsString(parameters) {
      if (!parameters) {
        return '';
      }

      const parametersList = [];
      Object.keys(parameters).forEach(key => {
        const value = parameters[key];
        parametersList.push(`${key}=${value}`);
      });
      return parametersList.join('&');
    },

    startPollJob(newFile) {
      if (this.pollJobs) {
        const pollJob = new JobPollService({
          jobId: newFile.jobId,
          milliseconds: this.pollInterval,
          asset: newFile,
          jobStatusUrl: this.jobStatusUrl,
          progressCallback: this.jobPoll,
          successCallback: this.jobSuccess,
          failedCallback: this.jobFailed
        });
        pollJob.startInterval();
        this.jobIntervals.push(pollJob);
      }
    },

    jobPoll(job, file) {
      const newFile = file;

      if (this.genericJobStatus && job.status !== 'FINISHED') {
        newFile.state = 'PROCESSING';
      } else if (job.status === 'VIDINET_JOB') {
        newFile.state = 'TRANSCODING';
      } else {
        newFile.state = job.status;
      }

      this.$set(this.selectedFiles, newFile.index, newFile);
    },

    singleAbort(selectedFile, index) {
      const file = selectedFile;

      if (this.isInitial) {
        this.selectedFiles.splice(index, 1);
        this.assetMetadataRows.splice(index, 1);
      } else if (file.uploading === undefined && this.isSaving) {
        file.pendingAbort = true;
        this.createError({
          file,
          state: 'ABORTED',
          errorMessage: ''
        });
      } else if (file.uploading && file.uploadProgress < 100) {
        file.pendingAbort = true; // createError is called from within the cancelUpload callback

        file.cancelUpload();
      } else if (file.uploading && !this.isFinished(file) && !this.isAborted(file)) {
        if (!file.abortInterval) {
          file.abortInterval = setInterval(() => {
            if (file.jobId) {
              this.stopInterval(file.abortInterval); // Abort the job

              axios.delete(`${this.abortJobUrl}${file.jobId}`);
            }
          }, 500);
        }
      }
    },

    jobSuccess(job, file) {
      const newFile = file;
      newFile.transcodeProgress = 100; // Check that the file hasn't already been added

      if (this.completedUploads.filter(completedFile => completedFile.jobId === file.jobId).length === 0) {
        this.completedUploads.push(newFile);
        /**
        * @event uploadSuccess
        * @description Emits everytime a file has succefully been uploaded
        * @type {Object} the file that was uploaded
        */

        this.$emit('uploadSuccess', newFile);
      }

      this.$set(this.selectedFiles, newFile.index, newFile);
      this.setCompletedStatus();
    },

    jobFailed(job, file) {
      if (this.failedUploads.filter(failedFile => failedFile.jobId === file.jobId).length === 0) {
        const failedFile = file;
        failedFile.uploadProgress = 100;
        failedFile.transcodeProgress = 100;
        let message = '';
        const errorMessage = job.data.find(o => o.key === 'errorMessage');

        if (errorMessage && errorMessage.length) {
          message = ` - ${errorMessage.value}`;
        }

        if (failedFile.state === 'ABORTED_PENDING') {
          failedFile.state = 'ABORTED';
        }

        failedFile.message = message;
        this.$nextTick(() => {
          this.$set(this.selectedFiles, failedFile.index, failedFile);
        });
        this.failedUploads.push(failedFile);
        this.setCompletedStatus();
        /**
        * @event uploadFailed
        * @description Emits everytime an upload has failed
        * @type {Object} the file that failed to be uploaded
        */

        this.$emit('uploadFailed', failedFile);
      }
    },

    getStatus(file) {
      return file.state;
    },

    getVariant(file) {
      if (file.state) {
        if (this.isFinished(file)) {
          return 'finished';
        } else if (this.isFailed(file) || this.isAborted(file)) {
          return 'failed';
        }
      }

      return 'progress';
    },

    isFinished(file) {
      return file.state && file.state.includes('FINISHED');
    },

    isFailed(file) {
      return file.state && (file.state.includes('ERROR') || file.state.includes('FAILED') || file.state === 'FILE_NOT_FOUND');
    },

    isAborted(file) {
      return file.state && file.state.includes('ABORTED');
    },

    isProgress(file) {
      return this.getStatus(file) !== undefined && !this.isFinished(file) && !this.isFailed(file) && !this.isAborted(file);
    },

    stopInterval(interval) {
      clearInterval(interval);
    },

    createError({
      file,
      state,
      errorMessage
    }) {
      const errorFile = file;
      errorFile.state = state;

      if (!errorFile.jobId) {
        errorFile.jobId = errorFile.index + errorFile.name;
      }

      this.jobFailed({
        jobId: errorFile.jobId,
        data: [{
          key: 'errorMessage',
          value: errorMessage
        }]
      }, errorFile);
    },

    formatSize(size) {
      return Size.formatSize(size);
    },

    // Tracks status of all active fileuploads and returns
    // a status of success when all files are uploaded and transcoded
    setCompletedStatus() {
      if (this.selectedFiles.length > 0) {
        const total = this.selectedFiles.length;
        const completed = this.completedUploads.length;
        const failed = this.failedUploads.length;

        if (completed + failed === total) {
          this.currentStatus = STATUS_COMPLETED;
        }
      }
    },

    calculateProgress(file) {
      return Math.floor((file.uploadProgress + file.transcodeProgress) / 2);
    },

    updateMetadata(field, index) {
      this.assetMetadataRows[index].asset[field.field.field] = field.value;
      this.assetMetadataRows[index].asset = cloneDeep(this.assetMetadataRows[index].asset);
    },

    satisfiedMetadataRows(index) {
      const editRow = this.assetMetadataRows[index].asset;

      for (let i = 0; i < this.metadataRowsWithKeys.length; i += 1) {
        if (this.metadataRowsWithKeys[i].edit && this.metadataRowsWithKeys[i].edit.mandatory && (editRow[this.metadataRowsWithKeys[i].edit.field] === '' || editRow[this.metadataRowsWithKeys[i].edit.field] === undefined || !RegExp(this.metadataRowsWithKeys[i].edit.mandatory).exec(editRow[this.metadataRowsWithKeys[i].edit.field]))) {
          return false;
        }
      }

      return true;
    }

  }
};

/**
 * @description Use this component to display selected assets and your custom bulk options.
 *
 * ***
 *
 * ###CSS-variables used:
 *
 * > **--contrast-color-1** text color of component.<br/>
 * > **--primary** background color of component.<br/>
 * @since 1.0
 */

var VdtBulkActions = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-bulk-actions"
    }, [_c('div', [_c('CloseIcon', {
      attrs: {
        "title": ""
      },
      nativeOn: {
        "click": function ($event) {
          _vm.$emit('resetSelectedAssets');
        }
      }
    }), _vm._v(" "), _c('span', [_vm._v(_vm._s(_vm.selectedAssets.length == 1 ? `${_vm.selectedAssets.length} ${_vm.singularName} selected` : `${_vm.selectedAssets.length} ${_vm.pluralName} selected`) + " ")])], 1), _vm._v(" "), _vm._t("buttons")], 2);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-7d4c5d32',
  name: 'VdtBulkActions',
  components: {
    CloseIcon: CloseCircleIcon
  },
  props: {
    /**
     * @description Array of selected assets to perform actions with.
     */
    selectedAssets: {
      type: Array,
      required: true
    },

    /**
     * @description String used in UI when a single asset is selected.
     */
    singularName: {
      type: String,
      required: false,
      default: 'asset'
    },

    /**
     * @description String used in UI when multiple assets are selected.
     */
    pluralName: {
      type: String,
      required: false,
      default: 'assets'
    }
  }
};

const EventBus = new Vue();

/* eslint-disable no-param-reassign */
/**
* event changedField todo!
* type {Object}
*/

/**
 * @description Raw Metadata component, can also be used to display any object in a tree view
 */

var RawMetadataChild = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('li', {
      staticClass: "vdt-raw-metadata__wrapper"
    }, [_vm.isFolder ? _c('span', {
      staticClass: "vdt-raw-metadata__folder",
      on: {
        "click": _vm.toggle
      }
    }, [_vm.open ? _c('ChevronDown', {
      attrs: {
        "title": ""
      }
    }) : _c('ChevronRight', {
      attrs: {
        "title": ""
      }
    }), _vm._v(" "), _c('span', [_vm._v(_vm._s(_vm.objectKey))])], 1) : _c('span', {
      staticClass: "vdt-raw-metadata__item"
    }, [_c('span', {
      staticClass: "key"
    }, [_vm._v(_vm._s(_vm.objectKey + ": "))]), _vm._v(" "), _vm.editing ? _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.text,
        expression: "text"
      }],
      staticClass: "vdt-raw-metadata__input",
      attrs: {
        "placeholder": _vm.item
      },
      domProps: {
        "value": _vm.text
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) {
            return;
          }

          _vm.text = $event.target.value;
        }
      }
    }) : _vm._e(), _vm._v(" "), _vm.editing ? _c('span', {
      staticClass: "vdt-raw-metadata__input-controls"
    }, [_c('Verify', {
      staticClass: "verify",
      attrs: {
        "title": "Save"
      },
      nativeOn: {
        "click": function ($event) {
          return _vm.sendChangedData($event);
        }
      }
    }), _vm._v(" "), _c('Dismiss', {
      staticClass: "dismiss",
      attrs: {
        "title": ""
      },
      nativeOn: {
        "click": function ($event) {
          return _vm.dissmissChanges($event);
        }
      }
    })], 1) : _c('span', {
      staticClass: "value",
      class: {
        editable: _vm.modifiable
      },
      on: {
        "click": _vm.toggleEditing
      }
    }, [_vm._v(_vm._s(_vm.item) + " "), !_vm.editing && _vm.modifiable ? _c('Pencil', {
      staticClass: "vdt-raw-metadata__edit",
      attrs: {
        "title": "Edit"
      }
    }) : _vm._e()], 1)]), _vm._v(" "), _vm.isFolder && _vm.currentContext ? _c('ul', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.open,
        expression: "open"
      }]
    }, _vm._l(_vm.item, function (subitem, index) {
      return _c('RawMetadataChild', {
        key: index,
        attrs: {
          "item": subitem,
          "max-depth": _vm.maxDepth - 1,
          "modifiable": _vm.modifiable,
          "object-key": index,
          "context": _vm.currentContext,
          "expand-all-children": _vm.expandAllChildren
        }
      });
    })) : _vm._e()]);
  },
  staticRenderFns: [],
  name: 'RawMetadataChild',
  components: {
    ChevronDown,
    ChevronRight,
    Pencil,
    Verify: Check,
    Dismiss: close
  },
  props: {
    /**
     * @description A raw JSON item from Vidispine.
     */
    item: {
      type: [Object, String, Array, Number, Boolean],
      required: true
    },
    maxDepth: {
      type: Number,
      required: false,
      default: 1
    },
    modifiable: {
      type: Boolean,
      required: false,
      default: false
    },
    objectKey: {
      type: [String, Number],
      required: true
    },
    context: {
      type: String,
      required: true
    },
    expandAllChildren: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      open: false,
      text: '',
      editing: false,
      currentContext: null
    };
  },

  computed: {
    isFolder() {
      return typeof this.item === 'object';
    }

  },
  watch: {
    expandAllChildren() {
      this.open = this.expandAllChildren;
    }

  },

  mounted() {
    if (this.maxDepth > 0 || this.expandAllChildren) {
      this.open = true;
    }

    this.currentContext = `${this.context}.${this.objectKey}`;

    if (this.modifiable) {
      this.text = cloneDeep(this.item);
    }
  },

  methods: {
    toggle() {
      if (this.isFolder) {
        this.open = !this.open;
      }
    },

    toggleEditing() {
      if (this.modifiable) {
        this.editing = !this.editing;
      }
    },

    sendChangedData() {
      // emit the context to the root parent, RawMetadata
      EventBus.$emit('rawmetadata.changeData', {
        context: this.currentContext,
        value: this.text
      });
      this.toggleEditing();
    },

    dissmissChanges() {
      this.text = cloneDeep(this.item);
      this.toggleEditing();
    }

  }
};

/* eslint-disable no-param-reassign */
/**
 * @description Raw Metadata component, can also be used to display any object in a tree view
 * @since 1.0
 *
 * ***
 *
 * ###CSS-variables used:
 *
 * > **--code-color-1** color of metadata keys.<br/>
 * > **--code-color-2** color of metadata values.<br/>
 * > **--danger** color of cross icon (dismiss changes) on hover.<br/>
 * > **--default** color of object names and chevron, color of edit icon (pencil).<br/>
 * > **--success** color of check icon (save changes) on hover.<br/>
 */

var VdtRawMetadata = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('ul', {
      staticClass: "vdt-raw-metadata__wrapper"
    }, [_c('li', [_vm.expandAll ? _c('span', {
      on: {
        "click": function ($event) {
          _vm.expandAllChildren = !_vm.expandAllChildren;
        }
      }
    }, [!_vm.expandAllChildren ? _c('ExpandAll', {
      staticClass: "vdt-raw-metadata__expandall",
      attrs: {
        "title": "Expand All"
      }
    }) : _c('CollapseAll', {
      staticClass: "vdt-raw-metadata__expandall",
      attrs: {
        "title": "Collapse All"
      }
    })], 1) : _vm._e(), _vm._v(" "), _vm.isFolder ? _c('span', {
      staticClass: "vdt-raw-metadata__folder",
      on: {
        "click": _vm.toggle
      }
    }, [_vm.open ? _c('ChevronDown', {
      attrs: {
        "title": ""
      }
    }) : _c('ChevronRight', {
      attrs: {
        "title": ""
      }
    }), _vm._v(" "), _c('span', [_vm._v(_vm._s(_vm.rootTitle))])], 1) : _c('span', {
      staticClass: "vdt-raw-metadata__item"
    }, [_c('span', {
      staticClass: "key"
    }, [_vm._v(_vm._s(_vm.objectKey + ": "))]), _vm._v(" "), _vm.editing ? _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.text,
        expression: "text"
      }],
      staticClass: "vdt-raw-metadata__input",
      attrs: {
        "placeholder": _vm.item
      },
      domProps: {
        "value": _vm.text
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) {
            return;
          }

          _vm.text = $event.target.value;
        }
      }
    }) : _vm._e(), _vm._v(" "), _vm.editing ? _c('span', {
      staticClass: "vdt-raw-metadata__input-controls"
    }, [_c('Verify', {
      staticClass: "verify",
      attrs: {
        "title": "Save"
      },
      nativeOn: {
        "click": function ($event) {
          _vm.sendChangedData(_vm.context, _vm.text);
        }
      }
    }), _vm._v(" "), _c('Dismiss', {
      staticClass: "dismiss",
      attrs: {
        "title": ""
      },
      nativeOn: {
        "click": function ($event) {
          return _vm.dissmissChanges($event);
        }
      }
    })], 1) : _c('span', {
      staticClass: "value",
      class: {
        editable: _vm.modifiable
      },
      on: {
        "click": _vm.toggleEditing
      }
    }, [_vm._v(_vm._s(_vm.item) + " "), !_vm.editing && _vm.modifiable ? _c('Pencil', {
      staticClass: "vdt-raw-metadata__edit",
      attrs: {
        "title": "Edit"
      }
    }) : _vm._e()], 1)]), _vm._v(" "), _vm.isFolder && _vm.currentContext ? _c('ul', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.open,
        expression: "open"
      }]
    }, _vm._l(_vm.item, function (subitem, index) {
      return _c('RawMetadataChild', {
        key: index,
        attrs: {
          "item": subitem,
          "max-depth": _vm.maxDepth - 1,
          "modifiable": _vm.modifiable,
          "object-key": index,
          "context": _vm.currentContext,
          "expand-all-children": _vm.expandAllChildren
        }
      });
    })) : _vm._e()])]);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-f3815982',
  name: 'VdtRawMetadata',
  components: {
    RawMetadataChild,
    ChevronDown,
    ChevronRight,
    ExpandAll,
    CollapseAll
  },
  props: {
    /**
     * @description Any javascript object, for example a raw item from Vidispine.
     */
    item: {
      type: [Object, String, Array, Number, Boolean],
      required: true
    },

    /**
     * @description Description of what the object contains.
     */
    rootTitle: {
      type: String,
      required: true
    },

    /**
     * @description How deep the component will open the inserted object.
     */
    maxDepth: {
      type: Number,
      required: false,
      default: 1
    },

    /**
     * @description The data is modifiable and an event will return when 'saved'.
     */
    modifiable: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * @description With true, a expand all button will appear at the top.
     */
    expandAll: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      open: false,
      text: '',
      editing: false,
      currentContext: null,
      objectClone: null,
      expandAllChildren: false
    };
  },

  computed: {
    isFolder() {
      return typeof this.item === 'object';
    }

  },
  watch: {
    expandAllChildren() {
      this.open = this.expandAllChildren;
    }

  },

  mounted() {
    if (this.maxDepth > 0) {
      this.open = true;
    }

    this.currentContext = this.rootTitle;

    if (this.modifiable) {
      this.text = cloneDeep(this.item);
    }

    EventBus.$on('rawmetadata.changeData', payload => {
      const {
        context,
        value
      } = payload;
      this.sendChangedData(context, value);
    });
  },

  methods: {
    toggle() {
      if (this.isFolder) {
        this.open = !this.open;
      }
    },

    sendChangedData(context, value) {
      const contextNoRoot = context.substring(context.indexOf('.') + 1, context.length);
      this.objectClone = cloneDeep(this.item);
      this.updateValue(contextNoRoot, this.objectClone, value);
    },

    updateValue(context, object, value) {
      const dotIndex = context.indexOf('.');

      if (dotIndex === -1) {
        if (typeof object[context] === 'number' && typeof value === 'number') {
          object[context] = parseFloat(value);
        } else {
          object[context] = value;
        }
        /**
        * The resulting object when changing a value
        *
        * @event changedRawMetadata
        * @type {Object}
        */


        this.$emit('changedRawMetadata', this.objectClone);
        this.editing = false;
      } else {
        const contextMinusRoot = context.substring(dotIndex + 1, context.length);
        const childObject = object[context.substring(0, dotIndex)];
        this.updateValue(contextMinusRoot, childObject, value);
      }
    },

    toggleEditing() {
      if (this.modifiable) {
        this.editing = !this.editing;
      }
    },

    dissmissChanges() {
      this.text = cloneDeep(this.item);
      this.toggleEditing();
    }

  }
};

/**
 * @description Create a metadata-field, emits the info you need
 * @since 1.1
 */

var VdtFieldCreator = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-metadata-manager__field-wrapper"
    }, [_c('form', {
      on: {
        "submit": function ($event) {
          $event.preventDefault();
        }
      }
    }, [_c('h3', [_vm._v("Create a field")]), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__field"
    }, [_c('label', [_vm._v("Name of the field in Vidispine?")]), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__field-input",
      class: {
        'filled': _vm.fieldName !== ''
      }
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.fieldName,
        expression: "fieldName"
      }],
      attrs: {
        "disabled": _vm.field && _vm.field.name,
        "placeholder": "snake_case VS name.."
      },
      domProps: {
        "value": _vm.fieldName
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) {
            return;
          }

          _vm.fieldName = $event.target.value;
        }
      }
    })])]), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__field"
    }, [_c('label', [_vm._v("Name of the field in frontend?")]), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__field-input",
      class: {
        'filled': _vm.fieldKey !== ''
      }
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.fieldKey,
        expression: "fieldKey"
      }],
      attrs: {
        "placeholder": "camelCase FE key.."
      },
      domProps: {
        "value": _vm.fieldKey
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) {
            return;
          }

          _vm.fieldKey = $event.target.value;
        }
      }
    })])]), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__field"
    }, [_c('label', [_vm._v("Title of the field in the UI?")]), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__field-input",
      class: {
        'filled': _vm.fieldTitle !== ''
      }
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.fieldTitle,
        expression: "fieldTitle"
      }],
      attrs: {
        "placeholder": "UI Title.."
      },
      domProps: {
        "value": _vm.fieldTitle
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) {
            return;
          }

          _vm.fieldTitle = $event.target.value;
        }
      }
    })])]), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__field"
    }, [_c('label', [_vm._v("What type of field?")]), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__field-dropdown",
      class: {
        'filled': _vm.selectedType !== ''
      }
    }, [_c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedType,
        expression: "selectedType"
      }],
      on: {
        "change": function ($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });
          _vm.selectedType = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
        }
      }
    }, [_c('option', {
      attrs: {
        "value": "",
        "disabled": "disabled"
      }
    }, [_vm._v("Choose a type")]), _vm._v(" "), _vm._l(_vm.fieldTypes, function (fieldType) {
      return _c('option', {
        key: fieldType,
        attrs: {
          "disabled": _vm.field && _vm.field.name
        },
        domProps: {
          "value": fieldType
        }
      }, [_vm._v(" " + _vm._s(fieldType) + " ")]);
    })], 2)])]), _vm._v(" "), _vm.selectedType === 'dropdown' ? _c('div', {
      staticClass: "vdt-metadata-manager__field"
    }, [_c('h3', [_vm._v("Dropdown options")]), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__field-additional"
    }, [_c('label', [_vm._v("Add dropdown values")]), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__field-input"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.dropdownValue,
        expression: "dropdownValue"
      }],
      attrs: {
        "placeholder": "Value.."
      },
      domProps: {
        "value": _vm.dropdownValue
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) {
            return;
          }

          _vm.dropdownValue = $event.target.value;
        }
      }
    })]), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__field-additional__button"
    }, [_c('plus', {
      attrs: {
        "title": "Add value"
      },
      nativeOn: {
        "click": function ($event) {
          return _vm.addDropdown($event);
        }
      }
    }), _vm._v(" "), _c('minus', {
      attrs: {
        "title": "Remove last value"
      },
      nativeOn: {
        "click": function ($event) {
          _vm.dropdownArray.pop();
        }
      }
    })], 1), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__field-dropdown"
    }, [_c('select', [_c('option', {
      attrs: {
        "value": "",
        "disabled": "disabled"
      }
    }, [_vm._v("dropdown result:")]), _vm._v(" "), _vm._l(_vm.dropdownArray, function (value) {
      return _c('option', {
        key: value,
        domProps: {
          "value": value
        }
      }, [_vm._v(" " + _vm._s(value) + " ")]);
    })], 2)])])]) : _vm._e(), _vm._v(" "), _vm.selectedType === 'checkbox' ? _c('div', {
      staticClass: "vdt-metadata-manager__field"
    }, [_c('label', [_vm._v("Checkbox default?")]), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__field-dropdown",
      class: {
        'filled': _vm.checkboxDefault !== ''
      }
    }, [_c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.checkboxDefault,
        expression: "checkboxDefault"
      }],
      on: {
        "change": function ($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });
          _vm.checkboxDefault = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
        }
      }
    }, [_c('option', {
      attrs: {
        "value": "",
        "disabled": "disabled"
      }
    }, [_vm._v("Choose one")]), _vm._v(" "), _vm._l(_vm.checkboxArray, function (value) {
      return _c('option', {
        key: value,
        domProps: {
          "value": value
        }
      }, [_vm._v(" " + _vm._s(value) + " ")]);
    })], 2)])]) : _vm._e(), _vm._v(" "), _vm.selectedType !== 'checkbox' && _vm.selectedType !== 'counter' && _vm.selectedType !== 'link' ? _c('div', {
      staticClass: "vdt-metadata-manager__field"
    }, [_c('label', [_vm._v("Insert a RegExp pattern if you want the field to be mandatory")]), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__field-input",
      class: {
        'filled': !_vm.mandatoryError
      }
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.fieldMandatory,
        expression: "fieldMandatory"
      }],
      attrs: {
        "placeholder": "/^[a-öA-Ö0-9 ]{1,10}$/"
      },
      domProps: {
        "value": _vm.fieldMandatory
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) {
            return;
          }

          _vm.fieldMandatory = $event.target.value;
        }
      }
    })]), _vm._v(" "), _c('label', [_vm._v("Insert a tooltip for the RegExp pattern, so that a user can identify problems with their input")]), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__field-input"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.fieldTooltip,
        expression: "fieldTooltip"
      }],
      attrs: {
        "placeholder": "Insert 1-10 number of characters or integers"
      },
      domProps: {
        "value": _vm.fieldTooltip
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) {
            return;
          }

          _vm.fieldTooltip = $event.target.value;
        }
      }
    })])]) : _vm._e(), _vm._v(" "), _c('button', {
      staticClass: "vdt-button vdt-button--default",
      attrs: {
        "disabled": _vm.disabledField
      },
      on: {
        "click": _vm.addField
      }
    }, [_vm._v(" " + _vm._s(_vm.field && _vm.field.name ? 'Change Field' : 'Add Field') + " ")])])]);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-3fe2e1e6',
  name: 'VdtFieldCreator',
  components: {
    plus,
    minus
  },
  props: {
    /**
     * @description Set it to true if you only want fewer options
     */
    simple: {
      type: Boolean,
      required: false,
      default: false
    },
    field: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  data() {
    return {
      fieldName: '',
      fieldKey: '',
      fieldTitle: '',
      fieldMandatory: '',
      mandatoryError: false,
      fieldTooltip: '',
      selectedType: '',
      fieldTypes: ['string', 'string-exact', 'date', 'checkbox', 'dropdown', 'link', 'time', 'number', 'counter'],
      dropdownArray: [],
      checkboxArray: ['true', 'false'],
      dropdownValue: '',
      checkboxDefault: ''
    };
  },

  computed: {
    disabledField() {
      return this.fieldName === '' || this.fieldKey === '' || this.fieldTitle === '' || this.selectedType === '' || this.checkboxDefault === '' && this.selectedType === 'checkbox' || this.dropdownArray.length === 0 && this.selectedType === 'dropdown';
    }

  },
  watch: {
    field(newField, oldField) {
      if (!newField && oldField || newField && !oldField || newField.name !== oldField.name) {
        this.setField();
      }
    }

  },

  mounted() {
    this.setField();
  },

  methods: {
    addField() {
      if (this.fieldMandatory) {
        try {
          const match = this.fieldMandatory.match(new RegExp('^/(.*?)/([gimy]*)$'));
          RegExp(match[1], match[2]);
          this.mandatoryError = false;
        } catch (err) {
          /* eslint-disable */
          console.log(err);
          /* eslint-enable */

          this.mandatoryError = true;
          return;
        }
      }

      let emitStr = 'createField';

      if (this.field && this.field.name) {
        emitStr = 'changeField';
      }

      if (this.selectedType === 'dropdown') {
        this.$emit(emitStr, {
          fieldName: this.fieldName,
          fieldKey: this.fieldKey,
          fieldTitle: this.fieldTitle,
          type: this.selectedType,
          options: this.dropdownArray,
          mandatory: this.fieldMandatory,
          tooltip: this.fieldTooltip
        });
      } else if (this.selectedType === 'checkbox') {
        this.$emit(emitStr, {
          fieldName: this.fieldName,
          fieldKey: this.fieldKey,
          fieldTitle: this.fieldTitle,
          type: this.selectedType,
          default: this.checkboxDefault
        });
      } else if (this.selectedType !== 'link' && this.selectedType !== 'counter') {
        this.$emit(emitStr, {
          fieldName: this.fieldName,
          fieldKey: this.fieldKey,
          fieldTitle: this.fieldTitle,
          type: this.selectedType,
          mandatory: this.fieldMandatory,
          tooltip: this.fieldTooltip
        });
      } else {
        this.$emit(emitStr, {
          fieldName: this.fieldName,
          fieldKey: this.fieldKey,
          fieldTitle: this.fieldTitle,
          type: this.selectedType
        });
      }
    },

    addDropdown() {
      if (this.dropdownValue !== '') {
        this.dropdownArray.push(this.dropdownValue);
        this.dropdownValue = '';
      }
    },

    setField() {
      this.fieldName = '';
      this.fieldTitle = '';
      this.dropdownArray = [];
      this.selectedType = '';
      this.fieldKey = '';
      this.fieldMandatory = '';
      this.fieldTooltip = '';

      if (this.field && this.field.name) {
        this.fieldName = this.field.name;

        if (this.field.data) {
          const {
            data
          } = this.field;

          for (let i = 0; i < data.length; i += 1) {
            const {
              key,
              value
            } = data[i];

            switch (key) {
              case 'title':
                this.fieldTitle = value;
                break;

              case 'dropdownValues':
                this.dropdownArray = value.split(',');
                break;

              case 'type':
                this.selectedType = value;
                break;

              case 'uiKey':
                this.fieldKey = value;
                break;

              case 'mandatory':
                this.fieldMandatory = value;
                break;

              case 'tooltip':
                this.fieldTooltip = value;
                break;

              default:
                break;
            }
          }

          this.selectedType = this.selectedType ? this.selectedType : this.field.type;
        }
      }
    }

  }
};

/**
 * @description Create a metadata-field/field-group, emits the info you need
 * @since 1.1
 */
var VdtFieldGroupCreator = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-metadata-manager__field-wrapper"
    }, [_c('form', {
      on: {
        "submit": function ($event) {
          $event.preventDefault();
        }
      }
    }, [_c('h3', [_vm._v("Create a group")]), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__field"
    }, [_c('label', [_vm._v("Name of the group in Vidispine?")]), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__field-input",
      class: {
        'filled': _vm.groupName !== ''
      }
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.groupName,
        expression: "groupName"
      }],
      attrs: {
        "placeholder": "snake_case VS name.."
      },
      domProps: {
        "value": _vm.groupName
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) {
            return;
          }

          _vm.groupName = $event.target.value;
        }
      }
    })])]), _vm._v(" "), _c('button', {
      staticClass: "vdt-button vdt-button--default",
      attrs: {
        "disabled": _vm.disabledGroup
      },
      on: {
        "click": _vm.emit
      }
    }, [_vm._v(" Add Group ")])])]);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-551778e3',
  name: 'VdtGroupCreator',
  props: {
    /**
     * @description Set it to true if you only want fewer options
     */
    simple: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      groupName: ''
    };
  },

  computed: {
    disabledGroup() {
      return this.groupName === '';
    }

  },
  methods: {
    emit() {
      this.$emit('createGroup', {
        groupName: this.groupName
      });
    }

  }
};

/**
 * @description Emits field and group
 * @since 1.1
 */
var VdtAddFieldToGroup = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-metadata-manager__field-wrapper"
    }, [_c('form', {
      on: {
        "submit": function ($event) {
          $event.preventDefault();
        }
      }
    }, [_c('h3', [_vm._v("Which group do you want to add field '" + _vm._s(_vm.field.name) + "' to?")]), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__field"
    }, [_c('div', {
      staticClass: "vdt-metadata-manager__field-dropdown",
      class: {
        'filled': _vm.groupName !== ''
      }
    }, [_c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.groupName,
        expression: "groupName"
      }],
      on: {
        "change": function ($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });
          _vm.groupName = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
        }
      }
    }, [_c('option', {
      attrs: {
        "value": "",
        "disabled": "disabled"
      }
    }, [_vm._v("Choose a group")]), _vm._v(" "), _vm._l(_vm.groups, function (group) {
      return _c('option', {
        key: group.name,
        domProps: {
          "value": group.name
        }
      }, [_vm._v(" " + _vm._s(group.name) + " ")]);
    })], 2)])]), _vm._v(" "), _c('button', {
      staticClass: "vdt-button vdt-button--default",
      attrs: {
        "disabled": _vm.disabledGroup
      },
      on: {
        "click": _vm.emit
      }
    }, [_vm._v(" Add To Group ")])])]);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-2aa24272',
  name: 'VdtAddFieldToGroup',
  props: {
    /**
     * @description Field that should be added to a group
     */
    field: {
      type: Object,
      required: true
    },

    /**
     * @description Groups that can be added to
     */
    groups: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      groupName: ''
    };
  },

  computed: {
    disabledGroup() {
      return this.groupName === '';
    }

  },
  methods: {
    emit() {
      this.$emit('addFieldToGroup', {
        parentGroupName: this.groupName,
        fieldName: this.field.name
      });
    }

  }
};

/**
 * @description Emits group to be added and selected group
 * @since 1.1
 */
var VdtAddGroupToGroup = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-metadata-manager__field-wrapper"
    }, [_c('form', {
      on: {
        "submit": function ($event) {
          $event.preventDefault();
        }
      }
    }, [_c('h3', [_vm._v("Which group do you want to add group '" + _vm._s(_vm.group.name) + "' to?")]), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__field"
    }, [_c('div', {
      staticClass: "vdt-metadata-manager__field-dropdown",
      class: {
        'filled': _vm.groupName !== ''
      }
    }, [_c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.groupName,
        expression: "groupName"
      }],
      on: {
        "change": function ($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });
          _vm.groupName = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
        }
      }
    }, [_c('option', {
      attrs: {
        "value": "",
        "disabled": "disabled"
      }
    }, [_vm._v("Choose a group")]), _vm._v(" "), _vm._l(_vm.groups, function (group) {
      return _c('option', {
        key: group.name,
        domProps: {
          "value": group.name
        }
      }, [_vm._v(" " + _vm._s(group.name) + " ")]);
    })], 2)])]), _vm._v(" "), _c('button', {
      staticClass: "vdt-button vdt-button--default",
      attrs: {
        "disabled": _vm.disabledGroup
      },
      on: {
        "click": _vm.emit
      }
    }, [_vm._v(" Add To Group ")])])]);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-f2df57bc',
  name: 'VdtAddGroupToGroup',
  props: {
    /**
     * @description Group that should be added to a group
     */
    group: {
      type: Object,
      required: true
    },

    /**
     * @description Groups that can be added to
     */
    groups: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      groupName: ''
    };
  },

  computed: {
    disabledGroup() {
      return this.groupName === '';
    }

  },
  methods: {
    emit() {
      this.$emit('addGroupToGroup', {
        parentGroupName: this.groupName,
        groupName: this.group.name
      });
    }

  }
};

/**
 * @description Emits fields and group
 * @since 1.1
 */
var VdtAddFieldsToGroup = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-metadata-manager__field-wrapper"
    }, [_c('form', {
      on: {
        "submit": function ($event) {
          $event.preventDefault();
        }
      }
    }, [_c('h3', [_vm._v("Which group do you want to add the " + _vm._s(_vm.selectedFields.length) + " fields to?")]), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__field"
    }, [_c('div', {
      staticClass: "vdt-metadata-manager__field-dropdown",
      class: {
        'filled': _vm.groupName !== ''
      }
    }, [_c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.groupName,
        expression: "groupName"
      }],
      on: {
        "change": function ($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });
          _vm.groupName = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
        }
      }
    }, [_c('option', {
      attrs: {
        "value": "",
        "disabled": "disabled"
      }
    }, [_vm._v("Choose a group")]), _vm._v(" "), _vm._l(_vm.groups, function (group) {
      return _c('option', {
        key: group.name,
        domProps: {
          "value": group.name
        }
      }, [_vm._v(" " + _vm._s(group.name) + " ")]);
    })], 2)])]), _vm._v(" "), _c('button', {
      staticClass: "vdt-button vdt-button--default",
      attrs: {
        "disabled": _vm.disabledGroup
      },
      on: {
        "click": _vm.emit
      }
    }, [_vm._v(" Add To Group ")])])]);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-20583284',
  name: 'VdtAddFieldsToGroup',
  props: {
    /**
     * @description Fields that should be added to a group
     */
    selectedFields: {
      type: Array,
      required: true
    },

    /**
     * @description Groups that can be added to
     */
    groups: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      groupName: ''
    };
  },

  computed: {
    disabledGroup() {
      return this.groupName === '';
    }

  },
  methods: {
    emit() {
      this.$emit('addFieldsToGroup', {
        parentGroupName: this.groupName,
        fields: this.selectedFields
      });
    }

  }
};

/**
 * @description Emits groups to be added and selected group
 * @since 1.1
 */
var VdtAddGroupsToGroup = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-metadata-manager__field-wrapper"
    }, [_c('form', {
      on: {
        "submit": function ($event) {
          $event.preventDefault();
        }
      }
    }, [_c('h3', [_vm._v("Which group do you want to add the " + _vm._s(_vm.selectedGroups.length) + " groups to?")]), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__field"
    }, [_c('div', {
      staticClass: "vdt-metadata-manager__field-dropdown",
      class: {
        'filled': _vm.groupName !== ''
      }
    }, [_c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.groupName,
        expression: "groupName"
      }],
      on: {
        "change": function ($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });
          _vm.groupName = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
        }
      }
    }, [_c('option', {
      attrs: {
        "value": "",
        "disabled": "disabled"
      }
    }, [_vm._v("Choose a group")]), _vm._v(" "), _vm._l(_vm.groups, function (group) {
      return _c('option', {
        key: group.name,
        domProps: {
          "value": group.name
        }
      }, [_vm._v(" " + _vm._s(group.name) + " ")]);
    })], 2)])]), _vm._v(" "), _c('button', {
      staticClass: "vdt-button vdt-button--default",
      attrs: {
        "disabled": _vm.disabledGroup
      },
      on: {
        "click": _vm.emit
      }
    }, [_vm._v(" Add To Group ")])])]);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-5fbdc67a',
  name: 'VdtAddGroupsToGroup',
  props: {
    /**
     * @description Groups that should be added to a group
     */
    selectedGroups: {
      type: Array,
      required: true
    },

    /**
     * @description Groups that can be added to
     */
    groups: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      groupName: ''
    };
  },

  computed: {
    disabledGroup() {
      return this.groupName === '';
    }

  },
  methods: {
    emit() {
      this.$emit('addGroupsToGroup', {
        parentGroupName: this.groupName,
        groups: this.selectedGroups
      });
    }

  }
};

/**
 * @description Changes a field definition with a textarea
 * @since 1.1
 */
var VdtChangeField = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-metadata-manager__field-wrapper"
    }, [_c('form', {
      on: {
        "submit": function ($event) {
          $event.preventDefault();
        }
      }
    }, [_c('h3', [_vm._v("Change whatever you want:")]), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__field"
    }, [_c('div', {
      staticClass: "vdt-metadata-manager__field-input"
    }, [_c('textarea', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.changedField,
        expression: "changedField"
      }],
      attrs: {
        "placeholder": "Insert JSON document",
        "rows": "20"
      },
      domProps: {
        "value": _vm.changedField
      },
      on: {
        "input": function ($event) {
          if ($event.target.composing) {
            return;
          }

          _vm.changedField = $event.target.value;
        }
      }
    })]), _vm._v(" "), _vm.parseError !== '' ? _c('label', {
      staticClass: "vdt-metadata-manager__field-input__error"
    }, [_vm._v(" " + _vm._s(_vm.parseError) + " ")]) : _vm._e()]), _vm._v(" "), _c('button', {
      staticClass: "vdt-button vdt-button--default",
      attrs: {
        "disabled": _vm.disabledSave
      },
      on: {
        "click": _vm.emit
      }
    }, [_vm._v(" Save Changes ")])])]);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-2688ca74',
  name: 'VdtChangeField',
  props: {
    /**
     * @description Field that is about to be changed
     */
    field: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      changedField: {},
      parseError: ''
    };
  },

  computed: {
    disabledSave() {
      return this.changedField === {};
    }

  },

  mounted() {
    this.changedField = JSON.stringify(cloneDeep(this.field), null, 2);
  },

  methods: {
    emit() {
      let jsonField = {};

      try {
        this.parseError = '';
        jsonField = JSON.parse(this.changedField);
        this.$emit('changeField', {
          field: jsonField
        });
      } catch (err) {
        this.parseError = 'Only integers and booleans are valid without quotes.';
      }
    }

  }
};

/**
 * @description Changes a groups field order by drag & drop
 * @since 1.1
 */
var VdtChangeGroup = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-metadata-manager__field-wrapper"
    }, [_c('form', {
      on: {
        "submit": function ($event) {
          $event.preventDefault();
        }
      }
    }, [_c('h3', [_vm._v("Change order of the fields, or click the name in order to change the field:")]), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__field"
    }, [_c('draggable', {
      model: {
        value: _vm.childFields,
        callback: function ($$v) {
          _vm.childFields = $$v;
        },
        expression: "childFields"
      }
    }, [_c('transition-group', {
      attrs: {
        "name": "sort"
      }
    }, _vm._l(_vm.childFields, function (childField, index) {
      return _c('div', {
        key: childField.name,
        staticClass: "vdt-metadata-manager__field-name"
      }, [childField.data ? _c('div', {
        on: {
          "click": function ($event) {
            _vm.$emit('changeField', childField);
          }
        }
      }, [_vm._v(" " + _vm._s(index + 1) + ". " + _vm._s(childField.data.find(metadata => metadata.key === 'title').value) + " ")]) : _vm._e(), _vm._v(" "), _c('close', {
        staticStyle: {
          "margin": ".2rem"
        },
        attrs: {
          "title": `Remove ${childField.name} from ${_vm.group.name}`
        },
        nativeOn: {
          "click": function ($event) {
            _vm.remove(childField.name);
          }
        }
      })], 1);
    }))], 1)], 1), _vm._v(" "), _c('button', {
      staticClass: "vdt-button vdt-button--default",
      on: {
        "click": _vm.emit
      }
    }, [_vm._v(" Save Changes ")])])]);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-48eda5cb',
  name: 'VdtChangeGroup',
  components: {
    draggable,
    close
  },
  props: {
    /**
     * @description Group that is about to be changed
     */
    group: {
      type: Object,
      required: true
    },

    /**
     * @description Array of all fields, will be picked.
     */
    fields: {
      type: Array,
      required: true
    },

    /**
     * @description Key for transient fields array in group data.
     */
    transientFieldsKey: {
      type: String,
      default: undefined,
      required: false
    }
  },

  data() {
    return {
      childFields: []
    };
  },

  computed: {
    transientFields() {
      if (!this.transientFieldsKey) {
        return [];
      }

      return this.getGroupDataValue(this.transientFieldsKey) || [];
    }

  },
  watch: {
    group(newVal, oldVal) {
      if (oldVal && newVal.name !== oldVal.name) {
        this.setChildFields();
      }
    }

  },

  mounted() {
    this.setChildFields();
  },

  methods: {
    getGroupDataValue(key) {
      try {
        const {
          data = []
        } = this.group;
        const {
          value
        } = data.find(d => d.key === key);
        return value ? JSON.parse(value) : undefined;
      } catch (_) {
        return undefined;
      }
    },

    emit() {
      this.$emit('changeGroup', {
        group: this.group.name,
        fieldOrder: this.childFields
      });
    },

    remove(fieldName) {
      if (this.transientFields.some(tf => tf.name === fieldName)) {
        const newTransientFields = this.transientFields.filter(tf => tf.name !== fieldName);
        const data = [{
          key: this.transientFieldsKey,
          value: newTransientFields
        }];
        const fieldOrder = this.childFields.filter(f => f.name !== fieldName);
        this.$emit('changeGroup', {
          group: this.group.name,
          fieldOrder,
          data
        });
      } else {
        this.$emit('removeFieldFromGroup', {
          group: this.group.name,
          field: fieldName
        });
        const fieldOrder = this.childFields.filter(f => f.name !== fieldName);
        this.$emit('changeGroup', {
          group: this.group.name,
          fieldOrder
        });
      }
    },

    capitalizeFirst(s) {
      return s.charAt(0).toUpperCase() + s.slice(1);
    },

    camelCaseToTitle(camel) {
      return this.capitalizeFirst(camel.split(/(?=[A-Z])/).join(' '));
    },

    setChildFields() {
      const {
        field: regularFields = []
      } = this.group;
      const fields = this.transientFieldsKey ? regularFields.concat(this.transientFields) : regularFields;
      this.childFields = fields.map(f => {
        const data = f.data || [];

        if (!(f.data && f.data.find(d => d.key !== 'title'))) {
          data.push({
            key: 'title',
            value: this.camelCaseToTitle(f.name)
          });
        }

        return Object.assign({}, {
          data
        }, f);
      });
      let fieldOrderObj = {};

      if (this.group.data) {
        fieldOrderObj = this.group.data.find(metadata => metadata.key === 'fieldOrder');
      }

      if (fieldOrderObj.key) {
        const fieldOrder = JSON.parse(fieldOrderObj.value); // eslint-disable-next-line

        this.childFields.sort((a, b) => fieldOrder[a.name] > fieldOrder[b.name] ? 1 : fieldOrder[b.name] > fieldOrder[a.name] ? -1 : 0);
      }
    }

  }
};

/**
 * @description Displays metadata fields and groups, and you can manage them as you please
 * @since 1.1
 *
 * ***
 *
 * ###CSS-variables used:
 *
 * > **--contrast-color-1** text color of bulk selection & text on active list navigation item.<br/>
 * > **--danger** text color of parsing error message.
 * Also background-color of "Delete" buttons.<br/>
 * > **--default** bulk selection background color, border color of text inputs.<br/>
 * > **--success** border color of text inputs with value.<br/>
 * > **--primary** background color of active list navigation item in mobile view.
 * Also the "Add to Group" buttons.<br/>
 */
var VdtManageMetadata = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      directives: [{
        name: "responsive",
        rawName: "v-responsive",
        value: _vm.breakpoints,
        expression: "breakpoints"
      }],
      staticClass: "vdt-metadata-manager-wrapper"
    }, [_c('div', {
      staticClass: "vdt-metadata-manager vdt-metadata-manager--big-bear"
    }, [_c('div', {
      staticClass: "vdt-metadata-manager__bulk"
    }, [_vm.selectedFields.length >= 1 ? _c('VdtBulkActions', {
      staticClass: "bulk-actions field-bulk",
      attrs: {
        "selected-assets": _vm.selectedFields,
        "singular-name": 'field',
        "plural-name": 'fields'
      },
      on: {
        "resetSelectedAssets": function ($event) {
          _vm.selectedFields = [];
        }
      }
    }, [_c('div', {
      attrs: {
        "slot": "buttons"
      },
      slot: "buttons"
    }, [_c('button', {
      staticClass: "vdt-button vdt-button--primary",
      on: {
        "click": _vm.addFieldsToGroup
      }
    }, [_vm._v(" Add to Group ")])])]) : _vm._e(), _vm._v(" "), _vm.selectedGroups.length >= 1 ? _c('VdtBulkActions', {
      staticClass: "bulk-actions group-bulk",
      attrs: {
        "selected-assets": _vm.selectedGroups,
        "singular-name": 'group',
        "plural-name": 'groups'
      },
      on: {
        "resetSelectedAssets": function ($event) {
          _vm.selectedGroups = [];
        }
      }
    }, [_c('div', {
      attrs: {
        "slot": "buttons"
      },
      slot: "buttons"
    }, [_c('button', {
      staticClass: "vdt-button vdt-button--primary",
      on: {
        "click": _vm.addGroupsToGroup
      }
    }, [_vm._v(" Add to Group ")])])]) : _vm._e()], 1), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__area vdt-metadata-manager__area-fields"
    }, [_c('div', {
      staticClass: "vdt-metadata-manager__area__header"
    }, [_c('span', [_vm._v("Fields")]), _vm._v(" "), _vm.enableSearch ? _c('VdtSearch', {
      staticClass: "vdt-metadata-manager__search",
      attrs: {
        "placeholder": 'Search for field.. '
      },
      on: {
        "search": function ($event) {
          _vm.emitQuery('field', $event);
        },
        "clear": function ($event) {
          _vm.emitQuery('field', '');
        }
      }
    }) : _vm._e(), _vm._v(" "), !_vm.disable.includes('create') ? _c('button', {
      staticClass: "vdt-button vdt-button--default",
      on: {
        "click": function ($event) {
          _vm.preview = 'createField';
        }
      }
    }, [_vm._v("+ Create Field")]) : _vm._e()], 1), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__area__list"
    }, [_c('VdtList', {
      attrs: {
        "break-point-width": _vm.listBreakPointWidth,
        "columns": _vm.fieldColumns,
        "assets": _vm.pagedFields,
        "checkbox": !_vm.disable.includes('add'),
        "selected-assets": _vm.selectedFields,
        "track-by": 'name',
        "list-name": 'fields',
        "actions": _vm.fieldActions
      },
      on: {
        "update:selectedAssets": function ($event) {
          _vm.selectedFields = $event;
        },
        "addFieldToGroup": function ($event) {
          _vm.addFieldToGroup($event);
        },
        "changeField": function ($event) {
          _vm.changeField($event);
        },
        "deleteField": function ($event) {
          _vm.deleteField($event);
        },
        "assetClick": function ($event) {
          _vm.openPreview('Field', $event);
        },
        "sortUpdate": function ($event) {
          _vm.sortOn('field', $event);
        }
      }
    })], 1), _vm._v(" "), _vm.fields.length >= _vm.number ? _c('VdtPaging', {
      attrs: {
        "first": _vm.fieldFirst,
        "number": _vm.number,
        "hits": _vm.fields.length,
        "page": _vm.fieldPage
      },
      on: {
        "gotoPage": function ($event) {
          _vm.gotoPage('field', $event);
        }
      }
    }) : _vm._e()], 1), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__area vdt-metadata-manager__area-groups"
    }, [_c('div', {
      staticClass: "vdt-metadata-manager__area__header"
    }, [_c('span', [_vm._v("Groups")]), _vm._v(" "), _vm.enableSearch ? _c('VdtSearch', {
      staticClass: "vdt-metadata-manager__search",
      attrs: {
        "placeholder": 'Search for group.. '
      },
      on: {
        "search": function ($event) {
          _vm.emitQuery('group', $event);
        },
        "clear": function ($event) {
          _vm.emitQuery('group', '');
        }
      }
    }) : _vm._e(), _vm._v(" "), !_vm.disable.includes('create') ? _c('button', {
      staticClass: "vdt-button vdt-button--default",
      on: {
        "click": function ($event) {
          _vm.preview = 'createGroup';
        }
      }
    }, [_vm._v("+ Create Group")]) : _vm._e()], 1), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__area__list"
    }, [_c('VdtList', {
      attrs: {
        "break-point-width": _vm.listBreakPointWidth,
        "columns": _vm.groupColumns,
        "assets": _vm.pagedGroups,
        "checkbox": !_vm.disable.includes('add'),
        "selected-assets": _vm.selectedGroups,
        "track-by": 'name',
        "list-name": 'groups',
        "actions": _vm.groupActions
      },
      on: {
        "update:selectedAssets": function ($event) {
          _vm.selectedGroups = $event;
        },
        "addGroupToGroup": function ($event) {
          _vm.addGroupToGroup($event);
        },
        "changeGroup": function ($event) {
          _vm.changeGroup($event);
        },
        "deleteGroup": function ($event) {
          _vm.deleteGroup($event);
        },
        "assetClick": function ($event) {
          _vm.openPreview('Group', $event);
        },
        "sortUpdate": function ($event) {
          _vm.sortOn('field', $event);
        }
      }
    })], 1), _vm._v(" "), _vm.groups.length >= _vm.number ? _c('VdtPaging', {
      attrs: {
        "first": _vm.groupFirst,
        "number": _vm.number,
        "hits": _vm.groups.length,
        "page": _vm.groupPage
      },
      on: {
        "gotoPage": function ($event) {
          _vm.gotoPage('group', $event);
        }
      }
    }) : _vm._e()], 1), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__area vdt-metadata-manager__preview"
    }, [_vm.preview === 'createField' ? _c('VdtFieldCreator', {
      on: {
        "createField": function ($event) {
          _vm.$emit('createField', $event);
        }
      }
    }) : _vm.preview === 'createGroup' ? _c('VdtFieldGroupCreator', {
      on: {
        "createGroup": function ($event) {
          _vm.$emit('createGroup', $event);
        }
      }
    }) : _vm.preview === 'changeField' ? _c('VdtFieldCreator', {
      attrs: {
        "field": _vm.field
      },
      on: {
        "changeField": function ($event) {
          _vm.$emit('changeField', $event);
        }
      }
    }) : _vm.preview === 'changeGroup' ? _c('VdtChangeGroup', {
      attrs: {
        "group": _vm.group,
        "fields": _vm.fields,
        "transient-fields-key": _vm.transientFieldsKey
      },
      on: {
        "changeGroup": function ($event) {
          _vm.$emit('changeGroup', $event);
        },
        "removeFieldFromGroup": function ($event) {
          _vm.$emit('removeFieldFromGroup', $event);
        },
        "changeField": function ($event) {
          _vm.changeField($event);
        }
      }
    }) : _vm.preview === 'deleteField' ? _c('div', {
      staticClass: "vdt-metadata-manager__area-delete"
    }, [_c('h3', [_vm._v("Delete field")]), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__field"
    }, [_c('span', [_vm._v("Are you sure you want to delete the field '" + _vm._s(_vm.field.name) + "'?")]), _vm._v(" "), _c('button', {
      staticClass: "vdt-button vdt-button--danger",
      on: {
        "click": function ($event) {
          _vm.$emit('deleteField', _vm.field.name);
        }
      }
    }, [_vm._v("Delete")])])]) : _vm.preview === 'deleteGroup' ? _c('div', {
      staticClass: "vdt-metadata-manager__area-delete"
    }, [_c('h3', [_vm._v("Delete group")]), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__field"
    }, [_c('span', [_vm._v("Are you sure you want to delete the group '" + _vm._s(_vm.group.name) + "'?")]), _vm._v(" "), _c('button', {
      staticClass: "vdt-button vdt-button--danger",
      on: {
        "click": function ($event) {
          _vm.$emit('deleteGroup', _vm.group.name);
        }
      }
    }, [_vm._v("Delete")])])]) : _vm.preview === 'addFieldToGroup' ? _c('VdtAddFieldToGroup', {
      attrs: {
        "field": _vm.field,
        "groups": _vm.groups
      },
      on: {
        "addFieldToGroup": function ($event) {
          _vm.$emit('addFieldToGroup', $event);
        }
      }
    }) : _vm.preview === 'addGroupToGroup' ? _c('VdtAddGroupToGroup', {
      attrs: {
        "group": _vm.group,
        "groups": _vm.groups
      },
      on: {
        "addGroupToGroup": function ($event) {
          _vm.$emit('addGroupToGroup', $event);
        }
      }
    }) : _vm.preview === 'addFieldsToGroup' ? _c('VdtAddFieldsToGroup', {
      attrs: {
        "selected-fields": _vm.selectedFields,
        "groups": _vm.groups
      },
      on: {
        "addFieldsToGroup": function ($event) {
          _vm.$emit('addFieldsToGroup', $event);
        }
      }
    }) : _vm.preview === 'addGroupsToGroup' ? _c('VdtAddGroupsToGroup', {
      attrs: {
        "selected-groups": _vm.selectedGroups,
        "groups": _vm.groups
      },
      on: {
        "addGroupsToGroup": function ($event) {
          _vm.$emit('addGroupsToGroup', $event);
        }
      }
    }) : _vm.preview === 'preview' ? _c('VdtRawMetadata', {
      attrs: {
        "root-title": _vm.rootTitle,
        "item": _vm.previewObject,
        "max-depth": 2
      }
    }) : _c('div', {
      staticClass: "vdt-metadata-manager__empty-preview"
    }, [_vm._v(" " + _vm._s(_vm.previewText) + " ")]), _vm._v(" "), _vm.previewObject !== {} && _vm.preview !== '' ? _c('button', {
      staticClass: "vdt-button vdt-button--close",
      on: {
        "click": function ($event) {
          _vm.previewObject = {}, _vm.preview = '';
        }
      }
    }) : _vm._e()], 1)]), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager vdt-metadata-manager--small-bear"
    }, [_c('div', {
      staticClass: "vdt-metadata-manager__bulk"
    }, [_vm.selectedFields.length >= 1 ? _c('VdtBulkActions', {
      staticClass: "bulk-actions field-bulk",
      attrs: {
        "selected-assets": _vm.selectedFields,
        "singular-name": 'field',
        "plural-name": 'fields'
      },
      on: {
        "resetSelectedAssets": function ($event) {
          _vm.selectedFields = [];
        }
      }
    }, [_c('div', {
      attrs: {
        "slot": "buttons"
      },
      slot: "buttons"
    }, [_c('button', {
      staticClass: "vdt-button vdt-button--primary",
      on: {
        "click": _vm.addFieldsToGroup
      }
    }, [_vm._v(" Add to Group ")])])]) : _vm._e(), _vm._v(" "), _vm.selectedGroups.length >= 1 ? _c('VdtBulkActions', {
      staticClass: "bulk-actions group-bulk",
      attrs: {
        "selected-assets": _vm.selectedGroups,
        "singular-name": 'group',
        "plural-name": 'groups'
      },
      on: {
        "resetSelectedAssets": function ($event) {
          _vm.selectedGroups = [];
        }
      }
    }, [_c('div', {
      attrs: {
        "slot": "buttons"
      },
      slot: "buttons"
    }, [_c('button', {
      staticClass: "vdt-button vdt-button--primary",
      on: {
        "click": _vm.addGroupsToGroup
      }
    }, [_vm._v(" Add to Group ")])])]) : _vm._e()], 1), _vm._v(" "), _vm.selectedContent === 'fields' ? _c('div', {
      staticClass: "vdt-metadata-manager__area vdt-metadata-manager__area-fields"
    }, [_c('div', {
      staticClass: "vdt-metadata-manager__area__header"
    }, [_c('div', {
      staticClass: "vdt-metadata-manager__area__header__navigation"
    }, [_c('div', {
      staticClass: "active"
    }, [_c('span', [_vm._v("Fields")]), _vm._v(" "), !_vm.disable.includes('create') ? _c('AddIcon', {
      nativeOn: {
        "click": function ($event) {
          _vm.preview = 'createField';
        }
      }
    }) : _vm._e()], 1), _vm._v(" "), _c('div', {
      staticClass: "inactive",
      on: {
        "click": function ($event) {
          _vm.selectedContent = 'groups';
        }
      }
    }, [_c('span', [_vm._v("Groups")])])]), _vm._v(" "), _vm.enableSearch ? _c('VdtSearch', {
      staticClass: "vdt-metadata-manager__search",
      attrs: {
        "placeholder": 'Search for field.. '
      },
      on: {
        "search": function ($event) {
          _vm.emitQuery('field', $event);

          _vm.emitQuery('group', $event);
        },
        "clear": function ($event) {
          _vm.emitQuery('field', '');

          _vm.emitQuery('group', '');
        }
      }
    }) : _vm._e()], 1), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__area__list"
    }, [_c('VdtList', {
      attrs: {
        "break-point-width": _vm.listBreakPointWidth,
        "columns": _vm.fieldColumns,
        "assets": _vm.pagedFields,
        "checkbox": !_vm.disable.includes('add'),
        "selected-assets": _vm.selectedFields,
        "track-by": 'name',
        "list-name": 'fields',
        "actions": _vm.fieldActions
      },
      on: {
        "update:selectedAssets": function ($event) {
          _vm.selectedFields = $event;
        },
        "addFieldToGroup": function ($event) {
          _vm.addFieldToGroup($event);
        },
        "changeField": function ($event) {
          _vm.changeField($event);
        },
        "deleteField": function ($event) {
          _vm.deleteField($event);
        },
        "assetClick": function ($event) {
          _vm.openPreview('Field', $event);
        },
        "sortUpdate": function ($event) {
          _vm.sortOn('field', $event);
        }
      }
    })], 1), _vm._v(" "), _vm.fields.length >= _vm.number ? _c('VdtPaging', {
      attrs: {
        "first": _vm.fieldFirst,
        "number": _vm.number,
        "hits": _vm.fields.length,
        "page": _vm.fieldPage
      },
      on: {
        "gotoPage": function ($event) {
          _vm.gotoPage('field', $event);
        }
      }
    }) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _vm.selectedContent === 'groups' ? _c('div', {
      staticClass: "vdt-metadata-manager__area vdt-metadata-manager__area-groups"
    }, [_c('div', {
      staticClass: "vdt-metadata-manager__area__header"
    }, [_c('div', {
      staticClass: "vdt-metadata-manager__area__header__navigation"
    }, [_c('div', {
      staticClass: "inactive",
      on: {
        "click": function ($event) {
          _vm.selectedContent = 'fields';
        }
      }
    }, [_c('span', [_vm._v("Fields")])]), _vm._v(" "), _c('div', {
      staticClass: "active"
    }, [_c('span', [_vm._v("Groups ")]), _vm._v(" "), !_vm.disable.includes('create') ? _c('AddIcon', {
      nativeOn: {
        "click": function ($event) {
          _vm.preview = 'createGroup';
        }
      }
    }) : _vm._e()], 1)]), _vm._v(" "), _vm.enableSearch ? _c('VdtSearch', {
      staticClass: "vdt-metadata-manager__search",
      attrs: {
        "placeholder": 'Search for group.. '
      },
      on: {
        "search": function ($event) {
          _vm.emitQuery('field', $event);

          _vm.emitQuery('group', $event);
        },
        "clear": function ($event) {
          _vm.emitQuery('field', '');

          _vm.emitQuery('group', '');
        }
      }
    }) : _vm._e()], 1), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__area__list"
    }, [_c('VdtList', {
      attrs: {
        "break-point-width": _vm.listBreakPointWidth,
        "columns": _vm.groupColumns,
        "assets": _vm.pagedGroups,
        "checkbox": !_vm.disable.includes('add'),
        "selected-assets": _vm.selectedGroups,
        "track-by": 'name',
        "list-name": 'groups',
        "actions": _vm.groupActions
      },
      on: {
        "update:selectedAssets": function ($event) {
          _vm.selectedGroups = $event;
        },
        "addGroupToGroup": function ($event) {
          _vm.addGroupToGroup($event);
        },
        "changeGroup": function ($event) {
          _vm.changeGroup($event);
        },
        "deleteGroup": function ($event) {
          _vm.deleteGroup($event);
        },
        "assetClick": function ($event) {
          _vm.openPreview('Group', $event);
        },
        "sortUpdate": function ($event) {
          _vm.sortOn('field', $event);
        }
      }
    })], 1), _vm._v(" "), _vm.groups.length >= _vm.number ? _c('VdtPaging', {
      attrs: {
        "first": _vm.groupFirst,
        "number": _vm.number,
        "hits": _vm.groups.length,
        "page": _vm.groupPage
      },
      on: {
        "gotoPage": function ($event) {
          _vm.gotoPage('group', $event);
        }
      }
    }) : _vm._e()], 1) : _vm._e(), _vm._v(" "), _vm.preview !== '' ? _c('div', {
      staticClass: "vdt-metadata-manager__area vdt-metadata-manager__preview"
    }, [_vm.preview === 'createField' ? _c('VdtFieldCreator', {
      on: {
        "createField": function ($event) {
          _vm.$emit('createField', $event);
        }
      }
    }) : _vm.preview === 'createGroup' ? _c('VdtFieldGroupCreator', {
      on: {
        "createGroup": function ($event) {
          _vm.$emit('createGroup', $event);
        }
      }
    }) : _vm.preview === 'changeField' ? _c('VdtFieldCreator', {
      attrs: {
        "field": _vm.field
      },
      on: {
        "changeField": function ($event) {
          _vm.$emit('changeField', $event);
        }
      }
    }) : _vm.preview === 'changeGroup' ? _c('VdtChangeGroup', {
      attrs: {
        "group": _vm.group,
        "fields": _vm.fields
      },
      on: {
        "changeGroup": function ($event) {
          _vm.$emit('changeGroup', $event);
        },
        "changeField": function ($event) {
          _vm.changeField($event);
        }
      }
    }) : _vm.preview === 'deleteField' ? _c('div', {
      staticClass: "vdt-metadata-manager__area-delete"
    }, [_c('h3', [_vm._v("Delete field")]), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__field"
    }, [_c('span', [_vm._v("Are you sure you want to delete the field '" + _vm._s(_vm.field.name) + "'?")]), _vm._v(" "), _c('button', {
      staticClass: "vdt-button vdt-button--danger",
      on: {
        "click": function ($event) {
          _vm.$emit('deleteField', _vm.field.name);
        }
      }
    }, [_vm._v("Delete")])])]) : _vm.preview === 'deleteGroup' ? _c('div', {
      staticClass: "vdt-metadata-manager__area-delete"
    }, [_c('h3', [_vm._v("Delete group")]), _vm._v(" "), _c('div', {
      staticClass: "vdt-metadata-manager__field"
    }, [_c('span', [_vm._v("Are you sure you want to delete the group '" + _vm._s(_vm.group.name) + "'?")]), _vm._v(" "), _c('button', {
      staticClass: "vdt-button vdt-button--danger",
      on: {
        "click": function ($event) {
          _vm.$emit('deleteGroup', _vm.group.name);
        }
      }
    }, [_vm._v("Delete")])])]) : _vm.preview === 'addFieldToGroup' ? _c('VdtAddFieldToGroup', {
      attrs: {
        "field": _vm.field,
        "groups": _vm.groups
      },
      on: {
        "addFieldToGroup": function ($event) {
          _vm.$emit('addFieldToGroup', $event);
        }
      }
    }) : _vm.preview === 'addGroupToGroup' ? _c('VdtAddGroupToGroup', {
      attrs: {
        "group": _vm.group,
        "groups": _vm.groups
      },
      on: {
        "addGroupToGroup": function ($event) {
          _vm.$emit('addGroupToGroup', $event);
        }
      }
    }) : _vm.preview === 'addFieldsToGroup' ? _c('VdtAddFieldsToGroup', {
      attrs: {
        "selected-fields": _vm.selectedFields,
        "groups": _vm.groups
      },
      on: {
        "addFieldsToGroup": function ($event) {
          _vm.$emit('addFieldsToGroup', $event);
        }
      }
    }) : _vm.preview === 'addGroupsToGroup' ? _c('VdtAddGroupsToGroup', {
      attrs: {
        "selected-groups": _vm.selectedGroups,
        "groups": _vm.groups
      },
      on: {
        "addGroupsToGroup": function ($event) {
          _vm.$emit('addGroupsToGroup', $event);
        }
      }
    }) : _vm.preview === 'preview' ? _c('VdtRawMetadata', {
      attrs: {
        "root-title": _vm.rootTitle,
        "item": _vm.previewObject,
        "max-depth": 2
      }
    }) : _c('div', {
      staticClass: "vdt-metadata-manager__empty-preview"
    }, [_vm._v(" " + _vm._s(_vm.previewText) + " ")]), _vm._v(" "), _vm.previewObject !== {} && _vm.preview !== '' ? _c('button', {
      staticClass: "vdt-button vdt-button--close",
      on: {
        "click": function ($event) {
          _vm.previewObject = {}, _vm.preview = '';
        }
      }
    }) : _vm._e()], 1) : _vm._e()])]);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-ba6b77e2',
  name: 'VdtManageMetadata',
  components: {
    VdtList,
    VdtPaging,
    VdtBulkActions,
    VdtRawMetadata,
    VdtFieldCreator,
    VdtFieldGroupCreator,
    VdtAddFieldToGroup,
    VdtAddGroupToGroup,
    VdtAddFieldsToGroup,
    VdtAddGroupsToGroup,
    VdtChangeField,
    VdtChangeGroup,
    VdtSearch,
    AddIcon: IncludeIcon
  },
  directives: {
    responsive: ResponsiveDirective
  },
  props: {
    /**
     * @description Array of fields to be displayed in corresponding list.
     */
    fields: {
      type: Array,
      required: true
    },

    /**
     * @description Array of groups to be displayed in corresponding list.
     */
    groups: {
      type: Array,
      required: true
    },

    /**
     * @description Force close preview from the outside, value set to true will force
     * close the preview pane.
     */
    closePreview: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * @description Text to be displayed in the preview pane, can be a success message or
     * empty state message for example.
     */
    successText: {
      type: String,
      required: false,
      default: 'No selection'
    },

    /**
     * Array actions to disable, to disable all actions type:
     * :disable="['add', 'create', 'change', 'delete', '']"
     */
    disable: {
      type: Array,
      required: false,
      default: () => []
    },

    /**
     * @description Pixel width breakpoint at which layout switches to adapt to smaller screens.
     */
    breakPointWidth: {
      type: Number,
      default: 900,
      required: false
    },

    /**
     * @description Pixel width breakpoint at which lists switches to adapt to smaller screens.
     */
    listBreakPointWidth: {
      type: Number,
      default: 400,
      required: false
    },

    /**
     * @description Enable a search which emits what value you write so that you can find
     * the fields and/or groups.
     */
    enableSearch: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * @description Key for transient fields array in group data.
     */
    transientFieldsKey: {
      type: String,
      default: undefined,
      required: false
    }
  },

  data() {
    return {
      selectedContent: 'fields',
      preview: '',
      field: {},
      group: {},
      fieldColumns: [{
        label: 'Name',
        key: 'name',
        type: 'String',
        size: 'small'
      }, {
        label: 'Type',
        key: 'type',
        type: 'String',
        size: 'small'
      }],
      groupColumns: [{
        label: 'Name',
        key: 'name',
        type: 'String',
        size: 'small'
      }, {
        label: 'Inheritance',
        key: 'inheritance',
        type: 'String',
        size: 'small'
      }],
      selectedFields: [],
      selectedGroups: [],
      previewObject: {},
      rootTitle: '',
      fieldFirst: 0,
      groupFirst: 0,
      number: 25,
      fieldPage: 1,
      groupPage: 1,
      showSuccessText: false
    };
  },

  computed: {
    previewText() {
      return this.showSuccessText ? this.successText : 'No selection';
    },

    pagedFields() {
      const pagedFields = cloneDeep(this.fields);

      return pagedFields.splice(this.fieldFirst, this.number);
    },

    pagedGroups() {
      const pagedGroups = cloneDeep(this.groups);

      return pagedGroups.splice(this.groupFirst, this.number);
    },

    fieldActions() {
      const fieldActions = [];

      if (!this.disable.includes('add')) {
        fieldActions.push({
          label: 'Add To Group',
          emit: 'addFieldToGroup'
        });
      }

      if (!this.disable.includes('change')) {
        fieldActions.push({
          label: 'Change',
          emit: 'changeField'
        });
      }

      if (!this.disable.includes('delete')) {
        fieldActions.push({
          label: 'Delete',
          emit: 'deleteField'
        });
      }

      return fieldActions;
    },

    groupActions() {
      const groupActions = [];

      if (!this.disable.includes('add')) {
        groupActions.push({
          label: 'Add To Group',
          emit: 'addGroupToGroup'
        });
      }

      if (!this.disable.includes('change')) {
        groupActions.push({
          label: 'Change',
          emit: 'changeGroup'
        });
      }

      if (!this.disable.includes('delete')) {
        groupActions.push({
          label: 'Delete',
          emit: 'deleteGroup'
        });
      }

      return groupActions;
    },

    breakpoints() {
      return {
        mobile: el => el.width <= this.breakPointWidth
      };
    }

  },
  watch: {
    closePreview() {
      if (this.closePreview) {
        this.preview = '';
        this.showSuccessText = true;
      }
    },

    preview() {
      if (this.preview !== '') {
        this.showSuccessText = false;
      }
    }

  },
  methods: {
    addFieldToGroup(field) {
      this.preview = 'addFieldToGroup';
      this.field = cloneDeep(field);
    },

    changeField(field) {
      this.preview = 'changeField';
      this.field = cloneDeep(field);
    },

    deleteField(field) {
      this.preview = 'deleteField';
      this.field = cloneDeep(field);
    },

    addGroupToGroup(group) {
      this.preview = 'addGroupToGroup';
      this.group = cloneDeep(group);
    },

    changeGroup(group) {
      this.preview = 'changeGroup';
      this.group = cloneDeep(group);
    },

    deleteGroup(group) {
      this.preview = 'deleteGroup';
      this.group = cloneDeep(group);
    },

    addFieldsToGroup() {
      this.preview = 'addFieldsToGroup';
    },

    addGroupsToGroup() {
      this.preview = 'addGroupsToGroup';
    },

    gotoPage(entity, newPageInfo) {
      if (entity === 'field') {
        this.fieldPage = newPageInfo.newPage;
        this.fieldFirst = newPageInfo.newFirst;
      } else {
        this.groupPage = newPageInfo.newPage;
        this.groupFirst = newPageInfo.newFirst;
      }
    },

    openPreview(title, object) {
      this.rootTitle = title;
      this.preview = 'preview';
      this.previewObject = object;
    },

    sortOn() {// entityType, entity
    },

    emitQuery(entity, query) {
      this.gotoPage(entity, {
        newPage: 1,
        newFirst: 0
      });
      this.$emit(`${entity}Search`, query);
    }

  }
};

var Autocomplete$1 = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-search__autocomplete"
    }, [_vm._l(_vm.autocompletes, function (autocomplete, autocompleteIndex) {
      return _c('div', {
        key: autocomplete.field,
        staticClass: "vdt-search__autocomplete-row"
      }, [_c('ul', {
        staticClass: "vdt-search__autocomplete-suggestions"
      }, _vm._l(autocomplete.suggestion, function (suggestion, suggestionIndex) {
        return _c('li', {
          key: suggestion,
          class: {
            'activeSuggestion': _vm.isActiveSuggestion({
              autocompleteIndex: autocompleteIndex,
              suggestionIndex: suggestionIndex
            })
          },
          on: {
            "mousedown": function ($event) {
              $event.preventDefault();
            },
            "click": function ($event) {
              _vm.pickAutocomplete(suggestion);
            }
          }
        }, [_vm._v(" " + _vm._s(suggestion) + " ")]);
      }))]);
    }), _vm._v(" "), !_vm.autocompletes.length ? _c('div', {
      staticClass: "vdt-search__autocomplete-row"
    }, [_c('ul', {
      staticClass: "vdt-search__autocomplete-suggestions"
    }, [_c('li', {
      staticClass: "activeSuggestion",
      on: {
        "mousedown": function ($event) {
          $event.preventDefault();
        },
        "click": _vm.addTag
      }
    }, [_vm._v(" Add tag: " + _vm._s(_vm.query) + " ")])])]) : _vm._e()], 2);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-91b8799c',
  name: 'Autocomplete',
  props: {
    /**
     * List of Autocomplete's and suggestions to show, raw form from vidispine
     */
    autocompletes: {
      type: Array,
      required: false,
      default: () => []
    },
    query: {
      type: String,
      required: true,
      default: ''
    }
  },

  data() {
    return {
      active: {
        autocomplete: 0,
        suggestion: 0
      },
      keys: {
        UP: 38,
        DOWN: 40,
        ENTER: 13,
        BACKSPACE: 8
      }
    };
  },

  computed: {
    currentAutocomplete() {
      if (this.autocompletes.length) {
        return this.autocompletes[this.active.autocomplete];
      }

      return {
        suggestion: [this.query]
      };
    }

  },
  watch: {
    autocompletes() {
      this.resetAutocompleteStep();
    }

  },

  mounted() {
    window.addEventListener('keydown', this.keyPressed);
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.keyPressed);
  },

  methods: {
    pickAutocomplete(query) {
      /**
      * Emits the autocomplete suggestion that was clicked
      *
      * @event pickedSuggestion
      * @type {Object}
      */
      this.$emit('pickedSuggestion', {
        query
      });
    },

    addTag() {
      /**
      * Emits the tag query that was clicked
      *
      * @event addTag
      * @type {Object}
      */
      this.$emit('addTag', {
        query: this.query
      });
    },

    isActiveSuggestion({
      autocompleteIndex = 0,
      suggestionIndex = 0
    }) {
      if (this.active.autocomplete === autocompleteIndex && this.active.suggestion === suggestionIndex) {
        return true;
      }

      return false;
    },

    activeSuggestionStyle({
      autocompleteIndex = 0,
      suggestionIndex = 0
    }) {
      if (this.isActiveSuggestion({
        autocompleteIndex,
        suggestionIndex
      })) {
        return {
          'background-color': 'lightgrey',
          color: 'white'
        };
      }

      return null;
    },

    prevSuggestion() {
      if (this.active.suggestion > 0) {
        this.active.suggestion = this.active.suggestion - 1;
      } else if (this.active.suggestion === 0 && this.active.autocomplete > 0) {
        if (this.active.autocomplete > 0) {
          this.active.autocomplete = this.active.autocomplete - 1;
          this.active.suggestion = this.currentAutocomplete.suggestion.length - 1;
        }
      }
    },

    nextSuggestion() {
      if (this.active.suggestion >= this.currentAutocomplete.suggestion.length - 1) {
        if (this.active.autocomplete < this.autocompletes.length - 1) {
          this.active.autocomplete = this.active.autocomplete + 1;
          this.active.suggestion = 0;
        }
      } else if (this.active.suggestion < this.currentAutocomplete.suggestion.length - 1) {
        this.active.suggestion = this.active.suggestion + 1;
      }
    },

    resetAutocompleteStep() {
      this.active.autocomplete = 0;
      this.active.suggestion = 0;
    },

    keyPressed(event) {
      switch (event.keyCode) {
        case this.keys.UP:
          this.prevSuggestion();
          break;

        case this.keys.DOWN:
          this.nextSuggestion();
          break;

        case this.keys.ENTER:
          this.pickAutocomplete(this.currentAutocomplete.suggestion[this.active.suggestion]);
          break;

        default: // Do nothing

      }
    }

  }
};

var ATag = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-tag-tag"
    }, [_c('div', {
      staticClass: "vdt-tag-tag__query-container"
    }, [_c('div', {
      staticClass: "vdt-tag-tag__hidden-container"
    }, [_c('div', {
      staticClass: "vdt-tag-tag__query-container vdt-tag-tag__query-container--hidden"
    }, [_c('span', [_vm._v(_vm._s(_vm.tag))])])]), _vm._v(" "), _c('span', {
      staticClass: "vdt-tag-tag__value"
    }, [_c('span', {
      staticClass: "value"
    }, [_vm._v(_vm._s(_vm.tag))])])]), _vm._v(" "), !_vm.hideRemove ? _c('span', {
      staticClass: "vdt-tag-tag__remove",
      on: {
        "click": function ($event) {
          _vm.$emit('remove', _vm.tag);
        }
      }
    }, [_vm._v(" × ")]) : _vm._e()]);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-0040577a',
  name: 'ATag',
  props: {
    /**
     * String of a tag
     */
    tag: {
      type: String,
      required: true
    },

    /**
     * Hide the remove 'x' button from tag content
     */
    hideRemove: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};

// import ClearIcon from 'vue-material-design-icons/Close.vue';
/**
 * @description Use this component to display current tags and with suggestions create new tags.
 *
 * ***
 *
 * ###CSS-variables used:
 *
 * > **--contrast-color-1** text color of component.<br/>
 * > **--primary** background color of component.<br/>
 * @since 1.2
 */

var VdtTag = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      ref: "tagWrapper",
      staticClass: "vdt-tag__wrapper"
    }, [_c('form', {
      staticClass: "vdt-tag__form",
      class: _vm.focusClass,
      on: {
        "click": function ($event) {
          _vm.setFocusOnInput();
        },
        "submit": function ($event) {
          $event.preventDefault();

          _vm.emitEnter();
        }
      }
    }, [_c('transition-group', {
      staticClass: "vdt-tag__query-field-wrapper",
      class: {
        multiple: _vm.values.length > 1
      },
      attrs: {
        "name": "fade-in",
        "tag": "span"
      }
    }, [_vm._l(_vm.visibleTags, function (tag, index) {
      return _c('div', {
        key: index,
        staticClass: "vdt-tag__tag-wrapper"
      }, [_c('ATag', {
        staticClass: "vdt-tag__tag",
        attrs: {
          "tag": tag
        },
        on: {
          "remove": function ($event) {
            _vm.removeTagAtIndex(index);
          }
        }
      })], 1);
    }), _vm._v(" "), _vm.showAutocomplete && _vm.hiddenTags.length ? _c('div', {
      key: "hiddenTags",
      staticClass: "vdt-more-tag",
      attrs: {
        "name": "fade-in"
      }
    }, [_c('div', {
      staticClass: "vdt-tag__query-tag--hidden-container"
    }, [_c('ATag', {
      staticClass: "vdt-tag__query-tag--hidden-toggle",
      attrs: {
        "tag": _vm.morePlaceholder,
        "hide-remove": true
      },
      nativeOn: {
        "click": function ($event) {
          _vm.showHidden = !_vm.showHidden;
        }
      }
    }), _vm._v(" "), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.showHidden,
        expression: "showHidden"
      }],
      staticClass: "vdt-tag__query--hidden-tags"
    }, _vm._l(_vm.hiddenTags, function (tag, index) {
      return _c('ATag', {
        key: index,
        attrs: {
          "tag": tag
        },
        on: {
          "remove": function ($event) {
            _vm.removeTagAtIndex(_vm.tagsVisible - 1 + index);
          }
        }
      });
    }))], 1)]) : _vm._e(), _vm._v(" "), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.query,
        expression: "query"
      }],
      key: "taginput",
      ref: "tag",
      staticClass: "vdt-tag__input",
      attrs: {
        "placeholder": _vm.placeholder
      },
      domProps: {
        "value": _vm.query
      },
      on: {
        "input": [function ($event) {
          if ($event.target.composing) {
            return;
          }

          _vm.query = $event.target.value;
        }, function ($event) {
          _vm.emitSearch($event);
        }],
        "keydown": function ($event) {
          if (!('button' in $event) && _vm._k($event.keyCode, "delete", [8, 46], $event.key, ["Backspace", "Delete"])) {
            return null;
          }

          _vm.removeLast();
        },
        "submit": function ($event) {
          $event.preventDefault();

          _vm.emitEnter();
        }
      }
    })], 2)], 1), _vm._v(" "), _c('transition', {
      attrs: {
        "name": "autocomplete-animation"
      }
    }, [_vm.showAutosuggestions ? _c('Autocomplete', {
      attrs: {
        "autocompletes": _vm.computedAutocompletes,
        "query": _vm.query
      },
      on: {
        "pickedSuggestion": function ($event) {
          _vm.pickedSuggestion($event);
        },
        "addTag": function ($event) {
          _vm.addTag($event);
        }
      }
    }) : _vm._e()], 1)], 1);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-5e16ef05',
  name: 'VdtTag',
  components: {
    Autocomplete: Autocomplete$1,
    ATag
  },
  props: {
    /**
     * @description Placeholder for the search input
     */
    placeholder: {
      type: String,
      default: 'Add a tag..',
      required: false
    },

    /**
     * @description Array of selected assets to perform actions with.
     */
    values: {
      type: Array,
      required: true
    },

    /**
     * @description List of Autocomplete's, raw form from vidispine
     */
    autocompletes: {
      type: Array,
      required: false,
      default: () => []
    },

    /**
     * @description How many tags should be visible in search-bar
     * before grouping them together
     */
    tagsVisible: {
      type: Number,
      required: false,
      default: 3
    },

    /**
     * @description Disable the backspace removal of tags.
     */
    disableBackspace: {
      type: Boolean,
      required: false,
      default: false
    }
    /**
     * @description Adds a clear-all-button.
    clearAll: {
      type: Boolean,
      required: false,
      default: false,
    },
    */

  },

  data() {
    return {
      showHidden: false,
      query: '',
      tagIsFocused: false,
      keys: {
        UP: 38,
        DOWN: 40,
        ENTER: 13,
        BACKSPACE: 8
      }
    };
  },

  computed: {
    isTaging() {
      return this.query.length > 0;
    },

    showAutocomplete() {
      return this.values.length > 0 || this.autocompletes.length > 0;
    },

    showAutosuggestions() {
      return this.tagIsFocused && this.isTaging && this.showAutocomplete;
    },

    computedAutocompletes() {
      return this.autocompletes.filter(autocomplete => autocomplete.suggestion.length > 0);
    },

    visibleTags() {
      return this.values.slice(0, this.tagsVisible - 1);
    },

    hiddenTags() {
      return this.values.slice(this.tagsVisible - 1, this.values.length);
    },

    focusClass() {
      return this.tagIsFocused ? 'vdt-tag__form--focused' : '';
    },

    morePlaceholder() {
      return `${this.hiddenTags.length} more...`;
    }

  },
  watch: {
    hiddenTags(val) {
      if (val.length === 0) {
        this.showHidden = false;
      }
    }

  },

  mounted() {
    this.$refs.tagWrapper.addEventListener('focusin', this.focusChanged);
    this.$refs.tagWrapper.addEventListener('focusout', this.focusChanged);
  },

  beforeDestroy() {
    this.$refs.tagWrapper.removeEventListener('focusin', this.focusChanged);
    this.$refs.tagWrapper.removeEventListener('focusout', this.focusChanged);
  },

  methods: {
    setFocusOnInput() {
      this.$refs.tag.focus();
    },

    focusChanged() {
      this.tagIsFocused = !this.tagIsFocused;
    },

    emitEnter() {
      /**
      * Emits the query on Enter keypress ( key:13 )
      * if no autocomplete is active
      *
      * @event enter
      * @type {String}
      */
      if (!this.showAutocomplete) {
        this.$emit('enter', this.query);
      }
    },

    emitSearch($event) {
      /**
      * Emitting the query in the input field, get autocomplete suggestions
      * with this query.
      *
      * @event search
      * @type {String}
      */
      this.$emit('search', $event.target.value);
    },

    removeTagAtIndex(index) {
      /**
      * Emit that tells you to remove a tag at a index in the list.
      *
      * @event removeTagAtIndex
      * @type {Object}
      */
      this.$emit('removeTagAtIndex', {
        index
      });
    },

    removeLast() {
      if (!this.disableBackspace && !this.query.length && this.values.length > 0) {
        this.removeTagAtIndex(this.values.length - 1);
      }
    },

    addTag(query) {
      /**
      * Emit that tells you to add a new tag.
      *
      * @event addTag
      * @type {Object}
      */
      this.$emit('addTag', query);
      this.query = '';
    },

    pickedSuggestion(suggestion) {
      /**
      * Emit that tells you to use the picked suggestion as a tag.
      *
      * @event pickedSuggestion
      * @type {Object}
      */
      this.$emit('pickedSuggestion', suggestion);
      this.query = '';
    }

  }
};

/**
 * @description Displays a list of columns and enables sorting of them.
 * Will update the column property once changes have been made.
 * @since 1.0
 *
 * ***
 *
 * ###CSS-variables used:
 *
 * > **--contrast-color-0** background color of column list items.<br/>
 * > **--contrast-color-3** color of unchecked checkbox, color of border, background color
 * of list.<br/>
 * > **--primary** color of checkboxes.<br/>
 */

var VdtColumnSorter = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-column-sorter"
    }, [_c('draggable', {
      model: {
        value: _vm.sortedColumns,
        callback: function ($$v) {
          _vm.sortedColumns = $$v;
        },
        expression: "sortedColumns"
      }
    }, [_c('transition-group', {
      attrs: {
        "name": "sort"
      }
    }, _vm._l(_vm.sortedColumns, function (column) {
      return _c('span', {
        key: column.key,
        staticClass: "vdt-column-sorter__list-item"
      }, [_c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: column.visible,
          expression: "column.visible"
        }],
        attrs: {
          "id": column.key,
          "type": "checkbox"
        },
        domProps: {
          "value": true,
          "checked": Array.isArray(column.visible) ? _vm._i(column.visible, true) > -1 : column.visible
        },
        on: {
          "change": function ($event) {
            var $$a = column.visible,
                $$el = $event.target,
                $$c = $$el.checked ? true : false;

            if (Array.isArray($$a)) {
              var $$v = true,
                  $$i = _vm._i($$a, $$v);

              if ($$el.checked) {
                $$i < 0 && _vm.$set(column, "visible", $$a.concat([$$v]));
              } else {
                $$i > -1 && _vm.$set(column, "visible", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
              }
            } else {
              _vm.$set(column, "visible", $$c);
            }
          }
        }
      }), _vm._v(" "), _c('label', {
        attrs: {
          "for": column.key
        }
      }, [_c('span', [_vm._v(_vm._s(column.label !== '' ? column.label : column.key))])]), _vm._v(" "), _vm.showSize ? _c('select', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: column.size,
          expression: "column.size"
        }],
        on: {
          "change": function ($event) {
            var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
              return o.selected;
            }).map(function (o) {
              var val = "_value" in o ? o._value : o.value;
              return val;
            });

            _vm.$set(column, "size", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
          }
        }
      }, _vm._l(_vm.sizes, function (size) {
        return _c('option', {
          key: size,
          domProps: {
            "value": size
          }
        }, [_vm._v(" " + _vm._s(size) + " ")]);
      })) : _vm._e()]);
    }))], 1)], 1);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-a6720022',
  name: 'VdtColumnSorter',
  components: {
    draggable
  },
  props: {
    /**
     * @description Array with columns to be displayed sorted.
     */
    columns: {
      type: Array,
      required: true
    },

    /**
     * @description Choose wheter or not to display and change size of columns.
     */
    showSize: {
      type: Boolean,
      required: false,
      default: true
    },

    /**
     * @description Optional array with various columns sizes to choose from.
     */
    sizes: {
      type: Array,
      required: false,
      default: () => ['small', 'medium', 'large']
    }
  },

  data() {
    return {
      sortedColumns: cloneDeep(this.columns)
    };
  },

  watch: {
    sortedColumns: {
      handler(newVal) {
        if (!isEqual(newVal, this.columns)) {
          /**
           * @event updateColumns
           * @description Emits the updated columns.
           * @type {Array}
           */
          this.$emit('updateColumns', newVal);
        }
      },

      deep: true
    },
    columns: {
      handler(newVal) {
        this.sortedColumns = cloneDeep(newVal);
      },

      deep: true
    }
  }
};

const STATUS_INITIAL$5 = 0;
const STATUS_PROGRESS$1 = 1;
const STATUS_SUCCESS$4 = 2;
const STATUS_FAILED$4 = 3;
/**
 * @description Shows an export-prompt that
 * makes an internal request /api/export-location that handles the request.
 *
 * @since 0.9
 *
 * ***
 *
 * ###CSS-variables used:
 *
 * > **--primary** background-color of "Export File" button.<br/>
 * > **--danger** background-color of "Start over" and "Abort" buttons.<br/>
 * > **--success** background-color of "Start over" button.<br/>
 */

var VdtExport = {
  render: function () {
    var _vm = this;

    var _h = _vm.$createElement;

    var _c = _vm._self._c || _h;

    return _c('div', {
      staticClass: "vdt-item-export"
    }, [_c('div', {
      staticClass: "vdt-item-export__form"
    }, [_vm.isInitial ? _c('form', {
      on: {
        "submit": function ($event) {
          $event.preventDefault();
        }
      }
    }, [_c('div', {
      staticClass: "vdt-item-export__form__input"
    }, [_vm.tag && !_vm.exportLocation ? _c('span', [_vm._v(" Choose export location for shape (" + _vm._s(_vm.tag) + "): ")]) : !_vm.tag && !_vm.exportLocation ? _c('span', [_vm._v(" Choose export location for item " + _vm._s(_vm.itemId) + ": ")]) : _vm.tag && _vm.exportLocation ? _c('span', [_vm._v(" Export shape (" + _vm._s(_vm.tag) + ") to \"" + _vm._s(_vm.exportLocation) + "\"")]) : !_vm.tag && _vm.exportLocation ? _c('span', [_vm._v(" Export item (" + _vm._s(_vm.itemId) + ") to \"" + _vm._s(_vm.exportLocation) + "\"")]) : _vm._e(), _vm._v(" "), !_vm.loading && !_vm.exportLocation ? _c('div', {
      staticClass: "vdt-item-export__form__input-container"
    }, [!_vm.exportLocationUrl ? _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedUri,
        expression: "selectedUri"
      }],
      ref: "vdt-item-export-uri",
      attrs: {
        "placeholder": "s3://access:secret@bucket/path/?region=eu-west-1"
      },
      domProps: {
        "value": _vm.selectedUri
      },
      on: {
        "keyup": function ($event) {
          if (!('button' in $event) && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) {
            return null;
          }

          _vm.startExportJob();
        },
        "input": function ($event) {
          if ($event.target.composing) {
            return;
          }

          _vm.selectedUri = $event.target.value;
        }
      }
    }) : _c('select', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedLocation,
        expression: "selectedLocation"
      }],
      on: {
        "change": function ($event) {
          var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
            return o.selected;
          }).map(function (o) {
            var val = "_value" in o ? o._value : o.value;
            return val;
          });
          _vm.selectedLocation = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
        }
      }
    }, [_c('option', {
      attrs: {
        "value": "",
        "disabled": "disabled"
      }
    }, [_vm._v("Choose a location")]), _vm._v(" "), _vm._l(_vm.exportLocations, function (location) {
      return _c('option', {
        key: location.name,
        domProps: {
          "value": location
        }
      }, [_vm._v(" " + _vm._s(location.name) + " ")]);
    })], 2)]) : _vm._e(), _vm._v(" "), _c('button', {
      staticClass: "vdt-button vdt-button--primary",
      attrs: {
        "disabled": _vm.selectedUri === '' && _vm.exportLocations.length > 0 && !_vm.selectedLocation.name
      },
      on: {
        "click": function ($event) {
          _vm.startExportJob();
        }
      }
    }, [_vm._v(" Export ")])])]) : _vm.isStarted ? _c('div', [_c('div', {
      staticClass: "vdt-item-export__progress"
    }, [_c('div', {
      staticClass: "spinner"
    }), _vm._v(" "), _c('span', [_vm._v("In progress")]), _vm._v(" "), _vm.exportStatus !== '' ? _c('pre', [_vm._v("          " + _vm._s(_vm.exportStatus) + " - Step " + _vm._s(_vm.currentStep) + " of " + _vm._s(_vm.totalSteps))]) : _vm._e(), _vm._v(" "), _c('button', {
      staticClass: "vdt-button vdt-button--danger",
      attrs: {
        "disabled": _vm.isFailed
      },
      on: {
        "click": _vm.abortExport
      }
    }, [_vm._v("Abort")])])]) : _vm.isSuccess || _vm.isFailed ? _c('div', {
      staticClass: "vdt-item-export__container"
    }, [_vm.isSuccess ? _c('div', {
      staticClass: "vdt-item-export__container__message"
    }, [!_vm.filename ? _c('span', [_vm._v("Item successfully exported to " + _vm._s(_vm.selectedUri !== '' ? _vm.selectedUri : _vm.selectedLocation.name) + ".")]) : _vm._e(), _vm._v(" "), _c('pre', [_vm._v("Export job ID: " + _vm._s(_vm.job.jobId))]), _vm._v(" "), _c('button', {
      staticClass: "vdt-button vdt-button--success",
      on: {
        "click": function ($event) {
          _vm.reset(false);
        }
      }
    }, [_vm._v(" Start over ")])]) : _vm._e(), _vm._v(" "), _vm.isFailed ? _c('div', {
      staticClass: "vdt-item-export__container__message"
    }, [_c('span', [_vm._v("Export failed")]), _vm._v(" "), _c('pre', [_vm._v(_vm._s(_vm.errorMessage))]), _vm._v(" "), _c('button', {
      staticClass: "vdt-button vdt-button--danger",
      on: {
        "click": function ($event) {
          _vm.reset(false);
        }
      }
    }, [_vm._v(" Start over ")])]) : _vm._e()]) : _vm._e()])]);
  },
  staticRenderFns: [],
  _scopeId: 'data-v-0a13c1e2',
  name: 'VdtExport',
  props: {
    /**
    * @description String of the exact backend url to abort a job.<br/>
    * **:abort-job-url="'/api/job/'"**<br/>
    * Result is a backend call to: **DELETE '/api/job/<str:job_id>'**
    */
    abortJobUrl: {
      type: String,
      required: true
    },

    /**
    * @description The id of the item.
    */
    itemId: {
      type: String,
      required: true
    },

    /**
    * @description The id of the shape that should be exported.
    * Default is to not export the shape by id.
    */
    shapeId: {
      type: String,
      required: false,
      default: null
    },

    /**
    * @description String of the exact backend url to find all the defined export locations.<br/>
    * **:export-location-url="'/api/export-location'"**<br/>
    * Result is a backend call to: **GET '/api/export-location'**
    */
    exportLocationUrl: {
      type: String,
      required: false,
      default: null
    },

    /**
    * @description String of the id of an export-location,
    * with this prop you will hardcode the export-location
    */
    exportLocation: {
      type: String,
      required: false,
      default: null
    },

    /**
    * @description String of the exact backend url to poll the job status.<br/>
    * **:job-status-url="'/api/job/'"**<br/>
    * Result is a backend call to: **GET '/api/job/<str:job_id>'**
    */
    jobStatusUrl: {
      type: String,
      required: true
    },

    /**
    * @description How often the job progress should be polled.
    */
    pollInterval: {
      type: Number,
      default: 2000,
      required: false
    },

    /**
    * @description String of the exact backend url to start the Export.<br/>
    * **:start-export-url="'/api/item-export'"**<br/>
    * Result is a backend call to: **POST '/api/item-export'**<br/>
    * With a body containing all the query-parameters you have provided to VdtExport.
    */
    startExportUrl: {
      type: String,
      required: true
    },

    /**
    * @description The item shape-tag used, a Vidispine query-parameter.
    * Finds a shape with the specified tag and uses that for export.
    * If not specified, the system will attempt to use the original shape.
    */
    tag: {
      type: String,
      required: false,
      default: null
    },

    /**
    * @description Boolean of the Vidispine query-parameter useOriginalFilename.<br/>
    * If set to true, the file(s) will be exported with their original filename, if available.
    */
    useOriginalFilename: {
      type: String,
      required: false,
      default: null
    },

    /**
    * @description Boolean of the Vidispine query-parameter useOriginalComponentFilename.<br/>
    * If set to true, the file(s) will be exported with
    * their original component filename if available.
    */
    useOriginalComponentFilename: {
      type: String,
      required: false,
      default: null
    },

    /**
    * @description Boolean of the Vidispine query-parameter start.<br/>
    * Defines a start time code for the media (optional).
    */
    start: {
      type: String,
      required: false,
      default: null
    },

    /**
    * @description Boolean of the Vidispine query-parameter start.<br/>
    * Defines a start time code for the media (optional).
    */
    end: {
      type: String,
      required: false,
      default: null
    }
  },

  data() {
    return {
      exportLocations: [],
      selectedLocation: {},
      selectedUri: '',
      exportStatus: '',
      currentStep: '',
      errorMessage: '',
      job: {},
      abortInterval: null,
      currentStatus: null,
      pollJob: null,
      loading: false
    };
  },

  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL$5;
    },

    isStarted() {
      return this.currentStatus === STATUS_PROGRESS$1;
    },

    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS$4;
    },

    isFailed() {
      return this.currentStatus === STATUS_FAILED$4;
    }

  },

  mounted() {
    if (this.exportLocationUrl) {
      this.getExportLocations();
    }

    if (this.exportLocation) {
      this.selectedLocation.name = this.exportLocation;
    }

    this.currentStatus = 0;
  },

  destroyed() {
    if (this.pollJob) {
      this.pollJob.stopInterval();
    }

    if (this.abortInterval) {
      this.stopInterval(this.abortInterval);
    }
  },

  methods: {
    reset() {
      // Reset form to initial state
      this.currentStatus = STATUS_INITIAL$5;
      this.abortInterval = null;
      this.pollJob = null;
      this.selectedLocation = {};
      this.selectedUri = '';
      this.exportStatus = '';
      this.currentStep = '';
      this.errorMessage = '';
      this.loading = false;
      this.job = {};
    },

    getExportLocations() {
      this.loading = true;
      axios.get(this.exportLocationUrl).then(response => {
        this.exportLocations = response.data.exportLocation;
        this.loading = false;
      });
    },

    startExportJob() {
      if (this.currentStatus === STATUS_PROGRESS$1) return;
      this.currentStatus = STATUS_PROGRESS$1;
      const data = {
        itemId: this.itemId,
        shapeId: this.shapeId,
        uri: this.selectedUri,
        locationName: this.selectedLocation.name,
        tag: this.tag,
        useOriginalFilename: this.useOriginalFilename,
        useOriginalComponentFilename: this.useOriginalComponentFilename,
        start: this.start,
        end: this.end
      };
      axios.post(this.startExportUrl, data).then(response => {
        this.job = response.data;
        this.startPollJob(this.job.jobId, this.itemId);
      }).catch(() => {
        this.reset();
      });
    },

    startPollJob(jobId, itemId) {
      this.pollJob = new JobPollService({
        jobId,
        milliseconds: this.pollInterval,
        asset: itemId,
        jobStatusUrl: this.jobStatusUrl,
        progressCallback: this.jobPoll,
        successCallback: this.jobSuccess,
        failedCallback: this.jobFailed
      });
      this.pollJob.startInterval();
    },

    jobPoll(job) {
      this.exportStatus = job.status;
      this.currentStep = job.currentStep.number;
      this.totalSteps = job.totalSteps;
    },

    jobSuccess(job) {
      this.currentStatus = STATUS_SUCCESS$4;
      /**
      * @event exportSuccess
      * @description Emits the job when the job is successfull
      * @type {Object} the successfull job
      */

      this.$emit('exportSuccess', job);
    },

    jobFailed(job) {
      this.currentStatus = STATUS_FAILED$4;
      const error = job.data.find(o => o.key === 'errorMessage');

      if (error !== undefined) {
        this.errorMessage = error.value;
      }
      /**
      * @event exportFailed
      * @description Emits the job when the job has failed
      * @type {Object} the failed job
      */


      this.$emit('exportFailed', job);
    },

    stopInterval(interval) {
      clearInterval(interval);
    },

    abortExport() {
      if (!this.abortInterval) {
        this.abortInterval = setInterval(() => {
          if (this.job.jobId) {
            this.stopInterval(this.abortInterval);
            axios.delete(`${this.abortJobUrl}${this.job.jobId}`);
          }
        }, 500);
      }
    }

  }
};

export { VdtBreadcrumbs, VdtFilter, VdtGrid, VdtGridHeader, VdtGridItem, VdtImagePreview, VdtListHeader, VdtListRow, VdtList, VdtLoginPrompt, VdtMetadata, VdtMetadataField, VdtMetadataTimeline, VdtMetadataTimelineEvent, VdtPaging, VdtProgressBar, VdtSearch, VdtShape, VdtShapeExport, VdtVideoPlayer, VidinetCostCard as VdtVidinetCostCard, VdtVidinetQC, VdtCognitive, VdtTranscode, VdtWebupload, VdtBulkActions, VdtRawMetadata, VdtManageMetadata, VdtTag, VdtColumnSorter, VdtDateTimePicker, VdtExport };
