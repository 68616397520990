import axios from 'axios'
import Storage from '../vs-entities/storage'
import ExportLocation from '../vs-entities/exportLocation'

export default {
  getStorages: ({
                  url,
                  size = '-',
                  files = '-',
                  status,
                  usedbytes = '-',
                  freebytes = '-',
                  freeamount = '-',
                  storagegroup,
                }) => axios.get('/api/storages', {
    params: {
      url,
      size,
      files,
      status,
      usedbytes,
      freebytes,
      freeamount,
      storagegroup,
    },
  }).then(({ data = {} }) => {
    const { storage } = data
    return { storages: storage.map(s => new Storage(s)), hits: storage.length }
  }),

  createStorage: data => axios.post('/api/storages', data)
    .then(response => ({
      storage: response.data.storage !== undefined
        ? new Storage(response.data.storage) : [],
    })),

  getExportLocations: () => axios.get('/api/export-location')
    .then(response => ({
      exportLocation: response.data.exportLocation !== undefined
        ? response.data.exportLocation.map(loc => new ExportLocation(loc)) : [],
      hits: response.data.exportLocation !== undefined
        ? response.data.exportLocation.length : 0,
    })),

  createExportLocation: data => axios.post('/api/export-location', data)
    .then(response => ({
      exportLocation: response.data.exportLocation !== undefined
        ? new ExportLocation(response.data.exportLocation) : [],
    })),
}
