/* eslint-disable no-shadow, no-param-reassign */
import groupApi from '../../api/group.api'

// initial state
// shape: [{ id, quantity }]
const state = {
  groups: [],
  hits: 0,
  query: '',
  first: 1,
  number: 100,
  status: null, // success, failed, loading

  group: null,
  addGroups: [],
  addQuery: '',
}

// getters
const getters = {
  groups: state => state.groups,
  hits: state => state.hits,
  status: state => state.status,
  query: state => state.query,
  first: state => state.first,
  number: state => state.number,
  group: state => state.group,
  addGroups: state => state.addGroups,
  addQuery: state => state.addQuery,
}

// actions
const actions = {
  getGroup({ commit }, groupName) {
    commit('SET_STATUS', 'loading')
    return groupApi.getGroup(groupName)
      .then((group) => {
        commit('SET_GROUP', group)
        commit('SET_STATUS', 'success')
      })
      .catch(() => {
        commit('SET_STATUS', 'failed')
      })
  },
  getGroups({ commit }) {
    commit('SET_STATUS', 'loading')
    return groupApi.getGroups(state.query, state.first, state.number, false)
      .then((response) => {
        const newGroups = response.groups
        const newHits = response.hits
        commit('ADD_GROUPS', newGroups)
        commit('SET_HITS', newHits)
        commit('SET_STATUS', 'success')
      })
      .catch(() => {
        commit('SET_STATUS', 'failed')
        commit('SET_HITS', 0)
      })
  },
  getAddGroups({ commit }) {
    return groupApi.getGroups(state.addQuery, 1, 100, false)
      .then(({ groups }) => {
        commit('SET_ADD_GROUPS', groups)
      })
      .catch(() => {
        commit('SET_ADD_GROUPS', [])
      })
  },
  createGroup({ commit, dispatch }, data) {
    commit('SET_STATUS', 'loading')
    return groupApi.createGroup(data)
      .then(() => {
        dispatch('getGroups')
        commit('SET_STATUS', 'success')
      })
      .catch((err) => {
        commit('SET_STATUS', 'failed')
        return err
      })
  },
  deleteGroup({ commit, dispatch }, data) {
    commit('SET_STATUS', 'loading')
    return groupApi.deleteGroup(data)
      .then(() => {
        dispatch('getGroups')
        commit('SET_STATUS', 'success')
      })
      .catch((err) => {
        commit('SET_STATUS', 'failed')
        return err
      })
  },
  addGroupsToGroups({ commit }, payload) {
    commit('SET_STATUS', 'loading')
    return groupApi.addGroupsToGroups(payload)
      .then(() => {
        commit('SET_STATUS', 'success')
      })
      .catch(() => {
        commit('SET_STATUS', 'failed')
        commit('SET_HITS', 0)
      })
  },
  deleteGroupFromGroup({ commit }, payload) {
    commit('SET_STATUS', 'loading')
    return groupApi.deleteGroupFromGroup(payload)
      .then(() => {
        commit('SET_STATUS', 'success')
      })
      .catch(() => {
        commit('SET_STATUS', 'failed')
        commit('SET_HITS', 0)
      })
  },
  addAssets({ commit }, {
    group, assets, permission,
  }) {
    commit('SET_STATUS', 'loading')
    return groupApi.addAssets(group, assets, permission)
      .then(() => {
        commit('SET_STATUS', 'success')
      })
      .catch(() => commit('SET_STATUS', 'failed'))
  },
}

// mutations
const mutations = {
  ADD_GROUPS(state, groups) {
    state.groups = groups
  },
  UNSHIFT_GROUP(state, groups) {
    state.groups = [...groups, ...state.groups]
  },
  SET_HITS(state, hits) {
    state.hits = hits
  },
  SET_QUERY(state, query) {
    state.query = query
  },
  SET_FIRST(state, first) {
    if (first) {
      state.first = first
    }
  },
  SET_NUMBER(state, number) {
    if (number) {
      state.number = number
    }
  },
  SET_STATUS(state, status) {
    state.status = status
  },
  SET_GROUP(state, group) {
    state.group = group
  },
  SET_ADD_GROUPS(state, groups) {
    state.addGroups = groups
  },
  SET_ADD_QUERY(state, query) {
    state.addQuery = query
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
