import axios from 'axios'
import { cloneDeep as _cloneDeep } from 'lodash-es'
import SearchItem from '../vs-entities/searchItem'
import DetailedItem from '../vs-entities/detailedItem'

export default {
  getItems: (query, filters, sort, order, first, number) => {
    const data = {
      query,
      filters,
      sort,
      order,
      first,
      number,
      content: 'metadata',
      field: 'itemId,created,durationSeconds,originalFilename,originalVideoCodec,collection_title,__collection,org_shape_total_size_bytes,TAPI_c_type,master_status,latest_tapi_status,abr_status,sp_item,representativeThumbnailNoAuth,ems_lastEditDate',
      master: true,
    }

    return axios.put('/api/items', data)
      .then(response => ({
        items: response.data.item !== undefined
          ? response.data.item.map(item => new SearchItem(item)) : [],
        hits: response.data.hits,
      }))
  },

  getCollectionItems: (id, filters, sort, order, first, number) => {
    const data = {
      filters,
      sort,
      order,
      first,
      number,
      content: 'metadata',
    }

    return axios.put(`/api/items/collection/${ id }`, data)
      .then(response => ({
        items: response.data.item !== undefined ? response.data.item
          .map(item => new SearchItem(item)) : [],
        hits: response.data.hits,
      }))
  },

  getItem: (itemId, oldItem) => {
    const content = 'metadata,shape,thumbnail,uri'

    return axios.get(`/api/item/${ itemId }?content=${ content }&methodType=AUTO&audioChannels=true`)
      .then((response) => {
        const item = response.data !== undefined ? response.data : {}
        const rawItem = _cloneDeep(item)
        return { item: new DetailedItem(oldItem, item), rawItem }
      })
  },

  setItemMetadataField: (id, field, value) => {
    const data = {
      field,
      value,
      content: 'metadata,shape,thumbnail,uri',
      methodType: 'AUTO',
    }

    return axios.put(`/api/item/${ id }`, data)
      .then(response => ({
        item: response.data !== undefined ? response.data : [],
      }))
  },

  setTAPImetadataField: (id, field, value) => {
    const data = {
      field,
      value,
      content: 'metadata,shape,thumbnail,uri',
      methodType: 'AUTO',
    }

    return axios.put(`/api/item/${ id }/TAPI_metadata`, data)
      .then(response => (
        { item: response.data !== undefined ? response.data : [] }
      ))
  },

  tagAudioChannel: (itemId, payload) => axios.put(`/api/item/${ itemId }/tag_audio_channel`, payload),

  addTrack: data => axios.put(`/api/virtual-track/${ data.id }`, data).then(() => 'success'),

  removeTrack: (itemId, uuid) => axios.delete(`/api/virtual-track/${ uuid }`).then(() => 'success'),

  deleteAudioChannel: (itemId, payload) => {
    const { type, language } = payload
    return axios.delete(`/api/item/${ itemId }/tag_audio_channel?type=${ type }&lang=${ language }`)
  },
  getItemsAutocomplete: (query, fields) => {
    const data = {
      query,
      fields,
    }

    return axios.put('/api/autocomplete/items', data)
      .then(response => ({
        autocomplete: response.data !== undefined
          ? response.data : [],
      }))
  },
  getFacets: (facets, filters = [], master = 'true', number = 1, field = 'durationSeconds', sort = 'durationSeconds', order = 'descending', content = 'metadata') => {
    const data = {
      facets,
      master,
      number,
      field,
      sort,
      order,
      content,
      filters,
    }

    return axios.put('/api/facet', data)
      .then(response => ({
        items: response.data !== undefined
          ? response.data : [],
      }))
  },
}
