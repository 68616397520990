export default class ExportLocation {
  constructor(exportLocation) {
    this.data = exportLocation
    const [host] = this.data.uri.split(':')
    const [name] = this.data.uri.split('@').slice(-1)

    if (host.indexOf('s3') > -1) {
      this.data.uri = `${host}://${name.split('?')[0]}`
    } else {
      this.data.uri = name
    }
  }

  /*
  static getKeys(storage, keyList) {
    const metadata = {};
    for (let i = 0; i < keyList.length; i += 1) {
      metadata.push(keyList[i]);
    }
    return metadata;
  }
  */
}
