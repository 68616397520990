import axios from 'axios'
import SearchCollection from '../vs-entities/searchCollection'
import SearchAsset from '../vs-entities/searchAsset'

export default {
  getCollections: (query, first, number, sort, order, filters) => {
    const data = {
      query,
      first,
      number,
      sort,
      order,
      filters,
      content: 'metadata',
      field: 'collectionId,title,created,TAPI_c_title,TAPI_c_type,TAPI_c_video_id,master_status,latest_tapi_status,abr_status,sp_item,mxdm_delete_lock',
    }

    return axios.put('/api/collections', data)
      .then(response => [{
        collections: response.data.collection !== undefined ? response.data
          .collection.map(collection => new SearchCollection(collection)) : [],
        hits: response.data.hits,
      }][0])
  },

  addCollection: (fields) => {
    const data = {
      fields,
    }

    return axios.put('/api/collection', data)
      .then(response => [{
        message: response,
      }][0])
  },

  addItems: (collections, items) => {
    const data = {
      collections,
      items,
    }

    return axios.put('/api/collections/items', data)
      .then(response => [{
        message: response.data.message,
      }][0])
  },

  getCollection: (id, fields = 'collectionId,title,created,user,TAPI_c_title,TAPI_c_production_year,TAPI_c_type,TAPI_c_powerMAM_id,TAPI_c_cumulus_id,TAPI_c_maxdome_id,TAPI_c_tenant_id,TAPI_c_video_id,TAPI_c_suported_partner,TAPI_c_license_start,mxdm_delete_lock,master_status,latest_tapi_status,abr_status,sp_item') => {
    const data = {
      content: 'metadata',
      field: fields,
    }

    return axios.put(`/api/collection/${ id }`, data)
      .then((response) => {
        const collection = response.data !== undefined ? response.data : {}
        return new SearchCollection(collection)
      })
  },

  getCollectionWithGroups: (id, fields = 'collectionId,title,created,user,TAPI_c_title,TAPI_c_production_year,TAPI_c_type,TAPI_c_powerMAM_id,TAPI_c_cumulus_id,TAPI_c_maxdome_id,TAPI_c_tenant_id,TAPI_c_video_id,TAPI_c_suported_partner,TAPI_c_license_start,mxdm_delete_lock,master_status,latest_tapi_status,abr_status') => {
    const data = {
      content: 'metadata',
      field: fields,
    }

    return axios.put(`/api/collection/${ id }`, data)
      .then((response) => {
        const collection = response.data !== undefined ? response.data : {}
        return new SearchAsset(collection, { includeFieldAttributes: true })
      })
  },

  getItemsAutocomplete: (id, query, fields) => {
    const data = {
      id,
      query,
      fields,
    }

    return axios.put('/api/autocomplete/collection', data)
      .then(response => ({
        autocomplete: response.data !== undefined
          ? response.data : [],
      }))
  },
  getCollectionsAutocomplete: (query, fields) => {
    const data = {
      query,
      fields,
    }

    return axios.put('/api/autocomplete/collections', data)
      .then(response => ({
        autocomplete: response.data !== undefined
          ? response.data : [],
      }))
  },
  setCollectionMetadataField: (id, field, value) => {
    const data = {
      field,
      value,
      content: 'metadata',
      methodType: 'AUTO',
    }

    return axios.put(`/api/collection/${ id }/metadata`, data)
      .then((response) => {
        const collection = response.data !== undefined ? response.data : {}
        return new SearchCollection(collection)
      })
  },
  getFacets: (facets, filters = []) => {
    const data = {
      facets,
      filters,
    }
    return axios.put('/api/facet/collection', data)
      .then(response => ({
        assets: response.data !== undefined
          ? response.data : [],
      }))
  },

  deleteCollection(asset_id) {
    return axios.delete(`/api/special-delete/collection/${ asset_id }/remove-related-entities`)
  },
}
