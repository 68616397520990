export default class Storage {
  constructor(storage) {
    Object.assign(this, storage)
    const { metadata, method } = storage
    const { field = [] } = metadata
    field.forEach(({ key, value }) => { this[key] = value })

    if (method.length) {
      const [{ uri }] = method
      const [host] = uri.split(':')
      const [name] = uri.split('@').slice(-1)
      if (host.indexOf('s3') > -1) {
        this.name = `${host}://${name.split('?')[0]}`
      } else {
        this.name = name
      }
    }
  }
}
