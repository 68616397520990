/* eslint-disable no-shadow, no-param-reassign */
import resourceApi from '../../api/resource.api'

// initial state
const state = {
  resources: [],
  resourceStatus: null, // success, failed, loading
}

// getters
const getters = {
  resources: state => state.resources,
  resourceStatus: state => state.resourceStatus,
}

// actions
const actions = {
  getResources({ commit }, payload) {
    commit('SET_RESOURCE_STATUS', 'LOADING')
    resourceApi.getResources(payload.resourceType)
      .then((response) => {
        const resources = response
        commit('ADD_RESOURCE', resources)
        commit('SET_RESOURCE_STATUS', 'SUCCESS')
      })
      .catch(() => commit('SET_RESOURCE_STATUS', 'FAILED'))
  },
}

// mutations
const mutations = {
  ADD_RESOURCE(state, resources) {
    state.resources = resources
  },

  SET_RESOURCE_STATUS(state, status) {
    state.resourceStatus = status
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
