import axios from 'axios'
import { cloneDeep as _cloneDeep } from 'lodash-es'
import SearchAsset from '../vs-entities/searchAsset'

export default {
  getAssets: ({
                first, number, filters, query, fields, facets, sort, order,
                collectionId, options = {}, queries = [],
              }) => {
    const data = {
      first,
      number,
      content: 'metadata',
      field: fields ? fields.join(',') : '',
      query,
      sort,
      order,
      queries,
      filters: _cloneDeep(filters),
      facets,
    }

    if (collectionId) {
      data.queries.push({
        operator: 'OR',
        queries: [
          {
            field: '__parent_collection',
            query: collectionId,
          },
          {
            field: '__collection',
            query: collectionId,
          },
        ],
      })
    }

    return axios.put('/api/search', data).then(({ data: response }) => {
      const { entry = [], hits = 0, facet = [] } = response
      return {
        assets: entry.map(e => new SearchAsset(e, options)),
        hits,
        facet,
      }
    })
  },
  getFacets: ({ facets, id, filters = [] }) => axios.put('/api/search', {
    first: 1,
    number: 1,
    facets,
    filters,
    sort: 'durationSeconds',
    order: 'descending',
    field: 'durationSeconds',
    queries: id ? [{
      operator: 'OR',
      queries: [
        { field: '__parent_collection', query: id },
        { field: '__collection', query: id },
      ],
    }] : [],
  }).then(({ data = {} }) => {
    const { facet = [], entry = [], hits } = data
    const sortedFacets = facet.map((f) => {
      const { count } = f
      const newCount = count.sort((a, b) => a.fieldValue.localeCompare(b.fieldValue))
      return { count: newCount, ...f }
    })
    let durationFacet = {}
    if (entry.length) {
      const { metadata: { duration = 0 } } = new SearchAsset(entry[0])
      durationFacet = {
        field: 'durationSeconds',
        count: [{ value: duration }],
      }
    }
    return { facets: [...sortedFacets, durationFacet], hits }
  }),
  getCollectionsAssets: ({ collections, fields }) => axios.put('/api/collections/items', {
    collections,
    fields,
  }).then(({ data }) => {
    return data.map((item) => {
      const { metadata: { timespan: [{ field } = {}] } = {} } = item
      const parsedItem = {}
      field.forEach((f) => {
        const { name, value: [{ value }] } = f
        parsedItem[name] = value
      })
      return parsedItem
    })
  }),
  getSupportedPartnersByCollectionId(collectionId) {
    return this.getAssets({
      collectionId,
      queries: [
        {
          operator: 'AND',
          queries: [
            {
              field: 'sp_item',
              query: true,
            },
          ],
        },
      ],
    })
  },
  getItem: (itemId) => {
    return axios.get(`/api/item/${ itemId }?content=metadata`).then(({ data: response }) => {
      return response.metadata.timespan[0]
    })
  },
  getItemMetaDataShape: (itemId) => {
    return axios.get(`/api/item/${itemId}?content=shape,metadata`).then(({data: response}) => {
      return response
    })
  },
  deleteItem: (itemId) => {
    return axios.delete(`/api/item/${ itemId }`).then(({ data: response }) => {
      return response
    })
  },
  updateEntityMetadata: (entity, id, body) => {
    return axios.put(`/api/${ entity }/${ id }/metadata`, body).then(({ data: response }) => {
      return response
    })
  },
  deleteExternalId: (itemId, externalId) => {
    return axios.delete(`/api/item/${ itemId }/external-id/${ externalId }`).then(({ data: response }) => {
      return response
    })
  },
  assignExternalId: (itemId, externalId) => {
    return axios.put(`/api/item/${ itemId }/external-id/${ externalId }`).then(({ data: response }) => {
      return response
    })
  },
}
