/* eslint-disable no-shadow, no-param-reassign, consistent-return */
import assetApi from '../../api/assets.api'
import FilterOrder from '../../components/new/Filters/FilterOrder.array'

const getMergedFilterCount = (filter, storedFilter) => {
  const defaultFilterCounts = filter && filter.count ? filter.count : []
  const storedFilterCounts = storedFilter && storedFilter.count ? storedFilter.count : []
  const storedFilterCountsWithoutDefaultFilterCounts = storedFilterCounts.filter(count => {
    return !filter || !filter.count || !filter.count.find(item => item.fieldValue === count.fieldValue)
  })
  return [
    ...defaultFilterCounts,
    ...storedFilterCountsWithoutDefaultFilterCounts,
  ]
}

const getFilterOrder = (storedFilters) => {
  return FilterOrder.map((filter) => {
    const storedFilter = storedFilters.find((item) => item.field === filter.field)
    const mergedFilterCount = getMergedFilterCount(filter, storedFilter)

    const count = mergedFilterCount.map((field) => {
      if (!storedFilter || !storedFilter.count) {
        return field
      }
      const storedField = storedFilter.count.find((item) => item.fieldValue === field.fieldValue)
      return {
        ...field,
        checked: storedField ? storedField.checked : field.checked,
      }
    })

    return {
      ...filter,
      open: storedFilter ? storedFilter.open : filter.open,
      count,
    }
  })
}

let defaultFilters = [
  'CLIP',
  'PREVIEW',
  'TRAILER',
  'MAIN_VIDEO',
  'FEATURETTE',
  'FULL_EPISODE',
  'BONUS_MATERIAL',
  'DELETED_SCENES',
  'HIGHLIGHTS',
]
let filterOrder

try {
  let localDefaultFilters = []
  const storedFilters = JSON.parse(localStorage.getItem('FILTERS'))
  if (storedFilters === null) throw FilterOrder
  for (let i = 0; i < storedFilters.length; i += 1) {
    const { field } = storedFilters[i]
    try {
      const storedCount = JSON.parse(localStorage.getItem(field))
      if (storedCount === null) throw localDefaultFilters
      const checked = storedCount.filter(({ checked }) => checked)
        .map(({ fieldValue }) => fieldValue)
      localDefaultFilters = localDefaultFilters.concat(checked)
      if (storedCount.length > 0) storedFilters[i].count = storedCount
    } catch (e) {
      localDefaultFilters = e
    }
  }
  filterOrder = getFilterOrder(storedFilters)
  if (localDefaultFilters.length) defaultFilters = localDefaultFilters
} catch (defaultValue) {
  filterOrder = [...defaultValue]
}

const state = {
  status: null,
  asset: null,
  assets: [],
  selectedAssets: [],
  hits: 0,
  first: 1,
  number: 20,
  facets: [],
  filters: filterOrder,
  queries: '',
  sort: null,
  order: null,
  autocompletes: [],
  collectionId: null,
}

const defaultFields = [
  'itemId',
  'collectionId',
  'originalFilename',
  'title',
  'collection_title',
  'TAPI_c_video_id',
  'created',
  'mediaType',
  'originalVideoCodec',
  'master_status',
  'latest_tapi_status',
  'abr_status',
  'TAPI_c_type',
  'TAPI_item_type',
  'durationSeconds',
  'org_shape_total_size_bytes',
  'representativeThumbnailNoAuth',
  'ems_lastEditDate',
  'number_idx',
  'rvs_rejection_reason',
  'tapi_job_id',
  'tapi_status',
  'lo_res_failed',
  'TAPI_c_priority_level',
  'TAPI_c_7tv_id',
  'sp_item',
  '__collection',
  'collection_comment',
  'tapi_supported_partner',
  'sp_status',
]
const defaultFacets = [
  'TAPI_c_type',
  'TAPI_c_tenant_id',
  'TAPI_c_suported_partner',
  'TAPI_item_type',
  'tapi_supported_partner',
  'originalVideoCodec',
  'originalAudioCodec',
  'mimeType',
  'originalFormat',
  'originalHeight',
  'originalWidth',
  'mediaType',
  'shapeTag',
  'master_status',
  'abr_status',
  'latest_tapi_status',
  'lo_res_failed',
  'TAPI_c_priority_level',
  'transcoding_mode',
  'is_blocked',
  'has_second_master_item',
  'safe_to_delete',
  'mxdm_delete_lock',
]
const defaultAutocomplete = [
  'TAPI_c_video_id',
  'itemId',
  'title',
]

// getters
const getters = {
  status: state => state.status,
  asset: state => state.asset,
  assets: state => state.assets,
  selectedAssets: state => state.selectedAssets,
  hits: state => state.hits,
  first: state => state.first,
  number: state => state.number,
  facets: state => state.facets,
  filters: state => state.filters,
  queries: state => state.queries,
  sort: state => state.sort,
  order: state => state.order,
  autocompletes: state => state.autocompletes,
  collectionId: state => state.collectionId,
  defaultFields: () => defaultFields,
}

const getActiveFilters = (filters) => {
  const activeFilters = []
  filters.forEach((filter) => {
    let { count = [] } = filter
    const { field = '', values = {} } = filter
    if (field === 'master_status') {
      const loRes = count.filter(({ fieldValue }) => fieldValue === 'lo_res_failed')
      if (loRes.length) {
        const [{ operator, checked }] = loRes
        if (checked) {
          activeFilters.push({ operator, values: ['true'], field: 'lo_res_failed' })
        }
      }
      count = count.filter(({ fieldValue }) => fieldValue !== 'lo_res_failed')
    }
    const { start = null, end = null } = values
    const OR = count.filter(({ checked, operator }) => checked && operator === 'OR')
    const NOT = count.filter(({ checked, operator }) => checked && operator === 'NOT')
    if (OR.length) {
      activeFilters.push({
        operator: 'OR',
        field,
        values: OR.map(({ fieldValue }) => fieldValue),
      })
    }
    if (NOT.length) {
      activeFilters.push({
        operator: 'NOT',
        field,
        values: NOT.map(({ fieldValue }) => fieldValue),
      })
    }
    if (start && end) {
      activeFilters.push({
        operator: 'OR',
        field,
        range: true,
        values: [{ start, end }],
      })
    }
  })
  return activeFilters
}

// actions
const actions = {
  getAssets(
    { commit },
    {
      first = state.first,
      number = state.number,
      query = state.queries,
      sort = state.sort,
      order = state.order,
      fields = defaultFields,
      facets = defaultFacets,
      filters = getActiveFilters(state.filters),
      append = false,
      setState = true,
    }
  ) {
    if (setState) commit('SET_STATUS', 'loading')
    const activeFilters = getActiveFilters(state.filters)
    return assetApi.getAssets({
      first, number, filters, query, fields, facets, sort, order,
    }).then(async ({ assets, hits, facet }) => {
      if (!setState) return { assets, hits, facets }
      if (append) {
        commit('ADD_ASSETS', assets)
      } else {
        commit('SET_ASSETS', assets)
      }
      commit('SET_HITS', hits)
      commit('SET_STATUS', 'success')
      if (!activeFilters.length && !state.queries.length) {
        facet = await assetApi.getFacets({ facets }).then(({ facets: f }) => f)
      }
      commit('SET_FACETS', { facet })
    }).catch(() => {
      commit('SET_ASSETS', [])
      commit('SET_HITS', 0)
      commit('SET_FACETS', [])
      commit('SET_STATUS', 'failed')
    })
  },
  getCollectionAssets(
    { commit },
    {
      first = state.first,
      number = state.number,
      query = state.queries,
      sort = state.sort,
      order = state.order,
      fields = defaultFields,
      facets = defaultFacets,
      filters = getActiveFilters(state.filters),
      collectionId = state.collectionId,
      append = false,
      setState = true,
    }
  ) {
    if (setState) commit('SET_STATUS', 'loading')
    return assetApi.getAssets({
      first, number, filters, query, fields, facets, sort, order, collectionId,
    }).then(async ({ assets, hits, facet }) => {
      if (!setState) return { assets, hits, facets }
      if (append) { commit('ADD_ASSETS', assets) } else {
        commit('SET_ASSETS', assets)
      }
      commit('SET_HITS', hits)
      commit('SET_STATUS', 'success')
      if (!filters.length && !state.queries.length) {
        const { collectionId } = state
        facet = await assetApi.getFacets({ facets, collectionId }).then(({ facets: f }) => f)
      }
      commit('SET_FACETS', { facet })
    }).catch(() => {
      commit('SET_ASSETS', [])
      commit('SET_HITS', 0)
      commit('SET_FACETS', [])
      commit('SET_STATUS', 'failed')
    })
  },
  getAggregatedStatus({ commit }) {
    const mediaObjectList = []
    state.assets.forEach(({ metadata }) => {
      if (metadata && defaultFilters.includes(metadata.type)) {
        mediaObjectList.push(metadata.id)
      }
    })
    if (mediaObjectList.length) {
      const data = {
        collections: mediaObjectList,
        fields: '__collection,master_status,latest_tapi_status,abr_status,lo_res_failed',
      }
      return assetApi.getCollectionsAssets(data).then((response) => {
        commit('SET_AGGREGATED_STATUS', response)
        commit('SET_STATUS', 'success')
      }).catch(() => {
        commit('SET_STATUS', 'failed')
      })
    }
    commit('SET_STATUS', 'success')
  },
  getAutocomplete(
    { commit },
    { query, fields = defaultAutocomplete }
  ) {
    commit('SET_STATUS', 'loading')
    return assetApi.getAutocomplete(query, fields, state.collectionId)
      .then(({ autocomplete }) => {
        commit('SET_AUTOCOMPLETE', autocomplete)
        commit('SET_STATUS', 'success')
      }).catch(() => {
        commit('SET_STATUS', 'failed')
      })
  },
  getFacets({ commit }, { facets = defaultFacets, collectionId, reset = false }) {
    return assetApi.getFacets({ facets, collectionId })
      .then(({ facets: facet }) => {
        commit('SET_FACETS', { facet, reset })
        return 'success'
      }).catch(() => {
        commit('SET_FACETS', [])
        return 'failed'
      })
  },
  setFilters({ commit }, filters = []) {
    commit('SET_FILTERS', filters)
  },
}

// mutations
const mutations = {
  SET_ASSETS(state, assets) {
    state.assets = assets
  },
  ADD_ASSETS(state, assets) {
    state.assets = [...state.assets, ...assets]
  },
  SET_SELECTED_ASSETS(state, assets) {
    state.selectedAssets = assets
  },
  SET_HITS(state, hits) {
    state.hits = hits
  },
  SET_FIRST(state, first) {
    state.first = first
  },
  SET_NUMBER(state, number) {
    state.number = number
  },
  SET_STATUS(state, status) {
    state.status = status
  },
  SET_FACETS(state, { facet, reset }) {
    for (let x = 0; x < state.filters.length; x += 1) {
      const { field, count: oldFacets = [] } = state.filters[x]
      if (facet) {
        const xIndex = facet.findIndex(({ field: f }) => f === field)
        if (xIndex > -1) {
          const newCount = [...oldFacets]
          const { count = [] } = facet[xIndex]
          for (let y = 0; y < count.length; y += 1) {
            const { value, fieldValue } = count[y]
            const yIndex = newCount.findIndex(({ fieldValue: v }) => v === fieldValue)
            const checked = defaultFilters.indexOf(fieldValue) > -1
            if (yIndex > -1) {
              const oldVal = oldFacets[yIndex]
              if (reset) {
                newCount.splice(yIndex, 1, {
                  ...oldVal, value, checked, fieldValue,
                })
              } else {
                newCount.splice(yIndex, 1, { ...oldVal, value, fieldValue })
              }
            } else if (value > 0) {
              newCount.push({
                checked, operator: 'OR', value, fieldValue,
              })
            }
          }
          state.filters[x].count = newCount
        }
        if (field === 'master_status') {
          let value = 0
          const loResIndex = facet.findIndex(({ field }) => field === 'lo_res_failed')
          if (loResIndex > -1) {
            const { count = [] } = facet[loResIndex]
            const failedIndex = count.findIndex(({ fieldValue }) => fieldValue === 'true')
            if (failedIndex > -1) {
              ({ value = 0 } = count[failedIndex])
            }
          }
          const y = oldFacets.findIndex(({ fieldValue }) => fieldValue === 'lo_res_failed')
          state.filters[x].count[y].value = value
        }
      }
    }
    state.facets = facet
  },
  SET_AUTOCOMPLETE(state, autocompletes) {
    state.autocompletes = autocompletes
  },
  SET_QUERIES(state, queries) {
    state.queries = queries
  },
  SET_FILTERS(state, filter) {
    const {
      field, count, values, open,
    } = filter
    const index = state.filters.findIndex(({ field: f }) => f === field)
    state.filters[index].open = open
    if (count) { state.filters[index].count = count }
    if (values) { state.filters[index].values = values }
  },
  CLEAR_FILTERS(state) {
    // TODO; create new 'RESET_FILTERS' and use 'saved' filters (EMS-2873)
    for (let i = 0; i < state.filters.length; i += 1) {
      const { count = [], values } = state.filters[i]
      for (let j = 0; j < count.length; j += 1) {
        count[j].checked = false
        count[j].operator = 'OR'
      }
      if (values) { values.start = null; values.end = null }
    }
  },
  SET_SORTING(state, sorting) {
    const { sort, order } = sorting
    if (!order) {
      state.sort = null
      state.order = null
    } else {
      state.sort = sort
      state.order = order
    }
  },
  SET_ASSET(state, asset) {
    state.asset = asset
  },
  SET_COLLECTION(state, collectionId) {
    state.collectionId = collectionId
  },
  SET_AGGREGATED_STATUS(state, itemList) {
    itemList.forEach(((item) => {
      const {
        __collection: collectionId = '',
        master_status: masterStatus = '',
        latest_tapi_status: tapiStatus = '',
        abr_status: abrStatus = '',
      } = item
      const parent = state.assets.find(asset => asset.metadata.id === collectionId)
      const { metadata = {} } = parent
      const status = [masterStatus, tapiStatus, abrStatus].join(',').toLowerCase()
      if (metadata.status === 'ERROR' || status.includes('error')) {
        metadata.status = 'ERROR'
      } else if (status.includes('reject')) {
        metadata.status = 'REJECTED'
      } else if (status.includes('queued') || status.includes('tanscode_queued')) {
        metadata.status = 'QUEUED'
      } else if (status.includes('auto_ingesting') || status.includes('tapi_ingesting')) {
        metadata.status = 'INGESTING'
      } else if (status.includes('uploading')) {
        metadata.status = 'UPLOADING'
      }
    }))
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
