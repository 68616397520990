/* eslint-disable no-shadow, no-param-reassign */
import axios from 'axios'
import router from '../../router'

// initial state
const state = {
  environment: null,
  tag: null,
  apUrl: '',
  tenantMap: {},
}

// getters
const getters = {
  environment: state => state.environment,
  tag: state => state.tag,
  apUrl: state => state.apUrl,
  tenantMap: state => state.tenantMap,
}

// actions
const actions = {
  logOut() {
    // deletes session and refreshes current page to show login screen
    axios.delete('/api/version').then(() => router.go())
  },
  getEnvironmentInfo({ commit }) {
    axios.get('/api/system/frontend-env-flag').then(({ data = {} }) => {
      const { FrontEndEnvFlag = '', build_tag: tag = '' } = data
      commit('SET_ENVIRONMENT', FrontEndEnvFlag)
      commit('SET_TAG', tag)
    }).catch(() => {
      commit('SET_ENVIRONMENT', null)
      commit('SET_TAG', null)
    })
  },
  getApUrl({ commit }) {
    axios.get('/api/system/ap_url').then(({ data }) => {
      commit('SET_APURL', data)
    }).catch((error) => {
      commit('SET_APURL', error)
    })
  },
  getTenantMap({ commit }) {
    return axios.get('/api/tenant-map').then(({ data }) => {
      const { timespan: [{ field: [{ value: [{ value = '' }] }] }] } = data
      const tenantMap = JSON.parse(value)
      commit('SET_TENANT_MAP', tenantMap)
      return tenantMap
    })
  },
}

// mutations
const mutations = {
  SET_ENVIRONMENT(state, env) {
    state.environment = env
  },
  SET_TAG(state, tag) {
    state.tag = tag
  },
  SET_APURL(state, url) {
    state.apUrl = url
  },
  SET_TENANT_MAP(state, tenants) {
    state.tenantMap = tenants
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
