import { DateTime, Duration } from 'luxon'

export default class Formater {
  static formatMinutes(timeBase = 0) {
    const unit = 'min'
    return `${(timeBase / 60).toFixed(0)} ${unit}`
  }
  // static formatDuration(milliseconds) {
  //   return Duration.fromObject({ milliseconds }).toFormat('hh:mm:ss:SSS');
  // }
  static formatDuration(value) {
    if (typeof value === 'string') {
      return Duration.fromObject({ milliseconds: value }).toFormat('hh:mm:ss:SSS')
    }
    const { seconds, milliseconds } = value
    if (seconds !== undefined) {
      if (Number.isNaN(seconds)) throw new Error('Value is not seconds')
      return Duration.fromObject({ seconds }).toFormat('hh:mm:ss:SSS')
    } else if (milliseconds !== undefined) {
      if (Number.isNaN(milliseconds)) throw new Error('Value is not milliseconds')
      return Duration.fromObject({ milliseconds }).toFormat('hh:mm:ss:SSS')
    }
    throw new Error('Supply either seconds or milliseconds')
  }
  static formatSize(bytes = 0) {
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
    if (i <= 1) return `${(bytes / (1024 ** 2)).toFixed(2)} MB`
    return `${(bytes / (1024 ** i)).toFixed(2)} ${units[i]}`
  }
  static formatBytes({ unit = 'GB', value = 0 }) {
    let bytes = value
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    const unitIndex = units.indexOf(unit)
    if (unitIndex === -1) throw new Error('Supplied unit is not valid')
    for (let i = unitIndex; i > 0; i -= 1) bytes *= 1024
    return bytes
  }
  static formatBitrate(bits = 0) {
    const units = ['Kb/s', 'Mb/s']
    const i = parseInt(Math.floor(Math.log(bits) / Math.log(10240000)), 10)
    return `${(bits / (1000 ** (i + 1))).toFixed(0)} ${units[i]}`
  }
  static formatFps(frames = 0) {
    if (typeof frames === 'number') {
      return `${frames.toFixed(0)} FPS`
    }
    return `${Number(frames).toFixed(0)} FPS`
  }
  static formatDate({ iso, format = 'DATETIME_SHORT' }) {
    const oldDate = new Date(iso)
    const offset = oldDate.getTimezoneOffset()
    let newDate = DateTime.fromJSDate(oldDate)
    newDate = newDate.minus(offset)
    return newDate.toLocaleString(DateTime[format])
  }
  static formatSamplingrate(bits = 0) {
    if (typeof bits === 'number') {
      return `${bits.toFixed(0)} Hz`
    }
    return `${Number(bits).toFixed(0)} Hz`
  }
}
