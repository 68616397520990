export default class Job {
  static getKeys(job, keyList) {
    const metadata = {}
    Object.keys(keyList).forEach((key) => {
      if (keyList[key]) {
        metadata[keyList[key]] = job[key]
      }
    })
    if (job.data) {
      job.data.forEach((field) => {
        if (field.key === 'itemId') {
          metadata[field.key] = field.value
        }
      })
    }
    return metadata
  }

  /*
  static getMetadataKeys(job, keyList) {
    const metadata = {};
    for (let i = 0; i < keyList.length; i += 1) {
      metadata.push(keyList[i]);
    }
    return metadata;
  }
  */
}
