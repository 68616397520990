import { isEmpty as _isEmpty } from 'lodash-es'
import Item from './item'
import SearchItem from './searchItem'
import Shape from './shapes'
import Formater from '../resources/formater'
// import Timespan from '../vs-entities/timespan';

export default class DetailedItem extends SearchItem {
  constructor(searchItem, item) {
    if (searchItem instanceof SearchItem) {
      super(searchItem)
    } else {
      super(item)
    }
    const flatTimespan = Item.getTimespan(item.metadata, '-INF', '+INF')
    const flatGroups = Item.getGroups(item.metadata)

    // Custom implementation for a metadata field called 'TAPI_item_metadata' in Vidispine
    // It will be a JSON blob of maxdome TAPI metadata
    // Fetch it from Vidispine and set as keyname TAPI on this object
    this.keyList = Object.assign(
      this.keyList,
      {
        originalFormat: 'videoFormat',
        originalAudioCodec: 'audioCodec',
        originalFilename: 'filename',
        TAPI_item_metadata: 'TAPI',
        tenant_id: 'tenantId',
        audioChannels: 'audioChannels',
        master_item: 'masterItem',
        __metadata_last_modified: 'lastEdited',
        master_status: 'masterStatus',
        latest_tapi_status: 'tapiStatus',
        abr_status: 'abrStatus',
        sp_item: 'spItem',
        TAPI_c_type: 'classification',
        TAPI_item_type: 'classification',
        originalUri: 'originalUri',
        TAPI_c_priority_level: 'tapiPriority',
        collection_title: 'collectionName',
        TAPI_c_video_id: 'videoId',
        ems_lastEditDate: 'lastEditDate',
        ems_lastEditUser: 'lastEditUser',
        abr_validation_error: 'abrValidationError',
        override_abr_validation: 'overrideAbrValidation',
        rvs_rejection_reason: 'rejectionReason',
        lo_res_failed: 'loResFailed',
        mxdm_delete_lock: 'permanent',
      }
    )

    this.metadata = Object.assign(this.metadata, Item.getFields(flatTimespan, this.keyList))

    if (this.metadata.TAPI) {
      // If we have a metadata value for TAPI_item_metadata
      // parse TAPI from a JSON string to a javascript object
      // and set it on ourselves
      this.metadata.TAPI = JSON.parse(this.metadata.TAPI)

      if (this.metadata.TAPI.v_duration) {
        this.metadata.TAPI.v_duration = new Date(this.metadata.TAPI.v_duration * 1)
          .toISOString().substr(11, 12)
      }
      if (this.metadata.TAPI.a_duration) {
        this.metadata.TAPI.a_duration = new Date(this.metadata.TAPI.a_duration * 1)
          .toISOString().substr(11, 12)
      }
      if (this.metadata.TAPI.tags) {
        const outputMarkings = []
        Object.keys(this.metadata.TAPI.tags.audio.qtags).forEach((tag) => {
          if (!_isEmpty(this.metadata.TAPI.tags.audio.qtags[tag])) {
            const newTag = []
            newTag.push(
              tag.toString().toUpperCase(),
              `(${Object.keys(this.metadata.TAPI.tags.audio.qtags[tag]).join(', ')})`
            )
            outputMarkings.push(newTag.join(' '))
          }
        })
        Object.keys(this.metadata.TAPI.tags.video).forEach((tag) => {
          outputMarkings.push(this.metadata.TAPI.tags.video[tag])
        })
        this.metadata.TAPI.formatedTags = outputMarkings.join(' | ')
      }
      this.metadata.supportedPartner = '7TV'
      if (this.metadata.TAPI.a_fileSize) {
        this.metadata.TAPI.a_fileSize = Formater.formatSize(this.metadata.TAPI.a_fileSize)
      }
      if (this.metadata.TAPI.v_fileSize) {
        this.metadata.TAPI.v_fileSize = Formater.formatSize(this.metadata.TAPI.v_fileSize)
      }
      if (this.metadata.TAPI.a_bitrate) {
        // eslint-disable-next-line camelcase
        const { a_bitrate = '0' } = this.metadata.TAPI
        const bitrateValue = Number(a_bitrate.toString().split('/')[0])
        this.metadata.TAPI.a_bitrate = Formater.formatBitrate(bitrateValue)
      }
      if (this.metadata.TAPI.v_bitRate) {
        // eslint-disable-next-line camelcase
        const { v_bitRate = '0' } = this.metadata.TAPI
        const bitrateValue = Number(v_bitRate.toString().split('/')[0])
        this.metadata.TAPI.v_bitRate = Formater.formatBitrate(bitrateValue)
      }
      if (this.metadata.TAPI.a_samplingRate) {
        this.metadata.TAPI.a_samplingRate += ' Hz'
      }
      if (this.metadata.TAPI.v_frameRate) {
        this.metadata.TAPI.v_frameRate = Formater.formatFps(this.metadata.TAPI.v_frameRate)
      }
      if (this.metadata.TAPI.v_bitDepth) {
        this.metadata.TAPI.v_bitDepth += ' Bits'
      }
    }

    if (flatGroups.length) {
      const trackContentMappingKeyList = {
        vt_trackName: 'name',
        vt_isOriginal: 'original',
        vt_is51: 'isSurround',
        vt_language: 'language',
      }

      const mappingKeyList = {
        vt_itemId: 'itemId',
        vt_shapeId: 'shapeId',
        vt_componentId: 'componentId',
        vt_channelPosition: 'channelPosition',
        vt_sourceTrackNumber: 'sourceTrackNumber',
        vt_sourceChannelNumber: 'sourceChannelNumber',
      }

      const virtualTracks = flatGroups.find(grp => grp.name === 'vt_virtualTracks')
      if (virtualTracks) {
        const tracks = []
        const { group: trackContentMappings = [] } = virtualTracks

        trackContentMappings.forEach((trackContentMapping) => {
          const { uuid } = trackContentMapping
          const trackMetadata = Item.getFields(trackContentMapping, trackContentMappingKeyList)

          Object.entries(trackMetadata).forEach(([key, value]) => {
            if (['false', 'true'].indexOf(value) > -1) {
              trackMetadata[key] = value === 'true'
            }
          })

          const trackNumbers = []
          const channelNumbers = []
          const { group: mappings = [] } = trackContentMapping
          let componentId = ''
          mappings.forEach((mapping) => {
            const mappingMetadata = Item.getFields(mapping, mappingKeyList)
            trackNumbers.push(mappingMetadata.sourceTrackNumber)
            channelNumbers.push(mappingMetadata.sourceChannelNumber);
            ({ componentId } = mappingMetadata)
          })

          const track = {
            uuid,
            ...trackMetadata,
            componentId,
            trackNumbers,
            channelNumbers,
          }
          tracks.push(track)
        })

        this.virtualTracks = tracks
      }
    }

    this.shapes = Shape.getAllShapes(item.shape)
    this.files = item.files.uri
    this.timespans = Item.getAllTimespans(item.metadata, '-INF', '+INF')

    const { thumbnails = {} } = item
    const { uri = [] } = thumbnails
    this.thumbnails = []
    for (let i = 0; i < uri.length; i += 1) {
      const uriPath = uri[i].split('/APInoauth')[1]
      this.thumbnails.push(`/apinoauth${uriPath}`)
    }
    const { masterStatus = '', tapiStatus = '', abrStatus = '' } = this.metadata
    const aggregatedStatus = [masterStatus, tapiStatus, abrStatus].join(',').toLowerCase()
    if (aggregatedStatus.includes('reject')) {
      this.metadata.status = 'REJECTED'
    } else if (aggregatedStatus.includes('error')) {
      this.metadata.status = 'ERROR'
    } else {
      this.metadata.status = 'OK'
    }
  }
}
