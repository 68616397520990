import axios from 'axios'

export default {
  getGroup: groupName => axios.get(`/api/user_group/${ groupName }`).then(({ data }) => data),

  getGroups: (query, first = 1, number = 10, roles) => {
    const data = {
      query,
      first,
      number,
      roles,
    }

    return axios.put('/api/user_groups', data)
      .then(response => ({
        groups: response.data.group !== undefined
          ? response.data.group : [],
        hits: response.data.hits,
      }))
  },

  createGroup: groupName => axios.put(`/api/user_group/${ groupName }`)
    .then(response => ({
      group: response.data.group !== undefined
        ? response.data.group : [],
    })),

  deleteGroup: data => axios.post('/api/user_group/manage', data),

  addGroupsToGroups: data => axios.put('/api/add_user_groups_to_groups', data)
    .then(response => ({
      groups: response.data.user !== undefined
        ? response.data.user : [],
      hits: response.data.hits,
    })),

  deleteGroupFromGroup: data => axios.delete(`/api/user_group/${ data.group.groupName }`
    + `/group/${ data.parentGroup.groupName }`)
    .then(response => ({
      groups: response.data.user !== undefined
        ? response.data.user : [],
      hits: response.data.hits,
    })),

  addAssets: (groupName, assets, permission) => {
    const data = {
      assets,
      permission,
    }
    return axios.put(`/api/user_group/${ groupName }/add_assets`, data)
      .then(response => ({
        groups: response.data.group !== undefined
          ? response.data.group : [],
      }))
  },
}
