import axios from 'axios'
import { DateTime } from 'luxon'
import SearchJob from '../vs-entities/searchJob'
import DetailedJob from '../vs-entities/detailedJob'

export default {
  getJob: ({
             jobId,
             metadata = true,
           }) => axios.get(`/api/job/${ jobId }?metadata=${ metadata }`)
    .then(({ data = {} }) => ({ job: new DetailedJob(data) })),
  getJobs: ({
              sort,
              type = 'all',
              user = false,
              first = 1,
              field,
              state = 'all',
              number = 5,
              idonly = false,
              priority = 'all',
              metadata = true,
              jobmetadata,
              starttimeTo,
              starttimeFrom,
              finishtimeTo,
              finishtimeFrom,
            }) => {
    const params = {
      sort,
      type,
      user,
      first,
      field,
      state,
      number,
      idonly,
      priority,
      metadata,
    }
    if (starttimeTo) {
      params.starttimeTo = DateTime.fromISO(starttimeTo)
        .toFormat('yyyy-MM-dd')
    }
    if (starttimeFrom) {
      params.starttimeFrom = DateTime.fromISO(starttimeFrom)
        .toFormat('yyyy-MM-dd')
    }

    if (finishtimeTo) {
      params.finishtimeTo = DateTime.fromISO(finishtimeTo)
        .toFormat('yyyy-MM-dd')
    }
    if (finishtimeFrom) {
      params.finishtimeFrom = DateTime.fromISO(finishtimeFrom)
        .toFormat('yyyy-MM-dd')
    }

    if (jobmetadata) {
      params.jobmetadata = jobmetadata
    }

    return axios.get('/api/job', {
      params,
    }).then(({ data = {} }) => {
      const { job = [], hits = 0 } = data
      return { jobs: job.map(j => new SearchJob(j)), hits }
    })
  },
  getJobTypes: () => axios.get('/api/jobtype').then(({ data }) => JSON.parse(data).uri),
  reRunJob: (jobId) => axios.post(`/api/job/${ jobId }/duplicate`).then(({ data }) => data),
}