/* eslint-disable no-shadow, no-param-reassign */

// initial state
const state = {
  showAlert: 0, // boolean or Number of seconds to show the alert
  messageAlert: '',
  messageVariant: 'danger',
}

// getters
const getters = {
  showAlert: state => state.showAlert,
  messageAlert: state => state.messageAlert,
  messageVariant: state => state.messageVariant,
}

// actions
const actions = {
  setAlert({ commit }, payload) {
    commit('SHOW_ALERT', payload.showAlert)
    commit('ALERT_RESPONSE', payload.messageAlert)
    commit('MESSAGE_VARIANT', payload.messageVariant)
  },
}

// mutations
const mutations = {
  SHOW_ALERT(state, showAlert) {
    state.showAlert = showAlert
  },

  ALERT_RESPONSE(state, messageAlert) {
    state.messageAlert = messageAlert
  },

  MESSAGE_VARIANT(state, messageVariant) {
    state.messageVariant = messageVariant
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
