/* eslint-disable no-shadow, no-param-reassign */
import libraryApi from '../../api/library.api'

// initial state
const state = {
  libraries: [],
  libraryHits: 0,
  librariesStatus: null, // success, failed, loading
}

// getters
const getters = {
  libraries: state => state.libraries,
  libraryHits: state => state.libraryHits,
  librariesStatus: state => state.libraries,
}

// actions
const actions = {
  getLibraries({ commit }, payload) {
    commit('SET_JOB_STATUS', 'loading')
    libraryApi.getLibraries(payload.query, payload.first, payload.number)
      .then((response) => {
        const newLibraries = response.libraries
        const newHits = response.hits
        if (payload.append) {
          commit('APPEND_JOBS', newLibraries)
          commit('APPEND_HITS', newHits)
        } else {
          commit('ADD_JOBS', newLibraries)
          commit('ADD_HITS', newHits)
        }
        commit('SET_JOB_STATUS', 'success')
      })
      .catch(() => commit('SET_JOB_STATUS', 'failed'))
  },
}

// mutations
const mutations = {
  ADD_JOBS(state, libraries) {
    state.libraries = libraries
  },

  APPEND_JOBS(state, libraries) {
    state.libraries = [...state.libraries, ...libraries]
  },

  APPEND_HITS(state, libraryHits) {
    state.libraryHits = [...state.libraryHits, ...libraryHits]
  },

  ADD_HITS(state, libraryHits) {
    state.libraryHits = libraryHits
  },

  // SET_DETAILED_JOB(state, { library }) {
  //   state.library = library;
  // },

  SET_JOB_STATUS(state, { status }) {
    state.librariesStatus = status
  },

}

export default {
  state,
  getters,
  actions,
  mutations,
}
