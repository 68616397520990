/* eslint-disable no-shadow, no-param-reassign */
import Vue from 'vue'
import collectionApi from '../../api/collection.api'
import newCollectionApi from '../../api/newCollection.api'
import filterHelper from '../../resources/filterHelper'

// initial state
const state = {
  collections: [],
  hits: 0,
  query: '',
  filters: [],
  activeFilters: [],
  facets: false,
  sort: null,
  order: null,
  first: 1,
  number: 100,
  collection: null,
  collectionId: '',
  status: null,
}
const defaultFields = [
  'collectionId',
  'TAPI_c_video_id',
  'created',
  'title',
  'master_status',
  'latest_tapi_status',
  'abr_status',
  'TAPI_c_type',
  'TAPI_c_tenant_id',
  'TAPI_c_priority_level',
  'TAPI_c_7tv_id',
  'TAPI_c_production_year',
  'TAPI_c_suported_partner',
  '__ancestor_collection',
  'ems_lastEditDate',
  'number_idx',
  'rvs_rejection_reason',
  'lo_res_failed',
  'is_blocked',
  'has_second_master_item',
  'failure_reason',
  'mxdm_delete_lock',
]

// getters
const getters = {
  collections: state => state.collections,
  hits: state => state.hits,
  status: state => state.status,
  query: state => state.query,
  filters: state => state.filters,
  activeFilters: state => state.activeFilters,
  facets: state => state.facets,
  sort: state => state.sort,
  order: state => state.order,
  first: state => state.first,
  number: state => state.number,
  collection: state => state.collection,
  collectionId: state => state.collectionId,
}

// actions
const actions = {
  getCollections({ commit }) {
    commit('SET_COLLECTION_STATUS', 'loading')
    collectionApi.getCollections(
      state.query, state.first, state.number,
      state.sort, state.order, state.activeFilters
    )
      .then((response) => {
        const newCollections = response.collections
        const newHits = response.hits
        commit('ADD_COLLECTIONS', newCollections)
        commit('ADD_HITS', newHits)
        commit('SET_COLLECTION_STATUS', 'success')
      })
      .catch(() => commit('SET_COLLECTION_STATUS', 'failed'))
  },
  getNewCollections({ commit }, { fields }) {
    commit('SET_STATUS', 'loading')
    newCollectionApi.getCollections(state.first, state.number, fields)
      .then(({ collections, hits }) => {
        commit('ADD_COLLECTIONS', collections)
        commit('SET_HITS', hits)
        commit('SET_STATUS', 'success')
      })
      .catch(() => {
        commit('ADD_COLLECTIONS', [])
        commit('SET_STATUS', 'failed')
        commit('SET_HITS', 0)
      })
  },
  getNewCollection(
    { commit, dispatch },
    { collectionId, preview = false, fields = defaultFields }
  ) {
    commit('SET_COLLECTION_STATUS', 'loading')
    return newCollectionApi.getCollection(collectionId, state.first, state.number, fields)
      .then(({ collection }) => {
        commit('SET_COLLECTION', collection)
        if (preview) { commit('assets/SET_ASSET', collection, { root: true }) }
        commit('SET_COLLECTION_STATUS', 'success')
        const { metadata = {} } = collection
        const { id = '' } = metadata
        dispatch('breadcrumb/getBreadcrumbs', id, { root: true })
      })
      .catch(() => {
        commit('SET_COLLECTION_STATUS', 'failed')
      })
  },
  addItems({ commit }, {
    collections, items,
  }) {
    commit('SET_COLLECTION_STATUS', 'loading')
    return collectionApi.addItems(collections, items)
      .then((response) => {
        commit('SET_COLLECTION_STATUS', 'success')
        return response
      })
      .catch(() => commit('SET_COLLECTION_STATUS', 'failed'))
  },
  getCollection({ commit }) {
    commit('SET_COLLECTION_STATUS', 'loading')
    return collectionApi.getCollection(state.collectionId)
      .then((response) => {
        const newCollection = response
        commit('SET_COLLECTION', newCollection)
        commit('SET_COLLECTION_STATUS', 'success')
      })
      .catch(() => {
        commit('SET_COLLECTION_STATUS', 'failed')
      })
  },
  getItemsAutocomplete({
    commit,
  }, {
    id, query, fields,
  }) {
    commit('SET_COLLECTION_STATUS', 'loading')
    return collectionApi.getItemsAutocomplete(id, query, fields)
      .then((response) => {
        commit('SET_COLLECTION_STATUS', 'success')
        return response
      })
      .catch(() => {
        commit('SET_COLLECTION_STATUS', 'failed')
      })
  },
  getAssetsAutocomplete({
    commit,
  }, {
    id, query, fields,
  }) {
    commit('SET_COLLECTION_STATUS', 'loading')
    return collectionApi.getAssetsAutocomplete(id, query, fields)
      .then((response) => {
        commit('SET_COLLECTION_STATUS', 'success')
        return response
      })
      .catch(() => {
        commit('SET_COLLECTION_STATUS', 'failed')
      })
  },
  getCollectionsAutocomplete({
    commit,
  }, {
    query, fields,
  }) {
    commit('SET_COLLECTION_STATUS', 'loading')
    return collectionApi.getCollectionsAutocomplete(query, fields)
      .then((response) => {
        commit('SET_COLLECTION_STATUS', 'success')
        return response
      })
      .catch(() => {
        commit('SET_COLLECTION_STATUS', 'failed')
      })
  },
  setCollectionMetadataField({
    commit,
  }, {
    id, vsField, value,
  }) {
    return collectionApi.setCollectionMetadataField(id, vsField, value)
      .then((response) => {
        const newCollection = response
        commit('SET_COLLECTION', newCollection)
        return newCollection
      })
      .catch(() => 'failed')
  },
  getFacets({
    commit,
  }, {
    facets,
  }) {
    commit('SET_COLLECTION_STATUS', 'loading')
    return collectionApi.getFacets(facets)
      .then((response) => {
        commit('SET_COLLECTION_STATUS', 'success')
        return response
      })
      .catch(() => {
        commit('SET_COLLECTION_STATUS', 'failed')
      })
  },
}

// mutations
const mutations = {
  ADD_COLLECTIONS(state, collections) {
    state.collections = collections
  },
  APPEND_COLLECTIONS(state, collections) {
    state.collections = [...state.collections, ...collections]
  },
  UNSHIFT_COLLECTIONS(state, collections) {
    state.collections = [...collections, ...state.collections]
  },
  SET_HITS(state, hits) {
    state.hits = hits
  },
  SET_QUERIES(state, queries) {
    state.queries = []
    queries.forEach((query) => {
      state.queries.push(query)
    })
  },
  SET_SORT(state, sort) {
    state.sort = sort
  },
  SET_ORDER(state, order) {
    state.order = order
  },
  SET_QUERY(state, query) {
    state.query = query
  },
  SET_FILTERS(state, filters) {
    Vue.set(state, 'filters', filters)
    state.activeFilters = filterHelper.getActiveFilters(filters)
  },
  APPEND_HITS(state, collectionHits) {
    state.hits = [...state.hits, ...collectionHits]
  },
  ADD_HITS(state, collectionHits) {
    state.hits = collectionHits
  },
  SET_FIRST(state, first) {
    if (first) {
      state.first = first
      localStorage.setItem('collectionsFirst', first)
    }
  },
  SET_NUMBER(state, number) {
    if (number) {
      state.number = number
    }
  },
  SET_COLLECTION_STATUS(state, status) {
    state.status = status
  },
  SET_COLLECTION(state, collection) {
    state.collection = collection
  },
  SET_COLLECTION_ID(state, collectionId) {
    state.collectionId = collectionId
  },
  SET_STATUS(state, status) {
    state.status = status
  },
  SET_FACETS(state, facets) {
    state.facets = facets
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
