/* eslint-disable no-shadow, no-param-reassign */

// initial state
const state = {
  status: '',
  DARK_MODE: true,
  MEDIA_OBJECTS_OPEN: false,
  preferences: {},
}

// getters
const getters = {
  DARK_MODE: state => state.DARK_MODE,
  MEDIA_OBJECTS_OPEN: state => state.MEDIA_OBJECTS_OPEN,
}

// actions
const actions = {
  getPreferences({ commit }) {
    commit('SET_STATUS', 'loading')
    try {
      const DARK_MODE = JSON.parse(localStorage.getItem('DARK_MODE'))
      if (DARK_MODE === null) throw state.DARK_MODE
      commit('SET_DARK_MODE', DARK_MODE)
    } catch (defaultValue) {
      commit('SET_DARK_MODE', defaultValue)
    }
    try {
      const MEDIA_OBJECTS_OPEN = JSON.parse(localStorage.getItem('MEDIA_OBJECTS_OPEN'))
      if (MEDIA_OBJECTS_OPEN === null) throw state.MEDIA_OBJECTS_OPEN
      commit('SET_MEDIA_OBJECTS_OPEN', MEDIA_OBJECTS_OPEN)
    } catch (defaultValue) {
      commit('SET_MEDIA_OBJECTS_OPEN', defaultValue)
    }
  },
  savePreferences({ commit, rootState, dispatch }) {
    commit('SET_STATUS', 'loading')
    const { assets: { filters: FILTERS = [] } = {} } = rootState
    const filters = FILTERS.map(({ field, label, open }) => ({ field, label, open }))
    localStorage.setItem('DARK_MODE', state.DARK_MODE)
    localStorage.setItem('MEDIA_OBJECTS_OPEN', state.MEDIA_OBJECTS_OPEN)
    localStorage.setItem('FILTERS', JSON.stringify(filters))
    FILTERS.forEach((filter) => {
      const { field, count = [] } = filter
      const message = count.map(({ fieldValue, checked, operator }) => ({
        fieldValue, checked, operator,
      }))
      localStorage.setItem(field, JSON.stringify(message))
    })
    commit('SET_STATUS', 'success')
    dispatch('setAlert', {
      showAlert: 3,
      messageAlert: 'Preferences saved!',
      messageVariant: 'success',
    }, { root: true })
  },
}

// mutations
const mutations = {
  SET_STATUS(state, status) {
    state.status = status
  },
  SET_DARK_MODE(state, DARK_MODE) {
    state.DARK_MODE = DARK_MODE
    const newPreferences = { ...state.preferences, DARK_MODE }
    state.preferences = newPreferences
    localStorage.setItem('preferences', JSON.stringify(newPreferences))
  },
  SET_FILTERS(state, FILTERS) {
    state.FILTERS = FILTERS
    const newPreferences = { ...state.preferences, FILTERS }
    state.preferences = newPreferences
    localStorage.setItem('preferences', JSON.stringify(newPreferences))
  },
  SET_MEDIA_OBJECTS_OPEN(state, MEDIA_OBJECTS_OPEN) {
    state.MEDIA_OBJECTS_OPEN = MEDIA_OBJECTS_OPEN
    const newPreferences = { ...state.preferences, MEDIA_OBJECTS_OPEN }
    state.preferences = newPreferences
    localStorage.setItem('preferences', JSON.stringify(newPreferences))
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
