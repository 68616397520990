import Vue from 'vue'
import VueRouter from 'vue-router'

const Items = () => import('../components/Items.vue')
// const Item = () => import('../components/Item.vue');
// const ItemABR = () => import('../components/ItemABR.vue');
const ItemSwitch = () => import('../components/ItemSwitch.vue')
const Collections = () => import('../components/Collections.vue')
const Collection = () => import('../components/Collection.vue')
const NotFoundComponent = () => import('../components/404.vue')

const Admin = () => import('../components/new/Admin.vue')
const Jobs = () => import('../components/new/Jobs.vue')
const Users = () => import('../components/new/Users.vue')
const Groups = () => import('../components/new/Groups.vue')
const Storages = () => import('../components/new/Storages.vue')
const Overview = () => import('../components/new/Overview.vue')
const OldOverview = () => import('../components/Overview.vue')
const Browse = () => import('../components/new/Browse.vue')
const Logout = () => import('../components/new/Logout.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'default',
    redirect: { name: 'Browse' },
  },
  {
    path: '/item',
    name: 'Items',
    component: Items,
  },
  {
    path: '/collection',
    name: 'Collections',
    component: Collections,
    props: true,
  },
  {
    path: '/browse',
    name: 'Browse',
    component: Browse,
  },
  {
    path: '/browse/:id',
    name: 'newCollection',
    component: Browse,
    props: true,
  },
  /**
   * Admin panel
   */
  {
    path: '/admin',
    name: 'Admin',
    component: Admin,
    redirect: { name: 'Overview' },
    children: [
      {
        path: 'overview',
        name: 'Overview',
        component: Overview,
      },
      {
        path: 'oldOverview',
        name: 'oldOverview',
        component: OldOverview,
      },
      {
        path: 'job',
        name: 'Jobs',
        component: Jobs,
      },
      {
        path: 'user',
        name: 'Users',
        component: Users,
      },
      {
        path: 'group',
        name: 'Groups',
        component: Groups,
      },
      {
        path: 'storage',
        name: 'Storages',
        component: Storages,
      },
      {
        path: 'logout',
        name: 'Logout',
        component: Logout,
      },
    ],
  },
  /**
   * Detailed Assets
   */
  {
    path: '/item/:id',
    name: 'Item',
    component: ItemSwitch,
    // component: Item,
  },
  {
    path: '/itemABR/:id',
    name: 'ItemABR',
    component: ItemSwitch,
    // component: ItemABR,
  },
  /**
   * Detailed collection
   */
  {
    path: '/collection/:id',
    name: 'Collection',
    component: Collection,
    props: true,
  },

  /**
   * Utilities
   * Always put at the bottom to avoid unwanted 404 messages
   */
  {
    path: '/*',
    name: '404',
    component: NotFoundComponent,
  },
]

export default new VueRouter({
  routes,
  mode: 'history',
})
