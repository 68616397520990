/* eslint-disable no-shadow, no-param-reassign */
import JobApi from '../../api/newJob.api'
import JobFilters from '../../components/new/Admin/JobFilters.array'

// initial state
const state = {
  job: null,
  jobs: [],
  hits: 0,
  sort: 'startTime',
  order: 'descending',
  first: 1,
  number: 40,
  status: null,
  filters: [...JobFilters],
  jobmetadata: '',
}

// getters
const getters = {
  job: state => state.job,
  jobs: state => state.jobs,
  hits: state => state.hits,
  sort: state => state.sort,
  order: state => state.order,
  first: state => state.first,
  number: state => state.number,
  status: state => state.status,
  filters: state => state.filters,
  jobmetadata: state => state.jobmetadata,
}

const getActiveFilters = (filters) => {
  let activeFilters = {}
  for (let i = 0; i < filters.length; i += 1) {
    const { count = [] } = filters[i]
    const { field = '', values = {} } = filters[i]
    const { start = null, end = null } = values
    if (count.length) {
      const checkedValues = count.filter(({ checked }) => checked)
      if (checkedValues.length) {
        activeFilters = { ...activeFilters, [field]: checkedValues.map(({ fieldValue }) => fieldValue).join(',') }
      }
    }

    if (start && end && field === 'starttime') {
      const starttimeFrom = start
      const starttimeTo = end
      activeFilters = { ...activeFilters, starttimeFrom, starttimeTo }
    }
    if (start && end && field === 'finishtime') {
      const finishtimeFrom = start
      const finishtimeTo = end
      activeFilters = { ...activeFilters, finishtimeFrom, finishtimeTo }
    }
  }
  return activeFilters
}

// actions
const actions = {
  getJobs({ commit }, {
    sort = state.sort,
    order = state.order,
    first = state.first,
    number = state.number,
    jobmetadata = state.jobmetadata,
    filters = getActiveFilters(state.filters),
  }) {
    commit('SET_STATUS', 'loading')
    let sorting
    if (sort && order) {
      if (order === 'descending') sorting = `${sort} desc`
      if (order === 'ascending') sorting = `${sort} asc`
    }
    return JobApi.getJobs({
      sort: sorting,
      order,
      first,
      number,
      jobmetadata,
      ...filters,
    }).then(({ jobs, hits }) => {
      commit('SET_JOBS', jobs)
      commit('SET_HITS', hits)
      commit('SET_STATUS', 'success')
    }).catch(() => {
      commit('SET_HITS', 0)
      commit('SET_STATUS', 'failed')
    })
  },
  getJob({ commit }, {
    jobId,
    metadata = true,
  }) {
    commit('SET_STATUS', 'loading'); return JobApi.getJob({
      jobId,
      metadata,
    }).then(({ job }) => {
      commit('SET_JOB', job)
      commit('SET_STATUS', 'success')
    }).catch(() => {
      commit('SET_JOB', null)
      commit('SET_STATUS', 'failed')
    })
  },
  getAllJobTypes({ commit }) {
    commit('SET_STATUS', 'loading')
    return JobApi.getJobTypes()
      .then((response) => {
        commit('SET_STATUS', 'success')
        return response
      })
      .catch(() => {
        commit('SET_STATUS', 'failed')
      })
  },
  setFilters({ commit }, filter = {}) {
    commit('SET_FILTERS', filter)
    commit('SET_FIRST', 1)
    return 'success'
  },
}

// mutations
const mutations = {
  SET_STATUS(state, status) {
    state.status = status
  },
  SET_JOB(state, job) {
    state.job = job
  },
  SET_JOBS(state, jobs) {
    state.jobs = jobs
  },
  SET_HITS(state, hits) {
    state.hits = hits
  },
  SET_FIRST(state, first) {
    state.first = first
  },
  SET_NUMBER(state, number) {
    state.number = number
  },
  SET_FILTERS(state, filter) {
    const {
      field, count, open, values,
    } = filter

    const index = state.filters.findIndex(({ field: f }) => f === field)
    if (count) { state.filters[index].count = count }
    if (values) { state.filters[index].values = values }
    state.filters[index].open = open
  },
  CLEAR_FILTERS(state) {
    for (let i = 0; i < state.filters.length; i += 1) {
      const { count = [], values } = state.filters[i]
      for (let j = 0; j < count.length; j += 1) {
        count[j].checked = false
      }
      if (values) { values.start = null; values.end = null }
    }
  },
  SET_SORTING(state, sorting) {
    const { sort, order } = sorting
    if (!order) {
      state.sort = null
      state.order = null
    } else {
      state.sort = sort
      state.order = order
    }
  },
  SET_JOBMETADATA(state, jobmetadata) {
    if (!jobmetadata.length) state.jobmetadata = ''; else {
      state.jobmetadata = `videoId=${jobmetadata.toLowerCase()}`
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
