<template>
  <div
    :class="{'darkMode': darkMode}"
    class="app">
    <div class="system-alert">
      <b-alert
        :show="showAlert"
        :variant="messageVariant"
        dismissible
        @dismissed="dismissAlert()">
        {{ typeof messageAlert === 'string' ? messageAlert : '' }}
        <ul v-if="Array.isArray(messageAlert)">
          <li
            v-for="(msg, index) in messageAlert"
            :key="index">
            {{ msg }}
          </li>
        </ul>
      </b-alert>
    </div>

    <main>
      <!-- Routes -> Entites goes in here -->
      <router-view v-if="authenticated"/>
      <login v-if="!authenticated"/>

      <b-modal
        v-if="authenticated"
        id="uploadModal"
        ref="uploadModal"
        v-model="uploadModalShow"
        title="Upload to Vidispine">
        <div class="modal-content__selection">
          <VdtWebupload
            :poll-interval="pollInterval"
            :tags="tags"
            :upload-passkey-url="uploadPasskeyUrl"
            :start-upload-url="startUploadUrl"
            :job-status-url="jobUrl"
            :abort-job-url="jobUrl"
            @uploadSuccess="getItems()"/>
        </div>
        <div slot="modal-footer">
          <button
            class="btn-md"
            @click="hideModal('uploadModal')">Close</button>
        </div>
      </b-modal>
    </main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { debounce as _debounce } from 'lodash-es'
// eslint-disable-next-line
import { VdtWebupload } from '@vidispine/vdt-vue-components/es';
import theNavigation from './components/theNavigation.vue'
import login from './components/new/Login.vue'
import store from './store'

export default {
  name: 'App',
  components: {
    theNavigation,
    login,
    store,
    VdtWebupload,
  },
  data() {
    return {
      uploadModalShow: false,
      uploadPasskeyUrl: '/api/upload/passkey',
      startUploadUrl: '/apinoauth/import/raw',
      jobUrl: '/api/job/',
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'authenticated',
      showAlert: 'showAlert',
      messageAlert: 'messageAlert',
      messageVariant: 'messageVariant',
      tags: 'tags',
    }),
    ...mapGetters('items/', {
      itemsQuery: 'query',
      itemsNumber: 'number',
      itemsFirst: 'first',
    }),
    ...mapGetters('preferences/', {
      darkMode: 'DARK_MODE',
    }),
    pollInterval() {
      return this.uploadModalShow ? 3000 : 10000
    },
  },
  watch: {
    authenticated(newVal, oldVal) {
      // If logged in and previously wasn't
      if (newVal && !oldVal) {
        this.dismissAlert()
      }
    },
  },
  mounted() {
    this.$store.dispatch('getUser')
    this.$store.dispatch('preferences/getPreferences')
    this.resize = _debounce(this.resize, 250)
    window.addEventListener('resize', this.resize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    resize() {
      const vh = window.innerHeight * 0.01
      this.$el.style.setProperty('--vh', `${vh}px`)
    },
    dismissAlert() {
      store.dispatch('setAlert', { showAlert: 0, messageAlert: {} })
    },
    hideModal(modalRef) {
      this.$refs[modalRef].hide()
    },
    getItems() {
      /**
       * Only refresh if on Items route and on the first page
       * to avoid weird/unexpected glitches on pages
       */
      if (this.authenticated && this.$route.name === 'Items' && this.itemsFirst < this.itemsNumber) {
        this.$store.dispatch('items/getItems', { query: this.itemsQuery, first: 1, number: this.itemsNumber })
      }
    },
  },
}

</script>

<style lang="scss">
@import "./assets/scss/base.scss";
:root {
  --primary: #0f777d;
}
.vdt-login-prompt {
  width: 40rem;
  @media (max-width: 800px) {
    width: 30rem;
  }
}
.tooltip * {
  font-size: 1.3rem !important;
}
</style>

<style lang="scss" scoped>
@import "./assets/scss/variables";

//Styles for App.vue
.app{
  // display:grid;
  // grid-template-columns: min-content auto;
  // grid-template-areas: "menu main";
  height: 100vh;
  overflow: hidden;
  // transition: grid-template-columns 0.2s ease-in;
  --font-dark: rgb(42,42,42);
  --font-semi: rgb(58,58,58);
  --font-normal: rgb(74,74,74);
  --font-light: rgb(90,90,90);
  --overlay-dark: rgb(206,206,206);
  --overlay-light: rgb(223,223,223);
  --base: rgb(239,239,239);
  --surface: rgb(255,255,255);
  --accent-color-1: rgb(61,169,252);
  --accent-color-2: rgba(61,169,252,.7);
  --accent-color-3: rgb(247,202,24);
  --accent-color-4: rgb(255,84,112);
  --accent-color-5: rgb(42,187,155);
  --color-on-accent: rgb(239,239,239);
  &.darkMode {
    --overlay-dark: rgb(42,42,42);
    --overlay-light: rgb(58,58,58);
    --base: rgb(74,74,74);
    --surface: rgb(90,90,90);
    --font-dark: rgb(206,206,206);
    --font-semi: rgb(223,223,223);
    --font-normal: rgb(239,239,239);
    --font-light: rgb(255,255,255);

  }
}

.system-alert{
  position: absolute;
  top: 1rem;
  z-index: 9999;
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;
  > * {
    pointer-events: auto;
    font-size: 1.3rem;
    > ul {
      color: inherit;
      list-style: none;
      padding: 0;
      margin: 0;
      > li {
        font-size: 1.3rem;
      }
    }
  }
}

.main-menu{
  grid-area: menu;
  padding-top: 2rem;
  padding-left: 1.5rem;
  background-color: $dark-color;
  overflow: hidden;

  h1{
    color: white;
    font-size: 3rem;
    font-weight: 900;
    margin-bottom: 2rem;
  }

  a{
    display: block;
    font-size: 1.8rem;
    color: rgba(white, 0.8);
    text-decoration: none;
    padding: 0.75rem 0;
    text-transform: capitalize;
    transition: color 0.2s;

    &:hover{
      color: white;
    }

    &.router-link-active{
      font-weight: 500;
      color: $contrast-color;
    }
  }
}

main {
  grid-area: main;
}
</style>
