/* eslint-disable no-shadow, no-param-reassign */
import collectionApi from '../../api/newCollection.api'

const state = {
  breadcrumbs: {},
  flatPath: null,
  status: null,
}

// getters
const getters = {
  breadcrumbs: state => state.breadcrumbs,
  flatPath: state => state.flatPath,
  status: state => state.status,
}

// actions
const actions = {
  getBreadcrumbs({ commit }, { collectionId }) {
    commit('SET_STATUS', 'loading')
    const flatPath = []

    function iterator(breadcrumb, id) {
      const [uid] = id.split(',')
      return collectionApi.getCollection(uid, 0, 0, ['__parent_collection', 'title', 'collectionId', 'TAPI_c_type', 'TAPI_item_type'])
        .then(async ({ collection }) => {
          const { metadata = {} } = collection
          const { collectionName } = metadata
          if (collectionName) flatPath.push(collectionName)
          let crumb = {}
          if (metadata.parent) {
            crumb.child = { ...breadcrumb, ...metadata }
            crumb = await iterator(crumb, metadata.parent)
          } else {
            const { child } = breadcrumb
            crumb = { ...metadata, child }
          }
          return crumb
        }).catch(() => {

        })
    }

    iterator({}, collectionId).then((response) => {
      const withRoot = {
        id: 'ROOT',
        type: 'ROOT',
        child: response,
        collectionName: 'EMS',
      }
      commit('SET_STATUS', 'success')
      commit('SET_BREADCRUMBS', withRoot)
      commit('SET_FLATPATH', flatPath.reverse().join('/'))
    }).catch(() => {
      commit('SET_STATUS', 'failed')
    })
  },
}

// mutations
const mutations = {
  SET_BREADCRUMBS(state, breadcrumbs) {
    state.breadcrumbs = breadcrumbs
  },
  SET_FLATPATH(state, flatPath) {
    state.flatPath = flatPath
  },
  SET_STATUS(state, status) {
    state.status = status
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
