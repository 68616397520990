import axios from 'axios'
import SearchAsset from '../vs-entities/searchAsset'
import SearchCollection from '../vs-entities/searchCollection'
import DetailedCollection from '../vs-entities/detailedCollection'

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  const str = s.toLowerCase()
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export default {
  getCollections: (first, number, fields) => {
    const data = {
      first,
      number,
      content: 'metadata,external',
      field: fields ? fields.join(',') : '',
      filters: [],
    }

    return axios.put('/api/newCollections', data).then(({ data: { hits = 0, collection = [] } }) => ({
      collections: collection.map(col => new SearchCollection(col)),
      hits,
    }))
  },
  getCollection: (id, first, number, fields) => {
    const data = {
      first,
      number,
      content: 'metadata',
      field: fields ? fields.join(',') : '',
    }

    return axios.put(`/api/newCollection/${ id }`, data)
      .then(({ data: collection }) => ({ collection: new DetailedCollection(collection) }))
  },
  getCollectionAssets: (id, first, number, fields, autocomplete, content = 'metadata') => {
    const data = {
      first,
      number,
      content,
      field: fields ? fields.join(',') : '',
      queries: [],
      filters: [],
    }

    if (autocomplete && autocomplete.length) {
      data.queries.push({
        operator: 'OR',
        queries: [{
          field: 'originalFilename',
          query: `*${ autocomplete }*`,
        }, {
          field: 'originalFilename',
          query: `*${ capitalize(autocomplete) }*`,
        }, {
          field: 'originalFilename',
          query: `*${ autocomplete.toLowerCase() }*`,
        }, {
          field: 'originalFilename',
          query: `*${ autocomplete.toUpperCase() }*`,
        }, {
          field: 'title',
          query: `*${ autocomplete }*`,
        }, {
          field: 'title',
          query: `*${ capitalize(autocomplete) }*`,
        }, {
          field: 'title',
          query: `*${ autocomplete.toLowerCase() }*`,
        }, {
          field: 'title',
          query: `*${ autocomplete.toUpperCase() }*`,
        }],
      })
    }

    data.queries.push({
      operator: 'OR',
      queries: [
        {
          field: '__parent_collection',
          query: id,
        },
        {
          field: '__collection',
          query: id,
        },
      ],
    })

    return axios.put('/api/search', data).then(({ data: response }) => {
      const { entry = [], hits = 0 } = response
      return {
        assets: entry.map(e => new SearchAsset(e)),
        hits,
      }
    })
  },
}
