/* eslint-disable no-shadow, no-param-reassign */
import userApi from '../../api/user.api'

// initial state
const state = {
  authenticated: true,
  user: {},
}

// getters
const getters = {
  authenticated: state => state.authenticated,
  user: state => state.user,
}

// actions
const actions = {
  setAuthenticated({ commit }, payload) {
    commit('AUTHENTICATED', payload.authenticated)
  },
  getUser({ commit }) {
    return userApi.getUser()
      .then((response) => {
        commit('SET_USER', response)
      })
      .catch(() => {
        commit('SET_USER', {})
      })
  },
}

// mutations
const mutations = {
  AUTHENTICATED(state, authenticated) {
    state.authenticated = authenticated
  },
  SET_USER(state, user) {
    state.user = user
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
