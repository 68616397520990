/* eslint max-classes-per-file: "off" */
export const PAL = 'PAL'
export const NTSC = 'NTSC'
export const NTSC30 = 'NTSC30'

const CONSTANT_TIMEBASES = {
  [PAL]: { denominator: 25, numerator: 1 },
  [NTSC]: { denominator: 30000, numerator: 1001 },
  [NTSC30]: { denominator: 30, numerator: 1 },
}

const FRAME_SEPARATORS = {
  ':': { dropFrame: false, field: 2 },
  ';': { dropFrame: true, field: 2 },
  '.': { dropFrame: false, field: 1 },
  ',': { dropFrame: true, field: 1 },
}

class TimeBase {
  constructor({ numerator = 1, denominator = 1 } = {}) {
    this.numerator = numerator
    this.denominator = denominator
  }

  toJSON() {
    return {
      denominator: this.denominator,
      numerator: this.numerator,
    }
  }

  toConstant() {
    let constant
    Object.entries(CONSTANT_TIMEBASES).find((thisTimeBase) => {
      const [thisTimeBaseText, thisTimeBaseType] = thisTimeBase
      const { numerator, denominator } = thisTimeBaseType
      if (numerator === this.numerator && denominator === this.denominator) {
        constant = thisTimeBaseText
        return true
      }
      return false
    })
    return constant
  }

  toText(useConstant = false) {
    if (useConstant) {
      const timeBaseText = this.toConstant()
      if (timeBaseText) return timeBaseText
    }
    if (this.numerator > 1) {
      const timeBaseText = [this.denominator, this.numerator].join(':')
      return timeBaseText
    }
    const timeBaseText = String(this.denominator)
    return timeBaseText
  }

  toRate(useConstant = false) {
    if (useConstant) {
      const rate = this.toConstant()
      if (rate) return rate
    }
    const rate = (this.denominator / this.numerator)
    return Number.isInteger(rate) ? rate : rate.toFixed(2)
  }
}

class TimeCode {
  constructor(
    { samples = 0, timeBase } = {},
    { dropFrame = false, field = 2 } = {}
  ) {
    this.samples = samples
    this.timeBase = new TimeBase(timeBase)
    this.dropFrame = dropFrame
    this.field = field
  }

  conformTimeBase(conformTo) {
    let timeBase = conformTo
    if (conformTo instanceof TimeCode === false) {
      timeBase = new TimeBase(conformTo)
    }
    const samples = this.samples / (this.timeBase.toRate() / timeBase.toRate())
    const timeCode = { samples, timeBase }
    return new TimeCode(timeCode)
  }

  toJSON() {
    return {
      samples: this.samples,
      timeBase: this.timeBase,
    }
  }

  toText() {
    let timeCodeText = String(this.samples)
    const timeBaseText = this.timeBase.toText()
    if (timeBaseText !== '1') {
      timeCodeText = [this.samples, timeBaseText].join('@')
    }
    return timeCodeText
  }

  toSeconds() {
    const { numerator, denominator } = this.timeBase
    return this.samples * (numerator / denominator)
  }

  toTime() {
    const { numerator, denominator } = this.timeBase
    const totalSeconds = this.toSeconds()
    const totalWholeSeconds = Math.floor(totalSeconds)
    let frames = this.samples
    let partialSeconds = 0
    if (totalWholeSeconds) {
      partialSeconds = totalSeconds - totalWholeSeconds
      frames = Math.floor((partialSeconds * (denominator / numerator)))
    }
    const hours = Math.floor(((totalWholeSeconds / 60 / 60) % 60))
    const minutes = Math.floor(((totalWholeSeconds / 60) % 60))
    const seconds = Math.floor((totalWholeSeconds % 60))
    return {
      hours,
      minutes,
      seconds,
      frames,
      partialSeconds,
    }
  }

  toDuration({ format } = {}) {
    const {
      hours,
      minutes,
      seconds,
    } = this.toTime()
    if (typeof format === 'string') {
      if (format.toLowerCase() === 'hhmmss') {
        return [
          hours.toFixed().padStart(2, '0'),
          minutes.toFixed().padStart(2, '0'),
          seconds.toFixed().padStart(2, '0'),
        ].join(':')
      }
      if (format.toLowerCase() === 'ddhhmmss') {
        const days = Math.floor(hours / 24)
        const remainingHours = hours % 24
        return [
          days.toFixed().padStart(2, '0'),
          remainingHours.toFixed().padStart(2, '0'),
          minutes.toFixed().padStart(2, '0'),
          seconds.toFixed().padStart(2, '0'),
        ].join(':')
      }
    }
    if (hours) {
      return [
        hours.toFixed(),
        minutes.toFixed().padStart(2, '0'),
        seconds.toFixed().padStart(2, '0'),
      ].join(':')
    }
    if (minutes >= 10) {
      return [
        minutes.toFixed().padStart(2, '0'),
        seconds.toFixed().padStart(2, '0'),
      ].join(':')
    }
    return [
      minutes.toFixed(),
      seconds.toFixed().padStart(2, '0'),
    ].join(':')
  }

  toSmpte() {
    const {
      hours,
      minutes,
      seconds,
      frames: unDroppedFrames,
    } = this.toTime()
    let frames = unDroppedFrames
    if (this.dropFrame && frames < 2 && seconds === 0 && minutes % 10 !== 0) {
      frames = 2
    }
    const hhmmss = [
      hours.toFixed().padStart(2, '0'),
      minutes.toFixed().padStart(2, '0'),
      seconds.toFixed().padStart(2, '0'),
    ].join(':')
    let frameSeparator = ':'
    Object.entries(FRAME_SEPARATORS).find((thisSeparator) => {
      const [thisSeparatorText, { dropFrame, field }] = thisSeparator
      if (dropFrame === this.dropFrame && field === this.field) {
        frameSeparator = thisSeparatorText
        return true
      }
      return false
    })
    return [
      hhmmss,
      frames.toFixed().padStart(2, '0'),
    ].join(frameSeparator)
  }
}

const formatTimeBaseType = timeBase => new TimeBase(timeBase)

const formatTimeBaseText = (timeBaseText) => {
  if (timeBaseText === undefined) {
    return formatTimeBaseType()
  }
  if (typeof timeBaseText === 'number') {
    return formatTimeBaseType({ denominator: timeBaseText })
  }
  if (timeBaseText.includes(':')) {
    const [denominator, numerator] = timeBaseText.split(':')
    return formatTimeBaseType({ denominator, numerator })
  }
  if (Object.keys(CONSTANT_TIMEBASES).includes(timeBaseText)) {
    return formatTimeBaseType(CONSTANT_TIMEBASES[timeBaseText])
  }
  const denominator = Number(timeBaseText)
  if (Number.isNaN(timeBaseText)) {
    throw new Error(`timeBaseText must be a number or PAL,NTSC,NTSC30 - is ${timeBaseText}`)
  }
  return formatTimeBaseType({ denominator })
}

const formatTimeBase = (timeBase) => {
  if (typeof (timeBase) === 'object') {
    return formatTimeBaseType(timeBase)
  }
  return formatTimeBaseText(timeBase)
}

const formatTimeCodeType = (timeCode, options) => new TimeCode(timeCode, options)

const formatTimeCodeText = (timeCodeText, options) => {
  if (timeCodeText === undefined) {
    const timeCode = { samples: 0 }
    return formatTimeCodeType(timeCode, options)
  }
  if (typeof timeCodeText === 'number') {
    const timeCode = { samples: timeCodeText }
    return formatTimeCodeType(timeCode, options)
  }
  if (timeCodeText.includes('@')) {
    const [samplesString, timeBaseText] = timeCodeText.split('@')
    const samples = Number(samplesString)
    const timeBase = formatTimeBaseText(timeBaseText)
    const timeCode = { samples, timeBase }
    return formatTimeCodeType(timeCode, options)
  }
  if (timeCodeText === '-INF') {
    const samples = -Infinity
    const timeCode = { samples }
    return formatTimeCodeType(timeCode, options)
  }
  if (timeCodeText === '+INF') {
    const samples = Infinity
    const timeCode = { samples }
    return formatTimeCodeType(timeCode, options)
  }
  const samples = Number(timeCodeText)
  if (Number.isNaN(samples)) {
    throw new Error(`timeBaseText must be a number or sample@timeBase - is ${timeCodeText}`)
  }
  const timeCode = { samples }
  return formatTimeCodeType(timeCode, options)
}

const formatSeconds = (
  seconds,
  timeBase = {},
  options
) => {
  const { denominator = 1, numerator = 1 } = timeBase
  const samples = (seconds * (denominator / numerator))
  const timeCode = { samples, timeBase }
  return new TimeCode(timeCode, options)
}

const formatSecondsPrecise = (
  seconds,
  timeBase = {},
  options
) => {
  const { numerator = 1 } = timeBase
  let { denominator = 1 } = timeBase
  if (!Number.isInteger(seconds)) {
    const decimalPlaces = String(seconds).split('.')[1].length
    denominator *= (10 ** decimalPlaces)
  }
  const samples = (seconds * (denominator / numerator)).toFixed()
  const timeCode = { samples, timeBase: { denominator, numerator } }
  return new TimeCode(timeCode, options)
}

const formatSmpte = (smpteText, timeBaseText, options = {}) => {
  if (smpteText === undefined) {
    const timeBase = formatTimeBase(timeBaseText)
    const timeCode = { samples: 0, timeBase }
    return formatTimeCodeType(timeCode)
  }
  if (typeof (smpteText) !== 'string') {
    throw new Error(`smpteText must be a string, is ${smpteText}`)
  }
  let hours; let minutes; let seconds; let frames
  let frameOptions = {}
  const hasFrameSeparator = smpteText.match(/[^0-9:\-_]/)
  if (hasFrameSeparator) {
    const [frameSeparator] = hasFrameSeparator
    const [hhmmss, splitFrames] = smpteText.split(frameSeparator)
    frames = splitFrames;
    [hours, minutes, seconds] = hhmmss.split(':')
    if (Object.keys(FRAME_SEPARATORS).includes(frameSeparator)) {
      frameOptions = FRAME_SEPARATORS[frameSeparator]
    }
  } else {
    [hours = 0, minutes = 0, seconds = 0, frames = 0] = smpteText.split(':')
  }
  const timeBase = formatTimeBase(timeBaseText)
  const { denominator, numerator } = timeBase
  const totalSeconds = (Number(hours) * 3600) + (Number(minutes) * 60) + Number(seconds)
  const samples = (totalSeconds * (denominator / numerator)) + Number(frames)
  const timeCode = { samples, timeBase }
  return formatTimeCodeType(timeCode, { ...frameOptions, ...options })
}

export {
  formatSeconds,
  formatSecondsPrecise,
  formatTimeBaseType,
  formatTimeBaseText,
  formatTimeCodeType,
  formatTimeCodeText,
  formatTimeBase,
  formatSmpte,
}
