import Vue from 'vue'
import Vuex from 'vuex'
import assets from './modules/assets'
import items from './modules/items'
import collections from './modules/collections'
import jobs from './modules/jobs'
import storages from './modules/storages'
import users from './modules/users'
import groups from './modules/groups'
import libraries from './modules/libraries'
import shapes from './modules/shapes'
import files from './modules/files'
import resources from './modules/resources'
import auth from './modules/auth'
import breadcrumb from './modules/breadcrumb'
import preferences from './modules/preferences'
import system from './modules/system'
import alert from './modules/alert'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    assets,
    items,
    collections,
    jobs,
    storages,
    users,
    groups,
    libraries,
    shapes,
    files,
    resources,
    auth,
    breadcrumb,
    preferences,
    system,
    alert,
  },
  strict: debug,
})
