/* eslint-disable no-shadow, no-param-reassign */
import shapeApi from '../../api/shape.api'

// initial state
const state = {
  tags: [],
  shapeTagStatus: null, // success, failed, loading
}

// getters
const getters = {
  tags: state => state.tags,
  shapeTagStatus: state => state.shapeTagStatus,
}

// actions
const actions = {
  getShapeTags({ commit }) {
    commit('SET_SHAPE_TAG_STATUS', 'loading')
    shapeApi.getShapeTags()
      .then((response) => {
        const uris = response
        commit('ADD_SHAPE_TAGS', uris)
        commit('SET_SHAPE_TAG_STATUS', 'success')
      })
      .catch(() => commit('SET_SHAPE_TAG_STATUS', 'failed'))
  },
}

// mutations
const mutations = {
  ADD_SHAPE_TAGS(state, tags) {
    state.tags = tags
  },

  SET_SHAPE_TAG_STATUS(state, { status }) {
    state.shapeTagStatus = status
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
