import { unset as _unset } from 'lodash-es'
import Item from './item'
import Formater from '../resources/formater'

export default class SearchItem extends Item {
  constructor(item) {
    super()
    if (item instanceof SearchItem) {
      const searchKeys = Object.keys(item)
      for (let i = 0; i < searchKeys.length; i += 1) {
        this[searchKeys[i]] = item[searchKeys[i]]
      }
    } else {
      const flatTimespan = Item.getTimespan(item.metadata, '-INF', '+INF')
      this.keyList = {
        itemId: 'id',
        title: 'title',
        created: 'created',
        originalVideoCodec: 'originalVideoCodec',
        originalFilename: 'filename',
        mediaType: 'mediaType',
        user: 'user',
        representativeThumbnailNoAuth: 'representativeThumbnail',
        durationSeconds: 'duration',
        master_item: 'masterItem',
        __collection: 'collectionId',
        TAPI_item_metadata: 'TAPI',
        org_shape_total_size_bytes: 'originalSize',
        master_status: 'masterStatus',
        latest_tapi_status: 'tapiStatus',
        abr_status: 'abrStatus',
        sp_item: 'spItem',
        collection_title: 'collectionTitle',
        TAPI_c_type: 'classification',
        TAPI_item_type: 'type',
        TAPI_c_priority_level: 'tapiPriority',
        ems_lastEditDate: 'lastEditDate',
        mxdm_delete_lock: 'permanent',
      }

      this.metadata = Object.assign({}, Item.getFields(flatTimespan, this.keyList))

      // format APInoatuh -> apinoauth
      if (this.metadata.representativeThumbnail) {
        const end = this.metadata.representativeThumbnail
          .slice(11, this.metadata.representativeThumbnail.length)
        this.thumbnail = `/apinoauth/${end}`
      } else {
        this.thumbnail = ''
      }
      if (this.metadata.duration !== undefined) {
        this.metadata.minutes = Formater.formatMinutes(this.metadata.duration)
      }
      if (this.metadata.TAPI !== undefined) {
        const temp = JSON.parse(this.metadata.TAPI)
        if (temp.a_fileSize || temp.v_fileSize) {
          this.metadata.size =
          Formater.formatSize(Number(temp.a_fileSize || '0') + Number(temp.v_fileSize || '0'))
        }
      }
      _unset(this.metadata, 'TAPI')
    }
  }
}
