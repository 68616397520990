import Collection from './collection'

export default class SearchCollection extends Collection {
  constructor(collection) {
    super()
    if (collection instanceof SearchCollection) {
      const searchKeys = Object.keys(collection)
      for (let i = 0; i < searchKeys.length; i += 1) {
        this[searchKeys[i]] = collection[searchKeys[i]]
      }
    } else {
      const flatTimespan = Collection.getTimespan(collection.metadata, '-INF', '+INF')
      const keyList = {
        mxdm_delete_lock: 'mxdm_delete_lock',
        TAPI_c_title: 'TAPI_c_title',
        TAPI_c_production_year: 'TAPI_c_production_year',
        TAPI_c_suported_partner: 'TAPI_c_suported_partner',
        TAPI_c_license_start: 'TAPI_c_license_start',
        TAPI_c_powerMAM_id: 'TAPI_c_powerMAM_id',
        TAPI_c_cumulus_id: 'TAPI_c_cumulus_id',
        TAPI_c_maxdome_id: 'TAPI_c_maxdome_id',
        TAPI_c_tenant_id: 'TAPI_c_tenant_id',
        collectionId: 'id',
        TAPI_c_video_id: 'videoId',
        TAPI_c_type: 'type',
        TAPI_item_type: 'type',
        title: 'collectionName',
        created: 'created',
        user: 'user',
        master_status: 'masterStatus',
        latest_tapi_status: 'tapiStatus',
        abr_status: 'abrStatus',
        sp_item: 'spItem',
        collection_comment: 'collection_comment',
      }

      this.metadata = Object.assign({}, Collection.getFields(flatTimespan, keyList))
      this.thumbnail = '../../static/folder.svg'
      const { masterStatus = '', tapiStatus = '', abrStatus = '' } = this.metadata
      if ([masterStatus, tapiStatus, abrStatus].some(status => status.includes('error'))) {
        this.metadata.status = 'ERROR'
      } else {
        this.metadata.status = 'OK'
      }

      if (this.metadata.TAPI) {
      // If we have a metadata value for TAPI_item_metadata
      // parse TAPI from a JSON string to a javascript object
      // and set it on ourselves
        this.metadata.TAPI = JSON.parse(this.metadata.TAPI)
      }

      if(this.metadata.collection_comment) {
        const commentField = flatTimespan.field.filter(field => field.name === 'collection_comment').pop()
        this.metadata.comment = {
          value: this.metadata.collection_comment,
          updatedBy: commentField.user,
          updatedDate: commentField.timestamp,
        }
      }
      this.type = 'Collection'
      this.metadata.locked = this.metadata.mxdm_delete_lock === 'true'
    }
  }
}
