import axios from 'axios'

export default {
  getUser: (userName = '') => axios.get(`/api/user${ userName ? `/${ userName }` : '' }`).then(({ data }) => data),

  getUsers: (query, first, number) => {
    const data = {
      query,
      first,
      number,
    }

    return axios.put('/api/users', data)
      .then(response => ({
        users: response.data.user !== undefined
          ? response.data.user : [],
        hits: response.data.hits,
      }))
  },

  createUser: data => axios.post('/api/user', data)
    .then(response => ({
      user: response.data.user !== undefined
        ? response.data.user : [],
    })),

  deleteUser: data => axios.post('/api/user/manage', data),

  addUsersToGroups: data => axios.put('/api/add_users_to_groups', data)
    .then(response => ({
      users: response.data.user !== undefined
        ? response.data.user : [],
      hits: response.data.hits,
    })),

  deleteUserFromGroup: data => axios.delete(`/api/user/${ data.user.userName }/group/${ data.group.groupName }`)
    .then(response => ({
      users: response.data.user !== undefined
        ? response.data.user : [],
      hits: response.data.hits,
    })),

  addAssets: (userName, assets, permission) => {
    const data = {
      assets,
      permission,
    }
    return axios.put(`/api/user/${ userName }/add_assets`, data)
      .then(response => ({
        users: response.data.user !== undefined
          ? response.data.user : [],
      }))
  },
  getUsersAutocomplete: (query) => {
    const data = {
      query,
      first: 1,
      number: 5,
    }

    return axios.put('/api/users', data)
      .then(response => ({
        autocomplete: response.data !== undefined
          ? response.data : [],
      }))
  },
}
