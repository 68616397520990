/* eslint-disable max-len */
import parseFileSize from 'filesize'
import { formatTimeBaseText, formatTimeCodeType } from './time'

const gcd = (a, b) => ((b) ? gcd(b, a % b) : a)

const parseAspectRatio = ({ width, height }) => {
  const denominator = gcd(width, height)
  const widthRatio = width / denominator
  const heightRatio = height / denominator
  return [widthRatio, heightRatio].join(':')
}

const parseKeyValuePairType = (keyValuePairType = []) => {
  const keyValuePairTypeReducer = (a, { key, value }) => ({ ...a, [key]: value })
  return keyValuePairType.reduce(keyValuePairTypeReducer, {})
}

const parseBaseMediaInfoType = (baseMediaInfoType = {}) => {
  const { property: keyValuePairType, ...props } = baseMediaInfoType
  const parsedKeyValuePairType = parseKeyValuePairType(keyValuePairType)
  return { ...parsedKeyValuePairType, ...props }
}

const parseBaseMetadata = (baseMetadata = []) => {
  const parsedKeyValuePairType = parseKeyValuePairType(baseMetadata)
  return parsedKeyValuePairType
}

const parseFileType = (fileType) => {
  const {
    uri: uriList = [],
    size: fileSizeBytes,
    hash,
    path,
  } = fileType
  const [uri] = uriList
  const fileSize = fileSizeBytes !== undefined ? parseFileSize(fileSizeBytes) : ''
  const fileName = path ? path.split('/').pop() : undefined
  return {
    uri,
    hash,
    fileSize,
    fileSizeBytes,
    path,
    fileName,
  }
}

const parseContainerComponent = (containerComponent, shapeType) => {
  const {
    mediaInfo: baseMediaInfoType = {},
    file: fileList,
    duration: durationTimeCode,
    metadata: baseMetadata,
  } = containerComponent
  const mediaInfo = parseBaseMediaInfoType(baseMediaInfoType)
  const metadata = parseBaseMetadata(baseMetadata)
  let {
    Format: containerFormat,
  } = mediaInfo
  const {
    Video_Format_List: videoFormat,
    Audio_Format_List: audioFormat,
    Text_Format_List: textFormat,
  } = mediaInfo
  if (containerFormat === undefined) {
    const { format } = containerComponent
    containerFormat = format
    if (format && format.includes(',')) {
      const formatList = format.split(',');
      [containerFormat] = formatList
      const { mimeType: mimeTypeList = [] } = shapeType
      const [mimeType] = mimeTypeList
      if (mimeType) {
        const [, mimeTypeFormat] = mimeType.split('/')
        if (mimeTypeFormat && formatList.includes(mimeTypeFormat)) {
          containerFormat = mimeTypeFormat
        }
      }
    }
  }
  let parsedContainerComponent = {
    containerFormat,
    videoFormat,
    audioFormat,
    textFormat,
  }
  if (durationTimeCode !== undefined && durationTimeCode.samples !== 0) {
    parsedContainerComponent.duration = formatTimeCodeType(durationTimeCode).toDuration()
  }
  if (fileList) {
    const [fileType] = fileList
    const parsedFileType = parseFileType(fileType)
    parsedContainerComponent = {
      ...parsedContainerComponent,
      ...parsedFileType,
      ...metadata,
    }
  }
  return parsedContainerComponent
}

const parseVideoComponent = (videoComponent) => {
  const {
    codec: videoCodec,
    bitrate,
    fieldOrder,
    resolution = {},
    mediaInfo: baseMediaInfoType = {},
    metadata: baseMetadata = [],
  } = videoComponent
  const mediaInfo = parseBaseMediaInfoType(baseMediaInfoType)
  const metadata = parseBaseMetadata(baseMetadata)
  const {
    Format: videoFormat,
    'Frame rate': timeBaseText,
    'Color space': colorSpace,
    'Color primaries': colorPrimaries,
    Colorimetry: chromaSubsampling,
  } = mediaInfo
  let timeBase
  const { averageFrameRate, realBaseFrameRate } = videoComponent
  if (realBaseFrameRate !== undefined) {
    timeBase = {
      numerator: realBaseFrameRate.denominator,
      denominator: realBaseFrameRate.numerator,
    }
  } else if (timeBaseText !== undefined) {
    timeBase = formatTimeBaseText(timeBaseText)
  } else if (averageFrameRate !== undefined) {
    timeBase = {
      numerator: averageFrameRate.denominator,
      denominator: averageFrameRate.numerator,
    }
  }
  let { 'Frame count': samples } = mediaInfo
  if (samples === undefined) {
    const { numberOfPackets } = videoComponent
    if (numberOfPackets !== undefined) samples = numberOfPackets
  }
  let timeCode
  let frameRate
  let smpte
  if (samples && timeBase) {
    timeCode = formatTimeCodeType({ samples, timeBase })
    frameRate = timeCode.timeBase.toText(false)
    smpte = timeCode.toSmpte(true)
  }
  const { height, width } = resolution
  const aspectRatio = parseAspectRatio(resolution)
  const dimension = `${width}x${height}`
  const videoBitrate = bitrate ? parseFileSize(bitrate, { bits: true }) : undefined
  const { v2_ingest_original_filename: filename } = metadata
  return {
    type: 'video',
    filename,
    videoFormat,
    dimension,
    frameRate,
    height,
    width,
    timeBase,
    timeCode,
    videoCodec,
    smpte,
    videoBitrate,
    fieldOrder,
    colorSpace,
    chromaSubsampling,
    aspectRatio,
    colorPrimaries,
    ...metadata,
  }
}


const parseAudioComponent = (audioComponent) => {
  const {
    channelCount: audioChannels,
    codec: audioCodec,
    mediaInfo: baseMediaInfoType = {},
    bitrate,
    metadata: baseMetadata,
  } = audioComponent
  const mediaInfo = parseBaseMediaInfoType(baseMediaInfoType)
  const metadata = parseBaseMetadata(baseMetadata)
  const {
    Format: audioFormat,
    Resolution: audioBitDepth,
    Bit_rate_mode: audioBitRateMode,
    'Sampling rate': sampleRate,
  } = mediaInfo
  const audioSamplerate = sampleRate ? parseFileSize(sampleRate, { base: 10, round: 1, symbols: { kB: 'kHz', B: 'Hz', mB: 'mHz' } }) : undefined
  const audioBitrate = bitrate ? parseFileSize(bitrate, { base: 10, round: 2, symbols: { kB: 'kbps', B: 'bps', MB: 'mbps' } }) : undefined
  const { v2_ingest_original_filename: filename } = metadata
  return {
    type: 'audio',
    filename,
    audioFormat,
    audioCodec,
    audioSamplerate,
    audioBitDepth,
    audioBitrate,
    audioBitRateMode,
    audioChannels,
    ...metadata,
  }
}

const parseBinaryComponent = (binaryComponent) => {
  const {
    metadata: baseMetadata,
  } = binaryComponent
  const metadata = parseBaseMetadata(baseMetadata)
  const {
    v2_ingest_original_filename: filename, language, fileType, v2_status: v2Status,
  } = metadata

  const fileUri = binaryComponent.file[0].uri[0]

  return {
    type: 'subtitle',
    filename,
    language,
    fileType,
    v2Status,
    fileUri,
    downloadLink: {
      link: fileUri.replace(/^https(.*)APInoAuth/gi, '/apinoauth'),
      fileName: filename,
    },
    ...metadata,
  }
}

const parseShape = (shapeType = {}, {
  splitComponents = true,
  multipleComponents = true,
}) => {
  let parsedShape = {}
  const {
    containerComponent,
    videoComponent: videoComponentList,
    audioComponent: audioComponentList,
    binaryComponent: binaryComponentList,
    mimeType: mimeTypeList,
    tag: tagList,
  } = shapeType
  if (tagList) parsedShape.tag = tagList.join(',')
  if (mimeTypeList) parsedShape.mimeType = mimeTypeList.join(',')
  if (containerComponent) {
    const parsedContainerComponent = parseContainerComponent(containerComponent, shapeType)
    if (splitComponents) {
      parsedShape.container = parsedContainerComponent
    } else {
      parsedShape = {
        ...parsedShape,
        ...parsedContainerComponent,
      }
    }
  }
  if (videoComponentList) {
    const parsedVideoComponent = videoComponentList.map(comp => parseVideoComponent(comp, shapeType))
    const [parsedComponent] = parsedVideoComponent
    if (splitComponents) {
      if (multipleComponents && parsedVideoComponent.length > 1) {
        parsedShape.video = parsedVideoComponent
      } else {
        parsedShape.video = parsedComponent
      }
    } else {
      parsedShape = {
        ...parsedShape,
        ...parsedComponent,
      }
    }
  }
  if (audioComponentList) {
    const parsedAudioComponent = audioComponentList.map(comp => parseAudioComponent(comp, shapeType))
    const [parsedComponent] = parsedAudioComponent
    if (splitComponents) {
      if (multipleComponents && parsedAudioComponent.length > 1) {
        parsedShape.audio = parsedAudioComponent
      } else {
        parsedShape.audio = parsedComponent
      }
    } else {
      parsedShape = {
        ...parsedShape,
        ...parsedComponent,
      }
    }
  }
  if (binaryComponentList) {
    const parsedBinaryComponent = binaryComponentList.map(comp => parseBinaryComponent(comp, shapeType))
    const [parsedComponent] = parsedBinaryComponent
    if (splitComponents) {
      if (multipleComponents && parsedBinaryComponent.length > 1) {
        parsedShape.binary = parsedBinaryComponent
      } else {
        parsedShape.binary = parsedComponent
      }
    } else {
      parsedShape = {
        ...parsedShape,
        ...parsedComponent,
      }
    }
  }
  return parsedShape
}

export {
  parseAspectRatio,
  parseKeyValuePairType,
  parseBaseMediaInfoType,
  parseFileType,
  parseContainerComponent,
  parseVideoComponent,
  parseAudioComponent,
  parseBinaryComponent,
  parseShape,
}
