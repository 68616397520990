function injectStyle (context) {
  require("!!../../node_modules/mini-css-extract-plugin/dist/loader.js!css-loader?{\"autoprefixer\":false,\"sourceMap\":false,\"minimize\":true}!../../node_modules/vue-loader/lib/style-compiler/index?{\"optionsId\":\"0\",\"vue\":true,\"id\":\"data-v-932ebfbc\",\"scoped\":true,\"sourceMap\":false}!sass-loader?{\"sourceMap\":false}!../../node_modules/vue-loader/lib/selector?type=styles&index=0!./theNavigation.vue")
}
/* script */
export * from "!!../../node_modules/babel-loader/lib/index.js?{\"babelrc\":false,\"presets\":[[\"/opt/vidispine-content-viewer/node_modules/babel-preset-poi/index.js\",{\"jsx\":\"react\"}]],\"cacheDirectory\":true}!../../node_modules/vue-loader/lib/selector?type=script&index=0!./theNavigation.vue"
import __vue_script__ from "!!../../node_modules/babel-loader/lib/index.js?{\"babelrc\":false,\"presets\":[[\"/opt/vidispine-content-viewer/node_modules/babel-preset-poi/index.js\",{\"jsx\":\"react\"}]],\"cacheDirectory\":true}!../../node_modules/vue-loader/lib/selector?type=script&index=0!./theNavigation.vue"
/* template */
import {render as __vue_render__, staticRenderFns as __vue_static_render_fns__} from "!!../../node_modules/vue-loader/lib/template-compiler/index?{\"id\":\"data-v-932ebfbc\",\"hasScoped\":true,\"optionsId\":\"0\",\"buble\":{\"transforms\":{}}}!../../node_modules/vue-loader/lib/selector?type=template&index=0!./theNavigation.vue"
/* template functional */
var __vue_template_functional__ = false
/* styles */
var __vue_styles__ = injectStyle
/* scopeId */
var __vue_scopeId__ = "data-v-932ebfbc"
/* moduleIdentifier (server only) */
var __vue_module_identifier__ = null
import normalizeComponent from "!../../node_modules/vue-loader/lib/runtime/component-normalizer"
var Component = normalizeComponent(
  __vue_script__,
  __vue_render__,
  __vue_static_render_fns__,
  __vue_template_functional__,
  __vue_styles__,
  __vue_scopeId__,
  __vue_module_identifier__
)

export default Component.exports
